@import "../../../../../styles/variablesstyles.scss";

.ds-ctm-txt {
  font-weight: 700;
  color: $theme-color-gray2;
}
.btn-bg-gray3 {
  background: $theme-color-black3 !important;
}
.custom-pass-space {
  margin-bottom: 32px !important;
}
.custom-confirm-pass-space1 {
  margin-bottom: 24px !important;
}

@media (min-width: 360px) and (max-width: 576px) {
  .modal-footer.social-mobile-modal-footer {
    padding: 0;
  }

  .custom-pass-space {
    margin-bottom: 8px !important;
  }

  .custom-confirm-pass-space1 {
    margin-bottom: 8px !important;
    overflow: auto;
    max-height: 300px;
    padding: 0px !important;
  }

  button.btn-wid.social-link-modal-btns.custom_btn_small {
    margin: 0px 0px 16px 0px;
    width: 100%;
  }

  .modal-footer.social-mobile-modal-footer {
    gap: 16px;
  }
}

@media (min-width: 320px) and (max-width: 360px) {
  .custom-pass-space {
    margin-bottom: 8px !important;
  }
  .custom-confirm-pass-space1 {
    margin-bottom: 8px !important;
    overflow: auto;
    max-height: 300px;
    padding: 0px !important;
  }
  .custom-pass-space {
    margin-bottom: 8px !important;
  }
  .modal-footer.social-mobile-modal-footer {
    padding: 0;
  }
  button.btn-wid.social-link-modal-btns.custom_btn_small {
    margin: 0px 0px 16px 0px;
    width: 100%;
  }

  .modal-footer.social-mobile-modal-footer {
    gap: 16px;
  }
}
.custom-confirm-pass-space1 {
  display: grid;
  grid-template-columns: repeat(2, 1fr); // 2 fields per row
  gap: 16px; // Space between rows and columns
  .custom_pass_container {
    width: 91%;
  }
  .input-group {
    display: flex;
    align-items: center;

    .index-label {
      margin-right: 8px; // Space between the index number and input
      color: #bcbcbc;
    }

    .phrase-input {
      flex-grow: 1; // Ensures input takes the remaining space
    }
    input[type="password"].phrase-input {
      font-family: "Verdana" !important;
      font-size: 14px !important;
      color: #bcbcbc !important;
      font-weight: 400;
    }
  }
}

@media (max-width: 768px) {
  .custom-confirm-pass-space1 {
    grid-template-columns: repeat(
      1,
      1fr
    ); // Switch to 1 column on smaller screens
  }
}
