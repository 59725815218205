$heading-font-size: 3rem;
$sub-heading-font-size: 2rem;
$sub-sub-heading-font-size: 1.5rem;
$text-fonr-size: 1rem;
$theme-pink-color: rgba(169, 16, 121, 1);
$card-background-color: rgba(255, 255, 255, 0.1);
$input-background-color: rgba(255, 255, 255, 0.05);
$input-placeholder-color: rgba(188, 188, 188, 1);
$theme-yellow-color: rgba(255, 245, 0, 1);
$theme-color-primary: rgba(0, 0, 0, 1);
$theme-color-secondary: rgba(169, 16, 121, 1);
$theme-color-info: rgba(55, 48, 236, 1);
$theme-color-success: rgba(39, 174, 96, 1);
$theme-color-warning: rgba(226, 185, 59, 1);
$theme-color-error: rgba(235, 87, 87, 1);
$theme-color-black1: rgba(0, 0, 0, 1);
$theme-color-black2: rgba(29, 29, 29, 1);
$theme-color-black3: rgba(40, 40, 40, 1);
$theme-color-white: rgba(255, 255, 255, 1);
$theme-color-black4: rgba(15, 15, 15, 1);
$theme-color-gray1: rgba(51, 51, 51, 1);
$theme-color-gray2: rgba(79, 79, 79, 1);
$theme-color-gray3: rgba(130, 130, 130, 1);
$theme-color-gray4: rgba(188, 188, 188, 1);
$theme-color-gray5: rgba(224, 224, 224, 1);
$theme-color-footer: rgba(177, 177, 177, 1);


/** $theme-heading-0-size:clamp(3rem, 1rem + 3.125vw, 6rem);
 $theme-heading-0-lh:clamp(3.5rem, 1.4583rem + 3.1901vw, 6.5625rem);
 $theme-heading-1-size: clamp(2rem, 1rem + 1.5625vw, 3.5rem);
 $theme-heading-1-lh:clamp(2.125rem, 0.975rem + 1.7969vw, 3.85rem);
 $theme-heading-2-size: clamp(2rem, 1.3333rem + 1.0417vw, 3rem);
 $theme-heading-2-lh:clamp(1.75rem, 0.7167rem + 1.6146vw, 3.3rem);
 $theme-heading-3-size:clamp(1.5rem, 0.8333rem + 1.0417vw, 2.5rem);
 $theme-heading-3-lh: clamp(1.5rem, 0.6667rem + 1.3021vw, 2.75rem);
 $theme-heading-4-size: 32px;
 $theme-heading-4-lh: 35.2px;
 $theme-heading-5-size: 24px;
 $theme-heading-5-lh: 26.4px;
 $theme-heading-6-size: 20px;
 $theme-heading-6-lh: 22px; **/

$theme-heading-0-size: clamp(3rem, 1rem + 3.125vw, 6rem);
$theme-heading-0-lh: clamp(3.5rem, 1.4583rem + 3.1901vw, 6.5625rem);
$theme-heading-1-size: clamp(2rem, 1rem + 1.5625vw, 3.5rem);
$theme-heading-1-lh: clamp(2.125rem, 0.975rem + 1.7969vw, 3.85rem);
$theme-heading-2-size: clamp(2rem, 1.3333rem + 1.0417vw, 3rem);
$theme-heading-2-lh: clamp(1.75rem, 0.7167rem + 1.6146vw, 3.3rem);
$theme-heading-3-size: clamp(1.5rem, 0.8333rem + 1.0417vw, 2.5rem);
$theme-heading-3-lh: clamp(1.5rem, 0.6667rem + 1.3021vw, 2.75rem);
$theme-heading-4-size: clamp(2rem, 1rem + 0.833vw, 2rem); 
$theme-heading-4-lh: clamp(2.2rem, 1rem + 0.916vw, 2.2rem); 
$theme-heading-5-size: clamp(1.5rem, 0.75rem + 0.625vw, 1.5rem); 
$theme-heading-5-lh: clamp(1.65rem, 0.8rem + 0.7vw, 1.65rem);
$theme-heading-6-size: clamp(1.25rem, 0.625rem + 0.417vw, 1.25rem);
$theme-heading-6-lh: clamp(1.375rem, 0.7rem + 0.458vw, 1.375rem); 


$theme-lg-fs: 20px;
$theme-lg-lh: 28px;
$theme-md-fs: 18px;
$theme-md-lh: 25.2px;
$theme-sm-fs: 16px;
$theme-sm-lh: 22.4px;
$theme-xsm-fs: 14px;
$theme-xsm-lh: 19.6px;

$theme-spacing1:  0.5rem;
$theme-spacing2: 1rem;
$theme-spacing3:  1.5rem;
$theme-spacing4: 2rem;
$theme-spacing5: clamp(1.25rem, 0.4167rem + 1.3021vw, 2.5rem);
$theme-spacing6: clamp(1.75rem, 0.5833rem + 1.8229vw, 3.5rem);
$theme-spacing7: clamp(2.25rem, 0.75rem + 2.3438vw, 4.5rem);
$theme-spacing8: clamp(2.5rem, 0.8333rem + 2.6042vw, 5rem);
$theme-spacing9: clamp(3rem, 1rem + 3.125vw, 6rem);
$theme-spacing10:clamp(3.75rem, 1.25rem + 3.9063vw, 7.5rem);
$theme-spacing11:clamp(2rem, 0.6667rem + 2.0833vw, 4rem);


$input-placeholder-size: 16px;

/* Dropdown colors*/
$theme-dropdown-background-black: rgba(15, 15, 15, 1); 

.uni-p-8 { padding: $theme-spacing1; }
.uni-p-16 { padding: $theme-spacing2; }
.uni-p-24 { padding: $theme-spacing3; }
.uni-p-32 { padding: $theme-spacing4; }
.uni-p-40 { padding: $theme-spacing5; }
.uni-p-56 { padding: $theme-spacing6; }
.uni-p-72 { padding: $theme-spacing7; }
.uni-p-80 { padding: $theme-spacing8; }
.uni-p-96 { padding: $theme-spacing9; }
.uni-p-120 { padding: $theme-spacing10; }

.uni-pl-8 { padding-left: $theme-spacing1; }
.uni-pl-16 { padding-left: $theme-spacing2; }
.uni-pl-24 { padding-left: $theme-spacing3; }
.uni-pl-32 { padding-left: $theme-spacing4; }
.uni-pl-40 { padding-left: $theme-spacing5; }
.uni-pl-56 { padding-left: $theme-spacing6; }
.uni-pl-72 { padding-left: $theme-spacing7; }
.uni-pl-80 { padding-left: $theme-spacing8; }
.uni-pl-96 { padding-left: $theme-spacing9; }
.uni-pl-120 { padding-left: $theme-spacing10; }
.uni-pr-8 { padding-right: $theme-spacing1; }
.uni-pr-16 { padding-right: $theme-spacing2; }
.uni-pr-24 { padding-right: $theme-spacing3; }
.uni-pr-32 { padding-right: $theme-spacing4; }
.uni-pr-40 { padding-right: $theme-spacing5; }
.uni-pr-56 { padding-right: $theme-spacing6; }
.uni-pr-72 { padding-right: $theme-spacing7; }
.uni-pr-80 { padding-right: $theme-spacing8; }
.uni-pr-96 { padding-right: $theme-spacing9; }
.uni-pr-120 { padding-right: $theme-spacing10; }
.uni-pb-8 { padding-bottom: $theme-spacing1; }
.uni-pb-16 { padding-bottom: $theme-spacing2; }
.uni-pb-24 { padding-bottom: $theme-spacing3; }
.uni-pb-32 { padding-bottom: $theme-spacing4; }
.uni-pb-40 { padding-bottom: $theme-spacing5; }
.uni-pb-56 { padding-bottom: $theme-spacing6; }
.uni-pb-72 { padding-bottom: $theme-spacing7; }
.uni-pb-80 { padding-bottom: $theme-spacing8; }
.uni-pb-96 { padding-bottom: $theme-spacing9; }
.uni-pb-120 { padding-bottom: $theme-spacing10; }
.uni-pt-8 { padding-top: $theme-spacing1; }
.uni-pt-16 { padding-top: $theme-spacing2; }
.uni-pt-24 { padding-top: $theme-spacing3; }
.uni-pt-32 { padding-top: $theme-spacing4; }
.uni-pt-40 { padding-top: $theme-spacing5; }
.uni-pt-56 { padding-top: $theme-spacing6; }
.uni-pt-72 { padding-top: $theme-spacing7; }
.uni-pt-80 { padding-top: $theme-spacing8; }
.uni-pt-96 { padding-top: $theme-spacing9; }
.uni-pt-120 { padding-top: $theme-spacing10; }
.uni-py-8 { padding-top: $theme-spacing1; padding-bottom: $theme-spacing1; }
.uni-py-16 { padding-top: $theme-spacing2; padding-bottom: $theme-spacing2; }
.uni-py-24 { padding-top: $theme-spacing3; padding-bottom: $theme-spacing3; }
.uni-py-32 { padding-top: $theme-spacing4; padding-bottom: $theme-spacing4; }
.uni-py-40 { padding-top: $theme-spacing5; padding-bottom: $theme-spacing5; }
.uni-py-56 { padding-top: $theme-spacing6; padding-bottom: $theme-spacing6; }
.uni-py-72 { padding-top: $theme-spacing7; padding-bottom: $theme-spacing7; }
.uni-py-80 { padding-top: $theme-spacing8; padding-bottom: $theme-spacing8; }
.uni-py-96 { padding-top: $theme-spacing9; padding-bottom: $theme-spacing9; }
.uni-py-120 { padding-top: $theme-spacing10; padding-bottom: $theme-spacing10; }
.uni-px-8 { padding-left: $theme-spacing1; padding-right: $theme-spacing1; }
.uni-px-16 { padding-left: $theme-spacing2; padding-right: $theme-spacing2; }
.uni-px-24 { padding-left: $theme-spacing3; padding-right: $theme-spacing3; }
.uni-px-32 { padding-left: $theme-spacing4; padding-right: $theme-spacing4; }
.uni-px-40 { padding-left: $theme-spacing5; padding-right: $theme-spacing5; }
.uni-px-56 { padding-left: $theme-spacing6; padding-right: $theme-spacing6; }
.uni-px-72 { padding-left: $theme-spacing7; padding-right: $theme-spacing7; }
.uni-px-80 { padding-left: $theme-spacing8; padding-right: $theme-spacing8; }
.uni-px-96 { padding-left: $theme-spacing9; padding-right: $theme-spacing9; }
.uni-px-120 { padding-left: $theme-spacing10; padding-right: $theme-spacing10; }
.uni-my-8 { margin-top: $theme-spacing1; margin-bottom: $theme-spacing1; }
.uni-my-16 { margin-top: $theme-spacing2; margin-bottom: $theme-spacing2; }
.uni-my-24 { margin-top: $theme-spacing3; margin-bottom: $theme-spacing3; }
.uni-my-32 { margin-top: $theme-spacing4; margin-bottom: $theme-spacing4; }
.uni-my-40 { margin-top: $theme-spacing5; margin-bottom: $theme-spacing5; }
.uni-my-56 { margin-top: $theme-spacing6; margin-bottom: $theme-spacing6; }
.uni-my-72 { margin-top: $theme-spacing7; margin-bottom: $theme-spacing7; }
.uni-my-80 { margin-top: $theme-spacing8; margin-bottom: $theme-spacing8; }
.uni-my-96 { margin-top: $theme-spacing9; margin-bottom: $theme-spacing9; }
.uni-my-120 { margin-top: $theme-spacing10; margin-bottom: $theme-spacing10; }
.uni-mx-8 { margin-left: $theme-spacing1; margin-right: $theme-spacing1; } 
.uni-mx-16 { margin-left: $theme-spacing2; margin-right: $theme-spacing2; }
.uni-mx-24 { margin-left: $theme-spacing3; margin-right: $theme-spacing3; }
.uni-mx-32 { margin-left: $theme-spacing4; margin-right: $theme-spacing4; }
.uni-mx-40 { margin-left: $theme-spacing5; margin-right: $theme-spacing5; }
.uni-mx-56 { margin-left: $theme-spacing6; margin-right: $theme-spacing6; }
.uni-mx-72 { margin-left: $theme-spacing7; margin-right: $theme-spacing7; }
.uni-mx-80 { margin-left: $theme-spacing8; margin-right: $theme-spacing8; }
.uni-mx-96 { margin-left: $theme-spacing9; margin-right: $theme-spacing9; }
.uni-mx-120 { margin-left: $theme-spacing10; margin-right: $theme-spacing10; }
.uni-mt-8 { margin-top: $theme-spacing1; }
.uni-mt-16 { margin-top: $theme-spacing2; }
.uni-mt-24 { margin-top: $theme-spacing3; }
.uni-mt-32 { margin-top: $theme-spacing4; }
.uni-mt-40 { margin-top: $theme-spacing5; }
.uni-mt-56 { margin-top: $theme-spacing6; }
.uni-mt-72 { margin-top: $theme-spacing7; }
.uni-mt-80 { margin-top: $theme-spacing8; }
.uni-mt-96 { margin-top: $theme-spacing9; }
.uni-mt-120 { margin-top: $theme-spacing10; }
.uni-mb-8 { margin-bottom: $theme-spacing1; }
.uni-mb-16 { margin-bottom: $theme-spacing2; }
.uni-mb-24 { margin-bottom: $theme-spacing3; }
.uni-mb-32 { margin-bottom: $theme-spacing4 }
.uni-mb-40 { margin-bottom: $theme-spacing5; }
.uni-mb-56 { margin-bottom: $theme-spacing6; }
.uni-mb-72 { margin-bottom: $theme-spacing7; }
.uni-mb-80 { margin-bottom: $theme-spacing8; }
.uni-mb-96 { margin-bottom: $theme-spacing9; }
.uni-mb-120 { margin-bottom: $theme-spacing10; }
.uni-ml-8 { margin-left: $theme-spacing1; }
.uni-ml-16 { margin-left: $theme-spacing2; }
.uni-ml-24 { margin-left: $theme-spacing3; }
.uni-ml-32 { margin-left: $theme-spacing4; }
.uni-ml-40 { margin-left: $theme-spacing5; }
.uni-ml-56 { margin-left: $theme-spacing6; }
.uni-ml-72 { margin-left: $theme-spacing7; }
.uni-ml-80 { margin-left: $theme-spacing8; }
.uni-ml-96 { margin-left: $theme-spacing9; }
.uni-ml-120 { margin-left: $theme-spacing10;}
.uni-mr-8 { margin-right: $theme-spacing1; }
.uni-mr-16 { margin-right: $theme-spacing2; }
.uni-mr-24 { margin-right: $theme-spacing3; }
.uni-mr-32 { margin-right: $theme-spacing4; }
.uni-mr-40 { margin-right: $theme-spacing5; }
.uni-mr-56 { margin-right: $theme-spacing6; }
.uni-mr-72 { margin-right: $theme-spacing7; }
.uni-mr-80 { margin-right: $theme-spacing8; }
.uni-mr-96 { margin-right: $theme-spacing9; }
.uni-mr-120 { margin-right: $theme-spacing10; }
.uni-mt-mb-64 { margin-top: $theme-spacing11; 
margin-bottom:$theme-spacing11;}

.bg-custom-trans { background-color:transparent !important}

@media (min-width: 786px) {
  $neww:rgb(238, 11, 11)
}
@media (max-width: 786px) {
  $neww:rgb(72, 219, 27)
}
@media (min-width: 2560px) {
  .container-xxl{
    max-width: 1450px;
  }
}

.p-l {
  padding-left: 4rem;
}
.heading-font-size {
  font-size: 3rem;
  font-weight: 700;
}
.sub-heading-font-size {
  font-size: 2.5rem;
  font-weight: 700;
}

.all-btn-end-single {
  display: flex;
  width: 100%;
  justify-content: end;
  .all-btn-end-button {
    background: transparent;
    border: 2px solid rgb(255, 255, 255);
    border-radius: 14px;
    color: white;
    padding: 5px 30px;
    font-size: 1.5rem;
    font-weight: 700;
    width: auto;
    line-height: 2.5rem;
  }
}
.all-btn-end-double {
  display: flex;
  width: 100%;
  justify-content: end;
  .all-btn-end-button {
    background: transparent;
    border: 2px solid rgb(255, 255, 255);
    border-radius: 14px;
    color: white;
    padding: 5px 30px;
    font-size: 1.5rem;
    font-weight: 700;
    margin-right: 10px;
    width: auto;
    line-height: 2.5rem;
  }
}

@media (max-width: 768px) {
  .p-l {
    padding-left: 3rem;
  }
  .heading-font-size {
    font-size: 2.5rem;
  }
  .sub-heading-font-size {
    font-size: 2rem;
  }
  .all-btn-end-single {
    button {
      font-size: 1rem;
    }
  }
  .all-btn-end-double {
    .all-btn-end-button {
      font-size: 1rem;
    }
  }
}

@media (max-width: 576px) {
  .p-l {
    padding-left: 0rem;
  }
  .heading-font-size {
    font-size: 2rem;
    text-align: center;
  }
  .sub-heading-font-size {
    font-size: 1.5rem;
  }
  .all-btn-end-single {
    justify-content: end;
    .all-btn-end-button {
      background: transparent;
      border: 2px solid rgb(255, 255, 255);
      border-radius: 14px;
      color: white;
      padding: 5px 30px;
      font-size: 1rem;
      font-weight: 700;
      height: auto;
    }
  }
  .all-btn-end-double {
    justify-content: space-between;
    .all-btn-end-button {
      background: transparent;
      border: 2px solid rgb(255, 255, 255);
      border-radius: 14px;
      color: white;
      padding: 5px 30px;
      font-size: 1rem;
      font-weight: 700;
      height: auto;
    }
  }
}


.uni-fs-700{
font-weight: 700;
}
.uni-fs-400{
  font-weight: 400 !important;
}

.uni-fs-400{
  font-weight: 400;
  }
  @media(min-width: 360px) and (max-width: 768px){
    .mt-0-mbl{
      margin-top: 0px !important;
    }
  }

  /*for landing page*/
  @media(min-width: 576px) and (max-width: 768px){
    .uni-my-32 { 
      margin-top: $theme-spacing1 !important;
     margin-bottom: $theme-spacing2 !important;
     }
     .no-data-found{
      font-size: 14px;
  }
  }
  @media(min-width: 360px) and (max-width: 576px){
    .uni-my-32 { 
      margin-top: $theme-spacing1 !important;
     margin-bottom: $theme-spacing2 !important;
     }
  }

  @media(min-width: 320px) and (max-width: 360px){

    .font-weight-600{
      font-weight: 600 !important;
    }
    .front-pages-second-heading{
       font-size: 12px !important;
       line-height: 16.8px !important;
    }
    .mb-32-mbl{
      margin-bottom: 2rem !important;
    }
    .mb-16-mbl{
      margin-bottom: 1rem !important;
    }
    .mt-8-mbl{
      margin-top: 0.5rem !important;
    }
    .mt-0-mbl{
      margin-top: 0px !important;
    }
    .uni-my-32 { 
      margin-top: $theme-spacing1 !important;
     margin-bottom: $theme-spacing2 !important;
     }
     .no-data-found{
         font-size: 12px;
     }

 }
 

  