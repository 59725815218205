

@import '../../../../../styles/variablesstyles.scss';


.sn-ctm-txt{
    font-weight: 700;
    color:$theme-color-gray2;
    font-size: $theme-xsm-fs;
    line-height: 19.6px;
  }

  .modal-width-notification{
    width: 753px;
  //  padding:  56px 72px 56px 72px;
  }

  .text-field-notification{
    color: $theme-color-gray5;
  }

  .social-links-main-hover:hover{
    background: var(--Gray-2, rgba(79, 79, 79, 1));
  }

  @media(min-width: 360px) and (max-width: 576px){
    .text-field-notification{
        
      font-size: 12px !important;
      line-height: 16px !important;
      font-weight: 400;
      letter-spacing: 0.5px;
   }
   .checkcircle-mobile{
    width: 14px;
    height: 14px;
  }
  .social-links-main-xsm{
    font-weight: 400 !important;
    margin-bottom: 0.5rem !important;
  }
  .social-link-main-title{
    margin-top: 0.5rem !important;
  }
  }

  @media(min-width: 320px) and (max-width: 360px){

    .text-field-notification{
        
       font-size: 12px !important;
       line-height: 16px !important;
       font-weight: 400;
       letter-spacing: 0.5px;
    }
    .social-links-main-hover{
      padding: 8px !important;
    }
    .checkcircle-mobile{
      width: 14px;
      height: 14px;
    }
    .mobile-modal-specing{
      margin-bottom: 16px !important;
    }
    .social-links-main-xsm{
      font-weight: 400 !important;
      margin-bottom: 0.5rem !important;
    }
    .social-link-main-title{
      margin-top: 0.5rem !important;
    }
  }