/******* Popup size********/
.uni-sm-popup {
  max-width: 566px;
  width: auto;
}
.uni-md-popup {
  max-width: 753px;
  width: auto;
}
.uni-roles-popup {
  max-width: 753px;
  width: auto;
}
.uni-lg-popup {
  max-width: 768px;
  width: auto;
}
.uni-Xl-popup {
  max-width: 953px;
  width: auto;
}
.uni-mid-popup {
  max-width: 620px;
  width: auto;
}
.uni-692-popup {
  width: 692px;
  max-width: 692px;
  /* width: auto; */
}
.uni-732-popup{
  width: 732px;
  max-width: 732px;
}

/******* Popup container********/
.modal-content {
  position: relative !important;
  padding: clamp(1.5rem, 0.9286rem + 2.8571vw, 3.5rem)
    clamp(1rem, 0rem + 5vw, 4.5rem) clamp(1.5rem, 0.9286rem + 2.8571vw, 3.5rem)
    clamp(1rem, 0rem + 5vw, 4.5rem) !important;
  /* padding: clamp(1.5rem, 0.9286rem + 2.8571vw, 3.5rem)
  clamp(1.5rem, 1.1727rem + 1.4545vw, 3.5rem)
  clamp(1.5rem, 0.9286rem + 2.8571vw, 3.5rem)
  clamp(1.5rem, 1.1727rem + 1.4545vw, 3.5rem) */
}
.modal-content-roles {
  position: relative !important;
  padding: clamp(1.5rem, 0.9286rem + 2.8571vw, 3.5rem)
    clamp(1rem, 0rem + 5vw, 4.5rem) clamp(1.5rem, 0.9286rem + 2.8571vw, 3.5rem)
    clamp(1rem, 0rem + 5vw, 4.5rem) !important;
    background: rgba(15, 15, 15, 1);
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    border: none;
    border-radius: 10px;
}
.modal-cross-icon {
  position: absolute !important;
  top: 0px;
  right: 0px;
}
.modal-header {
  padding: 0px !important;
}
.modal-footer {
  padding: 0px !important;
}
.modal-body {
  padding: 0px;
}

/******* Popup media query********/

@media (min-width: 768px) and(max-width: 1100px) {
  .uni-md-popup {
    max-width: 650px;
    margin: auto;
  }
}
@media (min-width: 576px) and (max-width: 768px) {
  .uni-md-popup {
    max-width: 650px;
    width: 500px;
    margin: auto;
  }
  .uni-mid-popup {
    max-width: 650px;
    width: 500px;
    margin: auto;
  }
  .modal-cross-icon {
    height: 13px;
    top: 10px;
    right: 0px;
  }
 
}
@media (min-width: 360px) and (max-width: 576px) {
  .uni-md-popup {
    max-width: 350px;
    width: 340px;
    margin: auto;
  }
  .uni-mid-popup {
    max-width: 350px;
    width: 280px;
    margin: auto;
  }
  .uni-roles-popup {
    max-width: 340px;
    width: 300px;
    margin: auto;
  }
  .modal-cross-icon {
    height: 13px;
    top: 0px;
    right: 10px;
  }
  /* .modal-content-roles{
    padding: 24px 16px 24px 16px !important;
} */
}

@media (min-width: 320px) and (max-width: 360px) {
  .uni-md-popup {
    max-width: 300px;
    /* width: 280px; */
    margin: auto;
  }
  .modal-content-roles{
       padding: 24px 16px 24px 16px;
  }
  /* .modal-content{
    padding: 24px 16px !important ;
  } */
  .modal-cross-icon {
    height: 13px;
    top: 0px;
    right: 10px;
  }
}
