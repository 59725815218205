

@import '../../../../../styles/variablesstyles.scss';


.ds-ctm-txt{
    font-weight: 700;
    color:$theme-color-gray2;
  }
  
  .cstm-txt-invite{
     background-color: $input-background-color;
     line-height: $theme-sm-lh;
    height: 124px;
  }
  .btn-wid-decline{
      background: $theme-color-black3;
      width: 190px;
  }
  .btn-wid-accept{
      width: 188px;
  }
  
  
  
  .cstm-inp-invite::placeholder{
        
     color: $theme-color-white;
     line-height: $theme-sm-lh;
     font-weight: 700;
     font-size: $theme-sm-fs;
  }
  .block-profile-box{
       
     width: 80px;
     height: 80px;
     background-color: $theme-color-gray1;
     border-radius: 50px;
  }
  .list-text{
    color: $theme-color-gray5;
    font-weight: 400;
  }
  .list-item{
    color: $theme-color-gray5;
  }
  .displayname{
        font-size: $theme-heading-5-size;
       line-height: $theme-heading-5-lh;
       color: $theme-color-white;
  }
  .userName-text{
   font-size: $theme-xsm-fs;
    line-height: $theme-xsm-lh;
    color:$theme-color-gray5;

  }

  @media(min-width: 360px) and (max-width: 576px){

    .block-profile-box{
      width: 38px !important;
      height: 38px !important;
    }
    .displayname{
      font-size: 16px !important;
      line-height: 17.6px !important;
      margin-bottom: 0px !important;
    }
    .userName-text{
      font-size: 12px !important;
      line-height: 16.8px !important;
    }
    .invite_firstcolumn{
      gap: 0.5rem !important;
    }
    .list-item {
      font-size: 14px;
     line-height: 19.6px !important;
     margin-bottom: 8px !important;
    }
  }

  @media(min-width: 320px) and (max-width: 360px){

    .block-profile-box{
      width: 38px !important;
      height: 38px !important;
    }
    .displayname{
      font-size: 16px !important;
      line-height: 17.6px !important;
      margin-bottom: 0px !important;
    }
    .userName-text{
      font-size: 12px !important;
      line-height: 16.8px !important;
    }
    .invite_firstcolumn{
      gap: 0.5rem !important;
    }
    .list-item {
      font-size: 14px;
     line-height: 19.6px !important;
     margin-bottom: 8px !important;
    }
  }