@import "../../../../styles/variablesstyles.scss";


.modal-content {
    padding: 0px 0px 56px $theme-spacing7;
    background: rgba(15, 15, 15, 1);
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    border: none;
    border-radius: 10px;
  }
  
  .uploadBanner-uploadicon{
    height: 102px;
    width: 126px;
}


.modal-width-upload-banner{
  width:  620px;
 margin: auto;
}

.custom-modal-width-lg {
  max-width: 800px;
  width: auto;
}
.custom-modal-width-md {
  max-width: 753px;
  width: auto;
}
  .custom-modal-width-sm {
    max-width: 566px;
    width: auto;
  }


  .custom-modal-width {
    max-width: 800px;
    width: auto;
  }
  
  .modal-header , .modal-footer{
    border: 0;
  }
  
  .modal-header{
    padding-bottom: 0;
  }
  .modal-body{
    padding-top: 0;
  }
  
  .btn-wid{
  width:174px;
  }
  
  .ctm-txt{
    font-weight: 700;
    color:$theme-color-gray3;
  }
  
  .ctm-txt2{
    font-weight: 400;
    color:$theme-color-gray3;
  }
  
  .modal-title{
    color:rgba(255, 255, 255, 1);
    font-weight: 700;
    line-height: 44px;
  }

  .btn-bg-gray3{
    background: $theme-color-black3;
  }

  .btn-bg-gray3:hover{
    background:  rgb(72, 71, 71) !important;
  }
  
  .btn-bg-black2{
    background: $theme-color-black2;
    color: $theme-color-gray3;
  }

  .chg-plchold-col::placeholder{
    color: $theme-color-gray2;

    color: rgba(255, 255, 255, 1);
    font-weight: 700;
    line-height: 44px;
  }
  .ctm-md-lh{
     line-height: 28px;
  }

  .modal-title-fs-32{
    margin-bottom: 0;
    color:$theme-color-white;
    font-weight: 700;
    line-height: $theme-heading-4-lh;
  }
  .modal-cross-icon{
    height: 46px;
    width: auto;
    display: flex;
    align-items:center ;
    justify-content: end;
  }

  .modal{
    ::-webkit-scrollbar-thumb {
      background: $theme-color-gray3;
      border-radius: 5px!important;
      transition: opacity 0.5s 0.5s ease-out;
    }
  }
  @media(min-width: 576px) and (max-width: 1024px){
    .modal-content {
      padding: 0 16px 24px 48px;
    }
    .custom-modal-width{
      max-width: 600px;
      width: 576px !important;
      margin: auto;
    }
  }
  @media(min-width: 576px) and (max-width: 768px){
    .uni-mbl-max-height{
      overflow-y: auto;
      max-height: 300px;
    }
  }
  
  @media(min-width: 360px) and (max-width: 576px){
    .modal-content {
      padding: 0 16px 24px 16px;
    }
    .cstm-width-report-reason{
      max-width: 171px !important;
}
    .buttoncomponent-mobile-modle{
        width: 100% !important;
    }
    .custom-mobile-email-width{
      max-width: 300px;
      width: 280px !important;
      margin: auto;
    }
    .modal-content-boxes-modal{
      padding: 0 6px 24px 16px !important;
    }
    .popup-space-mobile{
      padding: 8px 0px !important;
  }
    .custom-modal-width{
      max-width: 300px;
      width: 280px;
      margin: auto;
    }
    .modal-width-inviteModal{
      max-width: 300px;
      width: 280px;
      margin: auto;
    }
    .modal-body{
       padding: 0px !important;
       margin-bottom: 0px !important;
    }
    .modal-cross-icon{
      height: 16px !important;
    }
    .modal-btns{
      height: 30px !important;
      padding: 5px 107px 5px 107px !important;
      border-radius: 5px !important;
      font-size: 12px !important;
      line-height: 19.6px;
      width: 100%;
    }
    .mobile-modal-footer{
        padding-right: 0px !important;
        display: block !important;
        margin-top: 16px !important;
    }
    .modal-second-head-font{
      font-size: 12px !important;
      line-height: 16.8px !important;
    }
    .phone-number-input{
      margin-top: 0px !important;
    }
    .modaltitle-spacing{
      padding-right: 0px !important;
      padding-left: 0px !important;
   }
   .modal-cross-icon{
    img{
      margin: 17px -3px 0px 0px !important;
      height: 12px;
      width: 12px;
    }
  }
  .content-height{
    max-height: 208px !important;
    height: 208px !important;
  }
  .modal-title{
    line-height: 22.4px !important;
  }

  /** mobile spacing css **/
  .mbl-mt-8{
    margin-top: 0.5rem !important;
  }
  .mbl-mb-16{
    margin-bottom: 1rem !important;
  }
  .mbl-mb-8{
    margin-bottom: 0.5rem !important;
  }
  .mbl-mb-0{
    margin-bottom: 0px !important;
  }
  .mbl-mt-0{
  margin-bottom: 0px !important;
  }

   /*mobile footer */
   .mbl-modal-footer{
    flex-direction: column-reverse;
    padding-right: 0px !important;
    gap: 16px;
    align-items: center;
    margin-top: 1rem;
   }
   .mbl-full-width-btn{
    margin: auto;
    // padding: 0 124px;
    margin-right: 0px !important;
    margin-left: 0px !important;
    width: 100%;
   }
   .mbl-anchor-full-btn{
    width: 100%;
   }
   .pop-up-max-width{
    max-width: 200px;
  }
  .uni-mbl-max-height{
    overflow-y: auto;
    max-height: 300px;
  }
  }




  @media(min-width: 320px) and (max-width: 360px){
    .cstm-width-report-reason{
      max-width: 171px !important;
    }
    .modal-content {
      padding: 0 16px 24px 16px;
    }
    .modal-title{
      line-height: 22.4px !important;
    }
    .modaltitle-spacing{
         padding-right: 0px !important;
         padding-left: 0px !important;
    }
    .buttoncomponent-mobile-modle{
      width: 100% !important;
   }
    .custom-mobile-email-width{
      max-width: 300px;
      width: 280px !important;
      margin: auto;
    }
    .popup-space-mobile{
        padding: 8px 0px !important;
    }
    .modal-content-boxes-modal{
      padding: 0 6px 24px 16px !important;
    }
    .modal-width-inviteModal{
      max-width: 300px;
      width: 280px !important;
      margin: auto;
    }
    .custom-modal-width{
      max-width: 300px;
      width: 280px;
      margin: auto;
    }
    .modal-body{
       padding: 0px !important;
       margin-bottom: 0px !important;
    }
    .modal-cross-icon{
      height: 16px !important;
    }
    
    .modal-btns{
      height: 30px !important;
      padding: 5px 107px 5px 107px !important;
      border-radius: 5px !important;
      font-size: 12px !important;
      line-height: 19.6px;
      width: 100%;

    }
    .mobile-modal-footer{
        padding-right: 0px !important;
        display: block !important;
        margin-top: 16px !important;
    }
    .modal-second-head-font{
      font-size: 12px !important;
      line-height: 16.8px !important;
      margin-top: 8px !important;
      margin-bottom: 16px !important;
    }
    .phone-number-input{
      margin-top: 0px !important;
    }
 
    .modal-cross-icon{
      img{
        margin: 17px -3px 0px 0px !important;
        height: 12px;
        width: 12px;
      }
    }
    .uni-mbl-max-height{
      overflow-y: auto;
      max-height: 300px;
    }
    .content-height{
      max-height: 208px !important;
      height: 168px !important;
    }

    /*mobile footer */

    .mbl-modal-footer{
      flex-direction: column-reverse;
      padding-right: 0px !important;
      gap: 16px;
      align-items: center;
      margin-top: 1rem;
     }
     .mbl-full-width-btn{
      margin: auto;
      // padding: 0 124px;
      margin-right: 0px !important;
      margin-left: 0px !important;
      width: 100%;
     }
     .mbl-anchor-full-btn{
      width: 100%;
     }
     .pop-up-max-width{
      max-width: 200px;
    }
    /** mobile-class for margin**/
    .mbl-mb-8{
      margin-bottom: 0.5rem !important;
    }
    .mbl-mb-16{
      margin-bottom: 1rem !important;
    }
    .mbl-mt-8{
      margin-top: 0.5rem !important;
    }
    .mbl-mb-0{
        margin-bottom: 0px !important;
    }
    .mbl-mt-0{
      margin-bottom: 0px !important;
    }
  }