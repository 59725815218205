@import "../../../../../styles/variablesstyles.scss";
.font-col-wh{
    color: $theme-color-gray5;
}
.cancel-btn-bg{
    background: rgba(40, 40, 40, 1);
    margin-right: 16px !important;
}
.btn-wid-impersonating{

    width: 186px;
}
@media(min-width:360px) and (max-width: 576px){
    .btn-wid-impersonating{

        width: 100% !important;
    }
}

@media(min-width:320px) and (max-width: 360px){
    .btn-wid-impersonating{

        width: 100% !important;
    }
}