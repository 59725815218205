.cstm-button-modal-test-modal {
    background-color: transparent;
    font-size: 1rem;
    max-width: 100%;
    padding: 4px 12px;
    border: 2px solid white;
    text-wrap: nowrap;
    border-radius: 10px;
    color: white;
    width: 100%;
    display: inline-block;
    text-align: center;
  }