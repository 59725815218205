@import "../../../styles/variablesstyles.scss";
.search {
  .dropworks-dropdown {
    &-button {
      font-size: 16px;
      // font-weight: 700;
      color: rgba(188, 188, 188, 1);
      background-color: rgba(255, 255, 255, 0.05);
      padding: 9px 16px 9px 16px;
      border: none;
      border-radius: 10px;
      margin-right: 1rem;
      &-icon {
        color: yellow;
        font-size: 18px;
        margin-left: 1rem;
        vertical-align: sub;
      }
    }
    &-menu {
      background: rgba(15, 15, 15, 1);
      color: rgba(188, 188, 188, 1);
      font-size: 16px;
      font-weight: 400;
      max-width: 169px;
      &-option {
        padding: 1rem;
        color: rgba(188, 188, 188, 1);
      }
    }
  }

  .dropdown-item:hover {
    background-color: transparent;
    color: rgba(188, 188, 188, 1);
  }

  .custom-checkbox {
    display: none;
  }

  .custom-radio {
    min-width: 24px;
    height: 24px;
    border-radius: 50%;
    border: 2px solid #6c757d;
    position: relative;
  }

  .custom-checkbox:checked + .custom-radio {
    background-color: transparent;
    border: 2px solid yellow;
  }

  .custom-radio:after {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    width: 8px;
    height: 8px;
    background: yellow;
    border-radius: 50%;
    transform: translate(-50%, -50%) scale(0);
    transition: transform 0.2s ease;
  }

  .custom-checkbox:checked + .custom-radio:after {
    transform: translate(-50%, -50%) scale(1);
  }

  &-heading {
    font-size: $theme-heading-2-size;
    font-weight: 700;
  }
  .maxy-wid {
    width: 150px;
  }
  &-dropdownbutton {
    line-height: 22.4px !important;
    width: max-content;
    height: 42px;
  }

  &-inputbox {
    width: 498px !important;
  }
  &-button {
    .active {
      color: $theme-color-white;
      border-bottom: 1px solid;
    }
    &-all {
      background-color: transparent;
      color: $theme-color-gray4;
      padding: 20px;
      font-size: $theme-lg-fs;
      font-weight: 400;
      border: none;
      line-height: 28px;
    }
  }
  &-subheading {
    font-size: $theme-heading-5-size;
    font-weight: 700;
    margin-bottom: 2rem;
  }
  &-usericon {
    margin-bottom: 56px;
  }
  &-dropdown {
    background-color: rgba(255, 255, 255, 0.05);
    border: none;
  }
}
.search-custm-input {
  width: 498px;
}
// @media(min-width: 576px) and (max-width: 1024px){
//   .all-icon-mobile{
//       padding-left: 0px !important;
//   }
// }
@media (min-width: 360px) and (max-width: 576px) {
  .filter-option {
    font-size: 12px !important;
  }
  .custom-radio {
    min-width: 18px !important;
    height: 18px !important;
    border-radius: 50%;
    border: 2px solid #6c757d;
    position: relative;
  }
  .dropworks-dropdown-button-icon {
    color: yellow;
    font-size: 14px !important;
    margin-left: 0.5rem;
  }
  .all-icon-mobile {
    overflow-x: auto !important;
    flex-wrap: nowrap !important;

    &::-webkit-scrollbar {
      width: 0;
      height: 0;
    }
    scrollbar-width: none;
    -ms-overflow-style: none;
  }
  .search-subheading {
    font-size: 16px !important;
    line-height: 17px !important;
    font-weight: 700;
    margin-bottom: 1rem !important;
  }
  .search-usericon {
    margin-bottom: 1rem !important;
  }
  .dropworks-dropdown-button {
    padding: 5px 9px !important;
    font-size: 10px !important;
    height: 27px;
    border-radius: 5px !important;
    line-height: 16.8px;
    margin-right: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .search {
    .dropworks-dropdown {
      &-menu {
        max-height: 266px !important;
      }
    }
  }
}

@media (min-width: 320px) and (max-width: 360px) {
  .custom-radio {
    min-width: 18px !important;
    height: 18px !important;
    border-radius: 50%;
    border: 2px solid #6c757d;
    position: relative;
  }
  .filter-option {
    font-size: 12px !important;
  }
  .dropworks-dropdown-button-icon {
    color: yellow;
    font-size: 14px !important;
    margin-left: 0.5rem;
  }
  .all-icon-mobile {
    overflow-x: auto !important;
    flex-wrap: nowrap !important;

    &::-webkit-scrollbar {
      width: 0;
      height: 0;
    }
    scrollbar-width: none;
    -ms-overflow-style: none;
  }
  .search-subheading {
    font-size: 16px !important;
    line-height: 17px !important;
    font-weight: 700;
    margin-bottom: 1rem !important;
  }
  .search-usericon {
    margin-bottom: 1rem !important;
  }
  .dropworks-dropdown-button {
    padding: 5px 9px !important;
    font-size: 10px !important;
    height: 27px;
    border-radius: 5px !important;
    line-height: 16.8px;
    margin-right: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .search {
    .dropworks-dropdown {
      &-menu {
        max-height: 266px !important;
      }
    }
  }
}
