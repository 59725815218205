.not-found-return-home{
    width: 229px !important;
    height: 48px !important;
    white-space: nowrap;
}
.page-not-found-text{
    color: rgba(224, 224, 224, 1) !important;
}
.not-foundcontent{
    min-height: 800px;
    align-items: center;
    background: url('../../../assets/steps/retrofuturistic-circuit-loop.gif');
    background-repeat: no-repeat;
    background-position: 120%; 
    background-size: contain; 
    width:100%;
    height: calc(100vh - 83px);
    background-color: black;
    position: fixed;
    &-main{
        padding-left: 20%;
    }
}


@media (min-width: 2200px){
    .not-foundcontent{
        background-repeat: no-repeat;
        background-position: 180%; 
        background-size: contain; 

  
    }
}


@media (min-width: 1450px) and (max-width:2200px){
    .not-foundcontent{
        background-position: 130%; 
        background-size: 75%;   
    }
}


@media (min-width: 1024px) and (max-width:1450px){
    .not-foundcontent{
        background-position: 140%; 
        background-size: 65%; 
 
    }
}



@media (min-width: 320px) and (max-width:768px){
    .not-found-return-home{
        width: 160px !important;
        height: 36px !important;
        font-size: 12px !important;
        margin:auto;
        margin-top: 8px !important;
    }
}

@media  (max-width:1024px){
    .not-foundcontent{
        height: calc(100vh - 52px) !important;
        background-position: center 10%;
        background-size: 65%; 
        &-main{
            padding-left: 0% !important;
        }
    }
}