

@import '../../../../../styles/variablesstyles.scss';


.sn-ctm-txt{
    font-weight: 700;
    color:$theme-color-gray2;
    font-size: $theme-xsm-fs;
    line-height: 19.6px;
  }







  .uploadBanner_uploadbox {
    height: 476px;
    border: 2px dashed white;
    border-radius: 10px;
    display: flex;
    width: 100%;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    opacity: 0.75;
    background:transparent ;
}

.uploadBanner-upload-text{
  font-weight: 400;
  line-height: 57.6px;
}

.disabled-btn{
  background: $theme-color-black2 !important;
  &:hover {
    background-color: inherit; 
    color:#fff; 
    transform: none; 
    box-shadow: none; 
  }
}



@media(min-width: 360px) and (max-width: 576px){
  .uploadBanner_uploadbox {
    height: 248px !important;
    border-radius: 3.26px !important;
    border: 0.65px dashed white !important;
    width: 100% !important;
}
.uploadBanner-uploadicon{
  width: 41px !important;
  height: 33px !important;
}
}

@media(min-width: 320px) and (max-width: 360px){
  .uploadBanner_uploadbox {
    height: 248px !important;
    border-radius: 3.26px !important;
    border: 0.65px dashed white !important;
    width: 100% !important;
}
.uploadBanner-uploadicon{
  width: 41px !important;
  height: 33px !important;
}
}
