@import "../../../../../styles/variablesstyles.scss";

.sell-dropstock-modal{
   width: 753px !important;
}
.btn-bg-gray3-approve{
    background: rgba(40, 40, 40, 1);
    margin-right: 16px !important;
    color: $theme-color-gray5;
    font-weight: 400;
}
.custom-tree-img{
   width: 130px;
   height: 130px;
   border-radius: 10px;
}
.sell-dropstocks-subpara{
   font-weight: 400;
   color: $theme-color-gray4;
}
.sell-stocks{
    font-weight: 400 !important;
    color: rgba(224, 224, 224, 1);
}
.sell-dropstock-sellbtn{
    width: 100%;
    height: 45px;
    background: $theme-color-error !important;
    font-size: 16px !important;
    font-weight: 700;
}

.price-input-field::placeholder{
    font-weight: 700 !important; 
    color: $theme-color-white !important;   
    font-size: $theme-sm-fs;
}
.quantity-inupt-placeholder::placeholder{

   font-weight: 700 !important; 
   color: $theme-color-gray3 !important;   
   font-size: $theme-xsm-fs;
}




 .approve-modal-title{
    color: $theme-color-white;
    font-weight: 700;
    line-height: $theme-heading-3-lh;
 }
 .approve-modal-subtitle{
    font-weight: 700;
    color: $theme-color-gray3;
 }
 
 .sellDropstock-modal-text{
    font-weight: 700;
    color: $theme-color-white;
    line-height: 35.2px;
    font-size: 32px;
 }
 .sell-dropstocks-available{

    color: $theme-color-gray3;
 }
 .available-stocks{
     color: $theme-color-white;
     font-weight: 400;
 }
 .text-g3{
    font-weight: 400;
    color: $theme-color-gray3;
 }
 .text-g5{
    font-weight: 700;
    color: $theme-color-gray5;
    line-height: $theme-lg-lh;
 }

 @media(min-width: 320px ) and (max-width: 360px){

   .sell-dropstocks-header{
         margin-bottom: $theme-spacing1 !important;
   }
   .sell-stocks{
      font-size: 12px !important;
      line-height: 16.8px !important;
  }
  .custom-tree-img{
   width: 48px !important;
   height: 48px !important;
   border-radius: 5px ;
  }
  .sellDropstock-modal-text{
    font-size: 16px !important;
    line-height: 17.6px !important ;
    word-break: break-all;
  }
  .sell-dropstocks-subpara{
   font-size: 12px !important;
   line-height: 16.8px !important ;
  }
  .sell-dropstock-sellbtn{
   height: 30px !important;
   margin: 0px !important;
   width: 100% !important;
   font-size: 12px !important;
   line-height: 19.6px !important;
   border-radius: 5px;
  }
 }