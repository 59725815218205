@import "../../styles/variablesstyles.scss";




.custom-select-marketplace{
  width: fit-content !important;
 
  &__control {
    white-space: nowrap !important;
    background-color: transparent !important;
    border: none;
    font-size: 32px;
    font-weight: 700;
    line-height: 35.2px;
    text-align: left;
    color: $theme-color-white !important;
    box-shadow: none !important;
  }

  &__option {
    white-space: nowrap !important;
    color: white;
    padding: 16px;
  }
  &__single-value {
    color: white !important;
  }
  &__indicator-separator {
    width: 0px !important;
  }
  &__control {
    border: none !important;
  }
  &__input-container {
    display: hidden !important;
  }
}

.custom-select-marketplace__option:hover {
  color: white;
  border-radius: 10px !important;
  white-space: nowrap !important;
  background-color: rgba(79, 79, 79, 0.7) !important;
}
.custom-select-marketplace__option--is-focused{
  border-radius: 10px !important;
  white-space: nowrap !important;
  background-color: transparent !important;
}
.custom-select-marketplace__option--is-selected{
  border-radius: 10px !important;
  
  white-space: nowrap !important;
  background-color: rgba(79, 79, 79, 1) !important;
}
.custom-select-marketplace__control--menu-is-open{
//  width: 200px;
  white-space: nowrap !important;
  // max-width: 100px !important;
}
.css-b62m3t-container{
  width: 100% !important;
}
.custom-select-marketplace__menu-list {
  border-radius: 10px !important;
  padding: 8px 0px !important;
}
.custom-select-marketplace__value-container{
  padding: 0px !important;
}
.custom-select-marketplace__single-value{
  margin: 0px;
}

@media(min-width:360px ) and (max-width: 576px){
  .custom-select-marketplace__control{
    width: fit-content;
}
.css-3me91p-placeholder{
  font-size: 20px !important;
  line-height: 22px !important;
}
.custom-select-marketplace{
  &__option {
    padding: 8px 0px 8px 16px !important;
  }
}
.custom-select-marketplace__menu-list {
  border-radius: 10px !important;
  padding: 8px 0px !important;
}
}
@media(min-width:320px ) and (max-width: 360px){
  .custom-select-marketplace__control{
    width: fit-content;
}
.css-3me91p-placeholder{
  font-size: 20px !important;
  line-height: 22px !important;
}
.custom-select-marketplace__menu-list {
  border-radius: 10px !important;
  padding: 8px 0px !important;
}

.custom-select-marketplace{
  &__option {
    padding: 8px 0px 8px 16px !important;
  }
}
}