@import "../../../styles/variablesstyles.scss";
.custom_pass_container {
  position: relative;
  display: flex;
  align-items: center;
  color: #fff !important;
  

.custom_pass {
  width: 100%;
  background: $input-background-color;
  border: 0;
  border-radius: 10px 0px 0px 10px;
  color: rgb(255, 255, 255) !important;
  line-height: 60px;
  height: 60px;
  font-size: 16px;
  padding-left: 1rem;
  font-weight: 400;
}

input[type=password].custom_pass{
  font-family: 'Verdana'!important;
  font-size: 17px!important;
  color: #bcbcbc !important;
  font-weight: 700;
}
input[type=password].custom_pass::-ms-reveal {
  display: none;
}

input[type=password].custom_pass::placeholder {
  color: rgba(188, 188, 188, 1);
  font-size: $input-placeholder-size;
  font-weight: 400!important;
  font-family: 'Kumbh Sans'!important;  
}

.pr-40-imp{
padding-right: 40px !important;
}

.custom_pass::placeholder {
  color: rgba(188, 188, 188, 1);
  font-size: $input-placeholder-size;
  font-weight: 400!important;
  font-family: 'Kumbh Sans'!important;
}

.custom_pass:focus {
  outline: 0;
  border: 0;
}

.eye-icon {
  display: flex;
  align-items: center;
  height: 60px;
  background: $input-background-color;
  border-radius: 0px 10px 10px 0px;
  padding: 16px;
}

}

@media(min-width: 360px) and (max-width: 768px){

   

  .custom_pass{
       height: 36px !important;
       padding: 8px !important;
       width: 100%;
       border-radius: 5px 0 0 5px !important;
       line-height: 16.8px !important;
       font-size: 16px !important;
  }
  .custom_pass::placeholder {
    font-size: 14px !important;
    font-weight: 400!important;
    line-height: 16.8px !important;
  }
  .eye-icon {
    display: flex;
    align-items: center;
    height: 36px !important;
    background: $input-background-color;
    border-radius: 0px 5px 5px 0px !important;
    padding: 8px !important;
  }
  input[type=password].custom_pass{
    font-size: 14px!important;
  }

  .pr-40-imp{
    padding-right: 32px !important;
    padding-left: 0 !important;
    }
}
@media(min-width: 320px) and (max-width: 360px){
 
  .custom_pass_container {
    border-radius: 5px ;
  }
  .custom_pass{
       height: 36px !important;
       padding: 8px !important;
       width: 100%;
       border-radius: 5px 0 0 5px !important;
       line-height: 16.8px !important;
       font-size: 12px !important;
  }
  .custom_pass::placeholder {
    font-size: 12px !important;
    font-weight: 400!important;
    line-height: 16.8px !important;
  }
  .eye-icon {
    display: flex;
    align-items: center;
    height: 36px !important;
    background: $input-background-color;
    border-radius: 0px 5px 5px 0px !important;
    padding: 8px !important;
  }
  input[type=password].custom_pass{
    font-size: 12px!important;
  }

  .pr-40-imp{
    padding-right: 32px !important;
    padding-left: 0 !important;
    }
}