.audio-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #f5f5f5;
  }
  .bhhh{
    background-color: #2c2c2c;
    padding: 12px 16px;
    border-radius: 10px;
    width: 350px;
    max-width: 100%;
    margin-bottom: 8px;
    text-align: end;
  }
  .audio-player {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #2c2c2c;
    padding: 0px 16px;
    padding-bottom: 0;
    border-radius: 10px;
    width: 350px;
    max-width: 100%;
  }
  .fss-16{
    font-size: 12.8px !important;
  }
  .audio-image{
    height: 40px;
    padding-right: 8px;
  }
  .audio-btn {
    background-color: transparent;
    border: none;
    color: white;
    font-size: 24px;
    cursor: pointer;
    transition: transform 0.2s;
    
    &:hover {
      transform: scale(1.2);
    }
  }
  
  .audio-progress {
    flex: 1;
    margin: 0 12px;
    cursor: pointer;
    -webkit-appearance: none;
    appearance: none;
    height: 4px;
    background-color: #555;
    border-radius: 2px;
    
    &::-webkit-slider-thumb {
      -webkit-appearance: none;
      appearance: none;
      width: 16px;
      height: 16px;
      border-radius: 50%;
      background-color: white;
    }
  }
  
  .audio-time {
    color: white;
    font-size: 14px;
  }
  
  .audio-icon {
    color: #f9a825;
    font-size: 20px;
    margin-left: 12px;
  }
  
  .audio-reply {
    color: white;
    font-size: 18px;
    margin-left: 12px;
    cursor: pointer;
    
    &:hover {
      color: #ff4081;
    }
  }
  