@import "../../../styles/variablesstyles.scss";


.heading-beat-empty{
    font-weight: 700;
    color:$theme-color-white ;
    line-height: $theme-heading-3-lh;
}
.subHeading-beat-empty{
    color: $theme-color-gray3;
    font-weight: 700;
    letter-spacing: 0.1px;
}
.text-fields-beat{
    font-weight: 700;
    color:$theme-color-white ;
}
.font-yellow{
    color:$theme-yellow-color ;
}
.beat-empty-button{
    width: 191px;
    background: $theme-color-black2;
    color: $theme-color-gray3;
}
.text-fields-xsm-beat{
    color: $theme-color-gray3
}
.beatEmpty_uploadbox{
    height:172px;
    border: 2px dashed white;
    border-radius: 10px;
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    opacity: 0.75;
    background: transparent;
    cursor: pointer;
}
.beatEmpty_uploadbox2 {
    height:476px;
    border: 2px dashed white;
    border-radius: 10px;
    display: flex;
    width: 476px;
    justify-content: center;
    align-items: center;
    opacity: 0.75;
    background: #000000;
}


.upload-label{
  width: 100%;
}

.uploaded-artwork {
  width: 100%;
  object-fit: fill;
  height: 100%;
  border-radius: 10px;
}
.remove-file-icon {
  cursor: pointer;

}

.upload-label2{
  height: 100%;
  width: 100%;
}
.beat-empty-button-done{
  width: 191px;
  background: linear-gradient(93.86deg, #A91079 0.6%, #53063B 100.02%);
  color: $theme-color-white;
  margin-bottom: 0 !important;
}
.beat-first-col{
   width: 609px;
}
.audio-img{
  height: 70px;
  width: 70px;
}

.btn-beat-empty{
  margin-right: 89px;
}


@media(max-width: 1400px){
  .btn-beat-empty{
    margin-right: 0px;
  }
  .beatEmpty_uploadbox2 {
    border: 2px dashed white;
    border-radius: 10px;
    display: flex;
    width: 80%;
    justify-content: center;
    align-items: center;
    opacity: 0.75;
    background: #000000;
    aspect-ratio: 1;
    margin-left: auto;
}
.release-button{
  padding-right: 0px;
}

.second-column{
    justify-content: end !important;
    padding: 0px;
}
.beat-first-col{
  width: 50%;
}
.beat-empty-artwork{
  width: 80%;
}

}

  .beat-empty-button:hover{
    background: $theme-color-black2 !important;
  }
  .upload-img-2{
    height: 125px;
    width: 125px;
    border-radius: 10px;
  }
  .beat-text{
    color:$theme-color-gray4;
  }



  .ReactCrop{
    height: 100% !important;
    width: 100% !important;
  }

  .ReactCrop__child-wrapper{
    height: 100% !important;
  }
  .croped-image{
    height: 100%;
    width: 100%;
  }
