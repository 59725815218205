@import "../../styles/variablesstyles.scss";

.custom-tooltip {
    --bs-tooltip-bg: red;
    --bs-tooltip-color: var(--bs-white);
  }

  .tooltip-btn{
    border: none;
    margin: 0px;
    padding: 0px;
    background: transparent;
    color: white;
  }

  .tooltip-wrapper {
    display: inline-block;
    position: relative;
  }
  
  .tooltip-box {
    position: absolute;
    background-color: $theme-color-black3;
    color:$theme-color-gray4;
    padding:16px;
    border-radius: 10px;
    font-size: 14px;
    font-weight: 400;
    line-height: 19.6px;
    z-index: 10;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.3s, visibility 0.3s;
    width:max-content;
    max-width: 268px;
  
    &.visible {
      opacity: 1;
      visibility: visible;
    }
  
    &.tooltip-top {
      bottom: 100%;
      left: 50%;
      transform: translateX(-50%);
      margin-bottom: 5px;
    }

  
    &.tooltip-right {
      top: 50%;
      left: 100%;
      transform: translateY(-50%);
      margin-left: 5px;
    }
  
    &.tooltip-bottom {
      top: 100%;
      left: 50%;
      transform: translateX(-50%);
      margin-top: 5px;
    }
  
    &.tooltip-left {
      top: 50%;
      right: 100%;
      transform: translateY(-50%);
      margin-right: 5px;
    }
  }

  @media(min-width: 320px) and (max-width: 768px){

    .tooltip-box {
      padding:8px;
      border-radius: 5px;
      font-size: 10px;
      font-weight: 400;
      line-height: 16.8px;
      max-width: 208px;
      &.tooltip-right{
        top: 50%;
        right: 0px;
        left: auto;
        transform: translateY(-120%);
        margin-left: 5px;
      }
    }
    .tooltip-topers {
      transform: translateX(-96%) !important;
      margin-bottom: 5px !important;
      height: max-content;
      top: -47px !important;
    }
}
  