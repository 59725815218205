

@import '../../../../../styles/variablesstyles.scss';


.ds-ctm-txt{
    font-weight: 700;
    color:$theme-color-gray2;
  }
  .ctm-textarea{
     height: 180px; 
    background-color: rgba(255, 255, 255, 0.05);
    border-radius: 10px;
    width: 100%;
  }