@import "../../styles/variablesstyles.scss";

.cropper-btn{
    background: linear-gradient(93.86deg, #A91079 0.6%, #53063B 100.02%);
    border: none;
    color: white;
}
.cropper-btn:hover{
    background: linear-gradient(93.86deg, #A91079 0.6%, #53063B 100.02%),
linear-gradient(0deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3));

    border: none;
    color: white;
}