
@import "../../../styles/variablesstyles.scss";

.custom_inp_icon {
    width: 100%;
    background-color: $input-background-color;
    border: 0;
    border-radius: 10px;
    color: rgb(255, 255, 255);
    line-height: 60px;
    height: 60px;
    font-size: $input-placeholder-size !important;
    padding-left: 1rem;
    padding-right: 40px;
    margin-bottom: 1.5rem;
    font-weight: 400!important;
    max-height: 295px;
    padding-top: 15px;
    padding-bottom: 15px;
  }
  
  .custom_inp_icon::placeholder {
    color: rgba(188, 188, 188, 1);
    font-size: $input-placeholder-size;
    font-weight: 400!important;
  }
  
  .custom_inp_icon:focus {
    outline: 0;
    border: 0;
    background-color: $input-background-color;
  }
  
  .question-icon {
    position: absolute;
    right: 16px;
    cursor: pointer;
    top:17px;
    transform: all 0.7s ease;
  }

  .custom_inp_icon_container {
    position: relative;
    display: flex;
    align-items: center;
  }

  .hover-icon-color{
    color: $theme-color-gray4;
  }

  @media(min-width: 360px) and (max-width: 768px){
    .custom_inp_icon {
      width: 100%;
      background: $input-background-color;
      border: 0;
      border-radius: 5px;
      color: rgb(255, 255, 255);
      line-height: 36px;
      font-size: 12px !important;
      padding-left: 0.5rem;
      padding-right: 40px;
      margin-bottom: 1.5rem;
      font-weight: 700!important;
      height: 40px !important;
    }

    .custom_inp_icon::placeholder {
      color: rgba(188, 188, 188, 1);
      font-size: 12px !important;
      font-weight: 400!important;
    }
    .question-icon {
      position: absolute;
      right: 16px;
      cursor: pointer;
      top: 5px;}
  }

  @media(min-width: 320px) and (max-width: 360px){
    .custom_inp_icon {
      width: 100%;
      background: $input-background-color;
      border: 0;
      border-radius: 5px;
      color: rgb(255, 255, 255);
      line-height: 36px;
      font-size: 12px !important;
      padding-left: 0.5rem;
      padding-right: 40px;
      margin-bottom: 1.5rem;
      font-weight: 700!important;
      height: 36px !important;
    }
    .custom_inp_icon::placeholder {
      color: rgba(188, 188, 188, 1);
      font-size: 12px !important;
      font-weight: 400!important;
    }
    .question-icon {
      position: absolute;
      right: 16px;
      cursor: pointer;
      top: 5px;}
  }