@import "../../../styles/variablesstyles.scss";

.custom_btn {
  font-weight: 700;
  background: linear-gradient(93.86deg, #A91079 0.6%, #53063B 100.02%);

  border: 0;
  color: #fff;
  width: 100%;
  text-align: center;
  border-radius: 8px;
  line-height: 48px;
  margin-bottom: 1.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  // transition: background 0.4s ease;
}
.custom_btn_primary {
  font-weight: 700;
  background: linear-gradient(93.86deg, #A91079 0.6%, #53063B 100.02%);
  border: 0;
  color: #fff;
  width: 100%;
  font-size: 1rem;
  text-align: center;
  border-radius: 8px;
  line-height: 1.5rem;
  margin-bottom: 1.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding: 16px 80px 16px 80px;
  // transition: background 0.4s ease;
}
.custom_btn_secondary {
  font-weight: 700;
  background: rgba(252, 238, 33, 1);
  border: 0;
  color: rgba(0, 0, 0, 1);
  width: 100%;
  font-size: 1rem;
  text-align: center;
  border-radius: 8px;
  line-height: 1.5rem;
  margin-bottom: 1.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding: 16px 80px 16px 80px;
  // transition: background 0.4s ease;
}
.custom_btn_small {
  font-weight: 700;
  background: linear-gradient(93.86deg, #A91079 0.6%, #53063B 100.02%);

  border: 0;
  color: #fff;
  width: 241px;
  height: 48px;
  font-size: 14px;
  text-align: center;
  border-radius: 8px;
  line-height: 1.5rem;
  margin-bottom: 1.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding: 16px 80px 16px 80px;
  // transition: background 0.4s ease;
}
.custom_btn_normal {
  font-weight: 700;
  background: linear-gradient(93.86deg, #A91079 0.6%, #53063B 100.02%);

  border: 0;
  color: #fff;
  width: 275px;
  height: 55px;
  font-size: 1rem;
  text-align: center;
  border-radius: 8px;
  line-height: 1.5rem;
  margin-bottom: 1.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding: 16px 80px 16px 80px;
}
.custom_btn_medium {
  font-weight: 700;

  background: linear-gradient(93.86deg, #A91079 0.6%, #53063B 100.02%);


  

  border: 0;
  color: #fff;
  width: 307px;
  height: 62px;
  font-size: 1.125rem;
  text-align: center;
  border-radius: 8px;
  line-height: 1.5rem;
  margin-bottom: 1.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding: 18px, 90px, 18px, 90px;
}
.custom_btn_large {
  font-weight: 700;
  background: linear-gradient(93.86deg, #A91079 0.6%, #53063B 100.02%);

  border: 0;
  color: rgba(255, 255, 255, 1);
  width: 341px;
  height: 68px;
  font-size: 1.25rem;
  text-align: center;
  border-radius: 8px;
  line-height: 1.5rem;
  margin-bottom: 1.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding: 18px, 90px, 18px, 90px;
}
.custom-ghost-btn {
  font-weight: 700;
  background: transparent;
  border: 0;
  border: 1px solid $theme-color-gray5;
  color: $theme-color-gray5;
  padding: 9px 16px;
  text-align: center;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1rem;
}
.custom-ghost-btn:hover {
  background: $theme-color-gray5;
  color: $theme-color-black1;
}
.custom-inactive-btn {
  font-weight: 700;
  border: 1px solid;
  border-image-source: linear-gradient(
    313.89deg,
    #1d1d1d 1.71%,
    #282828 98.51%
  );
  background: linear-gradient(313.89deg, #1D1D1D 1.71%, #282828 98.51%);
  border: 0;
  color: #fff;
  width: 100%;
  text-align: center;
  border-radius: 8px;
  line-height: 48px;
  cursor:pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
}
.gradient-active-btn {
  font-weight: 700;
  border: 1px solid;
  background: linear-gradient(93.86deg, #A91079 0.6%, #53063B 100.02%);
  border: 0;
  color: #fff;
  width: 100%;
  text-align: center;
  border-radius: 8px;
  line-height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
}

.custom_btn_primary:hover,.custom-inactive-btn:hover,.custom_btn:hover,.custom_btn_secondary:hover ,.custom_btn_small:hover,.custom_btn_normal:hover,.custom_btn_medium:hover,.custom_btn_large:hover  {
  box-shadow:inset 1px 0 0px rgb(0 0 0 / 0%), inset 0px 0 7px rgba(0, 0, 0, 0), inset 0px 0 84px rgba(0, 0, 0, 0.332), inset 0px 0 95px rgba(0, 0, 0, 0); 
  -webkit-transition: all 0.7s;
	-moz-transition: all 0.7s;
	transition: all 0.7s ease;
}
.custom_btn:active,.custom_btn_primary:active,.custom_btn_secondary:active,.custom_btn_small:active,.custom_btn_normal:active,.custom_btn_medium:active,.custom_btn_large:active{
  background: rgb(255, 153, 221);
  transition: background 0.7s ease;
}


@media(min-width: 360px) and (max-width: 768px) {
  .custom_btn_small {
    font-weight: 700;
    background: linear-gradient(93.86deg, #A91079 0.6%, #53063B 100.02%);
    border: 0;
    color: #fff;
    width: 241px;
    height: 30px;
    font-size: 14px;
    text-align: center;
    border-radius: 8px;
    line-height: 19.6px;
    margin-bottom: 1.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    padding: 16px 80px 16px 80px;
  }

  .custom-inactive-btn {
    font-weight: 700;
    border: 1px solid;
    border-image-source: linear-gradient(
      313.89deg,
      #1d1d1d 1.71%,
      #282828 98.51%
    );
    background: linear-gradient(313.89deg, #1D1D1D 1.71%, #282828 98.51%);
    border: 0;
    color: #fff;
    width: 100%;
    font-size: 14px;
    text-align: center;
    border-radius: 8px;
    line-height: 30px;
    cursor:pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
  }

}


@media(min-width: 320px) and (max-width: 360px) {
  .custom_btn_small {
    font-weight: 600;
    background: linear-gradient(93.86deg, #A91079 0.6%, #53063B 100.02%);
  
    border: 0;
    color: #fff;
    width: 241px;
    height: 30px;
    font-size: 14px;
    text-align: center;
    border-radius: 5px;
    line-height: 19.6px;
    margin-bottom: 1.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    padding: 16px 80px 16px 80px;
    // transition: background 0.4s ease;
  }
  .custom_btn{
     border-radius: 5px;
  }

  .custom-inactive-btn {
    font-weight: 600;
    border: 1px solid;
    border-image-source: linear-gradient(
      313.89deg,
      #1d1d1d 1.71%,
      #282828 98.51%
    );
    background: linear-gradient(313.89deg, #1D1D1D 1.71%, #282828 98.51%);
    border: 0;
    color: #fff;
    width: 100%;
    font-size: 14px;
    text-align: center;
    border-radius: 5px;
    line-height: 30px;
    cursor:pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
  }

}