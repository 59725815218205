.player-crossIcon:hover{
    background-color:#282828;
    border-radius: 500px;
    cursor: pointer;
    height: 20px;
    width: 20px;
  }

  
  @media(min-width: 360px) and (max-width: 768px) {
    .yellowtick{
      width: 12px;
      height: 12px;
    }
    .bluetick{
      width: 14px;
      height: 14px;
    }
  }

  @media(min-width: 320px) and (max-width: 360px) {
    .yellowtick{
      width: 12px;
      height: 12px;
    }
    .bluetick{
      width: 14px;
      height: 14px;
    }
  }