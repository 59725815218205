@import "../../../../styles/variablesstyles.scss";

.login_register_google:hover {
  background: rgb(188, 179, 179) !important;
}

.form-control:focus{
  color: inherit !important;
  box-shadow: none;
  border-color: transparent;
  // background: transparent;
}

// input:-webkit-autofill,
// input:-webkit-autofill:hover,
// input:-webkit-autofill:focus,
// input:-webkit-autofill:active {
  // -webkit-box-shadow: 0 0 0 1000px transparent inset !important;
  // -webkit-text-fill-color: #ffffff !important;
  // transition: all 2000s ease-in-out 0s !important; // Ensures the autofill color stays as intended
// }

.form-control:disabled,.form-control:focus{
  background-color: $input-background-color;
}

.form-control{
  box-shadow: none;
  border-color: transparent;
  background: transparent;
}
.is-invalid-2{
padding-right:calc(3.5em + .75rem) !important;
background-position: right calc(2.375em + .1875rem) center !important;

}

.form-control > .is-invalid ,.form-control >.is-valid{
  border: 0;
  background: transparent;
}

.login {
  position: relative;
  &_content {
    padding: 0;
  }
  &_normal {
    font-weight: 400;
    color: $theme-color-gray4;
  }
  &_register {
    margin-top: 20px;
  }
  &_icon{
    font-size: 20px;
  }
  &_or {
    color: $theme-color-gray4;
  }
  &_register_google {
    background: $theme-color-white !important;
    color: rgba(31, 31, 31, 1) !important;
    transition: background-color 0.3s ease, transform 0.3s ease, box-shadow 0.6s ease !important;
  }
  &_logins {
    color: rgba(255, 245, 0, 1);
    cursor: pointer;
    text-decoration: none;
  }
  &_privacy {
    color: $theme-color-gray2;
    &_link {
      color: $theme-color-info;
      text-decoration: none;
    }
  }

  &_already_registered {
    color: $theme-color-gray4;
  }
}

// @media (max-width: 768px) and (min-width: 577px) {
//   .login {
//     &_normal {
//       font-weight: 400;
//       color: $theme-color-gray4;
//       margin: 20px 0px;
//     }
//     &_privacy {
//       color: $theme-color-gray2;
//       &_link {
//         color: $theme-color-info;
//       }
//     }
//     &_or {
//       color: $theme-color-gray4;
//       margin: 14px auto;
//     }
//     &_already_registered {
//       margin-bottom: 20px;
//     }
//   }
// }

// @media (max-width: 576px) {
//   .login {
//     &_content {
//       width: 100%;
//       padding: 20px;
//     }
//     &_or {
//       color: $theme-color-gray4;
//       margin: 10px auto;
//     }
//     &_normal {
//       font-weight: 400;
//       color: $theme-color-gray4;
//       margin: 15px 0px;
//       height: auto;
//     }
//     &_inp {
//       font-size: 16px;
//       line-height: 2.7rem !important;
//       height: 2.7rem !important;
//     }
//     &_inp::placeholder {
//       font-size: 1rem !important;
//     }
//     &_register_btn,
//     &_register_google {
//       font-size: 1rem !important;
//       line-height: 2.7rem !important;
//       height: 2.7rem !important;
//     }
//     &_privacy {
//       color: $theme-color-gray2;
//       &_link {
//         color: $theme-color-info;
//       }
//     }
//     &_already_registered {
//       margin-bottom: 15px;
//     }
//     &_heading{
//       margin-bottom: 8px !important;
//     }
//   }
//   .login-specing{
//     margin-bottom: 8px !important;
//   }
//   .login_register_google{
//     background: $theme-color-white !important;
//     color: rgba(31, 31, 31, 1) !important;
//     height: 30px !important;
//     width: 100%;
//     border-radius: 5px !important;
//   }
// }



@media(min-width: 360px) and (max-width: 768px) {
  .login-input-first{
    margin-top: 8px !important;
    margin-bottom: 8px !important;
  }
  .login {
    &_content {
      padding: 0px;
    }
    &_heading{
      margin-bottom: 8px !important;
    }
    &_normal {
      font-weight: 400;
      color: $theme-color-gray4;
    }
    &_register {
      margin-top: 16px !important;
      margin-bottom: 8px !important;
      height: 30px !important;
      font-size: 12px !important;
      line-height: 17.6px;
      border-radius: 5px !important;
    }
    &_icon{
      font-size: 20px;
    }
    &_or {
      color: $theme-color-gray4;
    }
    &_register_google {
      background: $theme-color-white !important;
      color: rgba(31, 31, 31, 1) !important;
      height: 30px !important;
      margin-top: 8px;
      margin-bottom: 16px !important;
      font-size: 12px;
      border-radius: 5px !important;
    }
    &_logins {
      color: rgba(255, 245, 0, 1);
      cursor: pointer;
      text-decoration: none;
    }
    &_privacy {
      color: $theme-color-gray2;
      &_link {
        color: $theme-color-info;
        text-decoration: none;
      }
    }
  
    &_already_registered {
      color: $theme-color-gray4;
      font-size: 14px !important;
      margin-bottom: 8px !important;
    }
  }
  .mobile-margin-top{ 
    margin-bottom: 8px;
   }
   .text-danger-mobile{
    font-size: 12px;
   }
}

@media(min-width: 320px) and (max-width: 360px) {
  .text-danger-mobile{
    font-size: 12px;
   }
  .login-input-first{
    margin-top: 8px !important;
    margin-bottom: 8px !important;
  }
  .login {
    &_content {
      padding: 0px;
    }
    &_heading{
      margin-bottom: 8px !important;
    }
    &_normal {
      font-weight: 400;
      color: $theme-color-gray4;
    }
    &_register {
      margin-top: 16px !important;
      margin-bottom: 8px !important;
      height: 30px;
      font-size: 12px !important;
      line-height: 17.6px;
      border-radius: 5px !important;
    }
    &_icon{
      font-size: 20px;
    }
    &_or {
      color: $theme-color-gray4;
    }
    &_register_google {
      background: $theme-color-white !important;
      color: rgba(31, 31, 31, 1) !important;
      height: 30px !important;
      margin-top: 8px;
      // margin-bottom: 16px !important;
      font-size: 12px;
      border-radius: 5px !important;
    }
    &_logins {
      color: rgba(255, 245, 0, 1);
      cursor: pointer;
      text-decoration: none;
    }
    &_privacy {
      color: $theme-color-gray2;
      &_link {
        color: $theme-color-info;
        text-decoration: none;
      }
    }
  .mobile-mb-16{
margin-bottom: 16px !important;
  }
    &_already_registered {
      color: $theme-color-gray4;
      font-size: 14px !important;
      margin-bottom: 8px !important;
    }
  }
  .mobile-margin-top{ 
    margin-bottom: 8px;
   }
   .text-danger-mobile{
    font-size: 12px;
   }
   .login-cstm-pass{
    margin-top: 8px !important;
   }
   .needs-validation{
       margin-top: 1rem;
   }
}
.login_register_google:hover {
  background: rgb(188, 179, 179) !important; 
}