@import "../../../../../styles/variablesstyles.scss";
 
.content {
    color: $theme-color-gray5;
}
 
.box {
    width: 80px;
    height: 80px;
    background-color: rgba(217, 217, 217, 1);
    border-radius: 10px;
    display: inline;
}
.text-field{
    width: 487px;
}
 
.modal-width-inviteModal{
   width:753px ;

}
.content-height{
    height: 448px;
    overflow: auto;
}

.project-img{
    width:100%;
    border-radius: 10px;
}

@media(min-width: 360px) and (max-width: 576px){

    .box {
        width: 30px !important;
        height: 30px !important;
        border-radius: 5px !important;
        padding-right: 30px !important;
    }
 
    .invite-flex{
        gap: 0.5rem !important;
        align-items: center !important;
    }
    .content-height{
        height: 375px;
        overflow: auto;
    }
}

@media(min-width: 320px) and (max-width: 360px){

    .box {
        width: 30px !important;
        height: 30px !important;
        border-radius: 5px !important;
        padding-right: 30px !important;
    }
 
    .invite-flex{
        gap: 0.5rem !important;
        align-items: center !important;
    }
    .content-height{
        height: 375px;
        overflow: auto;
    }
}

 