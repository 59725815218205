

@import '../../../../../styles/variablesstyles.scss';


.sn-ctm-txt{
    font-weight: 700;
    color:$theme-color-gray2;
    font-size: $theme-xsm-fs;
    line-height: 19.6px;
  }

  .modal-width-login-history{
    width: 753px;
  //  padding:  56px 72px 56px 72px;
  }

  .text-field-login-history{
    color: $theme-color-gray5;
    font-weight: 400;
    
   
  }
  .text-field-login-history-first{
    color: $theme-color-gray5;
    font-weight: 700;
    
  }
  .border-bottom{
    border-bottom: 1px solid var(--Gray-1, rgba(51, 51, 51, 1))
  }

  @media(min-width: 360px) and (max-width: 576px){
    .text-field-login-history-first{
         font-size: 16px !important;
         line-height: 17.6px !important;
    }
  }
  @media(min-width: 320px) and (max-width: 360px){
    .text-field-login-history-first{
         font-size: 16px !important;
         line-height: 17.6px !important;
    }
    .text-field-login-history{
      font-size: 12px !important;
      line-height: 16.8px !important;
      font-weight: 400;
    }
  }