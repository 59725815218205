@import "../../styles/variablesstyles.scss";

.BeatesIcon-icon{
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    padding: 1rem;
    cursor: pointer;
    transition: background 1s ease-in-out;

    &-container{
        height: 194px;
        width: 194px;
        border-radius: 10px;
        margin-bottom: 1rem;
        position: relative;
    }
    &-box{
        height: 194px;
        width: 194px;
        background: $theme-color-gray5;
        border-radius: 10px;
        margin-bottom: 1rem;
        position: relative;
        &-icon{
            position: absolute;
            right: 0px;
            bottom: 0px;
            display: block;
            opacity: 0;
            transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;
            transform: translateY(20px); 
            width: 65px;
            height: 65px;
        }
    }
    &-heading{
        font-size: $theme-lg-fs;
        line-height: 28px;
        width: 100%;
        font-weight: 400;
        margin-bottom: 0.5rem;
        color:$theme-color-white ;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    &-subheading{
        font-size: $theme-sm-fs;
        font-weight: 400;
        width: 100%;
        line-height: 22px;
        color: $theme-color-gray4;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}
.BeatesIcon-icon:hover{
    background: rgba(255, 255, 255, 0.1);
    border-radius: 10px;
    .BeatesIcon-icon-box-icon{
        opacity: 1;
        transform: translateY(0); 
    }
}


.swiper-button-prev{
color: white!important; 
  font-size: 18px;
  width: 20px;
  height: 20px;
//   opacity: 0;
//     visibility: hidden;
//   background-color: black !important;
  position: absolute !important;
  left: -2px !important;
  top: 120px !important;
//   display: none !important;
}
.swiper-button-next {
  color: white!important; 
  font-size: 18px;
//   opacity: 0;
//     visibility: hidden;
  width: 20px !important;
  height: 20px !important;
  background-color: black !important;
  position: absolute !important;
  right: -22px !important;
  top: 120px !important;
//   display: none !important;

}
.icon-hover-use{
    position: relative;

}

// .icon-hover-use:hover .swiper-button-prev,
// .icon-hover-use:hover .swiper-button-next {
//   opacity: 1 !important;
//   visibility: visible !important;
// }
  .swiper-button-prev:after, .swiper-button-next:after {
    font-family: swiper-icons;
    font-size: 25px !important;
}
.custom-prev,
.custom-next {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 10;
  cursor: pointer;
  color: #000; 
  font-size: 14px !important;
}




@media (min-width: 768px) and (max-width: 1023px) {
    
.BeatesIcon-icon{
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    padding: 1rem;
    
    &-container{
        height: 130px;
        width: 130px;
        border-radius: 10px;
        margin-bottom: 1rem;
        position: relative;
    }
    &-box{
        height: 130px;
        width: 130px;
        background: $theme-color-gray5;
        border-radius: 10px;
        margin-bottom: 1rem;
        position: relative;
        &-icon{
            position: absolute;
            right: 0px;
            bottom: 0px;
            display: none;
        }
    }
    &-heading{
        font-size: $theme-lg-fs;
        line-height: 28px;
        width: 100%;
        font-weight: 400;
        margin-bottom: 0.5rem;
        color:$theme-color-white ;
    }
    &-subheading{
        font-size: $theme-sm-fs;
        font-weight: 400;
        width: 100%;
        line-height: 22px;
        color: $theme-color-gray4;
    }
}
.BeatesIcon-icon:hover{
    background: none !important;
    border-radius: 10px;
    .BeatesIcon-icon-box-icon{
        display: block;
        width: 45px;
    }
}
     }

@media (min-width: 1024px) and (max-width: 1199px) {
    
.BeatesIcon-icon{
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    padding: 1rem;
    
    &-container{
        height: 135px;
        width: 135px;
        border-radius: 10px;
        margin-bottom: 1rem;
        position: relative;
    }
    &-box{
        height: 135px;
        width: 135px;
        background: $theme-color-gray5;
        border-radius: 10px;
        margin-bottom: 1rem;
        position: relative;
        &-icon{
            position: absolute;
            right: 0px;
            bottom: 0px;
            display: none;
        }
    }
    &-heading{
        font-size: $theme-sm-fs;
        margin-bottom: 0px; 
    }
    &-subheading{
        font-size: $theme-xsm-fs;
    }
}
.BeatesIcon-icon:hover{
    background: rgba(255, 255, 255, 0.1);
    border-radius: 10px;
    .BeatesIcon-icon-box-icon{
        display: block;
        width: 45px;
    }
}
     }

@media (min-width: 1200px) and (max-width: 1399px) {
 
.BeatesIcon-icon{
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    padding: 1rem;
    
    &-container{
        height: 169px;
        width: 168px;
        border-radius: 10px;
        margin-bottom: 1rem;
        position: relative;
    }
    &-box{
        height: 169px;
        width: 168px;
        background: $theme-color-gray5;
        border-radius: 10px;
        margin-bottom: 1rem;
        position: relative;
        &-icon{
            position: absolute;
            right: 0px;
            bottom: 0px;
            display: none;
        }
    }
    &-heading{
        font-size: $theme-lg-fs;
        line-height: 28px;
        width: 100%;
        font-weight: 400;
        margin-bottom: 0.5rem;
        color:$theme-color-white ;
    }
    &-subheading{
        font-size: $theme-sm-fs;
        font-weight: 400;
        width: 100%;
        line-height: 22px;
        color: $theme-color-gray4;
    }
}
.BeatesIcon-icon:hover{
    background: rgba(255, 255, 255, 0.1);
    border-radius: 10px;
    .BeatesIcon-icon-box-icon{
        display: block;
    }
}
  }

  
  @media (min-width: 576px) and (max-width: 1024px) {
    .BeatesIcon-icon {
      display: block;
    padding: 1rem 1rem 1rem 0rem;
    }
    .BeatesIcon:hover {
        background: none;
      }
      .swiper-button-prev{
        display: none !important;
      }
      .swiper-button-next{
          display: none !important;
        }
    
   
  }

  @media(min-width: 360px) and (max-width: 576px){

    .BeatesIcon-icon{
    display: block !important;
    padding: 1rem 1rem 1rem 0rem;
    
    &-container{
        width: 145px;
        height: 145px;
        border-radius:5px;
        margin-bottom: 8px !important;
    }
        &-box{
            width: 145px;
            height: 145px;
            border-radius:5px;
            margin-bottom: 8px !important;
            &-icon{
                display: none;
            }
        }
        &-heading{
            font-size: $theme-sm-fs;
            margin-bottom: 0px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            width: 145px;
        }
        &-subheading{
            font-size: $theme-xsm-fs;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            width: 145px;
         
        }
      
    }
    .beats-icon-mobile-view{
        width: min-content !important;
        margin-right: 24px !important;
        padding: 0rem !important;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 145px;
    }
    .BeatesIcon:hover {
        background: none !important;
      }
      .swiper-button-prev{
        display: none !important;
      }
      .swiper-button-next{
          display: none !important;
        }
  }


  @media(min-width: 320px) and (max-width: 360px){

    .BeatesIcon-icon:nth-of-type(even) {
        margin-right: 20px !important;
      }
    .BeatesIcon-icon{
    padding: 1rem 0rem 1rem 0rem;
     width: calc(50% - 24px) !important;
    margin-right: 24px !important;

        &-container{
            width: 130px;
            height: 130px;
            border-radius:5px;
            margin-bottom: 8px !important;
        }
        &-box{
            width: 130px;
            height: 130px;
            border-radius:5px;
            margin-bottom: 8px !important;
            &-icon{
                display: none;
            }
        }
        &-heading{
            font-size: 14px !important;
            line-height: 19.6px;
            font-weight: 400;
            margin-bottom: 0px; 
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            width: 145px;
        }
        &-subheading{
           font-size: 12px;
           line-height: 16.8px;
           white-space: nowrap;
           overflow: hidden;
           text-overflow: ellipsis;
           width: 145px;
         
        }
      
    }
    .beats-icon-mobile-view{
        width: min-content !important;
        margin-right: 24px !important;
        padding: 0rem !important;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 145px;
    }
      .BeatesIcon-icon:hover{
        background: none !important;

      }
      .swiper-button-prev{
        display: none !important;
      }
      .swiper-button-next{
          display: none !important;
        }
  }