
@import "../../../../../styles/variablesstyles.scss";



.investor-box-parent-daws{
    gap: $theme-spacing3;
}
.investor-boxs-daws{
    width: 187px;
    height: 187px;
    position: relative;
    border-radius: 10px;
    background: linear-gradient(rgba(29, 29, 29, 1), rgba(40, 40, 40, 1));
}
.investor-boxs-under{
    position: absolute;
    bottom: $theme-spacing4;
    left: $theme-spacing4;
    font-size: $theme-heading-5-size;
    line-height: 24.4px;
    color: $theme-color-gray5; 
    font-weight: 700;
    right: $theme-spacing4;
}
.investor-boxs-radiobutton{
    position: absolute;
    right: $theme-spacing1;
    top: $theme-spacing1;
}
.ctm-daws-text{
      color: $theme-color-gray2;
      font-size: $theme-xsm-fs;
      line-height: $theme-xsm-lh;
}
@media(min-width: 320px) and (max-width: 576px){

    .daws-content-mobile-space{
          margin-bottom: 1rem !important;
    }
}
@media(min-width: 360px) and (max-width: 576px){
        .investor-boxs-daws{
            width: 128px !important;
            height: 128px !important;
            border-radius: 5px !important ;
        }
    }
    @media(min-width: 320px) and (max-width: 360px){
        .investor-boxs-daws{
            width: 119px !important;
            height: 119px !important;
            border-radius: 5px !important ;
        }
        .investor-box-parent-daws{
            gap: 0.5rem;
            column-gap: 1.2rem;
        }
    }



