@import "../../../../../styles/variablesstyles.scss";

.ds-ctm-txt {
  font-weight: 700;
  color: $theme-color-gray2;
}
.btn-bg-gray3 {
  background: $theme-color-black3 !important;
}
.custom-pass-space {
  margin-bottom: 32px !important;
}
.custom-confirm-pass-space {
  margin-bottom: 24px !important;
}
.gray4 {
  color: $theme-color-gray4 !important;
}
.gray3 {
  color: $theme-color-gray4 !important;
}
.b-line {
  text-decoration: underline;
  text-decoration-color: $theme-color-gray4 !important;
}
.r-text {
  text-align: right;
}
.l-text {
  text-align: left;
}
.max-btn {
  color: rgba(252, 238, 33, 1) !important;
  font-weight: 400 !important;
  padding-right: $theme-spacing2 !important ;
}
.input-max::placeholder {
  color: $theme-color-gray4 !important;
}

.input-max {
  font-weight: 500 !important;
}

@media (min-width: 360px) and (max-width: 576px) {
  .modal-footer.social-mobile-modal-footer {
    padding: 0;
  }

  .custom-pass-space {
    margin-bottom: 8px !important;
  }

  .custom-confirm-pass-space {
    margin-bottom: 8px !important;
  }

  button.btn-wid.social-link-modal-btns.custom_btn_small {
    margin: 0px 0px 16px 0px;
    width: 100%;
  }

  .modal-footer.social-mobile-modal-footer {
    gap: 16px;
  }
}

@media (min-width: 320px) and (max-width: 360px) {
  .custom-pass-space {
    margin-bottom: 8px !important;
  }
  .custom-confirm-pass-space {
    margin-bottom: 8px !important;
  }

  .custom-pass-space {
    margin-bottom: 8px !important;
  }

  .custom-confirm-pass-space {
    margin-bottom: 8px !important;
  }

  .modal-footer.social-mobile-modal-footer {
    padding: 0;
  }
  button.btn-wid.social-link-modal-btns.custom_btn_small {
    margin: 0px 0px 16px 0px;
    width: 100%;
  }

  .modal-footer.social-mobile-modal-footer {
    gap: 16px;
  }
}

.deposit-amount-input .custom_inp {
  background-color: rgba(255, 255, 255, 0.05);
}
.scroll-pr-20{
  padding-right: 20px !important;
}
.scroll-pl-20{
  padding-left: 20px !important;
}