@font-face {
  font-family: "Gotham";
  src: url("../src/assets/font/GothamMedium.ttf");
}

@font-face {
  font-family: "Geomanist";
  src: url("../src/assets/font/geomanist-regular-webfont.ttf");
}
@import "../src/styles/variablesstyles.scss";

.kumbh-sans {
  font-family: "Kumbh Sans", sans-serif;
  font-optical-sizing: auto;
  font-style: normal;
  font-variation-settings: "YOPQ" 300;
}
.h-80vh{
  min-height: 80vh;
}

:root {
  --bs-table-bg: rgb(233, 19, 19);
}
.text-capitalize {
  text-transform: capitalize;
 
}
.disable-field{
  background: #ffffff06 !important;
  color: #828282 !important;
}
.disable-item{
  cursor: not-allowed !important;
}

body {
  margin: 0;
  font-family: "Kumbh Sans", sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
  background-color: #000;
}
p {
  margin: 0;
}


input:focus,
textarea:focus,
button:focus,
a:focus,
div:focus {
  outline: none;
}

@media (max-width: 768px) and (min-width: 577px) {
  body {
    font-size: 1.5rem;
  }
  .b-green-2, .b-red-2{
    border-radius: 5px !important;
  }
}

@media (max-width: 576px) {
  body {
    font-size: 1rem;
  }
  .b-green-2, .b-red-2{
    border-radius: 5px !important;
  }
}

.row {
  margin: 0;
}

* {
  padding: 0;
  margin: 0;
}

p {
  margin: 0;
}

/* #root {
  width: 100vw;
  min-height: 100vh;
  
  } */

input {
  font-family: "Kumbh Sans", sans-serif !important;
}

button {
  font-family: "Kumbh Sans", sans-serif !important;
}

.row {
  margin: 0;
}

.scrollbar-track {
  background-color: transparent !important;
  z-index: 100 !important;
}

.scrollbar-track-y {
  width: 12px !important;
  z-index: 100 !important;
 

}

.scrollbar-thumb {
  width: 12px !important;
  /* background-color: rgb(255, 238, 238) !important; */
  border-radius: 5px !important;
  z-index: 100 !important;
 
}

.b-0{
  border: 0;
}
.b-red-2{
  border: 1px solid  #dc3545!important;
  border-radius: 10px;
  background-color: rgba(255, 255, 255, 0.05);
box-shadow: none !important;
}
.b-green-2{
  border: 1px solid #198754!important;
  border-radius: 10px;
  /* background-color: transparent !important; */
  box-shadow: none !important;
}
.react-apexcharts {
  height: 100% !important; /* Force the chart to cover the full height of the parent */
}

.invalid-feedback{
display: block !important;
}
/* @media(min-width:1921px){
  .page-content{
    margin: 12.5% 23% !important;

  }  
}
@media(max-width:1920px) and (min-width:1200px){
  .page-content{
    margin: 7.5% 19% !important;
  }  
} */
@media (max-width: 1199px) {
  .page-content {
    margin: 60px auto !important;
    padding: 10px;
  }
}

@media (min-width: 1400px) {
  .container-new {
    max-width: 1500px !important;
  }
}

.component-width {
  width: 1388px;
}

/* width */
::-webkit-scrollbar {
  width: 5px !important;
  z-index: 100 !important;
}
.error-red{
  color: #dc3545 !important;
}
/* Track */
::-webkit-scrollbar-track {
  background: transparent !important;
  transition: opacity 0.5s 0.5s ease-out;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background:rgba(130, 130, 130, 1);
  border-radius: 5px !important;
  transition: opacity 0.5s 0.5s ease-out;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgba(130, 130, 130, 1);
  opacity: 0.5;
}

.no-styles {
  all: unset !important;
}

.fade {
  transition: opacity 200ms ease-out, opacity 200ms ease-out;
}

.fade.fade-in {
  opacity: 1;
  /* transform: scale(1); */
}

.fade.fade-out {
  opacity: 0;
  /* transform: scale(0.9); */
}

.cursor-pointer {
  cursor: pointer;
}

.gradient-text {
  background: linear-gradient(
    to right,
    rgba(55, 48, 236, 0.7),
    rgba(169, 16, 121, 1)
   25%);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  font-weight: 700;
}

.gradient-text-2 {
  background: linear-gradient(
    to right bottom,
    rgba(169, 16, 121, 1),
    rgba(252, 238, 33, 1)
  );
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  font-weight: 700;
}

.gradient-btn-2 {
  background: linear-gradient(
    298.83deg,
    #fcee21 2.95%,
    #a91079 85.66%
  ) !important;
}

.container,
.container-xl,
.container-xxl {
  padding-left: 0 !important;
  padding-right: 0 !important ;
}

.uni-fw-400 {
  font-weight: 400 !important;
}

.uni-fw-700 {
  font-weight: 700;
}

.uni-fw-600 {
  font-weight: 600 !important;
}

@media (min-width: 2560px) {
  .container-xxl {
    max-width: 1386px !important;
  }
}
@media (min-width: 1400px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl,
  .container-xxl {
    max-width: 1386px !important;
  }
}

.min-h-800 {
  min-height: 800px;
}

.w-30{
  width: 30%;
}
/* 
.css-tj5bde-Svg{
  opacity: 0 !important;
  } */
.css-1xc3v61-indicatorContainer{
  background-size: 0.6rem !important;
}
.custom-select__indicator{
  background-size: 0.6rem !important;
}
input:-webkit-autofill {
  background-color: #f0f0f000 !important;
  /* 
  background-image: none !important; Keep it the same, removing any background image 
  background-color: linear-gradient(to right, rgb(232, 240, 254), rgba(70, 90, 126, 0.4)) !important;  Using a linear gradient for the background */
}
.fs-18{
  font-size: 18px !important;
}

.css-8mmkcg,.css-8mmkcg:active{
  opacity: 1 !important;
}

.loader {
  width: 50px;
  aspect-ratio: 1;
  border-radius: 50%;
  background:radial-gradient(farthest-side,rgba(169, 16, 121, 1) 94%,#0000) top/8px 8px no-repeat,conic-gradient(#0000 30%,rgba(169, 16, 121, 1));
  -webkit-mask: radial-gradient(farthest-side,#0000 calc(100% - 8px),#000 0);
  animation: l13 1s infinite linear;
}
/* .loader-white {
  width: 50px;
  aspect-ratio: 1;
  border-radius: 50%;
  background:radial-gradient(farthest-side,rgb(236, 231, 235) 94%,#0000) top/8px 8px no-repeat,conic-gradient(#0000 30%,rgba(236, 231, 235));
  -webkit-mask: radial-gradient(farthest-side,#0000 calc(100% - 8px),#000 0);
  animation: l13 1s infinite linear;
} */
@keyframes l13{ 
  100%{transform: rotate(1turn)}
}
@media(min-width: 360px) and (max-width: 768px){

  .invite-disabled-btn-mbl{
      pointer-events: none;
  }
  .error-red{
    font-size: 10px !important;
  }

}

@media(max-width: 1024px){
  .mobile-order-1{
    order: 1 !important;
  }

}

@media(min-width: 320px) and (max-width: 360px){

  .invite-disabled-btn-mbl{
      pointer-events: none;
  }
  ::-webkit-scrollbar {
    width: 5px !important;
    z-index: 100 !important;
    height: 3px !important;
  }
  .error-red{
    font-size: 10px !important;
  }
}


@media(max-width: 768px){
  .hide-on-web {
    display: inline-block;
  }

}

@media(min-width: 768px){
  .hide-on-web {
    display: none;
  }
  .mt-3rem{
    margin-top: 2.5rem !important;
    }

}
.dropdown-toggle::after{
  display: none !important;
}


.bg-black{
  background-color: #000 !important;
  background: #000 !important;
}






.loader-white {
  width: 48px;
  height: 48px;
  border: 5px solid #FFF;
  border-bottom-color: transparent;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
  }

  @keyframes rotation {
  0% {
      transform: rotate(0deg);
  }
  100% {
      transform: rotate(360deg);
  }
  } 

  .mandatary::after{
    content: "*";
    color:#dc3545  !important;
    font-size: inherit;
  }
  .white-space-n{
    white-space: nowrap !important;
  }
.uni-mb-12{
  margin-bottom: 12px;
}