@import "../../styles/variablesstyles.scss";

.invite {
    &_firstcolumn {
        gap: $theme-spacing2;
        &_imagebox {
            width: 130px;
            height: 130px;
            border-radius: 50%;

        }
        &_vocalist {
            border: none;
            color: black;
            width: fit-content;
            line-height: 41px;
            line-height: 22.4px;
            border-radius: 9px, 16px, 9px, 16px;
            padding: 9px 16px 9px 16px;
            border-radius: 10px;
            color: white;
            background-color: #FFFFFF1A;
            min-width: 90px;
            width: max-content;
        }
        &_percentage {
            border: none;
            color: black;
            width: fit-content;
            height: 41px;
            line-height: 22.4px;
            border-radius: 9px, 16px, 9px, 16px;
            padding: 9px 16px 9px 16px;
            border-radius: 10px;
            color: white;
            background-color: #FFFFFF1A;
        }
        &_activebtn-yel {
            border: none;
            color: black;
            width: fit-content;
            height: 41px;
            line-height: 22.4px;
            padding: 9px 16px 9px 16px;
            border-radius: 10px;
            color: rgba(252, 238, 33, 1);
            background-color: #FFFFFF1A;
            min-width: 64px;
        }
        &_activebtn-red {
            border: none;
            color: black;
            width: fit-content;
            height: 41px;
            line-height: 22.4px;
            padding: 9px 16px 9px 16px;
            border-radius: 10px;
            color: rgba(235, 87, 87, 1);
            background-color: #FFFFFF1A;
            min-width: 64px;

        }
        &_activebtn-grn {
            border: none;
            color: black;
            width: fit-content;
            height: 41px;
            line-height: 22.4px;
            padding: 9px 16px 9px 16px;
            border-radius: 10px;
            color: rgba(39, 174, 96, 1);
            background-color: #FFFFFF1A;
            min-width: 64px;
        }

        &_activebtn-gry {
            border: none;
            color: black;
            width: fit-content;
            height: 41px;
            line-height: 22.4px;
            padding: 9px 16px 9px 16px;
            border-radius: 10px;
            color: rgba(130, 130, 130, 1);
            background-color: #FFFFFF1A;
            min-width: 64px;
        }

    }

    &-subheading {
        font-size: $theme-sm-fs;
        font-weight: 400;
        line-height: 22px;
        color: $theme-color-gray4;
    }

    .invite-main {
        width: calc(50% - 8px);
        border: rgba(255, 255, 255, 0.05) !important;
        position: relative;  
    }

    .fw-700 {
        font-weight: 700;
        margin: 0;
        display: flex;
        align-items: center;
        text-wrap: nowrap;


    }
    .invite-subname{

        color: $theme-color-gray5;
        line-height: 22.4px;
    }
   
}

.invite-main:hover {
    background: rgba(255, 255, 255, 0.1);
    border-radius: 10px;
 
}


@media (max-width:1400px) {
    .invite-main {
        width: calc(100% - 8px) !important;
        display: flex;
        justify-content: center;
    }
}
@media(min-width: 576px) and (max-width: 1024px){
    .invite_firstcolumn{
         flex-wrap: wrap;
    }
    .invite-mobile-view-btns{
       margin-top: 1rem !important; 
     }
}

@media(min-width: 360px) and (max-width: 576px){
    .invite {
        &_firstcolumn {
            flex-wrap: wrap;
            // align-items: flex-start !important;
            &_imagebox {
                width: 58px;
                height: 58px;    
            }
            &_vocalist{
                padding: 4px 9px;
                height: 25px;
                border-radius: 5px;
                font-size: 10px;
                vertical-align: middle !important;
                width: max-content !important;
                min-width: auto !important;
                line-height: 19.6px;
            }
            &_percentage {
                width: 36px;
                height: 25px;
                line-height: 19.6px;
                padding: 4px 9px;
                border-radius: 5px;
                font-size: 10px;
            }
            &_activebtn-yel{
                width: 48px;
                height: 25px;
                line-height: 19.6px;
                padding: 4px 9px;
                border-radius: 5px;
                font-size: 10px;
            }
            &_activebtn-red{
                width: 60px;
                height: 25px;
                line-height: 19.6px;
                padding: 4px 9px;
                border-radius: 5px;
                font-size: 10px;
            }
            &_activebtn-grn {
                width: 64px;
                height: 25px;
                line-height: 19.6px;
                padding: 4px 9px;
                border-radius: 5px;
                font-size: 10px;
            }
            &_activebtn-gry {
                width: 54px;
                height: 25px;
                line-height: 19.6px;
                padding: 4px 9px;
                border-radius: 5px;
                font-size: 10px;
            }
         
        }
     }
    .invite-name{
      font-size: 16px;
      line-height: 19.6px !important;
     }
     .invite-subname{
        font-size: 14px;
        line-height: 19.6px !important;
     }
     .invite-mobile-view-btns{
        gap: 0.7rem !important;
        position: absolute;
        left: 12px;
        bottom: 8px;
        margin-top: 0px !important;
        
     }
     .invite-mobile-bluetick{
        width: 16px !important;
     }
     .invite-main{
        background: rgba(0, 0, 0, 0.25);
        border-radius: 10px;
        padding: 0.5rem !important;
        margin-bottom: 16px !important;
        width: calc(100%) !important;
        position: relative;  
        height: 113px !important;
       
    }
    
}
@media(max-width: 360px){
    .invite {
        &_firstcolumn {
            flex-wrap: wrap;
            align-items: flex-start !important;
            &_imagebox {
                width: 58px;
                height: 58px;    
            }
            &_vocalist{
                padding: 4px 9px;
                height: 25px;
                border-radius: 5px;
                font-size: 10px;
                vertical-align: middle !important;
                width: max-content !important;
                min-width: auto !important;
                line-height: 19.6px;
            }
            &_percentage {
                width: 36px;
                height: 25px;
                line-height: 19.6px;
                padding: 4px 9px;
                border-radius: 5px;
                font-size: 10px;
            }
            &_activebtn-yel{
                width: 48px;
                height: 25px;
                line-height: 19.6px;
                padding: 4px 9px;
                border-radius: 5px;
                font-size: 10px;
            }
            &_activebtn-red{
                width: 60px;
                height: 25px;
                line-height: 19.6px;
                padding: 4px 9px;
                border-radius: 5px;
                font-size: 10px;
            }
            &_activebtn-grn {
                width: 64px;
                height: 25px;
                line-height: 19.6px;
                padding: 4px 9px;
                border-radius: 5px;
                font-size: 10px;
            }
            &_activebtn-gry {
                width: 54px;
                height: 25px;
                line-height: 19.6px;
                padding: 4px 9px;
                border-radius: 5px;
                font-size: 10px;
            }
         
        }
     }
    .invite-name{
      font-size: 16px;
      line-height: 19.6px;
     }
     .invite-subname{
        font-size: 14px;
        line-height: 19.6px !important;
     }
     .invite-mobile-view-btns{
        gap: 0.5rem !important;
        margin-top: 0px !important;
        position: absolute;
        left: 12px;
        bottom: 8px;
     }
     .invite-mobile-bluetick{
        width: 16px !important;
     }
   .invite-main {
       width: calc(50% - 8px);
       border: 1px solid rgba(255, 255, 255, 0.05) !important;
       position: relative;  
       height: 113px !important;
   }
    
}