@import "../../../styles/variablesstyles.scss";

.track-page {
  &-main {
    &-sub:nth-child(even) {
      >div {
        margin-left: auto;
        width: 373px;
      }
    }

  }

  &-heading {
    font-size: $theme-heading-2-size;
    font-weight: 700;
  }

  &-sub-song-name {
    font-size: 24px;
    font-weight: 700;
    line-height: 26.4px;
    text-align: left;
  }

  .track-metaballsmenudropdown {
    padding: 13px 16px;
  }

  .maxy-wid {
    width: 150px;
  }

  &-inputbox {
    width: 498px !important;
  }

  &-subheading {
    font-size: $theme-heading-5-size;
    margin-bottom: 2rem;
    width: fit-content;
    padding-bottom: 2px;
    font-size: 32px;
    font-weight: 700;
    line-height: 35.2px;
    text-align: left;
  }

  &-subheading-link {
    font-size: $theme-heading-5-size;
    font-weight: 700;
    margin-bottom: 2rem;
    width: fit-content;
    padding-bottom: 2px;
  }

  &-usericon {
    margin-bottom: 56px;
  }

  &-dropdown {
    background-color: rgba(255, 255, 255, 0.05);
    border: none;
  }

  &-body {
    display: flex;
    height: 35vh;
    margin: 5vh 0 0 0;
    align-items: flex-end;
  }

  &-content {
    margin-left: 25px;
  }

  &-sub-content {
    display: flex;
    align-items: center;
    gap: 20px;
    justify-content: start;
    margin-left: 1.5rem;
    &-img {
      width: 130px;
      height: 130px;
      border-radius: 50%;
      object-fit: cover;
    }
  }

  &-details {
    // display: flex;
    align-items: flex-start;
    flex-direction: column;
  }

  &-sub-details {
    display: flex;
    align-items: flex-start;
  }

  &-song-title {
    font-size: 18px;
    font-weight: 400;
    max-width: 314px;
    white-space: break;
    line-height: 25px;
    color: rgb(224, 224, 224);

  }

  &-para {
    font-size: 16px;
    font-weight: 400;
    line-height: 22.4px;
    text-align: "justified";
    color: $theme-color-gray5;
  }

  &-image {
    width: auto;
    height: 35vh;
    border-radius: 16px;
    object-fit: cover;
  }

  &-playback-Btn {
    cursor: pointer;
    color: $theme-color-white;
    transition: transform 0.2s ease-in-out, box-shadow 0.2s ease;

    &:hover {
      transform: scale(1.04);
      // box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.2);
    }

    &:active {
      transform: scale(0.95);
      // box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.15);
    }
  }

  &-bottons {
    display: flex;
    gap: 16px;
    max-width: 493px;
    max-height: 48px;
    // margin-left: 10px;
    color: $theme-color-white;
    height: 24px;
  }

  .track-page-subheading-link:hover {
    color: $theme-color-white;
    border-bottom: 1px solid;
    padding-bottom: 1px;
    cursor: pointer;
  }
}
.track-pc-hide-icon{
   display: none;
}
.mbl-heart-icon-track{
  display: none;
}

.track-page-palyer {
  width: 19.2px;
  height: 16.8px;
}

.overflow-x-hidden {
  overflow-x: auto;
}

.play-btn-track {
  width: 90px;
  height: 90px;
}

.mobile-arrow-track{
  display: none !important;
}


.uni-bg-disable {
  background: linear-gradient(313.89deg, #1D1D1D 1.71%, #282828 98.51%) !important;
  width: 198px;
}

.user-profiles-select-buttons {
  color: $theme-color-gray5;
  // font-size: $theme-xsm-fs;
  // line-height: 19.6px;
  color: $theme-color-gray5;
  // padding: 14px 70px 14px 70px;
  width: 202px !important;
  padding: 10px;
}


@media (min-width: 1024px) and (max-width: 1199px) {

  .track-page-song-title {
    max-width: 279px;
  }
}

@media(max-width: 1400px) {

  .track-page-song-title {

    max-width: 300px;
  }
}

@media(max-width: 1600px) {
  .track-page-main {
    width: 90%;
  }
}
@media(min-width: 576px) and (max-width: 1024px){
    .track-page {
      &-body {
        display: flex;
        height: auto;
        margin-top: 0px;
        flex-direction: column;
        margin-bottom: 16px !important;
      }
  
      &-image {
        width: 100%;
        height: 312px;
        border-radius: 10px;
      }
  
      &-content {
        margin-left: 0px;
      }
  
      &-subheading {
        font-size: 16px;
        margin-bottom: 1rem;
        white-space: nowrap;
  
      }
  
      &-main {
        // display: block !important;
        width: 100% !important;
      }
  
      &-sub-content {
        margin-left: 0px !important;
        &-img {
          width: 58px;
          height: 58px;
        }
      }
  
      &-sub-song-name {
        font-size: 14px;
        line-height: 19.6px;
        text-wrap: nowrap;
        margin-bottom: 0px;
      }
  
    }
    .track-page-main-sub:nth-child(even) > div {
      margin-left: auto;
      width: 100%;
  }
  
    .play-btn-track {
      width: 40px;
      height: 40px;
      margin-left: 16px !important;
    }
  
    .mobile-viewtrackbutton {
      display: block !important;
    }
  
    .track-page-main-sub {
      margin-bottom: 0.5rem !important;
    }
  
    .track-page-song-title {
      font-size: 14px;
      font-weight: 400;
      line-height: 19.6px;
  
    }
  
    .track-page-content {
      margin-top: 16px;
    }
  
    .user-lables {
      font-weight: normal;
      border: 0;
      cursor: pointer !important;
      color: rgb(255, 255, 255);
      padding: 6px 8px;
      text-align: center;
      border-radius: 10px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-weight: 400;
      left: 0;
      font-size: 14px !important;
      line-height: 19.6px;
      display: inline !important;
    }
    .user-lables-gradient{
      margin: 0px 8px 8px 8px;
    }
    .head{
      padding: 10px 0px;
    }
    .track-page-content{
      position: relative;
      width: 100%;
    }
    // .track-page-playback-Btn{
    //   position: absolute;
    //   top: 5px;
    //   left: 185px;
    // }
    // .heart-icon-track{
    //   position: absolute;
    //   top: 0px;
    //   right: 0px;
    //   padding: 10px 0px !important;
    //   > img{
    //     width: 24px;
    //     height: 24px;
    //   }
    // }
    .image-track{
      width: 100%;
    }
    .bg-three-dot{
      background: linear-gradient(313.89deg, #1D1D1D 1.71%, #282828 98.51%);
      border-radius: 10px;
    }
    .track-page-sub-details{
      // width: 100%;
      align-items: center !important;
    }
    .drop-track{
      margin-left: auto;
    }
    .track-page-head-content{
      margin-bottom: 16px !important;
    }
    .track-mbl-view{
      display: flex;
      align-items: baseline !important;
      justify-content: space-between;
    }
    .mbl-heart-icon-track{
      height: 24px !important;
      width: 24px !important;
      display: inline !important;
  
    }
    .track-pc-hide-icon{
      display: inline !important;
   }
   .track-page-playback-Btn{
    //  visibility: hidden;
    display: none;
  }
  .heart-icon-track{
     display: none !important;
  }
  .track-lable-btm{
    margin-bottom: 1rem !important;
  }
  
}
@media(min-width:576px) and (max-width: 767px){
  .mobile-arrow-track{
    background: linear-gradient(313.89deg, #1D1D1D 1.71%, #282828 98.51%);
    font-size: 12px;
    font-weight: 600;
    line-height: 19.6px;
    padding: 5px;
    border-radius: 5px;
    color: #FFFFFF;
    display: flex !important;
  }
  .convert-more{
    display: none !important;
  }
  .user-profiles-select-buttons {
    color: $theme-color-gray5;
    // font-size: $theme-xsm-fs;
    // line-height: 19.6px;
    color: $theme-color-gray5;
    // padding: 14px 70px 14px 70px;
    width: 100% !important;
    font-size:12px;
    margin: 0px;
    margin-bottom: 16px !important;
  }
  .uni-bg-disable {
    background: linear-gradient(313.89deg, #1D1D1D 1.71%, #282828 98.51%) !important;
    width: 198px;
  }
}

@media(min-width: 320px) and (max-width: 576px) {
  .track-page {
    &-body {
      display: flex;
      height: auto;
      margin-top: 0px;
      flex-direction: column;
      margin-bottom: 16px !important;
    }

    &-image {
      width: 100%;
      height: 312px;
      border-radius: 10px;
    }

    &-content {
      margin-left: 0px;
    }

    &-subheading {
      font-size: 16px;
      margin-bottom: 1rem;
      white-space: nowrap;

    }

    &-main {
      display: block !important;
      width: 100% !important;
      margin-top: 3rem !important;
    }

    &-sub-content {
         gap: 0.5rem;
         margin-left: 0px !important;
      &-img {
        width: 58px;
        height: 58px;
      }
    }

    &-sub-song-name {
      font-size: 14px;
      line-height: 19.6px;
      text-wrap: nowrap;
      margin-bottom: 0px;
    }

  }
  .uni-bg-disable {
    background: linear-gradient(313.89deg, #1D1D1D 1.71%, #282828 98.51%) !important;
    width: 198px;
  }

  .user-profiles-select-buttons {
    color: $theme-color-gray5;
    // font-size: $theme-xsm-fs;
    // line-height: 19.6px;
    color: $theme-color-gray5;
    // padding: 14px 70px 14px 70px;
    width: 100% !important;
    font-size:12px;
    margin: 0px;
    margin-bottom: 16px !important;
  }

  .track-page-main-sub:nth-child(even) > div {
    margin-left: auto;
    width: 100%;
}
  .play-btn-track {
    width: 40px;
    height: 40px;
    margin-left: 16px !important;
  }

  .mobile-viewtrackbutton {
    display: block !important;
  }

  .track-page-main-sub {
    margin-bottom: 0.5rem !important;
  }

  .track-page-song-title {
    font-size: 14px;
    font-weight: 400;
    line-height: 19.6px;
    width: 70%;
    margin-top: 0.5rem !important;
  }

  .track-page-content {
    margin-top: 16px;
  }

  .user-lables {
    font-weight: normal;
    border: 0;
    cursor: pointer !important;
    color: rgb(255, 255, 255);
    padding: 6px 8px;
    text-align: center;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 400;
    font-size: 12px ;
    left: 0;
    line-height: 19.6px;
    display: inline !important;
  }
  .user-lables-gradient{
    margin: 0px 8px 8px 8px;
  }
  .head{
    padding: 10px 0px;
  }
  .track-page-content{
    position: relative;
    width: 100%;
  }
  // .track-page-playback-Btn{
  //   position: absolute;
  //   top: 5px;
  //   left: 122px;
  // }
  // .heart-icon-track{
  //   position: absolute;
  //   top: 0px;
  //   right: 0px;
  //   padding: 10px 0px !important;
  //   > img{
  //     width: 24px;
  //     height: 24px;
  //   }
  // }
  .image-track{
    width: 100%;
  }
  .bg-three-dot{
    background: linear-gradient(313.89deg, #1D1D1D 1.71%, #282828 98.51%);
    border-radius: 10px;
  }
  .track-page-sub-details{
    width: 100%;
    align-items: center !important;
  }
  .drop-track{
    margin-left: auto;
  }
  .track-page-head-content{
    margin-bottom: 16px !important;
  }
  .mobile-arrow-track{
    background: linear-gradient(313.89deg, #1D1D1D 1.71%, #282828 98.51%);
    font-size: 12px;
    font-weight: 600;
    line-height: 19.6px;
    padding: 5px;
    border-radius: 5px;
    color: #FFFFFF;
    display: flex !important;
  }
  .convert-more{
    display: none !important;
  }
  .track-more-btn{
    margin-top: 1rem !important;
  }
  .track-metaballsmenudropdown {
    padding: 13px 0px !important;
  }
  .track-mbl-view{
    display: flex;
    align-items: baseline !important;
    justify-content: space-between;
  }
  .mbl-heart-icon-track{
    height: 24px !important;
    width: 24px !important;
    display: inline !important;

  }
  .track-pc-hide-icon{
    display: inline !important;
 }
 .track-page-playback-Btn{
  //  visibility: hidden;
  display: none;
}
.heart-icon-track{
   display: none !important;
}
.track-lable-btm{
  margin-bottom: 1rem !important;
}
}

@media(min-width: 320px) and (max-width: 360px) {
  .track-page {
    &-body {
      display: flex;
      height: auto;
      margin-top: 0px;
      flex-direction: column;
      margin-bottom: 16px !important;
    }

    &-image {
      width: 100%;
      height: 312px;
      border-radius: 10px;
    }

    &-content {
      margin-left: 0px;
    }

    &-subheading {
      font-size: 16px;
      margin-bottom: 1rem;
      white-space: nowrap;

    }

    &-main {
      display: block !important;
      width: 100% !important;
      margin-top: 3rem !important;
    }

    &-sub-content {
      gap: 0.5rem;
      margin-left: 0px !important;
      &-img {
        width: 58px;
        height: 58px;
      }
    }

    &-sub-song-name {
      font-size: 14px;
      line-height: 19.6px;
      text-wrap: nowrap;
      margin-bottom: 0px;
    }

  }

  .track-page-main-sub:nth-child(even) > div {
    margin-left: auto;
    width: 100%;
}
  .play-btn-track {
    width: 40px;
    height: 40px;
    margin-left: 16px !important;
  }

  .mobile-viewtrackbutton {
    display: block !important;
  }

  .track-page-main-sub {
    margin-bottom: 0.5rem !important;
  }

  .track-page-song-title {
    font-size: 14px;
    font-weight: 400;
    line-height: 19.6px;
    width: 70%;
    margin-top: 0.5rem !important;
  }

  .track-page-content {
    margin-top: 16px;
  }

  .user-lables {
    font-weight: normal;
    border: 0;
    cursor: pointer !important;
    color: rgb(255, 255, 255);
    padding: 6px 8px;
    text-align: center;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 400;
    font-size: 14px;
    line-height: 19.6px;
    display: inline !important;
  }
  .user-lables-gradient{
    margin: 0px 8px 8px 8px;
  }
  .head{
    padding: 10px 0px;
  }
  .track-page-content{
    position: relative;
    width: 100%;
  }
 
  .image-track{
    width: 100%;
  }
  .bg-three-dot{
    background: linear-gradient(313.89deg, #1D1D1D 1.71%, #282828 98.51%);
    border-radius: 10px;
  }
  .track-page-sub-details{
    width: 100%;
    align-items: center !important;
  }
  .drop-track{
    margin-left: auto;
  }
  .track-page-head-content{
    margin-bottom: 16px !important;
  }
  .mobile-arrow-track{
    background: linear-gradient(313.89deg, #1D1D1D 1.71%, #282828 98.51%);
    font-size: 12px;
    font-weight: 600;
    line-height: 19.6px;
    padding: 5px;
    border-radius: 5px;
    color: #FFFFFF;
    display: flex !important;
  }
  .convert-more{
    display: none !important;
  }
  .track-more-btn{
    margin-top: 1rem !important;
  }
  .track-metaballsmenudropdown {
    padding: 13px 0px !important;
  }
  .track-mbl-view{
    display: flex;
    align-items: baseline !important;
    justify-content: space-between;
  }
  .mbl-heart-icon-track{
    height: 24px !important;
    width: 24px !important;
    display: inline !important;
  }
  .track-pc-hide-icon{
    display: inline !important;
 }
 .track-page-playback-Btn{
  //  visibility: hidden;
  display: none;
}
.heart-icon-track{
   display: none !important;
}
.track-lable-btm{
  margin-bottom: 1rem !important;
}
}