
@import "../../../styles/variablesstyles.scss";



.uni-fs-24{
    font-size: 24px;
}
.notification-setting-icon{
    color: $theme-color-gray4;
    opacity: 0.7;
}
.notification-setting-icon:hover{
    transition: all 0.7s ease;
    color: $theme-color-white;
    opacity: 1;
}

.notify-yellow-dot{
    width: 10px;
    height: 10px;
    background-color: yellow;
    border-radius: 50%;
    display: inline-block;
}

.fc-g5{
    color: $theme-color-gray5;
}

.fc-g3{
    color: $theme-color-gray3;
}
.notify-subheading{
    font-size: $theme-lg-fs;
   line-height: $theme-lg-lh;
}

.notify-show-more{
    color: $theme-color-gray4;
    border-bottom: 1px solid $theme-color-gray4;
    font-size: 14px;
    line-height: 19.6px;
    cursor: pointer;
    
}

.notify-section{
border-top: 1px solid $theme-color-gray4;
}

.notify-section-2{
    border-top: 1px solid $theme-color-gray4;
    border-bottom: 1px solid $theme-color-gray4;
    margin-bottom: 112px;
    }

.text-container {
    width: 100%;         
    white-space: nowrap;  
    overflow: hidden;    
    text-overflow: ellipsis; 
    border: 1px solid #ccc; 
    padding: 5px;        
}
.ellipsis-icon{
  
    display: -webkit-box !important;
    -webkit-box-orient: vertical !important;
    -webkit-line-clamp: 1 !important;
    line-clamp: 1 !important;
    overflow: hidden;
    text-overflow: ellipsis;
}
.ellipsis-icon-isExpanded{
    display: -webkit-box !important;
    -webkit-line-clamp: 1 !important;
    line-clamp: 1 !important;
    overflow: hidden;
    text-overflow: ellipsis;
}

@media(min-width: 576px) and (max-width: 768px){
    .notification-mobile-date{
        font-size: 14px;
        line-height: 17.8px;
        white-space:nowrap;
    }
}
@media(min-width: 360px) and (max-width: 567px){

    .notification-setting-icon {
        margin-left: 8px !important;
        width: 14px;
        height: 14px;
        margin-bottom: 2px;
    }
    .notify-subheading {
        font-size: 16px !important;
        line-height: 17.6px !important;
    }
    .notify-yellow-dot{
        width: 7px !important;
        height: 7px !important;
    }
    .notification-mobile-date{
        font-size: 12px;
        line-height: 16.8px;
        white-space:nowrap;
    }
}

@media(min-width: 320px) and (max-width: 360px){

    .notify-subheading {
        font-size: 16px !important;
        line-height: 17.6px !important;
    }
    .notification-mobile-date{
        font-size: 12px;
        line-height: 16.8px;
        white-space:nowrap;
    }
    .notification-short-text{
        font-size: 14px;
        line-height: 19.6px;
        font-weight: 400;
    }
    .chevronright-mobileview{
        font-size: 14px;
    }
    .notification-setting-icon {
        margin-left: 8px !important;
        width: 14px;
        height: 14px;
        margin-bottom: 2px;
    }
    .notification-longtext-mbl{
        font-size: 14px !important;
        line-height: 19.6px;
    }
    .notify-yellow-dot{
        width: 7px !important;
        height: 7px !important;
    }
}

