@import "../../styles/variablesstyles.scss";

.meatballs-menu {
  font-weight: normal;
  background: transparent;
  cursor: pointer;
  border: 0;
  color: $theme-color-gray4;
  cursor: pointer;
  padding: 9px 16px;
  text-align: center;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1rem;
  
  &-image {
    height: 9px;
    opacity: 0.6;
    transition: all 0.7s ease;
  }
}
.dropdown-menu-meatballs-Icon {
  background-color: $theme-dropdown-background-black;
  border-radius: 10px;
  overflow: hidden
}
.dropdown-item-meatballs {
  padding: 0px;
  color: $theme-color-gray4 ;
  line-height: 22.4px !important;
}
.dropdown-menu-meatballs-Icon-li > a{
  padding: 16px;
}

.dropdown-item-meatballs:hover{
  color: $theme-color-white !important;
  background-color: transparent !important;
}

.dropdown-menu-meatballs-Icon-li:hover{
  background: rgba(255, 255, 255, 0.3);

  // background: linear-gradient(313.89deg, #1D1D1D 1.71%, #282828 98.51%);
  // border-radius: 10px;
  color: $theme-color-white !important;
  .dropdown-item-meatballs{
     color: $theme-color-white !important;
  }
}
.meatballs-menu:hover,.meatballs-menu:focus-within{
  .meatballs-menu-image{
    opacity: 1;
  }
}

.dropdown, .dropdown-center, .dropend, .dropstart, .dropup, .dropup-center{
  position:static !important;
}

.dropdown.position-absolute{
  position: absolute !important;
}
.meatballs-menu-image-opacity{
  opacity: 1 !important;
}
.dropdown-item:focus{
  background-color: transparent;
  color: $theme-color-gray4;
}


// .dropdown-menu-meatballs-Icon-li:first-child:hover{
//   border-radius: 10px 10px 0px 0px !important;
//   background-color: red;
// }
// .dropdown-menu-meatballs-Icon-li:last-child:hover{
//   border-radius: 0px 0px 10px 10px !important;
// }
@media(min-width: 360px) and (max-width: 576px){
  .dropdown-item-meatballs {
    padding: 0px;
    line-height: 16.3px !important;
    font-size: 12px !important;
  }
  .dropdown-menu-meatballs-Icon-li{
    padding: 8px;
    text-align: center;
  }
  .dropdown-menu-meatballs-Icon-li > a{
    padding: 0px;
  }
  .dropdown-menu-meatballs-Icon {
    border-radius: 5px;
  }
  .meatballs-menu-image{
    width: 20px !important;
    height: 2px !important;
  }

}

@media(min-width: 320px) and (max-width: 360px){
  .meatballs-menu-image{
    width: 20px !important;
    height: 2px !important;
  }
  .dropdown-item-meatballs {
    padding: 0px;
    line-height: 16.3px !important;
    font-size: 12px !important;
  }
  .dropdown-menu-meatballs-Icon-li{
    padding: 8px;
  }
  .dropdown-menu-meatballs-Icon {
    border-radius: 5px;
  }
  .dropdown-menu-meatballs-Icon-li > a{
    padding: 0px;
  }

}