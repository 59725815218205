.chat-container {
    display: flex;
    justify-content: flex-end;
     margin-bottom: 8px;
     position: relative;
     .message-bubble-send-image {
      background-color: #1c1f24;
      border-radius: 10px;
      padding: 5px;
      color: #ffffff;
      // max-width: 300px;
      font-family: Arial, sans-serif;
      // width: 280px;
     }
  
    .message-bubble {
      background-color: #1c1f24;
      border-radius: 10px;
      padding: 5px;
      color: #ffffff;
      max-width: 300px;
      font-family: Arial, sans-serif;
      width: 280px;
     
  
      .file-info {
        display: flex;
        align-items: flex-start;
        background: black;
        border-radius: 5px;
        padding: 12px;
        .file-icon {
          margin-right: 10px;
  
          .icon-image {
            width: 32px;
            height: 32px;
          }
        }
  
        .file-details {
          flex: 1;
  
          .file-name {
            font-weight: bold;
            font-size: 14px;
          }
  
          .file-meta {
            font-size: 12px;
            color: #a9a9a9;
          }
        }
  
        .downlaodIcon{
          cursor: pointer;
          height: 27px;
  
          &:hover {
            color: #58a6ff;
          }
        }
      }
  
      .message-time {
        font-size: 10px;
        color: #a9a9a9;
        text-align: right;
        margin-top: 5px;
      }
    }
  }
  // .arrowdropdown-message-file{
  //    position: absolute;
  //    left: 260px;
  // }
 
  @media(max-width: 768px){
    .message-time {
      font-size: 10px;
      line-height: 14px;
    }
  }