.dw-maintenance {
  overflow: hidden;
  &-video{
    position: fixed;
  top: 50%;
  left: 50%;
  min-width: 100%;
  min-height: 100%;
  width: auto;
  height: auto;
  z-index: -1;
  transform: translate(-50%, -50%);
  }
  &-child {
    min-height: 100vh;
    background: rgba(0, 0, 0, 0.62);;
    position:absolute;
    width: 100%;
 
    &-header {
      display: flex;
      width: 100%;
      justify-content: space-between;
      img{
        margin: 10px;
        
      }
    }
    &-main{
      display: flex;
      flex-direction: column;
      height: calc(100vh - 72px);
    &-content {
      display: flex;
      justify-content: center;
      width: 100%;
      height: 33%;
      align-items: end;
      &-heading {
        font-size: 4.5rem;
        color: #fff;
        font-weight: 700;
        text-align: center;
        // margin-top: 110px;
      }
    }
    &-content2{
      height: 25%;
    }

  }
  }
}


.timer{
  width: 100%;
  color: #fff;
  display: flex;
  justify-content: center;
  max-width: 1200px;
  // margin: 165px auto 0 auto;
  margin:0 auto;
&-children{
  font-size: 7rem;
  width: 100%;
  display: flex;
flex-wrap: wrap;
justify-content: center;
span{
margin-right: 20px;
}
  &-name{
    font-size: 3rem;
    width: 100%;
    display: flex;
flex-wrap: wrap;
justify-content: center;
  }
}
ul {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  justify-content: center;
  padding: 0;
  width: 100%;
  li::before {
    content: ':';
    position: absolute;
    top: 45%;
    left: 100%;
    font-size: 60px;
    font-weight: 700;
    color: #fff;
    transform: translateY(-50%);
    margin-left: -15px;
}
li:last-child::before {
  content: ''; /* Remove content for the last child */
}

  li {
    max-width: 20%;
    flex: 0 0 25%;
    text-align: center;
    position: relative;
    color: #fff;
    span{
      font-size: 2rem;
    }
    h3 {
      color: #ffffff;
      font-size: 66px;
      font-weight: 700;
  }

}
}
}



@media (max-width:768px ) and (min-width:481px)
{


  .dw-maintenance-child-main-content-heading{
  // margin-top: 100px;
  img{
    margin: 10px;
  }
}
.dw-maintenance-child-main-content{
  height: 31%;
}
.dw-maintenance-child-main-content2{
  height: 21%;
}
  .timer{
    // margin-top: 140px;
    li::before {
      font-size: 40px !important;
      top: 39% !important;
      left: 120% !important;
    }
    li {
      max-width: 25% !important;
      span{
        font-size: 1.5rem !important;
      }
    }
    ul li h3 {
      font-size: 45px;
    }
  }
  .dw-maintenance-child-main-content-heading{
    font-size: 3rem;
    // margin-top: 75px !important;
  }

 
}
@media (max-width:480px)
{
  .timer{
    // margin-top: 135px;
    li::before {
      font-size: 20px !important;
      top: 45% !important;
      left: 120% !important;
    }
    li {
      max-width: 25% !important;
      span{
        font-size: 1rem !important;
      }
    }
    ul li h3 {
      font-size: 30px;
    }
  }
  .dw-maintenance-child-main-content2{
    height: 22%;
  }
  .dw-maintenance-child-main-content-heading{
    font-size: 2.5rem;
    // margin-top: 90px !important;
  }
  .dw-maintenance-child-header{
    justify-content: center;
  }
  .dw-maintenance-child-main-header{
    justify-content: center;
  }
 
}
