@import "../../../../../styles/variablesstyles.scss";

.ds-ctm-txt {
  font-weight: 700;
  color: $theme-color-gray2;
}
#WalletDropstocksDepositModal .copy-box-text{
  word-break:break-all;
}
.btn-bg-gray3 {
  background: $theme-color-black3 !important;
}
.custom-pass-space {
  margin-bottom: 32px !important;
}
.custom-confirm-pass-space {
  margin-bottom: 24px !important;
}
.gray4 {
  color: $theme-color-gray4 !important;
}
.b-line {
  text-decoration: underline;
  text-decoration-color: $theme-color-gray4 !important;
}
.r-text{
  text-align: right;
}
.l-text{
  text-align: left;
}
.border-radius-10{
border-radius: 10px;
}

.parent-qr{
  gap:$theme-spacing4;
display: flex;
flex-direction: row;
width: 100%;
}

@media (min-width: 360px) and (max-width: 576px) {
  .parent-qr{
  display: flex;
  flex-direction: column !important;
  // width: max-content !important;
  }
  .modal-footer.social-mobile-modal-footer {
    padding: 0;
  }

  .custom-pass-space {
    margin-bottom: 8px !important;
  }

  .custom-confirm-pass-space {
    margin-bottom: 8px !important;
  }

  button.btn-wid.social-link-modal-btns.custom_btn_small {
    margin: 0px 0px 16px 0px;
    width: 100%;
  }

  .modal-footer.social-mobile-modal-footer {
    gap: 16px;
  }
}

@media (min-width: 320px) and (max-width: 360px) {
  .custom-pass-space {
    margin-bottom: 8px !important;
  }
  .custom-confirm-pass-space {
    margin-bottom: 8px !important;
  }
  .parent-qr{
  display: flex;
  flex-direction: column !important;
  // width: max-content !important;
  }

  .custom-pass-space {
    margin-bottom: 8px !important;
  }

  .custom-confirm-pass-space {
    margin-bottom: 8px !important;
  }

  .modal-footer.social-mobile-modal-footer {
    padding: 0;
  }
  button.btn-wid.social-link-modal-btns.custom_btn_small {
    margin: 0px 0px 16px 0px;
    width: 100%;
  }

  .modal-footer.social-mobile-modal-footer {
    gap: 16px;
  }
}
