

@import '../../../../../styles/variablesstyles.scss';


.ds-ctm-txt{
    font-weight: 700;
    color:$theme-color-gray2;
  }
  .btn-bg-gray3{
    background: $theme-color-black3 !important;
  }
  .ctm-inp-color1:focus{
    outline: 0;
    border: 1px solid rgba(39, 174, 96, 1);
  }
  .ctm-inp-color2:focus{
    outline: 0;
    border: 1px solid rgba(235, 87, 87, 1);
  }