@import "../../../../../styles/variablesstyles.scss";

.content {
  color: $theme-color-gray5;
}

.box {
  width: 80px;
  height: 80px;
  background-color: rgba(217, 217, 217, 1);
  border-radius: 10px;
  display: inline;
}

.text-field {
  width: 487px;
}

.text-content {
  color: $theme-color-gray5;
}

.inviteModal_uploadbox {
  height: 172px;
  border: 2px dashed rgba(188, 188, 188, 1);
  border-radius: 10px;
  display: flex;
  width: 100%;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  opacity: 0.75;
  background: rgba(255, 255, 255, 0.02);
}
.uploadicon {
  height: 70px;
  width: 70px;
}
.invite-textarea {
  height: 116px;
  background-color: $input-background-color;
}
.preview-text {
  color: $theme-color-gray2;
}
.upload-text {
  font-weight: 400;
  line-height: 57.6px;
}
.custom-select-dropdown {
  background-color: transparent;
  height: 60px;
  font-size: 1rem;
  font-weight: 400;
  color: $theme-color-gray5;
  padding-left: 0px;
  padding-right: 0px;
}

/* When .form-control and .is-invalid are both present on the same element */
.form-control.is-invalid .custom-select__indicator {
  padding-right: 40px !important;
}
.form-control.is-valid .custom-select__indicator {
  padding-right: 40px !important;
}

.form-control.is-invalid.custom-select-dropdown {
  padding: 0px !important;
}
.form-control.is-valid.custom-select-dropdown {
  padding: 0px !important;
}

@media (min-width: 576px) and (max-width: 768px) {
  .custom-select-dropdown {
    height: 36px !important;
    border-radius: 5px !important;
    margin: 8px 0px !important;
  }
  .inviteModal_uploadbox {
    height: 248px;
    border-radius: 3.26px;
    width: 100%;
    border: 0.65px dashed rgba(188, 188, 188, 1);
  }
}

@media (min-width: 360px) and (max-width: 576px) {
  .custom-select-dropdown {
    height: 36px !important;
    border-radius: 5px !important;
    margin: 8px 0px !important;
  }
  .inviteModal_uploadbox {
    height: 248px;
    border-radius: 3.26px;
    width: 100%;
    border: 0.65px dashed rgba(188, 188, 188, 1);
  }
}
@media (min-width: 320px) and (max-width: 360px) {
  .custom-select-dropdown {
    height: 36px !important;
    border-radius: 5px !important;
    // margin: 8px 0px !important;
  }
  .inviteModal_uploadbox {
    height: 248px;
    border-radius: 3.26px;
    width: 100%;
    border: 0.65px dashed rgba(188, 188, 188, 1);
  }
}
