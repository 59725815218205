@import "../../../../styles/variablesstyles.scss";
.newmarketplace {
  background: url("../../../../assets/img/background/2560x1440.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  min-height: 100vh;
  color: white;
  
  .backdrop-none{
backdrop-filter: none !important;
  }
  &-dynamic-image-beats {
    // background: url("../../../../assets/img/temporary-images/beat_image_png.png");
    background-repeat: no-repeat;
    background-size: cover;
    color: white;
    position: absolute;
    background-position: center;
    top: 0px;
    height: 60vh;
    width: 100%;
  }
  &-dynamic-image-track{
    // background-image: url("../../../../assets/img/temporary-images/track_image_png.png");
    background-repeat: no-repeat;
    background-size: cover;
    color: white;
    position: absolute;
    background-position: center;
    top: 0px;
    height: 62vh;
    width: 100%;
  }
  &-dynamic-image-project {
    // background: url("../../../../assets/img/temporary-images/project_image_png.png");
    background-repeat: no-repeat;
    background-size: cover;
    color: white;
    position: absolute;
    background-position: center;
    top: 0px;
    height: 62vh;
    width: 100%;
  }

  .scroll-content {
    padding: 0 !important;
  }

  &_content {
    position: sticky;
    top: 0;
    z-index: 99;
    // height: 83px;
    display: flex;
    align-items: center;
    padding: 0 56px;
    background: rgb(0 0 0 / 45%);
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    backdrop-filter: blur(18.6px);
    -webkit-backdrop-filter: blur(18.6px);
    &_nav {
      width: 100%;
      &_sidebar {
        overflow: scroll;
        position: fixed;
        top: 0;
        left: 0;
        height: 100vh;
        width: 50%;
        background-color: rgba(255, 255, 255, 0.15);
        backdrop-filter: blur(12px);
        box-shadow: -10px 0 10px rgba(0, 0, 0, 0.1);
        list-style: none;
        display: none;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        margin-bottom: 0 !important;

        &_links {
          li {
            list-style: none;
            font-size: 16px !important;
            font-weight: 400;
            color: rgba(255, 255, 255, 1);
            // padding-top: 20px;

            a {
              text-decoration: none;
              color: rgba(255, 255, 255, 1);
            }
          }
        }

        li {
          list-style: none;
          font-size: 22px;
          font-weight: 400;
          color: rgba(255, 255, 255, 1);
          padding-top: 10px !important;

          a {
            text-decoration: none;
            color: rgba(255, 255, 255, 1);
          }
        }
      }

      &_navbar {
        display: flex;
        justify-content: space-between;
        position: relative;
        align-items: center;

        &_logo {
          min-width: 253px;
          li {
            list-style: none;
            font-size: 22px;
            font-weight: 400;
            color: rgba(255, 255, 255, 1);

            a {
              text-decoration: none;
              color: rgba(255, 255, 255, 1);
            }
          }
        }

        &_first {
          display: flex;
          align-items: center;
          width: fit-content;
          margin: auto;
          padding-left: 0;

          li {
            list-style: none;
            font-size: 18px;
            line-height: 25.2px;
            font-weight: 400;
            color: $theme-color-gray5;
            margin: 0 28px;
            padding: 29px 0;
            width: max-content;
            a{
              text-decoration: none;
              color: $theme-color-gray5;
              transition: all 0.7s ease;
              &:hover {
              color: $theme-color-white;
              // font-weight: 500;
              }
              .uni-ml-8 {
                margin-left: 8px;
              }
             
            }
          }

          &_trade {
            position: relative;

            &_dropdown {
              display: none;
              position: absolute;
              padding: 16px;
              width: max-content;
              margin-top: 16px;

              &_submenu {
                padding-top: 0px !important;
                padding-bottom: 0 !important;
                margin: 0 !important;
              }
            }
            &_dropdown {
              display: none;
              position: absolute;
              padding: 16px;
              margin-top: 16px;

              &_submenu {
                padding-top: 0px !important;
                padding-bottom: 0 !important;
                margin: 0 !important;
              }
            }
          }

          &_links_products {
            position: relative;

            &_dropdown {
              display: none;
              position: absolute;
              padding: 16px;
              // top: 40px;
              margin-top: 16px;
              background-color: black;

              &_submenu {
                padding-top: 0px !important;
                padding-bottom: 0 !important;
                margin: 0 !important;
                font-size: 16px !important;
              }
            }
            &_dropdown {
              display: none;
              position: absolute;
              padding: 16px;
              // top: 40px;
              margin-top: 16px;
              background-color: black;

              &_submenu {
                padding-top: 0px !important;
                padding-bottom: 0 !important;
                margin: 0 !important;
                font-size: 16px !important;
              }
            }
          }

          
          &_links {
            position: relative;

            &_dropdown {
              display: none;
              position: absolute;
              padding: 16px;
              margin-top: 16px;
              background-color: black;
              &_submenu {
                padding-top: 0px !important;
                padding-bottom: 0 !important;
                margin: 0 !important;
                font-size: 16px !important;
              }
            }
            &_dropdown {
              display: none;
              position: absolute;
              padding: 16px;
              margin-top: 16px;
              background-color: $theme-color-black4;
              border-radius: 10px;
              &_submenu {
                padding-top: 0px !important;
                padding-bottom: 0 !important;
                margin: 0 !important;
                font-size: 16px !important;
              }
            }
          }
          .newmarketplace_content_nav_navbar_first_links.dropdown:hover
            .newmarketplace_content_nav_navbar_first_links_dropdown,
          .newmarketplace_content_nav_navbar_first_links.dropdown:focus-within
            .newmarketplace_content_nav_navbar_first_links_dropdown,
          .newmarketplace_content_nav_navbar_first_links_products.dropdown:hover
            .newmarketplace_content_nav_navbar_first_links_dropdown,
          .newmarketplace_content_nav_navbar_first_links_products.dropdown:focus-within
            .newmarketplace_content_nav_navbar_first_links_dropdown,
          .newmarketplace_content_nav_navbar_first_links_trade.dropdown:hover
            .newmarketplace_content_nav_navbar_first_links_dropdown,
          .newmarketplace_content_nav_navbar_first_links_trade.dropdown:focus-within
            .newmarketplace_content_nav_navbar_first_links_dropdown,
          .newmarketplace_content_nav_navbar_first_links-2.dropdown:hover
            .newmarketplace_content_nav_navbar_first_links_dropdown2,
          .newmarketplace_content_nav_navbar_first_links-2.dropdown:focus-within
            .newmarketplace_content_nav_navbar_first_links_dropdown2 {
            display: block;
            width: max-content;
          }
        }

        &_second {
          display: flex;
          align-items: center;
          width: fit-content;
          margin: 0;
          padding-left: 0;

          &_links {
            &_dropdown {
              display: none;
              position: absolute;
              padding: 0px;
              margin-top: 16px;
              background: $theme-color-black4;
              box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
              border-radius: 10px;
              &_submenu {
                padding-top: 0px !important;
                padding-bottom: 0 !important;
                margin: 0 !important;
                color: rgba(188, 188, 188, 1);
                text-align: center;
              }
            }
          }
          li {
            list-style: none;
            font-size: 18px;
            line-height: 22.4px;
            font-weight: 400;
            color: rgba(188, 188, 188, 1);
            padding: 29px 0;
            a {
              text-decoration: none;
              color: rgba(188, 188, 188, 1);
            }
          }
          .imgg {
            padding: 20px 0 !important;
          }
          & :not(.a):hover .newmarketplace_content_nav_navbar_second_links_dropdown,
          & :not(.a):focus-within
            .newmarketplace_content_nav_navbar_second_links_dropdown {
            display: block;
            overflow: hidden;
          }
        }
      }
    }
  }

  &_outlet {
    position: relative;
    background: rgb(0 0 0 / 45%);
    box-shadow: 0 4px 30px rgb(0 0 0 / 0%);
    backdrop-filter: blur(18.6px);
    -webkit-backdrop-filter: blur(18.6px);
    background-position: center;
    overflow-y: auto;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    > div:first-child {
      min-height: calc(100vh - 123px);
      margin-top: 40px;
      // padding-left: 0 !important;
      // padding-right: 0 !important ;
    }
  }

  .cursor-pointer {
    cursor: pointer;
  }
}
.sub-dropdown-ul {
  padding-left: 0px !important;
}
.sub-dropdown-li {
  margin: 0px !important;
  padding: 18px 32px !important;
}
.dropdown_menu {
  display: none;

  &.open {
    display: block;
  }

  &.close {
    display: none;
  }
}

.dropdown_menu2 {
  display: none;

  &.open {
    display: block;
  }

  &.close {
    display: none;
  }
}


.dropdown_menu3 {
  display: none;

  &.open {
    display: block;
  }

  &.close {
    display: none;
  }
}
@media (max-width: 1024px) {
  .menu-button {
    display: block !important;
  }

  .newmarketplace {
    background: url("../../../../assets/img/background/1024x768.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 100vh;
    color: white;

    transition: all 0.3s ease-in-out;

    &_content {
      &_nav {
        &_sidebar {
          padding: 0px;
          position: fixed;
          top: 0;
          right: 0;
          height: 100vh;
          width: 50%;
          background-color: rgba(255, 255, 255, 0.15);
          backdrop-filter: blur(100px);
          box-shadow: -10px 0 10px rgba(0, 0, 0, 0.1);
          list-style: none;
          display: none;
          flex-direction: column;
          align-items: flex-start;
          justify-content: flex-start;
          z-index: 100;
          transition: all 0.75s ease-in-out;

          &_logo {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;
            padding-right: 10px;
          }

          > li {
            padding-left: 20px;
          }
        }

        &_navbar {
          display: flex;
          justify-content: space-between;
          position: relative;

          &_logo {
            li {
              list-style: none;
              font-size: 22px;
              font-weight: 400;
              color: rgba(255, 255, 255, 1);

              a {
                text-decoration: none;
                color: rgba(255, 255, 255, 1);
              }
            }
          }

          &_first {
            display: none;
            align-items: center;
            justify-content: space-around;
            width: 20%;

            li {
              list-style: none;
              font-size: 22px;
              font-weight: 400;
              color: rgba(255, 255, 255, 1);

              a {
                text-decoration: none;
                color: rgba(255, 255, 255, 1);
              }
            }

            &_links {
              position: relative;

              &_dropdown {
                display: none;
                position: absolute;
                padding: 0px;
                text-align: center;
                top: 40px;

                li {
                  list-style: none;
                  font-size: 18px;
                  line-height: 22.4px;
                  font-weight: 400;
                  color: rgba(188, 188, 188, 1) !important;
                  padding: 29px 0;
                  a {
                    text-decoration: none;
                    color: $theme-color-gray4;
                  }
                }
              }
            }

            &_links-2{
          
                list-style: none;
                font-size: 18px;
                line-height: 22.4px;
                font-weight: 400;
                color: rgb(209, 14, 14) !important;
                padding: 29px 0;
                a {
                  text-decoration: none;
                  color: $theme-color-gray4;
                }
              
            }

            &_links:hover {
              &_dropdown {
                display: block;
                position: absolute;
                padding: 0px;
                text-align: center;
                top: 90px !important;
              }
            }
          }

          &_second {
            display: flex;
            align-items: center;
            justify-content: space-around;
            width: 40%;

            li {
              list-style: none;
              font-size: 22px;
              font-weight: 400;
              color: rgba(255, 255, 255, 1);

              a {
                text-decoration: none;
                color: rgba(255, 255, 255, 1);
              }
            }
          }
        }
      }
    }
    &_outlet > div:first-child {
      min-height: calc(100vh - 92px);
      // margin-top: 40px;
    }
  }
}

@media (max-width: 576px) {
  .newmarketplace {
    background: url("../../../../assets/img/background/360x640.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 100vh;
    color: white;

    &_content {
      &_nav {
        &_sidebar {
          position: fixed;
          top: 0;
          right: 0;
          height: 100vh;
          width: 50%;
          background-color: rgba(255, 255, 255, 0.15);
          backdrop-filter: blur(100px);
          box-shadow: -10px 0 10px rgba(0, 0, 0, 0.1);
          list-style: none;
          display: none;
          flex-direction: column;
          align-items: flex-start;
          justify-content: flex-start;
          z-index: 100;
          transition: 0.75s ease-out;

          &_logo {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;
            padding-right: 10px;
          }
        }

        &_navbar {
          display: flex;
          justify-content: space-between;
          position: relative;

          &_logo {
            li {
              list-style: none;
              font-size: 22px;
              font-weight: 400;
              color: rgba(255, 255, 255, 1);

              a {
                text-decoration: none;
                color: rgba(255, 255, 255, 1);
              }
            }
          }

          &_first {
            display: none;
            align-items: center;
            justify-content: space-around;
            width: 20%;

            li {
              list-style: none;
              font-size: 22px;
              font-weight: 400;
              color: rgba(255, 255, 255, 1);

              a {
                text-decoration: none;
                color: rgba(255, 255, 255, 1);
              }
            }

            &_links {
              position: relative;

              &_dropdown {
                display: none;
                position: absolute;
                padding: 0px;
                text-align: center;
                top: 40px;

                li {
                  padding-top: 15px;
                }
              }
            }

            &_links:hover {
              &_dropdown {
                display: block;
                position: absolute;
                padding: 0px;
                text-align: center;
                top: 90px !important;
              }
            }
          }

          &_second {
            display: flex;
            align-items: center;
            justify-content: space-around;
            width: 80%;

            li {
              list-style: none;
              font-size: 22px;
              font-weight: 400;
              color: rgba(255, 255, 255, 1);

              a {
                text-decoration: none;
                color: rgba(255, 255, 255, 1);
              }
            }
          }
        }
      }
    }
  }
}

.mar-mx-24 {
  margin: auto 24px !important;
}

.mar-mr-12 {
  margin-right: 12px;
}

.dropdown:hover .dropdown_menu {
  display: block;
}

.menu-button {
  display: none;
}

@media (max-width: 1441px) {
  .newmarketplace_content {
    padding: 0px 28px !important;
  }

  .newstudioheader_content {
    padding: 0px 28px !important;
  }

  .newmarketplace_content_nav_navbar_logo {
    min-width: 50px !important;
    max-width: 50px !important;
  }
  .newstudioheader_content_nav_navbar_logo {
    min-width: 50px !important;
    max-width: 50px !important;
  }
  .newmarketplace_content_nav_navbar_first li {
    margin: 0 16px !important;
  }
  .newstudioheader_content_nav_navbar_first li {
    margin: 0 16px !important;
  }
}

.w-185 {
  width: 185px !important;
}
.li-p {
  padding: 18px !important;
}
.mini-submenus{
  min-width: 185px;
        list-style: none;
        font-size: 18px !important;
        line-height: 22.4px;
        font-weight: 400 ;
        color: $theme-color-gray4 !important;
    
        a {
          text-decoration: none;
          color: $theme-color-gray4 !important;
      
        }
      
    }
   .mini-submenus:last-child:hover, .mini-submenus>ul>li:hover,.mini-submenus>li>a{
      background: rgba(255, 255, 255, 0.3) !important;
      // border-radius: 10px !important;
          width: 100%;
      a{
        color: rgba(255, 255, 255, 1) !important;
      }
    }
 @media(min-width: 360px) and (max-width: 768px){
   .newmarketplace{
    background: url("../../../../assets/img/background/430x932.png");
  }

  .newmarketplace_outlet{
    background: rgb(0 0 0 / 45%);
    box-shadow: 0 4px 30px rgb(0 0 0 / 0%);
    backdrop-filter: blur(18.6px);
    -webkit-backdrop-filter: blur(18.6px);
    
    background-position: center;
    background: url(../../../../assets/img/background/360x640.png) !important;
    background-repeat: no-repeat !important;
    background-size: 100% 100% !important;
    min-height: calc(100vh - 52px);
    > div{
      padding: 0px 24px !important;
      overflow-x: hidden;
      margin-top: 16px !important;
    }
  }
  .full-width-child{
    margin-left: -24px !important;
    margin-right: -24px !important;
    width: calc(100% + 48px) !important;
  }
 }

//  position: relative;

//  min-height: calc(100vh - 84px);
//  overflow-y: auto;
//  display: flex;
//  justify-content: space-between;
//  flex-direction: column;
    

 @media(min-width: 320px) and (max-width: 360px){
  .newmarketplace {
    background: url("../../../../assets/img/background/360x640.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    min-height: 100vh;
  }
  .newmarketplace_outlet{
    background: rgb(0 0 0 / 45%);
    box-shadow: 0 4px 30px rgb(0 0 0 / 0%);
    // background: url("../../../../assets/img/background/360x640.png");
    backdrop-filter: blur(18.6px);
    -webkit-backdrop-filter: blur(18.6px);
    // background-position: center;
    // height: 100vh;
    // background-repeat: no-repeat;
    min-height: calc(100vh - 52px);
    background: url(../../../../assets/img/background/360x640.png) !important;
    background-repeat: no-repeat !important;
    background-size: 100% 100% !important;
    // background-size: cover;
    > div{
      padding: 0px 24px !important;
      overflow-x: hidden;
      margin-top: 16px;
    }
  }
  .full-width-child{
    margin-left: -24px !important;
    margin-right: -24px !important;
    width: calc(100% + 48px) !important;
  }
 }