// @import "../../../styles/variablesstyles.scss";

// .custom-text-lg {
//   font-size: 20px !important;
//   line-height: 28px !important;
//   margin: $theme-spacing1 auto;
// }
// .custom-text-md {
//   font-size: 18px;
//   line-height: 25.2px;
//   margin: $theme-spacing1 auto;
// }
// .custom-text-sm {
//   font-size: 16px !important;
//   line-height: 22.4px !important;
//   margin: $theme-spacing1 auto;
// }
// .custom-text-xsm {
//   font-size: 14px !important;
//   line-height: 19.6px !important;
//   margin: $theme-spacing1 auto;
// }

// @media (min-width: 360px) and (max-width: 576px) {
//   .custom-text-lg {
//     font-size: 14px !important;
//     line-height: 19.6px !important;
//     margin: $theme-spacing1 auto;
//   }
//   .custom-text-md {
//     font-size: 14px !important;
//     line-height: 16.8px !important;
//     margin: $theme-spacing1 auto;
//   }
//   .custom-text-sm {
//     font-size: 12px !important;
//     line-height: 16.8px !important;
//     margin: $theme-spacing1 auto;
//   }
//   .custom-text-xsm {
//     font-size: 10px !important;
//     line-height: 16.8px !important;
//     margin-bottom: 0 !important;
//   }
// }

// @media (min-width: 320px) and (max-width: 360px) {
//   .custom-text-lg {
//     font-size: 12px !important;
//     line-height: 16.8px !important;
//     margin: $theme-spacing1 auto;
//   }
//   .custom-text-md {
//     font-size: 16px;
//     line-height: 22.4px;
//     margin: $theme-spacing1 auto;
//   }
//   .custom-text-sm {
//     font-size: 14px !important;
//     line-height: 19.6px !important;
//     margin: 0px !important;
//   }
//   .custom-text-xsm {
//     font-size: 12px !important;
//     line-height: 16.8px !important;
//     margin-bottom: 0 !important;
//   }
// }


@import "../../../styles/variablesstyles.scss";

.custom-text-lg{
   font-size:  $theme-lg-fs;
   line-height: $theme-lg-lh; 
   margin: $theme-spacing1 auto;
}
.custom-text-md{
    font-size:$theme-sm-fs;
    line-height:$theme-md-lh; 
    margin: $theme-spacing1 auto;
                                        
 }
 .custom-text-sm{
    font-size: $theme-sm-fs;
    line-height:$theme-sm-lh; 
    margin: $theme-spacing1 auto;

 }
 .custom-text-xsm{
    font-size: $theme-xsm-fs;
    line-height: $theme-xsm-lh; 
    margin: $theme-spacing1 auto;

 }

 @media(min-width: 360px) and (max-width: 576px) {

   .custom-text-lg{
      font-size:  12px !important;
      line-height: 16.8px !important; 
      margin: $theme-spacing1 auto;
   }
   .custom-text-md{
       font-size:12px;
       line-height:16.8px; 
       margin: $theme-spacing1 auto;
                                           
    }
    .custom-text-sm{
       font-size: 14px !important;
       line-height:19.6px !important; 
       margin: 0px !important;
   
    }
    .custom-text-xsm{
       font-size: 12px !important;
       line-height: 16.8px !important; 
       margin-bottom: 0 !important;
   
    }
 }

 @media(min-width: 320px) and (max-width: 360px) {

   .custom-text-lg{
      font-size:  12px !important;
      line-height: 16.8px !important; 
      margin: $theme-spacing1 auto;
      margin-top: 0px;
   }
   .custom-text-md{
       font-size:12px;
       line-height:16.8px; 
       margin: $theme-spacing1 auto;
                                           
    }
    .custom-text-sm{
       font-size: 14px !important;
       line-height:19.6px !important; 
       margin: 0px !important;
   
    }
    .custom-text-xsm{
       font-size: 12px !important;
       line-height: 16.8px !important; 
       margin-bottom: 0 !important;
   
    }
 }

 .link-btm .underline-opening{
    display: inline-block;
    cursor: pointer;
    -webkit-tap-highlight-color: transparent;
    transition: color 1s ease;
    box-sizing: border-box;
    text-transform: capitalize;
 }
 .link-btm .underline-opening:hover {
   text-decoration: underline;
   color:rgb(224, 224, 224);
   transition: all 0.3s ease 0s;
   filter: brightness(1.3);
   text-shadow: rgb(255, 255, 255) 1px 0 10px;
 }
 
 .link-btm .underline-opening:focus{
   text-decoration: underline;
   color:rgb(224, 224, 224);
 }
 