
@import "../../../styles/variablesstyles.scss";

  .custom_textarea_inp {
    width: 100%;
    background: $input-background-color;
    border: 0;
    resize: none;
    border-radius: 10px;
    color: rgb(255, 255, 255);
    line-height: 24px;
    font-size: $input-placeholder-size !important;
    padding-left: 1rem;
    padding-right: 40px;
    margin-bottom: 1.5rem;
    font-weight: 400!important;
    height: 180px;
    padding-top: 15px;
    padding-bottom: 15px;
    max-height: 295px;
    min-height: 102px;
  }
  
  .custom_textarea_inp::placeholder {
    color: rgba(188, 188, 188, 1);
    font-size: $input-placeholder-size;
    font-weight: 400!important;
  }
  
  .custom_textarea_inp:focus {
    outline: 0;
    border: 0;
  }

     
  @media(min-width: 360px) and (max-width: 576px){

    .custom_textarea_inp {
      line-height: 16.8px !important;
      height: 100px !important;
      padding: 8px !important;
      margin-bottom: 0px !important;
      border-radius: 5px !important;
    }
    .custom_textarea_inp::placeholder {
      font-size: 12px !important;
      font-weight: 400!important;
      line-height: 16.8px !important;
   
    }
  }
   
  @media(min-width: 320px) and (max-width: 360px){

    .custom_textarea_inp {
      line-height: 16.8px !important;
      height: 100px !important;
      padding: 8px !important;
      margin-bottom: 0px !important;
      border-radius: 5px !important;
    }
    .custom_textarea_inp::placeholder {
      font-size: 12px !important;
      font-weight: 400!important;
      line-height: 16.8px !important;
   
    }
  }