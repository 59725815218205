@import "../../../styles/variablesstyles.scss";


.heading0 {
  font-size: $theme-heading-0-size;
  line-height: $theme-heading-0-lh;
  font-weight: 700;
}
.heading1 {
  font-size: $theme-heading-1-size;
  line-height: $theme-heading-1-lh;
  font-weight: 700;
}
.heading2 {
  font-size: $theme-heading-2-size;
  line-height: $theme-heading-2-lh;
  font-weight: 700;
}
.heading3 {
  font-size: $theme-heading-3-size;
  line-height: $theme-heading-3-lh !important;
  font-weight: 700;
}
.heading4 {
  font-size: $theme-heading-4-size;
  line-height: $theme-heading-4-lh;
  font-weight: 700;
}
.heading5 {
  font-size: $theme-heading-5-size;
  line-height: $theme-heading-5-lh;
  font-weight: 700;
}
.heading6 {
  font-size: $theme-heading-6-size;
  line-height: $theme-heading-6-lh;
  font-weight: 700;
}

// @media (max-width:576px){
//   .heading0 {
//     font-size: $theme-heading-0-size;
//     line-height: $theme-heading-0-lh;
//     font-weight: 700;
//   }
//   .heading1 {
//     font-size: $theme-heading-1-size;
//     line-height: $theme-heading-1-lh;
//     font-weight: 700;
//   }
//   .heading2 {
//     font-size: $theme-heading-2-size;
//     line-height: $theme-heading-2-lh;
//     font-weight: 700;
//   }
//   .heading3 {
//     font-size: $theme-heading-5-size;
//     line-height: $theme-heading-5-lh;
//   }
//   .heading4 {
//     font-size: $theme-heading-4-size;
//     line-height: $theme-heading-4-lh;
//     font-weight: 700;
//   }
//   .heading5 {
//     font-size: $theme-heading-5-size;
//     line-height: $theme-heading-5-lh;
//     font-weight: 700;
//   }
//   .heading6 {
//     font-size: $theme-heading-6-size;
//     line-height: $theme-heading-6-lh;
//     font-weight: 700;
//   }
  
// }

@media(min-width: 360px) and (max-width: 576px){
  .heading0 {
    font-size: $theme-lg-fs;
    line-height:$theme-heading-6-lh;
  }
  .heading1 {
    font-size: $theme-lg-fs;
    line-height:$theme-heading-6-lh;
  }
  .heading2 {
    font-size: $theme-lg-fs !important;
    line-height:$theme-heading-6-lh !important;
  }
  .heading3 {
    font-size: $theme-lg-fs !important;
    line-height:$theme-heading-6-lh !important;
  }
  .heading4 {
    font-size: $theme-lg-fs;
    line-height:$theme-heading-6-lh !important;
  }
  .heading5 {
    font-size: $theme-lg-fs;
    line-height:$theme-heading-6-lh;
  }
  .heading6 {
    font-size: $theme-heading-6-size;
    line-height: $theme-heading-6-lh;
  }
}

@media(min-width: 320px) and (max-width: 360px){
  .heading0 {
    font-size: $theme-lg-fs;
    line-height:$theme-heading-6-lh;
  }
  .heading1 {
    font-size: $theme-lg-fs;
    line-height:$theme-heading-6-lh;
  }
  .heading2 {
    font-size: $theme-lg-fs !important;
    line-height:$theme-heading-6-lh !important;
  }
  .heading3 {
    font-size: $theme-lg-fs !important;
    line-height:$theme-heading-6-lh !important;
  }
  .heading4 {
    font-size: $theme-lg-fs;
    line-height:$theme-heading-6-lh !important;
  }
  .heading5 {
    font-size: $theme-lg-fs;
    line-height:$theme-heading-6-lh;
  }
  .heading6 {
    font-size: $theme-heading-6-size;
    line-height: $theme-heading-6-lh;
  }
}
