.message{


  .spinner {
    width: 40px;
    height: 40px;
    border: 4px solid #f3f3f3; /* Light gray */
    border-top: 4px solid #3498db; /* Blue */
    border-radius: 50%;
    animation: spin 1s linear infinite;
  }
  
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  justify-content: left;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.05);
  min-height:56px;
  width: 100%;
  border-radius: 10px;
  position: relative;

&-inp {
  display: flex;
  // min-height:56px;


  &-icon{
    height: 24px;
    width: 24px;
    padding: 3px !important;
    background: transparent;
    border: none;
    color: white;
    margin: 0px 16px 0px 16px;
    color: rgba(188, 188, 188, 1);
  }
  &-cross-icon{
    height: 24px;
    width: 24px;
    padding: 3px !important;
    background: transparent;
    cursor: pointer;
    border: none;
    color: white;
    // margin: 0px 10px 0px 0px;
    color: rgba(188, 188, 188, 1);
    
  }
  &-input{
    width: 100%;
    border: 0;
    padding: 18px 0px 0px 0px;
    color: rgb(255, 255, 255);
    font-size: 16px !important;
    background-color:transparent;
    resize: none;
  }
  &-input:focus{
    outline: 0;
   border: 0;
  }
  &-closeicon{
    // position: absolute;
    right: 0px;
    font-size: 18px;
    color: rgba(188, 188, 188, 1);
    align-items: center;
    display: flex;
    height: 100%;
    width: 56px;
  }
}
}

/* Target the emoji class in the picker */
.custom-emoji-picker .emoji {
  font-size: 2rem;
  position: absolute;
  bottom: 60px;
}

.bg-transparent{
  background-color: transparent;
  border: 0;
}