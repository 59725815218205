@import "../../../../styles/variablesstyles.scss";

.palyer {
  display: flex;
  align-items: center;
  width: inherit;
  height: 97px;
 background-color:  rgba(0, 0, 0, 0.54);
  box-shadow: 0 4px 30px rgb(0 0 0 / 54%);
  backdrop-filter: blur(40px);
  padding: 0px 40px 0px 20px;
  position:fixed;
  z-index: 100;
  bottom: 0;
  width: 100%;

  &-nowPlay {
    display: block;
  }

  &-nowPlay> span {
    display: inline-block;
    font-size: 1.05rem;
    vertical-align: top;
  }

  &-nowPlay span#npAction {
    padding: 21px;
    width: 30%;
  }

  &-nowPlay span#npTitle {
    padding: 21px;
    text-align: right;
    width: 70%;
  }

  &-song-image {
    display: flex;
    align-items: flex-start;
    height: 65px;
    width: 65px;
    border-radius: 5px;
  }

  &-details {
    display: flex;
    align-items: flex-start;
  }
}
.heart-image{
  height: 24px;
}
.meatballsplayer{
  margin-top: 4px;
}
.play-pause{
  width: 60px;
  height: 60px;
}
.all-music-btn{
  height: 60px;
}
.player-content {
  display: flex;
  align-items: center;
  justify-content: end;
  // margin-left: 20px;
}
.player-name {
  font-size: 20px;
  font-weight: 600;
  line-height: 22px;
  color :rgb(255, 255, 255);

  margin-bottom: 0px;
  text-align: left;
  display: flex;
  align-items: flex-start;
}
.player-song-title {
  font-size: 14px;
  font-weight: 400;
  line-height: 19.6px;
  text-align: left;
  color: rgba(188, 188, 188, 1) !important;
  max-width: 333px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.player-controls {
  display: flex;
  align-items: center;
}
.play-time{
    color: $theme-color-gray3;
    font-size: 0.8rem;
}
.progress {
  --bs-progress-bar-bg: rgb(169 16 121);
  border-radius: 30px;
  // --bs-progress-bg:$theme-color-gray3; 
  --bs-secondary-bg: #828282 !important;
}
.voice-progress{
  --bs-voice-progress-bar-bg: rgba(252, 238, 33, 1) !important;
  border-radius: 30px;
}
.cursor-pointer {
  cursor: pointer;
}
.custom-audio-heart-icon{
   width: 24px ;
   height: 24px ;
}


input[type="range"]{
  accent-color: rgba(252, 238, 33, 1) !important ;
}


input[type='range'] {
  overflow: hidden;
  // width: 80px;
  -webkit-appearance: none;
  background-color: #bdb5b5;
  cursor: pointer;
  border-radius: 10px;
}


input[type='range']::-webkit-slider-thumb {
  width: 0px;
  -webkit-appearance: none;
  height: 5px;
  cursor: ew-resize;
  background: rgba(252, 238, 33, 1);
  box-shadow: -80px 0 0 80px rgba(252, 238, 33, 1);
  cursor: pointer;
  border-radius: 10px;

}

input[type="range"]::-moz-range-progress {
background-color: rgba(252, 238, 33, 1); 
cursor: pointer;
border-radius: 10px;
}

@media(min-width: 360px) and (max-width: 768px){
  .palyer {
    display: flex;
    align-items: center;
    width: inherit;
    height: 78px;
    background-color: rgba(0, 0, 0, 0.54);
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.54);
    -webkit-backdrop-filter: blur(40px);
    backdrop-filter: blur(40px);
    padding: 8px 24px 16px 24px;
    position: fixed;
    bottom: 0;
    width: 100%;
}
.palyer-song-image {
  display: flex;
  align-items: flex-start;
  height: 54px;
  width: 54px;
  border-radius: 5px;
}
.player-name {
  font-size: 14px;
  font-weight: 700;
  line-height: 19.6px;
  color :rgb(255, 255, 255);
  margin-bottom: 0px;
  text-align: left;
  display: flex;
  align-items: flex-start;
}
.player-song-title {
  font-size: 12px;
  font-weight: 400;
  line-height: 16.8px;
  text-align: left;
  color: rgb(188, 188, 188) !important;
  -webkit-box-orient: vertical !important;
  -webkit-line-clamp: 1 !important;
  line-clamp: 1 !important;
  display: -webkit-box !important;
  overflow: hidden;
  text-overflow: ellipsis;
  width: auto;
}
.sound-image{
  display: none;
}
input[type=range] {
  overflow: hidden;
  -webkit-appearance: none;
  background-color: #bdb5b5;
  cursor: pointer;
  display: none;
  border-radius: 10px;
}
.meatballsplayer{
  display: none !important;
}
.cross-icon{
  position: absolute;
  right: 24px;
  top: 0px;
  height: auto !important;
  width: auto;
}
.heart-image{
  display: none !important;
}
.play-time{
  display: none !important;
}
.music-bar{
  position: absolute;
  bottom: 4px;
  left: 24px;
  width: 87% !important;
}
.cross-icon-audio{
  height: 10px;
  width: 10px;
}
}

@media(min-width: 320px) and (max-width: 360px){
  .palyer {
    display: flex;
    align-items: center;
    width: inherit;
    height: 78px;
    background-color: rgba(0, 0, 0, 0.54);
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.54);
    -webkit-backdrop-filter: blur(40px);
    backdrop-filter: blur(40px);
    padding: 8px 24px 16px 24px;
    position: fixed;
    bottom: 0;
    width: 100%;
}
.palyer-song-image {
  display: flex;
  align-items: flex-start;
  height: 54px;
  width: 54px;
  border-radius: 5px;
}
.player-name {
  font-size: 14px;
  font-weight: 700;
  line-height: 19.6px;
  color :rgb(255, 255, 255);

  margin-bottom: 0px;
  text-align: left;
  display: flex;
  align-items: flex-start;
}
.player-song-title {
  font-size: 12px;
  font-weight: 400;
  line-height: 16.8px;
  text-align: left;
  color: rgb(188, 188, 188) !important;
  -webkit-box-orient: vertical !important;
  -webkit-line-clamp: 1 !important;
  line-clamp: 1 !important;
  display: -webkit-box !important;
  overflow: hidden;
  text-overflow: ellipsis;
  width: auto;
}
.sound-image{
  display: none;
}
input[type=range] {
  overflow: hidden;
  -webkit-appearance: none;
  background-color: #bdb5b5;
  cursor: pointer;
  display: none;
  border-radius: 10px;
}
.meatballsplayer{
  display: none !important;
}
.cross-icon{
  position: absolute;
  right: 24px;
  top: 0px;
  height: auto !important;
  width: auto;
}
.heart-image{
  display: none !important;
}
.play-time{
  display: none !important;
}
.music-bar{
  position: absolute;
  bottom: 4px;
  left: 24px;
  width: 87% !important;
}
.cross-icon-audio{
  height: 10px;
  width: 10px;
}
}