
/* CSS */
.like_heart {
  --fuschia:white;
  --size: 5;
  width:36px;
  height:36px; 
  cursor: pointer;
  color: transparent;
  transition: all ease 1s;
}

.like_heart svg {
  height: 100%;
  width: 100%;
  stroke: var(--fuschia);
  stroke-width:var(---size);
}

.like_heart._active {
  color: var(--fuschia);
  transition: all ease 1s;
}
.like_heart.active {
  color: var(--fuschia);
}


@media(min-width: 320px) and (max-width: 768px){
  .like_heart svg {
  width: 24px !important;
  height: 24px !important;
  }
  .like_heart {
    width:20px;
    height:23px; 
  }
}
