@import "../../styles/variablesstyles.scss";
.user-lables{
    font-weight:normal;
    background: linear-gradient(313.89deg, #1D1D1D 1.71%, #282828 98.51%);
    border: 0;
    cursor: pointer !important;
    color:$theme-color-white;
    padding:9px 16px;
    text-align: center;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1rem;
    line-height: 22.4px;
    &-gradient{
      background: linear-gradient(298.83deg, #FCEE21 2.95%, #A91079 85.66%);
    }
    &-gradient2{
      background: linear-gradient(298.83deg, #A91079 2.95%, #3730EC 85.66%);
    }
  }