
@import "../../../../../styles/variablesstyles.scss";



.investor-box-parent-deposit{
    gap: 48px;
}
.custom-modal-deposit {
    max-width: 800px;
    width: max-content;
}
.investor-boxs-beat-artwork{
    width: 250px;
    height: 250px;
    position: relative;
    border-radius: 10px;
    background: linear-gradient(rgba(29, 29, 29, 1), rgba(40, 40, 40, 1));
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);

}
.investor-boxs-under-modal2-beat{
    position: absolute;
    bottom: $theme-spacing4;
    left: $theme-spacing4;
    font-size: 24px;
    line-height: 26.4px;
    color: $theme-color-gray5; 
    font-weight: 600;
    right: $theme-spacing4;
}
.investor-boxs-radiobutton{
    position: absolute;
    right: $theme-spacing1;
    top: $theme-spacing1;
}
.ctm-daws-text{
      color: $theme-color-gray2;
      font-size: $theme-xsm-fs;
      line-height: $theme-xsm-lh;
}
@media(min-width: 360px) and (max-width: 576px){

    .custom-modal-deposit {
        max-width: auto !important;
        width: auto !important;
    }
    .investor-boxs-deposit{
        width: 120px !important;
        height: 120px !important;
         border-radius: 5px !important;
    
    }
    .investor-box-parent-deposit{
        gap: 0.5rem !important;
    }
    .investor-boxs-under-modal2-beat{
        bottom: 1rem !important;
        left: 1rem !important;
        font-size: 14px !important;
        line-height: 19.6px !important;
        right: 1rem !important;
    }
}
@media(min-width: 320px) and (max-width: 360px){

    .custom-modal-deposit {
        max-width: auto !important;
        width: auto !important;
    }
    .investor-boxs-deposit{
        width: 120px !important;
        height: 120px !important;
         border-radius: 5px !important;
    }
    .investor-box-parent-deposit{
        gap: 0.5rem !important;
    }
    .investor-boxs-under-modal2{
        bottom: 1rem !important;
        left: 1rem !important;
        font-size: 14px !important;
        line-height: 19.6px !important;
        right: 1rem !important;
    }
}



