@import "../../../../../styles/variablesstyles.scss";
 
.content {
    color: $theme-color-gray5;
}
.select-title{
   font-size:  $theme-heading-5-size;
 line-height:  $theme-heading-5-lh;
 font-weight: 700;
}
.select-subtitle{
    font-weight: 400;
    font-size: $theme-color-gray4;
}
.select-box {
    width: 80px;
    height: 80px;
    //background-color: rgba(217, 217, 217, 1);
    border-radius: 10px;
    display: inline;
}
.text-field{
    width: 487px;
}
.btn-wid:disabled {
    opacity: 0.5 ; 
    cursor: not-allowed; 
  }
  
.no-beats-message {
    color: #999;
    font-size: 1.2rem;
    margin-top: 20px;
  }


.content-height{
    height: 448px;
    overflow: auto;
}
@media(min-width: 360px) and (max-width: 576px){

    .select-beat-content{
        height: 330px !important;
    }
}
@media(min-width: 320px) and (max-width: 360px){

    .select-beat-content{
        height: 330px !important;
    }
}

 