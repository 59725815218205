@import "../../../../../styles/variablesstyles.scss";

.profile-pic-white-space-rectangle{
    width: 476px;
    background-color: $theme-color-white;
    height: 212px;
    border-radius: 10px;
   border: 2px rgba(188, 188, 188, 1) dotted;
}

.w-auto{
    width: auto;
}

.w-476{
width: 476px;
}


@media(min-width: 360px) and (max-width: 576px){

    .profile-pic-white-space-rectangle {
        width: 100%;
        height: 248px;
        border-radius: 5px;
    }
}
@media(min-width: 320px) and (max-width: 360px){

    .profile-pic-white-space-rectangle {
        width: 100%;
        height: 248px;
        border-radius: 5px;
    }
}