
@import "../../../../../styles/variablesstyles.scss";



.investor-box-parent{
    gap: $theme-spacing3;
}
.investor-boxs{
    width: 187px;
    height: 187px;
    position: relative;
    border-radius: 10px;
    background: linear-gradient(rgba(29, 29, 29, 1), rgba(40, 40, 40, 1));
}
.investor-boxs-under{
    position: absolute;
    bottom: $theme-spacing4;
    left: $theme-spacing4;
    font-size: $theme-heading-5-size;
    line-height: 24.4px;
    color: $theme-color-gray5; 
    font-weight: 700;
    right: $theme-spacing4;
}
.investor-boxs-radiobutton{
    position: absolute;
    right: $theme-spacing1;
    top: $theme-spacing1;
}
.ctm-daws-text{
      color: $theme-color-gray2;
      font-size: $theme-xsm-fs;
      line-height: $theme-xsm-lh;
}
.unblock-button{
    width: 196px;
    border: none;
    color: white;
    height: 48px;
    border-radius: 8px;
    padding: 14px 70px 14px 70px;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    background: linear-gradient(313.89deg, #1D1D1D 201.71%,20#282828 2098.51%);
}
.block-username{
    color: $theme-color-gray5;
    font-weight: 400;
}
.block-username-block{
    color: $theme-color-gray1;
}
.block-img{

    opacity: 0.3;
}



@media(min-width: 360px) and (max-width: 576px){
    .blocked-user-mobile{
        flex-direction: column;
        width: 115px !important;
    }
    .blocked-user-profile-mobile{
        flex-direction: column;
 
    }
    .blockeduser-mobile-view{
        display: flex;
        flex-wrap: wrap;
        gap: 11px;
    }
    .unblock-button{
        padding: 5px 27px !important;
        width: 100% !important;
        border-radius: 5px !important;
        height: 30px !important;
        margin-top: 0.5rem;
        font-size: 14px !important;
        line-height: 19.6px !important;
    }
    .undo-button{
        padding: 5px 27px !important;
        width: 100% !important;
        border-radius: 5px !important;
        height: 30px !important;

    }
    .block-img{
        width: 30px !important;
        height: 30px !important;
    }
    .without-blocked{
        width: 30px !important;
        height: 30px !important;
    }
}


@media(min-width: 320px) and (max-width: 360px){
    .blocked-user-mobile{
        flex-direction: column;
        width: 115px !important;
    }
    .blocked-user-profile-mobile{
        flex-direction: column;
 
    }
    .blockeduser-mobile-view{
        display: flex;
        flex-wrap: wrap;
        gap: 11px;
    }
    .unblock-button{
        padding: 5px 27px !important;
        width: 100% !important;
        border-radius: 5px !important;
        height: 30px !important;
        margin-top: 0.5rem;
        font-size: 14px !important;
        line-height: 19.6px !important;
    }
    .undo-button{
        padding: 5px 27px !important;
        width: 100% !important;
        border-radius: 5px !important;
        height: 30px !important;

    }
    .block-img{
        width: 30px !important;
        height: 30px !important;
    }
    .without-blocked{
        width: 30px !important;
        height: 30px !important;
    }
}