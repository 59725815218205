@import "../../../styles/variablesstyles.scss";

.img-king {
  height: 130px;
  width: 130px;
  border-radius: 50%;
}
.custom-select-overview-dropdown {
  width: 100%;
  background: rgba(255, 255, 255, 0.05);
  border: 0;
  border-radius: 0.5rem;
  color: rgb(188, 188, 188);
  line-height: 3.5rem;
  font-size: 16px;
  height: 3.5rem;
  font-weight: 400;
}
.track-overview-dropdown {
  background: rgba(255, 255, 255, 0.05);
}

.treeImage-track{
  height: 476px;
  width: 476px;
  border-radius: 16px;
}

.project-font {
  font-size: $theme-heading-3-size !important;
  line-height: $theme-heading-3-lh !important;
  font-weight: 700 !important;
}
.percent-btn {
  width: 63px;
  height: 41px;
  padding: 9px 16px 9px 16px;
  background: $card-background-color;
  color: $theme-color-white;
  font-weight: 400;
}
.custom-metaballs {
  width: 24px;
  height: 24px;
  img {
    width: 24px;
  }
}

.collabrator-tab{
  p{
    font-size: 14px !important;
    padding: 8px !important;
  }
}

.chat-img {
  position: fixed;
 bottom: 10%;
  right: 8%;
  z-index: 10;
  scale: 0.8;
}

.min-height-cover {
  position: relative;
}

.disabled-btn-track{
  background-color: transparent;
  border: none;
}
.custom-metaballs2 {
  img {
    width: 24px;
  }
}
.credit-selectdropdown::placeholder {
  color: $theme-color-gray4 !important;
}

.custom-selectarrow {
  width: 106.2px;
  height: 59px;
  background: $card-background-color;
}
.select-track {
  width: 106.62px;
  height: 59px;
  background: $card-background-color;
  text-align: center;
  font-size: 16px;
}

.fg-color {
  color: $theme-color-gray2;
}
.track-overview-col {
  width: 609px;
}

.fg-color-g5 {
  color: $theme-color-gray5;
}

.content-sunheading {
  color: $theme-color-white;
  font-weight: 700 !important;
}

.producer-border {
  border-top: 1px solid $theme-color-white;
  max-height: 328px;
  overflow-y: auto;
}
.border-style {
  border-bottom: 1px solid $theme-color-gray2;
  padding-bottom: 16px;
}

.producer-main {
  width: 576px;
}

.fs-color-g3 {
  font-weight: 700;
  color: $theme-color-gray3;
}

.fw-400-wh {
  font-weight: 700;
  color: $theme-color-white;
  font-size: $theme-sm-fs;
  line-height: $theme-sm-lh;
}

.textFeild-error-missing{
  padding-right: 45px !important;
}

.missing-text {
  font-weight: 700;
  line-height: $theme-heading-4-lh;
  color: rgb(220, 53, 69);
}

.fc-gray4 {
  font-weight: 400;
  color: $theme-color-gray4;
}
.downloadicon-metaballs-file-center {
  gap: 1rem !important;
  align-items: center;
}

.release-btn {
  width: 140px;
  height: 48px;
  background: $theme-color-black2;
  color: $theme-color-gray3;
}
.release-btn2 {
  width: 140px;
  height: 48px;
  white-space: nowrap;
}
.release-btn3{
  width: 140px;
  height: 48px;
  white-space: nowrap;
  background: var(--Black-3, rgba(40, 40, 40, 1)) !important;

}
.track-plus-icon {
  height: 130px;
  width: 130px;
  background: var(--Gray-1, rgba(51, 51, 51, 1));
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 60px;
  color: white;
  position: relative;
}
.under-plus-icon {
  position: absolute;
  width: 32px;
  height: 32px;
  bottom: 84px;
  font-weight: 200;
}
.missing-steps {
  border-radius: 10px !important;
  background: rgb(0, 0, 0, 0.5);
}
.release-content-border {
  border-bottom: 1px solid $theme-color-white;
  padding-bottom: 40px;
}

.track-tooltip {
  height: 20px;
  width: 20px;
  opacity: 0.7;
}
.track-tooltip:hover {
  opacity: 1;
  transition: all 0.7s ease;
}

.track-textarea {
  line-height: 24px;
  height: 116px;
  padding: 16px;
  font-weight: 400 !important;
}
.king-roland-text{
  overflow: hidden;
  text-overflow: ellipsis;
}

.textfield-track:hover{
  color: ( rgba(225, 225, 225, 1));
  transition: all 0.7s ease-in-out;
}

@media (min-width: 768px) and (max-width: 1023px) {
  .img-king {
    height: auto;
    width: 80px;
    border-radius: 50%;
  }
  .collaborators-content {
    flex-wrap: wrap;
  }
  .collaborators-content-under {
    width: 100%;
  }
  .file-center-main {
    flex-wrap: wrap;
    width: 100% !important;
  }
  .select-track {
    width: 98.62px;
    height: 55px;
    background: rgba(255, 255, 255, 0.1);
    text-align: center;
    font-size: 16px;
  }
}
@media (min-width: 1024px) and (max-width: 1199px) {
  .img-king {
    height: auto;
    width: 80px;
    border-radius: 50%;
  }
  .king-roland-text {
    font-size: 22px;
  }
  .collaborators-content {
    flex-wrap: wrap;
    width: 565px;
    margin: auto;
  }
  .collaborators-content-under {
    width: 100%;
    margin-bottom: $theme-spacing3;
  }
  .select-track {
    width: 98.62px;
    height: 55px;
    background: rgba(255, 255, 255, 0.1);
    text-align: center;
    font-size: 16px;
  }
  .producer-main {
    width: 450px;
  }
  .beat-empty-artwork {
    width: 80%;
  }
  .track-overview-col {
    width: 50%;
  }
}
@media (min-width: 1200px) and (max-width: 1399px) {
  .img-king {
    height: auto;
    width: 80px;
    border-radius: 50%;
  }

  .collaborators-content-under {
    width: 100%;
    margin-bottom: $theme-spacing3;
  }
  .select-track {
    width: 98.62px;
    height: 55px;
    background: rgba(255, 255, 255, 0.1);
    text-align: center;
    font-size: 16px;
  }
  .producer-main {
    width: 500px;
  }
}
