@import "../../styles/variablesstyles.scss";

.project-icon{
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    padding: 1rem;
    cursor: pointer;
    &-label{
        position: absolute;
        bottom: 0;
        width: max-content;
        background-color: $theme-color-gray1 !important;
        box-shadow: 0px 4px 9.3px 0px #000000BF;
    }
   

    &-box{
        // display: flex;
        justify-content: center;
        height: 194px;
        width: 194px;
        
        border-radius: 10px;
        margin-bottom: 1rem;
        position: relative;
        &-icon{
            position: absolute;
            right: 0px;
            bottom: 0px;
            display: none;
        }
    }
    &-heading{
        font-size: $theme-lg-fs;
        line-height: 28px;
        width: 100%;
        font-weight: 400;
        margin-bottom: 0.5rem;
        color:$theme-color-white ;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    &-subheading{
        font-size: $theme-sm-fs;
        font-weight: 400;
        width: 100%;
        line-height: 22px;
        color: $theme-color-gray4;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}
.project-icon:hover{
    background: $card-background-color;
    border-radius: 10px;
}
.label-margin{
    margin-left: 0.5rem;
    margin-bottom: 11px;
}






@media (min-width: 576px) and (max-width: 1024px) {
    .project-icon {
      display: block;
      padding: 1rem 1rem 1rem 0rem;
    }
    .label-margin{
        margin-left: $theme-spacing1 !important;
    }
   
  }


@media (min-width: 768px) and (max-width: 1023px) {
    .project-icon{
        padding: 1rem 1rem 1rem 0rem;
        &-box{
            height: 130px;
            width: 130px;
            background: $theme-color-gray5;
            border-radius: 10px;
            margin-bottom: 1rem;
            position: relative;
            &-icon{
                position: absolute;
                right: 0px;
                bottom: 0px;
                display: none;
            }
        }
    }
     }
@media (min-width: 1024px) and (max-width: 1199px) {
    .project-icon{
        padding: 0rem;
        &-box{
            height: 135px;
            width: 135px;
            background: $theme-color-gray5;
            border-radius: 10px;
            margin-bottom: 1rem;
            position: relative;
            &-icon{
                position: absolute;
                right: 0px;
                bottom: 0px;
                display: none;
            }
          
        }
        &-heading{
            font-size: $theme-sm-fs;
            margin-bottom: 0px;
        }
        &-subheading{
            font-size: $theme-xsm-fs;
         
        }

    }
     }
@media (min-width: 1200px) and (max-width: 1399px) {
    .project-icon{
        padding: 0rem;
        &-box{
            height: 169px;
            width: 168px;
            background: $theme-color-gray5;
            border-radius: 10px;
            margin-bottom: 1rem;
            position: relative;
            &-icon{
                position: absolute;
                right: 0px;
                bottom: 0px;
                display: none;
            }
        }
    }
    .project-icon:hover {
        background: none;
      }
  }
  @media(min-width: 360px) and (max-width: 576px){

    .project-icon{
    display: block !important;
    padding: 1rem 1rem 1rem 0rem;
        &-box{
            width: 145px;
            height: 145px;
            border-radius:5px;
            margin-bottom: 8px !important;
        }
        &-heading {
            font-size: 14px !important;
            line-height: 19.6px;
            font-weight: 400;
            margin-bottom: 0px; 
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            width: 145px;
          }
          &-subheading {
            font-size: 12px !important;
            line-height: 16.8px !important;
            white-space: pre-line;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            width: 145px;
          }
      
    }
    .project-icon-mobile-view{
          width: min-content !important;
          margin-right: 24px !important;
          padding: 0rem !important;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          width: 145px;
    }
    .project-icon:hover {
        background: none;
      }
      .label-margin{
        margin-left: $theme-spacing1 !important;
    }
  }

  @media(min-width: 320px) and (max-width: 360px){
    .project-icon:nth-of-type(even) {
        margin-right: 20px !important;
      }

    .project-icon{
        padding: 1rem 0rem 1rem 0rem;
        width: calc(50% - 24px) !important;
        margin-right: 24px !important;
        &-box{
            width: 130px;
            height: 130px;
            border-radius:5px;
            margin-bottom: 8px !important;
        }
        &-heading {
            font-size: 14px !important;
            margin-bottom: 0px;
            line-height: 19.6px !important;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            width: 145px;
           
          }
          &-subheading {
            font-size: 12px !important;
            line-height: 16.8px !important;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            width: 145px;
          }
      
    }
    .project-icon-mobile-view{
          margin-right: 24px !important;
          padding: 0rem !important;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          width: 145px;
    }
    .project-icon:hover {
        background: none;
      }
      .label-margin{
        margin-left: $theme-spacing1 !important;
    }
  }