@import "../../../styles/variablesstyles.scss";
.ProjectPage {
  &-heading {
    font-size: $theme-heading-2-size;
    font-weight: 700;
  }
  &-sub-song-name {
    font-size: 24px;
    font-weight: 700;
    line-height: 26.4px;
    text-align: left;
  }
  .maxy-wid {
    width: 150px;
  }
  &-inputbox {
    width: 498px !important;
  }
  &-subheading {
    font-size: $theme-heading-5-size;
    margin-bottom: 1.8rem;
    width: fit-content;
    padding-bottom: 2px;
    font-size: 32px;
    font-weight: 700;
    line-height: 35.2px;
    text-align: left;
  }
  &-subheading-link {
    font-size: $theme-heading-5-size;
    font-weight: 700;
    margin-bottom: 2rem;
    width: fit-content;
    padding-bottom: 2px;
  }
  &-usericon {
    margin-bottom: 56px;
  }
  &-dropdown {
    background-color: rgba(255, 255, 255, 0.05);
    border: none;
  }

  &-body {
    display: flex;
    height: 35vh;
    margin: 5vh 0 0 0;
  }
  &-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: end;
    margin-left: 25px;
  }
  &-sub-content {
    display: flex;
    align-items: center;
    gap: 20px;
    justify-content: start;
    margin-left: 1.5rem;
    &-img {
      width: 130px;
      height: 130px;
      border-radius: 50%;
      object-fit: cover;
    }
  }
  &-details {
    display: flex;
    align-items: flex-start;
  }
  &-sub-details {
    display: flex;
    align-items: flex-start;
  }

  &-song-title {
    font-size: 20px;
    font-weight: 400;
    max-width: 350px;
    white-space: break;
    line-height: 28px;
    text-align: left;
    color: $theme-color-gray5;
  }
  &-para {
    font-size: 16px;
    font-weight: 400;
    line-height: 22.4px;
    text-align: "justified";
    color: $theme-color-gray5;
  }
  &-image {
    width: auto;
    height: 35vh;
    border-radius: 16px;
    object-fit: cover;
  }
  &-playback-Btn {
    max-width: 90px;
    max-height: 90px;
    margin-left: 10px;
    cursor: pointer;
    color: $theme-color-white;
  }
  &-bottons {
    display: flex;
    gap: 16px;
    max-width: 460px;
    max-height: 48px;
    margin-left: 10px;
    color: $theme-color-white;
  }

  .ProjectPage-subheading-link:hover {
    color: $theme-color-white;
    border-bottom: 1px solid;
    padding-bottom: 1px;
    cursor: pointer;
  }
}
.width-210 {
  width: 210px;
}
.heart-icon-hide-pc-project {
  display: none;
}

/*Tablet UI*/
@media (min-width: 767px) and (max-width: 1023px) {
  .ProjectPage {
    &-heading {
      font-size: $theme-heading-2-size;
      font-weight: 700;
    }
    &-sub-song-name {
      font-size: 20px;
      line-height: 21px;
    }
    .maxy-wid {
      width: 150px;
    }
    &-inputbox {
      width: 498px !important;
    }
    &-subheading {
      font-size: 24px;
      line-height: 25px;
    }
    &-subheading-link {
      font-size: $theme-heading-5-size;
      font-weight: 700;
      margin-bottom: 2rem;
      width: fit-content;
      padding-bottom: 2px;
    }
    &-usericon {
      margin-bottom: 56px;
    }
    &-dropdown {
      background-color: rgba(255, 255, 255, 0.05);
      border: none;
    }

    &-body {
      display: flex;
      height: 35vh;
      margin: 5vh 0 0 0;
    }
    &-content {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: end;
      margin-left: 25px;
    }
    &-sub-content {
      margin-left: 0rem !important;
      gap: 0.5rem;
      &-img {
        width: 100px;
        height: 100px;
      }
    }
    &-details {
      display: flex;
      align-items: flex-start;
    }
    &-sub-details {
      align-items: baseline;
    }

    &-song-title {
      font-size: 20px;
      font-weight: 400;
      max-width: 350px;
      white-space: break;
      line-height: 28px;
      text-align: left;
      color: $theme-color-gray5;
    }
    &-para {
      font-size: 16px;
      font-weight: 400;
      line-height: 22.4px;
      text-align: "justified";
      color: $theme-color-gray5;
    }
    &-image {
      width: auto;
      height: 35vh;
      border-radius: 16px;
      object-fit: cover;
    }
    &-playback-Btn {
      max-width: 90px;
      max-height: 90px;
      margin-left: 10px;
      cursor: pointer;
      color: $theme-color-white;
    }
    &-bottons {
      display: flex;
      gap: 16px;
      max-width: 460px;
      max-height: 48px;
      margin-left: 10px;
      color: $theme-color-white;
    }

    .ProjectPage-subheading-link:hover {
      color: $theme-color-white;
      border-bottom: 1px solid;
      padding-bottom: 1px;
      cursor: pointer;
    }
  }
  .width-210 {
    width: 210px;
    height: fit-content;
  }
}

/*Mobile UI*/
@media (min-width: 360px) and (max-width: 767px) {
  .ProjectPage {
    &-heading {
      font-size: $theme-heading-2-size;
      font-weight: 700;
    }
    &-sub-song-name {
      font-size: 14px;
      font-weight: 700;
      line-height: 19.6px;
      text-align: left;
      text-wrap: nowrap;
      margin: 0px;
    }
    .maxy-wid {
      width: 150px;
    }
    &-inputbox {
      width: 498px !important;
    }
    &-subheading {
      font-size: $theme-heading-5-size;
      margin-bottom: 1.5rem;
      width: fit-content;
      padding-bottom: 2px;
      font-size: 16px;
      font-weight: 600;
      line-height: 17.6px;
      text-align: left;
    }
    &-subheading-link {
      font-size: $theme-heading-5-size;
      font-weight: 700;
      margin-bottom: 2rem;
      width: fit-content;
      padding-bottom: 2px;
    }
    &-usericon {
      margin-bottom: 56px;
    }
    &-dropdown {
      background-color: rgba(255, 255, 255, 0.05);
      border: none;
    }

    &-body {
      display: flex;
      height: auto !important;
      margin: 0% 0 0 0;
      flex-direction: column;
    }
    &-content {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: end;
      margin-left: 0px;
      width: 100%;
      margin-top: 16px;
    }
    &-sub-content {
      display: flex;
      align-items: center;
      gap: 20px;
      justify-content: start;
      margin-left: 0px;
      &-img {
        width: 58px;
        height: 58px;
        border-radius: 50%;
        object-fit: cover;
      }
    }
    &-details {
      display: flex;
      align-items: baseline;
      justify-content: space-between;
    }
    &-sub-details {
      display: flex;
      align-items: center;
      width: 100%;
    }

    &-song-title {
      font-size: 14px;
      font-weight: 400;
      max-width: 100%;
      white-space: break;
      line-height: 19.6px;
      text-align: left;
      color: $theme-color-gray5;
      align-items: baseline;
    }
    &-para {
      font-size: 16px;
      font-weight: 400;
      line-height: 22.4px;
      text-align: "justified";
      color: $theme-color-gray5;
    }
    &-image {
      width: 100%;
      height: 312px;
      border-radius: 16px;
      object-fit: cover;
    }
    &-playback-Btn {
      max-width: 90px;
      max-height: 90px;
      margin-left: 10px;
      cursor: pointer;
      color: $theme-color-white;
    }
    &-bottons {
      display: flex;
      gap: 16px;
      max-width: 460px;
      max-height: 48px;
      margin-left: 0px;
      color: $theme-color-white;
      flex-direction: column;
      position: relative;
      margin-top: 1rem !important;
      margin-bottom: 1rem !important;
    }

    .ProjectPage-subheading-link:hover {
      color: $theme-color-white;
      border-bottom: 1px solid;
      padding-bottom: 1px;
      cursor: pointer;
    }
  }
  .width-210 {
    width: 100%;
    margin: 0px !important;
  }
  // .heart-icon-project{
  //   padding: 0px !important;
  //   position: absolute;
  //   right: 0px;
  //   top: -124px;
  //   >img{
  //     width: 24px;
  //     height: 24px;
  //   }
  // }
  .drop-project {
    margin-left: auto;
  }
  .heart-icon-hide-project-mbl {
    display: none;
  }
  .heart-icon-hide-pc-project {
    display: block;
  }
}
@media (min-width: 320px) and (max-width: 360px) {
  .ProjectPage {
    &-heading {
      font-size: $theme-heading-2-size;
      font-weight: 700;
    }
    &-sub-song-name {
      font-size: 14px;
      font-weight: 700;
      line-height: 19.6px;
      text-align: left;
      text-wrap: nowrap;
      margin: 0px;
    }
    .maxy-wid {
      width: 150px;
    }
    &-inputbox {
      width: 498px !important;
    }
    &-subheading {
      font-size: $theme-heading-5-size;
      margin-bottom: 1rem !important;
      width: fit-content;
      padding-bottom: 2px;
      font-size: 16px;
      font-weight: 600;
      line-height: 17.6px;
      text-align: left;
    }
    &-subheading-link {
      font-size: $theme-heading-5-size;
      font-weight: 700;
      margin-bottom: 2rem;
      width: fit-content;
      padding-bottom: 2px;
    }
    &-usericon {
      margin-bottom: 56px;
    }
    &-dropdown {
      background-color: rgba(255, 255, 255, 0.05);
      border: none;
    }

    &-body {
      display: flex;
      height: auto !important;
      margin: 0% 0 0 0;
      flex-direction: column;
    }
    &-content {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: end;
      margin-left: 0px;
      width: 100%;
      margin-top: 16px;
    }
    &-sub-content {
      display: flex;
      align-items: center;
      gap: 20px;
      justify-content: start;
      margin-left: 0px;
      &-img {
        width: 58px;
        height: 58px;
        border-radius: 50%;
        object-fit: cover;
      }
    }
    &-details {
      display: flex;
      align-items: baseline;
      justify-content: space-between;
    }
    &-sub-details {
      display: flex;
      align-items: center;
      width: 100%;
    }

    &-song-title {
      font-size: 14px;
      font-weight: 400;
      max-width: 100%;
      white-space: break;
      line-height: 19.6px;
      text-align: left;
      color: $theme-color-gray5;
      align-items: baseline;
    }
    &-para {
      font-size: 16px;
      font-weight: 400;
      line-height: 22.4px;
      text-align: "justified";
      color: $theme-color-gray5;
    }
    &-image {
      width: 100%;
      height: 312px;
      border-radius: 16px;
      object-fit: cover;
    }
    &-playback-Btn {
      max-width: 90px;
      max-height: 90px;
      margin-left: 10px;
      cursor: pointer;
      color: $theme-color-white;
    }
    &-bottons {
      display: flex;
      gap: 16px;
      max-width: 460px;
      max-height: 48px;
      margin-left: 0px;
      color: $theme-color-white;
      flex-direction: column;
      position: relative;
      margin-top: 1rem !important;
      margin-bottom: 1rem !important;
    }

    .ProjectPage-subheading-link:hover {
      color: $theme-color-white;
      border-bottom: 1px solid;
      padding-bottom: 1px;
      cursor: pointer;
    }
  }
  .width-210 {
    width: 100%;
    margin: 0px !important;
  }
  // .heart-icon-project{
  //   padding: 0px !important;
  //   position: absolute;
  //   right: 0px;
  //   top: -140px;
  //   >img{
  //     width: 24px;
  //     height: 24px;
  //   }
  // }
  .drop-project {
    margin-left: auto;
  }
  .heart-icon-hide-project-mbl {
    display: none;
  }
  .heart-icon-hide-pc-project {
    display: block;
  }
  // .cstm-project-mbl-btn{
  //   margin-top: 1rem !important;
  // }
}
