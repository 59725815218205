

@import '../../../../../styles/variablesstyles.scss';


.ds-ctm-txt{
    font-weight: 700;
    color:$theme-color-gray2;
    font-size: $theme-xsm-fs;
    line-height: 19.6px;
  }

  @media(min-width: 360px) and (max-width: 576px){
    .ds-ctm-txt{
       margin-bottom: 16px !important;
    }
  }

  @media(min-width: 320px) and (max-width: 360px){
    .ds-ctm-txt{
       margin-bottom: 16px !important;
    }
  }