@import "../../../styles/variablesstyles.scss";


.parent-arrow{
  right: 0px;
  position: absolute !important;
  z-index: 1;
  }

.newmessage {
  width: 100%;
  max-width: 1700px;
  margin-left: auto;
  margin-right: auto;
  background-color: rgba(0, 0, 0, 0.6);
  margin-top: 0px !important;

  .fs-gray3 {
    color: $theme-color-gray3;
  }
  .group-add{
    font-size: 14px !important;
  }

  .img-profilepic {
    width: 60px;
    height: 60px;
    border-radius: 50%;
  }

  .img-profilepic-project {
    width: 60px;
    height: 60px;
    border-radius: 20%;
  }

  .notify-yellow-dot {
    width: 10px;
    height: 10px;
    background-color: yellow;
    border-radius: 50%;
    display: inline-block;
  }

  &-left {
    // min-width: 450px;
    padding: 7px 16px 20px 16px;
    border-right: 2px solid rgba(255, 255, 255, 0.1);


    .add-icon-btn{
background-color: $theme-color-secondary;
padding: 12px;
border-radius: 50%;
position: absolute;
bottom: 60px;
right: 20px;
z-index: 100;
cursor: pointer;
    }
    &-chatsection {
      overflow: auto;
      height: calc(100vh - 350px);

      &-chat {

        padding: 10PX 16PX;
        width: 100%;
        display: flex;
        align-items: center;

        &-time {
          font-weight: 400;
          font-size: 14px;
          line-height: 19.6px;
          color: rgba(252, 238, 33, 1);
          text-align: center;
        }

        &-date {
          color: $theme-color-gray3;
          font-weight: 400;
          font-size: 14px;
          line-height: 19.6px;
          text-align: center;
        }

        &-heading {
          font-size: 20px;
          line-height: 22px;
          font-weight: 600;
          color: $theme-color-gray5;
          display: flex;
          justify-content: space-between;
          width: 297px;

        }

        &-text {
          font-size: 14px;
          line-height: 19.6px;
          font-weight: 400;
          color: $theme-color-gray3;
          display: flex;

          &-long-text {
            width: 247px;
            -webkit-box-orient: vertical !important;
            -webkit-line-clamp: 1 !important;
            line-clamp: 1 !important;
            display: -webkit-box !important;
            overflow: hidden;
            text-overflow: ellipsis;
            line-height: 21px;
          }
        }
      }
    }
  }

  &-right {
    height: 100%;
    width: 100%;
    &-start-coversartion{
      background-color: $theme-color-secondary;
      padding: 16px;
      border-radius: 16px;
      font-size: $theme-lg-fs;
      line-height: $theme-lg-lh;
    }
    &-heading-content {
      padding: 7px 0px;

      &-heading {
        width: 100%;

        a {
          font-size: 20px;
          line-height: 22px;
          font-weight: 600;
          color: rgb(224, 224, 224);
          display: flex;
          justify-content: space-between;
          text-decoration: none;
          width: max-content;
        }
      }
    }

    &-chatting {
      display: flex;
      flex-direction: column;
      position: relative;
      height: calc(100vh - 270px);
      width: 100%;
    }
  }

  &-chat-history {
    display: flex;
    overflow: auto;
    padding-left: 15px;
    padding-right: 10px;
    width: 100%;
  }

  &-receiver {
    display: flex;
    align-self: flex-start;
    width: 75%;
    // position: relative;


    &-message {
      display: flex;
      align-self: flex-start;
      padding: 8px 8px !important;
      margin-bottom: 10px;
      border-radius: 0px 10px 10px 10px;
      background-color: rgba(255, 243, 243, 0.1);
      border: 0;
      gap: 20px;
      position: relative;

      &-received-message {
        font-size: 12.8px;
        color: $theme-color-gray5;
      }

      &-time {
        font-size: 12.8px;
        color: $theme-color-gray5;
      }

    }

    &-chat-triangle {
      position: relative;
      margin-left: -15px;
      top: 0px;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 0px 15px 15px 0;
      border-color: transparent rgba(255, 243, 243, 0.1) transparent transparent;
    }
  }

  &-sender {
    display: flex;
    align-self: flex-end;
   max-width: 75%;
   width: auto !important;
  position: relative;

    &-message {
      display: flex;
      align-self: flex-end;
      padding: 18px 16px;
      margin-bottom: 10px;
      border: 0;
      align-items: center;
      border-radius: 10px 0px 10px 10px;
      background-color: rgb(169, 16, 121);
      gap: 20px;
    

      &-sender-message {
        font-size: 12.8px;
      }

      &-time {
        font-size: 12.8px;
      }
    }

    &-chat-triangle {
      position: relative;
      top: 0px;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 0px 15px 15px 0;
      border-color: transparent rgba(169, 16, 121, 1) transparent transparent;
      rotate: 270deg;
    }
  }

  &-send-message {
    position: absolute;
    bottom: -55px;
    width: 100%;
  }
}
.group-add{
  font-size: $theme-sm-fs !important;
}
.dobule-tick{
  width: 21px;
  height: 19px;
}
.img-unmute{
  width: 19px !important;
}
.sender-name{
  font-size: 11px !important;
  // color: rgba(180, 200, 255, 0.8);
  color: rgba(210, 180, 255, 0.9);

  // padding-bottom: 9px;
}
.custom-received-msg{
  display: flex;
  justify-content: space-between;
  align-items: center;
}


.newmessage-left-chatsection-chat:hover,
.newmessage-left-chatsection-chat:focus-within,
.newmessage-left-chatsection-chat:active,
.active-chat {
  background: rgba(255, 255, 255, 0.1);
  border-radius: 10px;
}

.message-tabs {
  p {
    font-size: 16px !important;
  }
}

.message-image{
  max-width: 350px;
  min-height:50px;

}

video-wrapper {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
}

.video-message {
  border-radius: 15px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  position: relative;
  max-width: 400px;
}

.play-button-overlay {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
}
.sender-image{
  margin-bottom: 10px;
}

.play-button {
  border: none;
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  font-size: 16px;
  cursor: pointer;
}
.userlist-plus-icon{
  height: 50px;
  width: 50px;
  background: var(--Gray-1, rgba(51, 51, 51, 1));
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 40px;
  color: white;
  position: relative;
}
.userlist-under-plus-icon{
  position: absolute;
  bottom: 12px;
  font-weight: 200;
}
.video-player {
  border-radius: 10px;
  position: relative;
  width: 100%; // Ensures video resizes according to container size
}

// .arrowdropdown-message-file {
//   display: none; 
//   position: absolute; 
//   right: 0;
// }

// /* Show dropdown on hover */
// .newmessage-sender:hover .arrowdropdown-message-file {
//   display: block;
// }

// .newmessage-sender:focus-within .arrowdropdown-message-file {
//   display: block;
// }

.newmessage-sender .parent-arrow {
  opacity: 0;
  display: none;
  visibility: hidden; 
  position: absolute;
  right: 0;
  transition: opacity 0.3s ease, visibility 0.3s ease;
}

.newmessage-sender:hover .parent-arrow {
  opacity: 1; 
  visibility: visible; 
}
.newmessage-sender:focus-within .parent-arrow {
   display: block;
}
@media (max-width: 1400px) {
  .newmessage {
    max-width: 1386px !important;
  }
}


@media(min-width: 360px) and (max-width: 768px) {
  .newmessage-left {
    min-width: 100%;
    padding: 0px;
    border-right: none;
    height: calc(100vh - 52px);
  }
  .newmessage-send-message {
    position: absolute;
    bottom: -52px !important;
    width: 100%;
  }

  .message-tabs {
    margin: 0px !important;
    margin-bottom: 16px !important;
    p{
      font-size: 12px !important;
      line-height: 16.8px !important;
    }
  }

  .newmessage-left-heading {
    margin: 0px !important;
    padding: 10px;
    margin-bottom: 16px !important;
  }

  // .newmessage-right {
  //   display: none;
  // }
  .search-input-message {
    margin: 0px !important;
    margin-bottom: 16PX !important;
  }

  .img-profilepic {
    width: 48px !important;
    height: 48px !important;
    border-radius: 50%;
  }

  .img-profilepic-project {
    width: 48px !important;
    height: 48px !important;
    border-radius: 20%;
  }

  .newmessage-left-chatsection-chat {
    padding: 0px !important;
  }

  .newmessage-left-chatsection-chat-heading {
    font-size: 16px !important;
    font-weight: 600 !important;
    line-height: 17.6px !important;
    width: 100% !important;
  }

  .newmessage-left-chatsection-chat-text-long-text {
    font-size: 12px !important;
    font-weight: 400 !important;
    line-height: 16.8px !important;
    width: 100% !important;
  }

  .newmessage-left-chatsection {
    height: calc(100vh - 268px) !important;
  }


  .newmessage-right{
    padding: 8PX !important;
    height: calc(100dvh - 52px) !important;

  }
  .newmessage-right-chatting{
    justify-content: flex-start !important;
    padding: 24px 0px 0px 0px !important;
    height: calc(100vh - 180px) !important;

  }
  .newmessage-receiver-message{
    padding: 8px !important;
    align-items: center;
    display: flex;
    align-items: center;
  }
  .newmessage-sender-message{
    padding: 8px !important;
    align-items: center;
  }
  .newmessage-receiver {
    width: 85%;
}
.newmessage-sender {
  width: 85%;
}
.mobile-d-none{
  display: none;
}
.newmessage-left-chatsection-chat-time {
  font-weight: 400;
  font-size: 12px;
  line-height: 15.6px;
  color: rgb(252, 238, 33);
  text-align: center;
}
.img-offNotification{
  width: 19px;
  height: 19px;
}

.newmessage .notify-yellow-dot {
  width: 8px;
  height: 8px;
  background-color: yellow;
  border-radius: 50%;
  display: inline-block;
}
.mobile-pr-16{
  padding-right: 16px;
}
.newmessage-left-chatsection-chat-date {
  color: rgb(130, 130, 130);
  font-weight: 400;
  font-size: 12px;
  line-height: 19.6px;
  text-align: center;
}
.mobile-p-0{
  padding: 0px !important;
}
.newmessage-left-chatsection-chat:hover,
.newmessage-left-chatsection-chat:focus-within,
.newmessage-left-chatsection-chat:active,
.active-chat {
  background: transparent;
  border-radius: 10px;
}
.newmessage-right-heading-content-heading a {
  font-size: 16px;
  line-height: 17.6px;
  font-weight: 600;
  color: rgb(224, 224, 224);
  display: flex;
  justify-content: space-between;
  text-decoration: none;
  width: max-content;
}
.newmessage-receiver-message-time {
  font-size: 10px;
}
.newmessage-sender-message-time {
  font-size: 10px;
}
.m-w-100{
  width: 100%;
}
.message-image{
  width: 100%;
  height: auto; 
  min-height: 12px !important;
  // height: 220px;
}
.mbl-delivery-status{
     margin-left: 0.2rem !important;
}
}

@media (max-width: 360px) {
  .newmessage-left {
    min-width: 100%;
    padding: 0px;
    border-right: 0px;
  }

  .message-tabs {
    margin: 0px !important;
    margin-bottom: 16px !important;
    p{
      font-size: 12px !important;
      line-height: 16.8px !important;
    }
  }

  .newmessage-left-heading {
    margin: 0px !important;
    padding: 10px;
    margin-bottom: 16px !important;
  }

  // .newmessage-right {
  //   display: none;
  // }
  .search-input-message {
    margin: 0px !important;
    margin-bottom: 16PX !important;
  }

  .img-profilepic {
    width: 48px !important;
    height: 48px !important;
    border-radius: 50%;
  }

  .img-profilepic-project {
    width: 48px !important;
    height: 48px !important;
    border-radius: 20%;
  }

  .newmessage-left-chatsection-chat {
    padding: 0px !important;
  }

  .newmessage-left-chatsection-chat-heading {
    font-size: 16px !important;
    font-weight: 600 !important;
    line-height: 17.6px !important;
    width: 100% !important;
  }

  .newmessage-left-chatsection-chat-text-long-text {
    font-size: 12px !important;
    font-weight: 400 !important;
    line-height: 16.8px !important;
    width: 100% !important;
  }

  .newmessage-left-chatsection {
    height: calc(100vh - 304px);
  }
  .newmessage-right{
    padding: 8px !important;
    height: calc(100vh - 52px) !important;
  }
  .newmessage-right-chatting{
    justify-content: flex-start !important;
    padding: 24px 0px 0px 0px !important;
    height: calc(100dvh - 180px) !important;
  }
  .newmessage-receiver-message{
    padding: 8px !important;
    align-items: center;
  }
  .newmessage-sender-message{
    padding: 8px !important;
    align-items: center;
  }
  .newmessage-receiver {
    width: 85%;
}
.newmessage-sender {
  width: 85%;
}
.mobile-d-none{
  display: none;
}
.newmessage-left-chatsection-chat-time {
  font-weight: 400;
  font-size: 12px;
  line-height: 15.6px;
  color: rgb(252, 238, 33);
  text-align: center;
}
.img-offNotification{
  width: 19px;
  height: 19px;
}
.newmessage .notify-yellow-dot {
  width: 8px;
  height: 8px;
  background-color: yellow;
  border-radius: 50%;
  display: inline-block;
}
.mobile-pr-16{
  padding-right: 16px;
}
.newmessage-left-chatsection-chat-date {
  color: rgb(130, 130, 130);
  font-weight: 400;
  font-size: 12px;
  line-height: 19.6px;
  text-align: center;
}
.mobile-p-0{
  padding: 0px !important;
}
.newmessage-left-chatsection-chat:hover,
.newmessage-left-chatsection-chat:focus-within,
.newmessage-left-chatsection-chat:active,
.active-chat {
  background: transparent;
  border-radius: 10px;
}
.newmessage-right-heading-content-heading a {
  font-size: 16px;
  line-height: 17.6px;
  font-weight: 600;
  color: rgb(224, 224, 224);
  display: flex;
  justify-content: space-between;
  text-decoration: none;
  width: max-content;
}
.newmessage-receiver-message-time {
  font-size: 10px;
}
.newmessage-sender-message-time {
  font-size: 10px;
}
.m-w-100{
  width: 100%;
}
.newmessage-send-message {
  position: absolute;
  bottom: 2px !important;
  width: 100%;
}

.error-message-chat{
  position: absolute;
  top: 0;
  z-index: 1;
}
.message-image{
  width: 100%;
  /* height: auto; */
  min-height: 12px !important;
  height: 220px;
}
.mbl-delivery-status{
  margin-left: 0.2rem !important;
}
}