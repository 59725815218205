@import "../../../../../styles/variablesstyles.scss";

.investor-box-parent {
  gap: $theme-spacing3;
}
.investor-boxs-modal-1 {
  width: 187px;
  height: 187px;
  position: relative;
  border-radius: 10px;
  background: linear-gradient(rgba(29, 29, 29, 1), rgba(40, 40, 40, 1));
}
.custom-modal-width-s {
  max-width: 754px;
}
.investor-boxs-under-modal-1 {
  position: absolute;
  bottom: $theme-spacing4;
  left: $theme-spacing4;
  font-size: $theme-heading-5-size;
  line-height: 24.4px;
  color: $theme-color-gray5;
  font-weight: 700;
  right: $theme-spacing4;
}
.investor-boxs-radiobutton {
  position: absolute;
  right: $theme-spacing1;
  top: $theme-spacing1;
}
.select-atleast {
  color: $theme-color-gray2;
}

.disable-interactions {
  pointer-events: none !important; /* Disable all interactions */
}
@media(min-width: 500px) and (max-width: 768px){
  .investor-boxs-modal-1 {
    width: 125px;
    height: 125px;
  }
  .investor-boxs-under-modal-1{
  font-size: 14px !important;
  line-height: 19.6px !important;
  bottom: 1rem !important;
  left: 1rem !important;
  right: 1rem !important;
  }
}

@media(min-width: 360px) and (max-width: 500px){
  .investor-boxs-modal-1 {
    width: 135px;
    height: 135px;
  }
  .investor-boxs-under-modal-1{
  font-size: 14px !important;
  line-height: 19.6px !important;
  bottom: 1rem !important;
  left: 1rem !important;
  right: 1rem !important;
  }
}
@media (min-width: 360px) and (max-width: 576px) {
  .select-atleast {
    margin-bottom: 1rem !important;
  }
}
@media (min-width: 320px) and (max-width: 360px) {
  .select-atleast {
    margin-bottom: 1rem !important;
  }
  .investor-boxs-modal-1 {
    width: 120px;
    height: 120px;
  }
  .investor-boxs-under-modal-1{
  font-size: 14px !important;
  line-height: 19.6px !important;
  bottom: 1rem !important;
  left: 1rem !important;
  right: 1rem !important;
  }
}

