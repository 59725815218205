@import "../../../styles/variablesstyles.scss";
.invite{
    &-heading{
        font-size: $theme-heading-2-size;
        font-weight: 700;
        line-height:$theme-heading-2-lh;
    }
    .maxy-wid{
        width: 150px;
    }
    &-inputbox{
        width: 498px !important;
    }
    &-button{
        transition:  transform 0.7s ease;
        .active{
            color: white;
            border-bottom: 1px solid;
            font-weight: 700;
            transition:  transform 0.7s ease;
        }
        &-all{
            background-color: transparent;
            color: $theme-color-gray4;
            padding: 20px;
            font-size: $theme-lg-fs;
            font-weight: 400;
            border: none;
            line-height:$theme-lg-lh;
        }
    }
    &-acexp{

         gap: $theme-spacing2;
         &-btn {
            width: fit-content;
            padding: 9px 16px;
            border: 1px solid rgba(224, 224, 224, 1);
            border-radius: 10px;
            background: transparent;
            color: rgba(224, 224, 224, 1);
            font-size: 16px;
            font-weight: 400;
            line-height: 22.4px;
            transition: background-color 0.7s ease, color 0.7s ease, transform 0.7s ease;
      
            &.active {
              background-color: rgba(255, 255, 255, 1); 
              color: rgba(15, 15, 15, 1);
            }
          }
    }
    
    &-subheading{
        font-size: $theme-heading-5-size;
        font-weight: 700;
        margin-bottom: 2rem;
    }
    &-usericon{
        margin-bottom: 56px ;
    }
    &-dropdown{
        background-color:  rgba(255, 255, 255, 0.05);
        border: none;
    }
}

@media (min-width: 1600px) {

    .container-1408{
      max-width: 1450px!important;
      width: 1450px;
      margin: auto!important;
    }
}

@media(min-width: 360px) and (max-width: 576px){
    .invite{
        &-heading{
           margin-bottom: $theme-spacing2 !important;
        }
    &-acexp{
        gap: 0.5rem !important;
        margin-bottom: 24px !important;
        &-btn {
           width: fit-content;
           padding: 5px 8px;
           border-radius: 5px;
           font-size: 10px;
           line-height: 16.8px;
           height: 27px;
         }
   }
}
}

@media(max-width: 360px){
    .invite{
        &-heading{
           margin-bottom: $theme-spacing2 !important;
        }
    &-acexp{
        gap: 0.5rem !important;
        margin-bottom: 24px !important;
        &-btn {
           width: fit-content;
           padding: 5px 8px;
           border-radius: 5px;
           font-size: 10px;
           line-height: 16.8px;
           height: 27px;
         }
   }
}
}
