@media (min-width:769px){
    .w-54-create{
        width: 80% !important;
    }
}

@media (max-width:768px){
    .w-54-create{
        width: 100% !important;
    }
}
