@import "../../../styles/variablesstyles.scss";





.plus-icon2{
    height: 24px;
    width: 24px;
    font-size: 20px;
}
.img-king {
    height: 130px;
    width: 130px;
    border-radius: 50%;
}
.upload-btn{
    height: 48px;
    width: 188px;
}
.percent-btn {
    width: 63px;
    height: 41px;
    padding: 9px 16px 9px 16px;
    background: $card-background-color ;
    color: $theme-color-white;
}
.spotify-btn {
    width: 130px;
    height: 41px;
    padding: 9px 16px 9px 16px;
    background: $card-background-color ;
    color: rgb(188, 188, 188);
}

.select-track {
    width: 106.62px;
    height: 59px;
    background: $card-background-color ;
    text-align: center;
    font-size: 16px;
}

.fg-color {
    color: $theme-color-gray2;
}

.fg-color-g5 {
    color: $theme-color-gray5 ;
}
.content-sunheading {
    color: $theme-color-white ;
    font-weight: 700 !important;
}

.producer-border {
    border-top: 1px solid $theme-color-white;
    max-height: 328px;
    overflow-y: auto;
}

.producer-main {
    width: 576px;
}

.fs-color-g3 {
    font-weight: 700;
    color: $theme-color-gray3 ;
}

.fw-400-wh {
    font-weight: 400;
    color: $theme-color-white ;
}

.missing-text {
    font-weight: 700;
    line-height: $theme-heading-4-lh;
    color: rgb(220, 53, 69);
}

.fc-gray4 {
    font-weight: 400;
    color: $theme-color-gray4;
}

.fs-56 {
    font-size: 56px;
    font-weight: 700;
    line-height: 61.6px;
    color: $theme-color-white
}

.release-btn {
    width: 191px;
    height: 48px;
    background:$theme-color-black2 ;
    color: $theme-color-gray3;
}
.track-plus-icon{
    height: 130px;
    width: 130px;
    background: var(--Gray-1, rgba(51, 51, 51, 1));
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 60px;
    color: white;
    position: relative;
}
.under-plus-icon{
    position: absolute;
    width: 32px;
    height: 32px;
    bottom: 84px;
    font-weight: 200;
}
.track-links-align{
    align-items: center;
    display: flex;
    line-height: 19px;
}

.textFeild-error {
    border-color: var(--bs-form-invalid-border-color);
    padding-right: calc(1.5em + .75rem);
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23dc3545'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-position: right calc(.375em + .1875rem) center;
    background-size: calc(.75em + .375rem) calc(.75em + .375rem);
  }

  .remove-icon{
    margin-right: -40px;
    margin-top: 19px;
  }
  


@media (min-width: 768px) and (max-width: 1023px) {
    .img-king {
        height: auto;
        width: 80px;
        border-radius: 50%;
    }
    .collaborators-content{
        flex-wrap: wrap;
    }
    .collaborators-content-under{
        width: 100% ;
    }
    .file-center-main{
        flex-wrap: wrap;
        width: 100% !important;
    }
    .select-track {
        width: 98.62px;
        height: 55px;
        background: rgba(255, 255, 255, 0.1);
        text-align: center;
        font-size: 16px;
    }
     }
@media (min-width: 1024px) and (max-width: 1199px) {
    .img-king {
        height: auto;
        width: 80px;
        border-radius: 50%;
    }
    .king-roland-text{
        font-size: 22px;
    }

    .collaborators-content-under{
        width: 100% ;
        margin-bottom: $theme-spacing3;
    }
    .select-track {
        width: 98.62px;
        height: 55px;
        background: rgba(255, 255, 255, 0.1);
        text-align: center;
        font-size: 16px;
    }
    .producer-main {
        width: 450px;
    }
    .track-links{

        padding-left: 52px;
    }
     }
@media (min-width: 1200px) and (max-width: 1399px) {
    .img-king {
        height: auto;
        width: 80px;
        border-radius: 50%;
    }

    .collaborators-content-under{
        width: 100% ;
        margin-bottom: $theme-spacing3;
    }
    .select-track {
        width: 98.62px;
        height: 55px;
        background: rgba(255, 255, 255, 0.1);
        text-align: center;
        font-size: 16px;
    }
    .producer-main {
        width: 500px;
    }
  }