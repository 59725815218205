.settingUp {
  position: relative;
  &_form{
    max-width: 546px;
  }
  &_uploadbox{
    // height:400px;
    aspect-ratio: 1;
    border: 2px dashed rgba(188, 188, 188, 1);
    border-radius: 10px;
    display: flex;
    width: 476px;
    justify-content: center;
    align-items: center;
    opacity: 0.75;
    background: #000000;
    margin-left: auto;
  }
  .w-476{
width: 476px;
display: flex;
justify-content: center;
margin-left: auto;
  }
  &_normal {
    font-weight: 400;
  }
  &_inp {
    width: 100%;
    background:  rgb(90 3 73 / 30%);
    border: 0;
    border-radius: 0.5rem;
    color: #bcbcbc;
    line-height: 3.5rem;
    font-size: 1.4rem;
    padding-left: 1rem;
    resize: none;
  }
  &_inp::placeholder {
    color:#bcbcbc;
    font-size: 1.3rem;
  }
  &_inp:focus {
    outline: 0;
    border: 0;
  }

  &_register_btn{
    font-weight: 700;
    background: rgba(169, 15, 121, 1);
    border: 0;
    color: #fff;
    width: 100%;
    text-align: center ;
    border-radius: 0.5rem;
    line-height: 3rem;
  }
  &_register_finish{
    font-weight: 600;
    background: rgba(255, 245, 0, 1);
    color: #000000;
    width: 130px;
  }
  &_or{
    color: rgba(188, 188, 188, 1);
  }
  &_register_google{
    font-weight: 700;
    background:rgba(255, 255, 255, 1);
    border: 0;
    color: rgba(31, 31, 31, 1);
    width: 100%;
    text-align: center ;
    border-radius: 0.5rem;
    line-height: 3rem;
  }
  &_logins{
    color:  rgba(255, 245, 0, 1);
  }
  .settingUp_uploadbox label{
    color: #bcbcbc
  }
}
.finish-settingup-mobile{
  display: none;
}

@media (max-width: 768px) and (min-width: 577px) {
}

@media (max-width: 576px) {
  .setting-flex-column-reverse{
    flex-direction: column-reverse;
  }
}

@media (max-width: 1200px) {
  .settingUp_uploadbox,.w-476{
    width: 376px !important;
      }
}
@media(min-width: 360px) and (max-width: 768px) {
  .settingUp_uploadbox{
    width: 100% !important;
    height: 312px;
   &-upload-icon{
      height: 30px !important;
      width: 41px !important;
    }
    label{
      font-size: 14px !important;
      margin-top: 10px !important;
    }
  }
  .settingUp .w-476{
    width: 100% !important;
  }
  .settingUp_register_finish{
    font-size: 14px !important;
    height: 30px !important;
  }
  .mobile-m-8-settingup{
    margin-top: 8px !important;
  }
  .finish-settingup-web{
    display: none;
    
  }
  .finish-settingup-mobile{
    height: 30px !important;
    font-size: 12px !important;
    display: block;
    line-height: 12px !important;

  }
  .margin-0-settingup{
    margin: 0px !important;
  }
}

@media(min-width: 320px) and (max-width: 360px) {
  .settingUp_uploadbox{
    width: 100% !important;
    height: 312px;
    &-upload-icon{
      height: 30px !important;
      width: 41px !important;
    }
    label{
      font-size: 14px !important;
      margin-top: 10px !important;
    }
  }
  .settingUp .w-476{
    width: 100% !important;
  }
  .settingUp_register_finish{
    font-size: 14px !important;
    height: 30px !important;
  }
  .mobile-m-8-settingup{
    margin-top: 8px !important;
  }
  .finish-settingup-web{
    display: none;
    
  }
  .finish-settingup-mobile{
    height: 30px !important;
    font-size: 12px !important;
    display: block;
    line-height: 12px !important;
  }
  .margin-0-settingup{
    margin: 0px !important;
  }
  
}