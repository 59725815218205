.custom-search {
  position: relative;
  display: flex;
  justify-content: left;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.05);
  height: 40px;
  width: 402px;
  border-radius: 10px;


  &-icon{
    height: 24px;
    width: 24px;
    padding: 3px !important;
    background: transparent;
    border: none;
    color: white;
    margin: 0px 16px 0px 16px;
    color: rgba(188, 188, 188, 1);
  }
  &-cross-icon{
    height: 24px;
    width: 24px;
    padding: 3px !important;
    background: transparent;
    border: none;
    color: white;
    margin: 0px 10px 0px 0px;
    color: rgba(188, 188, 188, 1);
  }
  &-input{
    width: 100%;
    border: 0;
    padding: 0px 40px 0px 0px;
    color: rgb(255, 255, 255);
    font-size: 16px !important;
    background-color:transparent;
  }
  &-input:focus{
    outline: 0;
   border: 0;
  }
  &-closeicon{
    position: absolute;
    right: 0px;
    font-size: 18px;
    color: rgba(188, 188, 188, 1)
  }
}
@media (min-width: 360px) and (max-width: 576px){
  .custom-search {
   height: 36px;
   width: 100% !important;
   border-radius: 5px;
   }
   .custom-search-input {
    width: 100%;
    border: 0;
    padding: 0px 40px 0px 0px;
    color: rgb(255, 255, 255);
    font-size: 12px !important;
    background-color: transparent;
}
.custom-search-cross-icon {
  height: 18px;
  width: 18px;
  padding: 3px !important;
  background: transparent;
  border: none;
  color: white;
  margin: 0px 10px 0px 0px;
  color: rgb(188, 188, 188);
}
.custom-search-icon {
  height: 18px;
  width: 18px;
  padding: 3px !important;
  background: transparent;
  border: none;
  color: white;
  margin: 0px 16px 0px 16px;
  color: rgb(188, 188, 188);
}
 }

@media (min-width: 320px) and (max-width: 360px){
 .custom-search {
  height: 36px;
  width: 100% !important;
  border-radius: 5px;
  }
  .custom-search-input {
    width: 100%;
    border: 0;
    padding: 0px 40px 0px 0px;
    color: rgb(255, 255, 255);
    font-size: 12px !important;
    background-color: transparent;
}
.custom-search-cross-icon {
  height: 18px;
  width: 18px;
  padding: 3px !important;
  background: transparent;
  border: none;
  color: white;
  margin: 0px 10px 0px 0px;
  color: rgb(188, 188, 188);
}
.custom-search-icon {
  height: 18px;
  width: 18px;
  padding: 3px !important;
  background: transparent;
  border: none;
  color: white;
  margin: 0px 16px 0px 16px;
  color: rgb(188, 188, 188);
}
}




