

@import '../../../../../styles/variablesstyles.scss';



.ds-ctm-txt{
    font-weight: 700;
    color:$theme-color-gray2;
  }
  .ctm-textarea-delete{
     height: 180px;
     background-color: rgba(255, 255, 255, 0.05);
  }
  .delete-account-btn{
    background-color: $theme-color-black3;
    color: $theme-color-gray5;
  }

  @media(min-width: 320px) and (max-width: 768px){

    .delete-account-modalcontent{
       margin-top: 0.5rem !important;
    }
   
    .delete-acount-labels{
       margin-bottom: 0.5rem !important;
       font-size: 14px !important;
       line-height: 19.6px !important;
       margin-top: 0px !important;
    }
    .delete-account-input-modal{
       margin-bottom: 0px !important;
    }
    .delete-account-btn{
      margin: 0px !important;
      width: 100%;
      order: +1;
    }
    .delete-account-space{
      margin-top: 1rem !important;
    }
    .delete-acount-labels-pass{
      margin-top: 16px !important;
    }
  }