@import "../../../../../styles/variablesstyles.scss";
 
.content {
    color: $theme-color-gray5;
}
 
.box {
    width: 80px;
    height: 80px;
    background-color: rgba(217, 217, 217, 1);
    border-radius: 10px;
    display: inline;
}
.text-field{
    width: 487px;
}
.content-height{
    height: 448px;
    overflow: auto;
}
.upload-modalbtn{
    width: 186px;
    height: 48px;
    background:$theme-color-black2 ;
    color: $theme-color-gray3;
}
 