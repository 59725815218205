@import "../../../styles/variablesstyles.scss";

.user-profile {
  &-heading {
    font-size: $theme-heading-2-size;
    font-weight: 700;
  }

  .following {

    border: 1px solid var(--Black-3, rgba(40, 40, 40, 1));
    background: transparent;
  }

  .hover {
    background: rgba(255, 255, 255, 0.3);
    border: 1px solid var(--Black-3, rgba(40, 40, 40, 1))
  }

  .uni-bg-disable {
    background: linear-gradient(313.89deg, #1D1D1D 1.71%, #282828 98.51%) !important;
    width: 198px;
  }

  .maxy-wid {
    width: 150px;
  }

  &-inputbox {
    width: 498px !important;
  }

  &-button {
    .active {
      color: $theme-color-white;
      border-bottom: 1px solid;
      padding-bottom: 19px;
    }

    &-backBtn {
      background-color: transparent;
      color: $theme-color-gray4;
      padding: 5px 40px 40px 0px;
      font-size: $theme-lg-fs;
      font-weight: 400;
      border: none;
      line-height: 28px;
    }

    &-image {
      background-color: transparent;
      color: $theme-color-gray4;
      width: 16.5px;
      height: 15px;
      cursor: pointer;
    }

    &-user-profile {
      background-color: transparent;
      color: $theme-color-gray4;
      padding: 20px;
      font-size: $theme-lg-fs;
      font-weight: 400;
      border: none;
      line-height: 28px;
    }
  }

  &-subheading {
    font-size: $theme-heading-5-size;
    font-weight: 700;
    margin-bottom: 2rem;
    width: fit-content;
    padding-bottom: 2px;
  }

  &-subheading-link {
    font-size: $theme-heading-5-size;
    font-weight: 700;
    margin-bottom: 2rem;
    width: fit-content;
    padding-bottom: 2px;
  }

  &-usericon {
    margin-bottom: 56px;
  }

  &-dropdown {
    background-color: rgba(255, 255, 255, 0.05);
    border: none;
  }

  &-body {
    display: flex;
    // height: 22vh;
    margin: 238px 0 0 0;
    align-items: end;
  }

  &-content {
    display: flex;
    flex-direction: column;
    align-items: baseline;
    justify-content: center;
    margin-left: 25px;
  }

  &-details {
    display: flex;
    align-items: center;
  }

  &-id {
    font-size: 1rem;
    font-weight: 400;
    line-height: 28px;
    text-align: left;
    color: $theme-color-gray5;
  }

  &-para {
    font-size: 16px;
    font-weight: 400;
    line-height: 22.4px;
    text-align: "justified";
    color: $theme-color-gray5;
  }

  &-image {
    width: auto;
    height: 200px;
    border-radius: 50%;
    object-fit: cover;
    // margin-top: 15px;
  }

  &-blueTik {
    width: 25px;
    height: 25px;
    margin-left: 10px;
    color: $theme-color-white;
  }

  &-bottons {
    display: flex;
    gap: 16px;
    max-width: 849px;
    max-height: 48px;
    color: $theme-color-white;
  }




  .user-profiles-follow-buttons {
    font-size: $theme-xsm-fs;
    line-height: 19.6px;
    color: $theme-color-gray5;
    padding: 14px 70px 14px 70px;
    width: 183px;
  }

  .user-profiles-select-buttons {
    color: $theme-color-gray5;
    // font-size: $theme-xsm-fs;
    // line-height: 19.6px;
    color: $theme-color-gray5;
    // padding: 14px 70px 14px 70px;
    width: 202px !important;
  }

  .user-profiles-Message-buttons {
    // font-size: $theme-xsm-fs;
    line-height: 19.6px;
    color: $theme-color-gray5;
    // padding: 14px 70px 14px 70px;
    width: 198px !important;
  }

  .user-profiles-invite-buttons {
    // font-size: $theme-xsm-fs;
    line-height: 19.6px;

    // padding: 14px 70px 14px 70px;
    width: 178px !important;

    a {
      color: $theme-color-gray5
    }
  }


  .user-profile-subheading-link:hover {
    color: $theme-color-white;
    border-bottom: 1px solid;
    padding-bottom: 1px;
    cursor: pointer;
  }
}

.bio-para-text{
  word-wrap: break-word;
  white-space: normal;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.user-profile-text {
  font-size: 56px;
  font-weight: 700;
  line-height: 61.6px;
  text-align: center;
  margin: 120px 0px;
}

.all-lables-mobile{
  display: none !important;
}



/* user-profile tablet ui */

@media(min-width: 767px) and (max-width: 1023px){
  .user-profile {
    &-body {
      margin: 50px 0 0 0;
    }
    .user-profiles-follow-buttons {
      width: 130px;
      height: 45px;
    }
    .user-profiles-select-buttons {
      width: 130px;
      height: auto;
      padding: 23px 23px;
    }
    .user-profiles-Message-buttons {
      width: 130px !important;
      height: 45px;
    }
    .user-profiles-invite-buttons {
      width: 130px !important;
      height: 45px;
    }
  }
}

/* user-profile Mobile ui */

@media(min-width: 320px) and (max-width: 767px) {
  .mobile-left-0{

    left: 0;

  }
  .mobile-order{
    order: -1;
  }
  .user-profile {
    margin-top: 0px !important;
    &-heading {
      font-size: $theme-heading-2-size;
      font-weight: 700;
    }
  
    .following {
  
      border: 1px solid var(--Black-3, rgba(40, 40, 40, 1));
      background: transparent;
    }
  
    .hover {
      background: rgba(255, 255, 255, 0.3);
      border: 1px solid var(--Black-3, rgba(40, 40, 40, 1))
    }
  
    .uni-bg-disable {
      background: linear-gradient(313.89deg, #1D1D1D 1.71%, #282828 98.51%) !important;
      width: 198px;
    }
  
    .maxy-wid {
      width: 150px;
    }
  
    &-inputbox {
      width: 498px !important;
    }
  
    &-button {
      .active {
        color: $theme-color-white;
        border-bottom: 1px solid;
        padding-bottom: 7px;
      }
  
      &-backBtn {
        background-color: transparent;
        color: $theme-color-gray4;
        padding: 1px 8px 20px 8px;
        font-size: $theme-lg-fs;
        font-weight: 400;
        border: none;
        line-height: 28px;
      }
  
      &-image {
        background-color: transparent;
        color: $theme-color-gray4;
        width: 11px;
        height: 10px;
        cursor: pointer;
      }
  
      &-user-profile {
        background-color: transparent;
        color: $theme-color-gray4;
        padding: 8px;
        font-size:12px;
        font-weight: 400;
        border: none;
        line-height: 16.8px;
      }
    }
  
    &-subheading {
      font-size: 16px;
      font-weight: 700;
      margin-bottom: 8px;
      width: -moz-fit-content;
      width: fit-content;
      padding-bottom: 0px;
  }
  
    &-subheading-link {
      font-size:16px;
      font-weight: 700;
      margin-bottom: 16px;
      width: fit-content;
      padding-bottom: 2px;
    }
  
    &-usericon {
      margin-bottom: 16px;
    }
  
    &-dropdown {
      background-color: rgba(255, 255, 255, 0.05);
      border: none;
    }
  
    &-body {
      display: flex;
      // height: 190px;
      margin: 48px 0 0 0;
      align-items: end;
      position: relative;
    }
  
    &-content {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin-left: 8px;
    }
  
    &-details {
      display: flex;
      align-items: center;
    }
  
    &-id {
      font-size: 14px;
      font-weight: 400;
      line-height: 19.6px;
      text-align: left;
      color: $theme-color-gray5;
    }
  
    &-para {
      font-size: 14px;
      font-weight: 400;
      line-height: 19.6px;
      text-align: "justified";
      color: rgb(224, 224, 224);
    }
  
    &-image {
      width: 100px;
      height: 100px;
      border-radius: 50%;
      object-fit: cover;
    }
  
    &-blueTik {
      width: 25px;
      height: 25px;
      margin-left: 10px;
      color: $theme-color-white;
    }
  
    &-bottons {
      display: flex;
      gap: 16px;
      max-width: 100%;
      max-height: 100%;
      color: $theme-color-white;
      flex-direction: column;
      gap: 8px !important;
    }
  
  
  
  
    .user-profiles-follow-buttons {
      font-size:12px;
      line-height: 19.6px;
      color: $theme-color-gray5;
      padding: 5px 70px 5px 70px;
      width: 100%;
      font-weight: 600;
    }
  
    .user-profiles-select-buttons {
      color: $theme-color-gray5;
      // font-size: $theme-xsm-fs;
      // line-height: 19.6px;
      color: $theme-color-gray5;
      // padding: 14px 70px 14px 70px;
      width: 100% !important;
      font-size:12px;
      margin: 0px;
    }
  
    .user-profiles-Message-buttons {
      // font-size: $theme-xsm-fs;
      line-height: 19.6px;
      color: $theme-color-gray5;
      // padding: 14px 70px 14px 70px;
      width: 100% !important;
      font-size:12px;
      margin: 0px;
    }
  
    .user-profiles-invite-buttons {
      // font-size: $theme-xsm-fs;
      line-height: 19.6px;
      width: 100% !important;
      font-size:12px;
      margin-bottom: 0px !important;
  
      a {
        color: $theme-color-gray5
      }
    }
  
  
    .user-profile-subheading-link:hover {
      color: $theme-color-white;
      border-bottom: 1px solid;
      padding-bottom: 1px;
      cursor: pointer;
    }
  }
  .all-lables{
    display: none !important;
    gap: 8px !important;
  }
  .user-label-profile{
    font-size: 10px !important;
  }
  .user-profile-body-h100{
    height: 100% !important;
  }
  .mobile-mt-0{
    margin-top: 0px !important;
  }
  .mobile-mt-16{
    margin-top: 16px !important;
  }
  .mobile-mb-16{
    margin-bottom: 16px !important;
  }

  .right-margin{
    margin-right: 10px;
  }
  .mobile-d-none{
    display: none !important;
  }

  .bio-para-text{
    word-wrap: break-word;
    white-space: normal;
    display: -webkit-box;
    -webkit-line-clamp: 5;
    line-clamp: 5;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .user-profile-text {
    font-size: 24px;
    font-weight: 400;
    line-height: 31.6px;
    text-align: center;
    margin: 80px 0px;
}

.all-lables-mobile{
  display: flex !important;
  margin-bottom: 16px;
  margin-top: 16px !important;
  gap: 8px !important;
}

.user-profile-option-width{
  width: 20px !important;
}
}