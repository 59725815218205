@import "../../../styles/variablesstyles.scss";

.marketplace-dw-select-main {
  margin-top: 0px !important;
  &-firstrow {
    height: 786px;
    background-color: rgba(0, 0, 0, 0.6);
    &-col1 {
      width: 1345px;
      display: flex;
      align-items: center;
      justify-content: end;
      padding-right: 92px !important;
      &-content {
        width: 623px;
        &-sm {
          max-width: 567px;
        }
      }
    }
    &-col2 {
      max-width: 1215px;
    }
  }

  &-thirdrow {
    width: 1386px;
    margin-top: 195px;
    margin-bottom: 400px;
    display: flex;
    &-left {
      max-width: 565px;
    }

    &-right {
      width: 821px;
      align-items: center;
      justify-content: end;
      display: flex;
      &-content {
        width: 534px;
      }
    }
  }
}
@media (max-width: 1440px) {
  .uni-px-24 {
    padding: 0 24px;
  }
  .marketplace-dw-select-main {
    &-firstrow {
      padding: 20px 0px 20px 20px !important;
      &-col1 {
        padding-right: 0 !important;
        &-content {
          max-width: 605px !important;
          &-sm {
            max-width: 567px !important;
          }
        }
      }
    }

    &-thirdrow {
      max-width: 100% !important;
      margin-top: 195px;
      margin-bottom: 400px;
      display: block !important;
      &-left {
        max-width: 565px !important;
        margin: auto;
      }

      &-right {
        align-items: center;
        justify-content: center;
        display: flex;
        width: 100% !important;
        margin-top: 100px !important;
        &-content {
          width: 100% !important;
          padding: 20px !important;
          justify-content: center;
          h1 {
            text-align: center !important;
          }

          button {
            margin: auto !important;
          }
        }
      }
    }
  }
}

.slick-slide {
  padding: 10px;
}

.slick-track {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

@media(min-width: 360px) and (max-width: 1000px){

  .marketplace-select-head{
          font-size: 32px !important;
          line-height: 35.2px;
  }
  .marketplace-dropworksSelect-main-head{
     padding: 16px !important;
     flex-direction: column;
     align-items: flex-start !important;
  }
}
@media(min-width: 320px) and (max-width: 360px){

  .marketplace-select-head{
          font-size: 32px !important;
          line-height: 35.2px;
  }
  .marketplace-dropworksSelect-main-head{
    padding: 16px !important;
    flex-direction: column;
    align-items: flex-start !important;
  }
  .marketplace-dw-content-space{
    margin-bottom: 8px !important;
  }
  .marketplace-dw-subcontent-space{
    margin-bottom: 24px !important;
  }
}