@import '../../../../styles/variablesstyles.scss';



.footer {
    background: rgba(0, 0, 0, 0.7);
    color: rgba(177, 177, 177, 1);
    position: relative;

    &_first {
        padding-top: 3rem;
        margin-bottom: 3rem;
        &_columns {
            &_first {
                &_heading {
                    font-weight: 700;
                    line-height: 2rem;
                }

                &_subheading {
                    font-weight: 400;
                    line-height: 2rem;
                    color: $theme-color-footer;
                }
            }

            &_second {
                &_heading {
                    font-weight: 700;
                    line-height: 2rem;
                    
                }
            
                &_subheading {
                    font-weight: 400;
                    line-height: 2rem;
                    color: $theme-color-footer;
                }
            }

            &_third {
                &_heading {
                    font-weight: 700;
                    line-height: 2rem;
                }

                &_subheading {
                    font-weight: 400;
                    line-height: 2rem;
                    color: $theme-color-footer;

                    &_icons {
                        color: #eed600;
                        font-size: 0.75rem;
                        margin-left: 0.5rem;
                    }
                }
            }
        }
    }

    &_second {
        
        min-height: 4.5rem;
        border-top: 1px solid rgba(255, 255, 255, 0.22);
        display: flex;

        &_first {
            margin: 0 11% !important;
            align-items: center;
            justify-content: space-between;
            flex-wrap: wrap;
            
            &_texts {
                &_copyright {
                    font-weight: 400;
                    &_icon{
                        font-size: 1.5rem;
                    }
                }
            }

            &_icons {
                gap: 1rem;
                align-items: center;

                &_icon {
                    font-size: 1.5rem;
                    text-align: center;
                    display: flex;
                    // align-items: center;
                    gap:0.5rem;

                    &_english {
                        font-size: 0.6rem;
                        font-weight: 700;
                        border-bottom: 1px solid;
                        margin-left: 6px;
                    }
                }
            }
        }

    }
}
.footer_mobile_view{
    display: none;
}
.footer-hr{
    display: none;
}
.mobile-footerlogo{
    display: none;
}
.footer-select-language{
    width: 100%;
    font-size: 10px;
    background: black;
    color: white;
    border: none;
    border-bottom: 0.7px solid white;
}
.footer-select-language:focus-visible {
    outline: none;
    box-shadow: none;
  }
  .footer-anchor{
    text-decoration: none;
    color: rgb(231, 231, 231);
    padding: 0px !important;
    margin: 0px !important;
    width: 24px !important;
    height: 24px !important;
    align-items: center;
    display: flex;
;
  }
// .footer-select-language:focus-visible{
//     display: none !important;
// }


@media (max-width:767px) {
    .footer {
        background: rgba(0, 0, 0, 0.7);
        color: rgba(177, 177, 177, 1);

        &_first {
            padding-top: 3rem;

            &_columns {
                &_first {
                    &_heading {
                        font-weight: 700;
                        line-height: 2rem;
                    }

                    &_subheading {
                        font-weight: 400;
                        line-height: 2rem;
                    }
                }

                &_second {
                    margin-top: 1rem;

                    &_heading {
                        font-weight: 700;
                        line-height: 2rem;
                    }

                    &_subheading {
                        font-weight: 400;
                        line-height: 2rem;
                    }
                }

                &_third {
                    margin-top: 1rem;

                    &_heading {
                        font-weight: 700;
                        line-height: 2rem;
                    }

                    &_subheading {
                        font-weight: 400;
                        line-height: 2rem;

                        &_icons {
                            color: yellow;
                            margin-left: 0.5rem;
                        }
                    }
                }
            }
        }

        &_second {
            margin-top: 3rem;
            min-height: 6rem;
            border-top: 1px solid rgba(255, 255, 255, 0.22);
            display: flex;

            &_first {
                align-items: center;
                justify-content: center;
                flex-wrap: wrap;

                &_texts {
                    display: flex;
                    align-items: center;
                    flex-direction: column;
                    width: 100%;

                    &_copyright {
                        font-weight: 400;
                    }
                }

                &_icons {
                    gap: 1rem;
                    align-items: center;

                    &_english {
                        font-size: 0.6rem;
                        font-weight: 700;
                        border-bottom: 2px solid;
                    }
                }
            }

        }
    }
}
@media(min-width: 360px) and (max-width: 768px){
    .mobile-footerlogo{
        display: block;
    }
}


@media(min-width: 360px) and (max-width: 768px){

    .footer_mobile_view{
        display:block;
    }
    .mobile-footerlogo{
        display: block;
    }
    .web-footerlogo{
        display: none;
    }
    .footer-thrid-coll{
         display: none;
    }
    .footer_first_columns_second{
         margin-top: 0px;
    }  
    .footer-hr{
        display: block;
    }
    .footer_second{
        border-top: none;
        margin-top: 0px !important;
        margin-bottom: 1rem !important;
    }
    .footer_second_first{
         margin: 0px !important;
         justify-content: start !important;
    }
    .footer_second_first_icons{
        font-size: 14px !important;
        line-height: 17.36px !important;
    }
    .footer_second_first_texts{
         margin-bottom: 0.5rem !important;
         align-items: baseline !important;
    }
    .footer_second_first_texts_copyright{
        font-size: 14px !important;
        line-height: 17.36px !important;
        margin-top: 8px;
    }
    .footer_first{
        margin-bottom:1.4rem !important ;
        padding-top:1rem !important ;
    }
    .footer_first_columns_first_heading{
          font-size: 16px !important;
          line-height: 17.6px !important;
          margin-bottom: 1rem;
    }
    .footer_first_columns_second_heading{
        font-size: 16px !important;
        line-height: 17.6px !important;
        margin-bottom: 1rem;
    }
    .footer_first_columns_third_heading{
        font-size: 16px !important;
        line-height: 17.6px !important;
        margin-bottom: 1rem;
    }
    .footer-options{
        font-size: 14px;
        line-height: 19.6px;
        margin-bottom: 1rem;
    }
}

@media(min-width: 320px) and (max-width: 360px){

    .footer_mobile_view{
        display:block;
    }
    .footer-thrid-coll{
         display: none;
    }
    .footer_first_columns_second{
         margin-top: 0px;
    }  
    .footer-hr{
        display: block;
    }
    .footer_second{
        border-top: none;
        margin-top: 0px !important;
        margin-bottom: 1rem !important;
    }
    .web-footerlogo{
        display: none;
    }
    .mobile-footerlogo{
        display: block;
    }
    .footer_second_first{
         margin: 0px !important;
         justify-content: start !important;
    }
    .footer_second_first_icons{
        font-size: 14px !important;
        line-height: 17.36px !important;
    }
    .footer_second_first_texts{
         margin-bottom: 0.5rem !important;
         align-items: start !important;
    }
    .footer_second_first_texts_copyright{
        font-size: 11px !important;
        line-height: 15.36px !important;
        margin-top: 8px;
        white-space: nowrap;
    }
    .footer_first{
        margin-bottom:1.4rem !important ;
        padding-top:1rem !important ;
    }
    .footer_first_columns_first_heading{
          font-size: 16px !important;
          line-height: 17.6px !important;
          margin-bottom: 1rem;
    }
    .footer_first_columns_second_heading{
        font-size: 16px !important;
        line-height: 17.6px !important;
        margin-bottom: 1rem;
    }
    .footer_first_columns_third_heading{
        font-size: 16px !important;
        line-height: 17.6px !important;
        margin-bottom: 1rem;
    }
    .footer-options{
        font-size: 14px;
        line-height: 19.6px;
        margin-bottom: 1rem;
    }
}

