@import "../../styles/variablesstyles.scss";

.cropper-btn{
    background: linear-gradient(93.86deg, #A91079 0.6%, #53063B 100.02%);
    border: none;
    color: white;
}
.cropper-btn:hover{
    background: linear-gradient(93.86deg, #A91079 0.6%, #53063B 100.02%),
linear-gradient(0deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3));

    border: none;
    color: white;
}
.percentage-cropper{
    transform: rotate(90deg);
    width: 40px;
    text-align: center;
    color: rgba(255, 255, 255, 1);
}
.cropper-tranform{
    transform: rotate(90deg);
}

.crooper-zoom{
    align-items: center;
    transform: rotate(-90deg);
    right: -100px !important;
    top: 45%;
}


@media (min-width: 320px) and (max-width: 768px) {
    .cropper-btn{
        font-size: 8px;
    }
}

