@import "../../../../../styles/variablesstyles.scss";

.btn-bg-gray3-approve{
    background: rgba(40, 40, 40, 1);
    margin-right: 16px !important;
    color: $theme-color-gray5;
    font-weight: 400;
}
.Notify-submission{
   color: $theme-color-gray5;
}



.approve-modal-width{
    width: 703px;
//   padding: 56px 72px 56px 72px  ;
 }
 .approve-modal-title{
    color: $theme-color-white;
    font-weight: 700;
    line-height: $theme-heading-3-lh;
 }
 .approve-modal-subtitle{
    font-weight: 700;
    color: $theme-color-gray3;
 }
 .approve-modal-img{
    height: 130px;
    width: 130px;
    border-radius: 50%;
 }
 .approve-modal-text{
    font-weight: 700;
    color: $theme-color-white;
    line-height:$theme-heading-5-lh ;
 }
 .text-g3{
    font-weight: 400;
    color: $theme-color-gray3;
 }
 .text-g5{
    font-weight: 700;
    color: $theme-color-gray5;
    line-height: $theme-lg-lh;
 }