
@import "../../../../../styles/variablesstyles.scss";



.investor-box-parent-roles{
    gap: $theme-spacing3;
}

.investor-boxs-roles{
    width: 187px;
    height: 187px;
    position: relative;
    border-radius: 10px;
    background: linear-gradient(rgba(29, 29, 29, 1), rgba(40, 40, 40, 1));
}
.investor-mobile-ui{
    display: none;
}
.investor-boxs-under{
    position: absolute;
    bottom: $theme-spacing4;
    left: $theme-spacing4;
    font-size: $theme-heading-5-size;
    line-height: 24.4px;
    color: $theme-color-gray5; 
    font-weight: 700;
    right: $theme-spacing4;
}
.investor-boxs-radiobutton{
    position: absolute;
    right: $theme-spacing1;
    top: $theme-spacing1;
}

@media(min-width: 576px) and (max-width: 768px){
    .investor-box-wrapper{
        overflow-y: auto;
        max-height: 300px;
    }
    .investor-boxs-under{
        font-size: 14px !important;
        line-height: 19.6px !important;
        bottom: 1rem !important;
        left: 1rem !important;
        right: 1rem !important;
    }
}

@media(min-width: 360px) and (max-width: 576px){
.btn-mo-mr-8{
    margin-right: 8px !important;
}
    .investor-box-parent-roles{
        column-gap: 8px !important;
        flex-wrap: wrap;
        gap: 0.5rem !important;
        margin-bottom: 0.5rem !important;

    }
    .investor-boxs-roles{
        width: 138px !important;
        height: 138px !important;
        border-radius: 5px !important ;
    }
    .investor-boxs-under{
        font-size: 14px !important;
        line-height: 19.6px !important;
        bottom: 1rem !important;
        left: 1rem !important;
        right: 1rem !important;
    }
    .investor-mobile-ui{
        display:block;
    }
    .investor-box-wrapper{
        overflow-y: auto;
        max-height: 300px;
    }
}

@media(min-width: 320px) and (max-width: 360px){
    .btn-mo-mr-8{
        margin-right: 8px !important;
    }
    .investor-box-parent-roles{
        column-gap: 8px !important;
        flex-wrap: wrap;
        gap: 0.5rem !important;
        margin-bottom: 0.5rem !important;

    }
    .investor-boxs-roles{
        width: 120px !important;
        height: 120px !important;
        border-radius: 5px !important ;
    }
    .investor-boxs-under{
        font-size: 14px !important;
        line-height: 19.6px !important;
        bottom: 1rem !important;
        left: 1rem !important;
        right: 1rem !important;
       

    }
    .investor-mobile-ui{
        display:block;
    }
    .investor-box-wrapper{
        overflow-y: auto;
        max-height: 300px;
    }
}





