.switch-main {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
    scale: 0.75;
  }
  
  .switch-main input { 
    opacity: 0;
    width: 0;
    height: 0;
  }
  
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
  }
  
  .slider:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: rgba(29, 29, 29, 1);
    -webkit-transition: .4s;
    transition: .4s;
  }
  
  input:checked + .slider {
    background-color: rgba(252, 238, 33, 1);
  }
  
  input:focus + .slider {
    box-shadow: 0 0 1px #2196F3;
  }
  
  input:checked + .slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
  }
  
  .slider.round {
    border-radius: 34px;
  }
  
  .slider.round:before {
    border-radius: 50%;
  }

  @media(min-width: 320px) and (max-width: 360px){

    .switch-main {
      position: relative;
      display: inline-block;
      width: 38px;
      height: 18px;
      scale: 0.75;
    }
    
    .switch-main input {
      opacity: 0;
      width: 0;
      height: 0;
    }
    
    .slider {
      position: absolute;
      cursor: pointer;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: #ccc;
      transition: background-color 0.4s;
      border-radius: 34px;
    }
    
    .slider:before {
      position: absolute;
      content: "";
      height: 14px;
      width: 14px;
      left: 2px;
      bottom: 2px;
      background-color: rgb(29, 29, 29);
      transition: transform 0.4s ease;
      border-radius: 50%;
    }
    
    .switch-main input:checked + .slider {
      background-color: rgba(252, 238, 33, 1);
    }
    
    .switch-main input:checked + .slider:before {
      transform: translateX(20px); 
    }
    
  }