@import "../../../styles/variablesstyles.scss";
.custom_pass_container_marketplace {
  position: relative;
  display: flex;
  align-items: center;
  color: #fff !important;

.custom_pass_marketplace {
  width: 100%;
  background: $input-background-color;
  border: 0;
  border-radius: 10px 0px 0px 10px;
  color: rgb(255, 255, 255) !important;
  line-height: 60px;
  height: 60px;
  font-size: 16px ;
  padding-left: 1rem;
  font-weight: 700;
}

input[type=password].custom_pass_marketplace{
  font-family: 'Verdana'!important;
  font-size: 17px!important;
  color: #bcbcbc !important;
}

input[type=password].custom_pass_marketplace::placeholder {
  color: rgba(188, 188, 188, 1);
  font-size: $input-placeholder-size;
  font-weight: 400!important;
  font-family: 'Kumbh Sans'!important;
}



.custom_pass_marketplace::placeholder {

  color: var(--Gray-3, rgba(130, 130, 130, 1));

  font-size: 14px;
  font-weight: 400!important;
  font-family: 'Kumbh Sans'!important;
}

.custom_pass_marketplace:focus {
  outline: 0;
  border: 0;
}

.eye-icon-marketplace{
  display: flex;
  align-items: center;
  height: 60px;
  background: $input-background-color;
  border-radius: 0px 10px 10px 0px;
}
}
.input-unit{
  font-size: 14px;
  font-weight: 400;
  line-height: 19.6px;
}

@media(min-width:360px) and (max-width: 576px){
  .custom_pass_marketplace {
    width: 100%;
    border-radius: 5px;
    font-size: 12px !important;
    line-height: 16.8px !important;
    margin-bottom: 0px !important;
  }
  input[type=password].custom_pass_marketplace{
    font-size: 12px!important;
    line-height: 16.8px !important;
  }
.input-unit{
  font-size: 12px !important;
    font-weight: 400;
    line-height: 16.8px !important;
}
.custom_pass_marketplace {
  width: 100%;
  background: $input-background-color;
  border: 0;
  border-radius: 10px 0px 0px 10px;
  color: rgb(255, 255, 255) !important;
  line-height: 36px !important;
  height: 36px !important;
  font-size: 12px !important;
  padding-left: 1rem;
  font-weight: 700!important;
}
.eye-icon-marketplace{
  display: flex;
  align-items: center;
  height: 36px !important;
  background: $input-background-color;
  border-radius: 0px 10px 10px 0px;
}
.custom_pass_marketplace::placeholder {
  color: var(--Gray-3, rgba(130, 130, 130, 1));

  font-size: 12px !important;
  font-weight: 400!important;
  font-family: 'Kumbh Sans'!important;
}
}

@media(min-width:320px) and (max-width: 360px){
  .custom_pass_marketplace {
    width: 100%;
    border-radius: 5px;
    font-size: 12px !important;
    line-height: 16.8px !important;
    margin-bottom: 0px !important;
  }
  input[type=password].custom_pass_marketplace{
    font-size: 12px!important;
    line-height: 16.8px !important;
  }
.input-unit{
  font-size: 12px !important;
    font-weight: 400;
    line-height: 16.8px !important;
}
.custom_pass_marketplace {
  width: 100%;
  background: $input-background-color;
  border: 0;
  border-radius: 10px 0px 0px 10px;
  color: rgb(255, 255, 255) !important;
  line-height: 36px !important;
  height: 36px !important;
  font-size: 12px !important;
  padding-left: 1rem;
  font-weight: 700!important;
}
.eye-icon-marketplace{
  display: flex;
  align-items: center;
  height: 36px !important;
  background: $input-background-color;
  border-radius: 0px 10px 10px 0px;
}
.custom_pass_marketplace::placeholder {
  color: var(--Gray-3, rgba(130, 130, 130, 1));

  font-size: 12px !important;
  font-weight: 400!important;
  font-family: 'Kumbh Sans'!important;
}
}