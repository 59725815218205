

@import '../../../../../styles/variablesstyles.scss';


.newmessage {
  width: 100%;
  max-width: 1700px;
  margin-left: auto;
  margin-right: auto;
  background-color: rgba(0, 0, 0, 0.6);

  .fs-gray3 {
    color: $theme-color-gray3;
  }

  .img-profilepic {
    width: 60px;
    height: 60px;
    border-radius: 50%;
  }

  .img-profilepic-project {
    width: 60px;
    height: 60px;
    border-radius: 20%;
  }

  .notify-yellow-dot {
    width: 10px;
    height: 10px;
    background-color: yellow;
    border-radius: 50%;
    display: inline-block;
  }

  &-left {
    // min-width: 450px;
    padding: 7px 16px 20px 16px;

    &-chatsection {
      overflow: auto;
      height: calc(100vh - 350px);

      &-chat {

        padding: 10PX 16PX;
        width: 100%;
        display: flex;
        align-items: center;

        &-time {
          font-weight: 400;
          font-size: 14px;
          line-height: 19.6px;
          color: rgba(252, 238, 33, 1);
          text-align: center;
        }

        &-date {
          color: $theme-color-gray3;
          font-weight: 400;
          font-size: 14px;
          line-height: 19.6px;
          text-align: center;
        }

        &-heading {
          font-size: 20px;
          line-height: 22px;
          font-weight: 600;
          color: $theme-color-gray5;
          display: flex;
          justify-content: space-between;
          width: 297px;

        }

        &-text {
          font-size: 14px;
          line-height: 19.6px;
          font-weight: 400;
          color: $theme-color-gray3;
          display: flex;

          &-long-text {
            width: 247px;
            -webkit-box-orient: vertical !important;
            -webkit-line-clamp: 1 !important;
            line-clamp: 1 !important;
            display: -webkit-box !important;
            overflow: hidden;
            text-overflow: ellipsis;
            line-height: 21px;
          }
        }
      }
    }
  }

  &-right {
    height: 100%;
    width: 100%;

    &-heading-content {
      padding: 7px 0px;

      &-heading {
        width: 100%;

        a {
          font-size: 20px;
          line-height: 22px;
          font-weight: 600;
          color: rgb(224, 224, 224);
          display: flex;
          justify-content: space-between;
          text-decoration: none;
          width: max-content;
        }
      }
    }

    &-chatting {
      display: flex;
      flex-direction: column;
      position: relative;
      height: calc(100vh - 270px);
      width: 100%;
    }
  }

  &-chat-history {
    display: flex;
    overflow: auto;
    padding-left: 15px;
    padding-right: 10px;
    width: 100%;
  }

  &-receiver {
    display: flex;
    align-self: flex-start;
    width: 75%;

    .img-profilepic {
      width: 60px;
      height: 60px;
      border-radius: 50%;
    }
    &-message {
      display: flex;
      align-self: flex-start;
      padding: 18px 16px;
      margin-bottom: 10px;
      border-radius: 0px 10px 10px 10px;
      background-color: rgba(255, 243, 243, 0.1);
      border: 0;
      gap: 20px;

      &-received-message {
        font-size: 12.8px;
        color: $theme-color-gray5;
      }

      &-time {
        font-size: 12.8px;
        color: $theme-color-gray5;

      }

    }

    &-chat-triangle {
      position: relative;
      margin-left: -15px;
      top: 0px;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 0px 15px 15px 0;
      border-color: transparent rgba(255, 243, 243, 0.1) transparent transparent;
    }
  }

  &-sender {
    display: flex;
    align-self: flex-end;
    width: 75%;

    &-message {
      display: flex;
      align-self: flex-end;
      padding: 18px 16px;
      margin-bottom: 10px;
      border: 0;
      align-items: center;
      border-radius: 10px 0px 10px 10px;
      background-color: rgb(169, 16, 121);
      gap: 20px;

      &-sender-message {
        font-size: 12.8px;
      }

      &-time {
        font-size: 12.8px;
      }
    }

    &-chat-triangle {
      position: relative;
      top: 0px;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 0px 15px 15px 0;
      border-color: transparent rgba(169, 16, 121, 1) transparent transparent;
      rotate: 270deg;
    }
  }

  &-send-message {
    position: absolute;
    bottom: -55px;
    width: 100%;
  }
}  

.img-profilepic-project-chatmodal{
   width: 60px !important;
   height: 60px !important;
   border-radius: 12px;
}
.backdrop-box-filter{
  backdrop-filter: blur(38.29999923706055px);
  box-shadow: 0px 4px 4px 0px #00000040;
  padding: 7px 11px;
}
#MessageChatModal .message{
    border-radius: 0px!important;
}

#MessageChatModal .newmessage-send-message{
  margin-bottom: 0rem!important;
}
#MessageChatModal .modal-content{
  padding: 0px!important;
  border-radius: 0px!important;
}
#MessageChatModal .modal-cross-icon{
 position: relative!important;
 margin-top: -3px!important;
 top: 0px;
 right: 0px!important;
}
#MessageChatModal .message-modal{
  position: fixed;
  right: 3%;
  bottom: -12%;
  width: 850px !important;
  max-width: 40vw;
}
#MessageChatModal .newmessage-chat-history{
  padding-top: 1rem;
}

.newmessage-left-chatsection-chat:hover,
.newmessage-left-chatsection-chat:focus-within,
.newmessage-left-chatsection-chat:active,
.active-chat {
  background: rgba(255, 255, 255, 0.1);
  border-radius: 10px;
}

.message-tabs {
  p {
    font-size: 16px !important;
  }
}
.newmessage-right-chatting-modal{
  height: 900px !important;
  max-height: 50dvh !important;
}

@media (max-width: 1400px) {
  .newmessage {
    max-width: 1386px !important;
  }
}