@import "../../../../styles/variablesstyles.scss";

.signUp-fill-normal{
font-weight: 400;
color: $theme-color-gray4;
}
.react-datepicker-wrapper {
  width: 100% !important;
}
.react-datepicker-popper{
  z-index: 10 !important;
}
.birthdate-input {
    display: flex;
    align-items: center;
    gap: 5px;
  }
  .date-segment {
    width: 40px;
    padding: 5px;
    border: 1px solid #ccc;
    border-radius: 4px;
    text-align: center;
    font-size: 16px;
  } 
  .date-segment:focus {
    outline: none;
    border-color: #007bff;
  }
  
  .date-segment::placeholder {
    color: #888;
  }
  
  .birthdate-input input[type="text"] {
    width: 40px; /* For month and day */
  }
  
  .birthdate-input input[type="text"]:nth-child(5) {
    width: 60px; /* For year */
  }
  
  input[type="date"]::-webkit-calendar-picker-indicator {
    display: none;
  }
  .bg-trans{
   background: rgba(255, 255, 255, 0.05);
  }
.h-s-60{
  height: 60px !important;
}
.calendar-icon{
  position: absolute;
  right: 34px;
  top: 15px;
}

.dark-theme-datepicker {
  background-color: black !important; 
  color: #ffffff; 
  border: 1px solid #444; 
}

.dark-theme-datepicker .react-datepicker__header {
  background-color: #3c3c3c;
  border-bottom: 1px solid #555;
}

.dark-theme-datepicker .react-datepicker__current-month,
.dark-theme-datepicker .react-datepicker__day-name {
  color: #ffffff; 
}

.dark-theme-datepicker .react-datepicker__day {
  color: #cccccc;  
  background-color: transparent; 
}

.dark-theme-datepicker .react-datepicker__day:hover {
  background-color: #555; 
  color: black !important ;
}
.dark-theme-datepicker .react-datepicker__year-select{
  background-color: black !important;
  color: white;
}

.dark-theme-datepicker .react-datepicker__month-select{
  background-color: black !important;
  color: white;
}
.dark-theme-datepicker .react-datepicker__day--selected {
  background-color: #0066cc; 
  color: #ffffff; 
}

.dark-theme-datepicker .react-datepicker__day--keyboard-selected {
  background-color: #0044cc; 
  color: #ffffff;
}

.dark-theme-datepicker .react-datepicker__year-select option {
  height: 1px !important;
  max-height: 10px !important;
}

.dark-theme-datepicker .react-datepicker__day--outside-month {
  color: #777777;
}

.dark-theme-datepicker .react-datepicker__navigation {
  background-color: transparent;
  color: #ffffff; 
}
.dark-theme-datepicker .react-datepicker__navigation:hover {
  color: #cccccc; 
}

.dark-theme-datepicker .react-datepicker__month-dropdown,
.dark-theme-datepicker .react-datepicker__year-dropdown {
  background-color: #2c2c2c;
  color: #ffffff;
  border: 1px solid #444;
}

.dark-theme-datepicker .react-datepicker__month-option:hover,
.dark-theme-datepicker .react-datepicker__year-option:hover {
  background-color: black !important;
  color: #ffffff;
}

@media(min-width: 768px) and (max-width: 1000px) {
  .fill-details-mobile-ui{
       gap: 1rem !important;
  }
  .ps-fill-details{
    padding-left: 0px !important;
  }
}

@media(min-width: 320px) and (max-width: 768px) {
  .signUp-fill-normal{
    margin-top: 8px !important;
    margin-bottom: 16px !important;
    }
    .mobile-p-0-filldetails{
      padding: 0px !important;
    }
    .mobile-m-0-filldetails{
      margin: 0px !important;
    }
    .mobile-mb-0-filldetails{
      margin-bottom: 0px !important;
    }
    .btn-submit-filldetails{
      height: 30px;
      font-size: 12px !important;
      margin-top: 16px;
    }
    .fill-details-space{  
      margin-top: 0px !important;
    }
}

@media(min-width: 320px) and (max-width: 360px) {
  .signUp-fill-normal{
    margin-top: 8px !important;
    margin-bottom: 16px !important;
    }
    .mobile-p-0-filldetails{
      padding: 0px !important;
    }
    .mobile-m-0-filldetails{
      margin: 0px !important;
    }
    .mobile-mb-0-filldetails{
      margin-bottom: 0px !important;
    }
    .btn-submit-filldetails{
      height: 30px;
      font-size: 12px !important;
      margin-top: 16px;
    }
    .fill-details-space{
      margin-top: 0px !important;
    }
}