@import "../../styles/variablesstyles.scss";

.home-tabs {
  .invite-button-all-home {
    background-color: transparent;
    color: $theme-color-gray3;
    width: 307px;
    height: 75px;
    height: 75px;
   line-height: 75px;
    text-align: center;
    font-size: 24px;
    font-weight: 700;
    cursor: pointer;
    position: relative;

    &::after {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 2px;
    }
  }

  &-container {
    display: flex;
    position: relative;
  border-bottom: 1px solid $theme-color-gray3;
  .invite-button-all-home{
    margin-right: 0 !important;
  }
  }

  .active-invite {
    color: white;
    font-weight: 700;
    transition: all 0.7s ease;
    


    &::after {
      opacity: 1;
    }
  }

  .underline {
    position: absolute;
    bottom: 0;
    height: 3px;
    width: 307px;
    background-color: #fff; // Default or dynamic color
    margin-bottom: -2px;
   left: 0px;
  }
}
@media(min-width: 576px) and (max-width: 1024px){
  .home-tabs{
    .invite-button-all-home {
      padding: 32px 43px;
      line-height: 19.6px;
      font-size: 18px;
      width: auto;
      height: 68px;
    }
  
  }
  .underline {
    height: 3px;
    width: 100% !important;   
  }
}
@media(min-width: 360px) and (max-width: 576px){
  .home-tabs{
    .invite-button-all-home {
      padding: 24px 10px;
      line-height: 17.6px;
      font-size: 14px;
      width: auto;
      height: 52px;
    }
  
  }
  .underline {
    height: 3px;
    width: 100% !important;   
  }
}

@media(min-width: 320px) and (max-width: 360px){
  .home-tabs{
    .invite-button-all-home {
      padding: 24px 10px;
      line-height: 17.6px;
      font-size: 14px;
      width: auto;
      height: 52px;
    }
  
  }
  .underline {
    height: 3px;
    width: 100% !important;   
  }
}
