

@import '../../../../../styles/variablesstyles.scss';


.ds-ctm-txt{
    font-weight: 700;
    color:$theme-color-gray2;
  }
  .custom-modal-width-release{
     width: 908px !important;
     height: 884px;
     max-width: 950px !important;
  }
  .custom-modal-release{
     margin: auto;
  }
  .release-subheading{
    font-weight: 400;
    color: $theme-color-gray5;
  }
  .dropstock-text{
    color: rgba(252, 238, 33, 1);
  }
  .hot-summer{
   font-size: $theme-heading-4-size;
    line-height:$theme-heading-4-lh ;
    color: $theme-color-white;
  }
  .hot-summer-under{
   color: $theme-color-gray4;
  }
  
  .cstm-txt-invite{
     background-color: $input-background-color;
     line-height: $theme-sm-lh;
    height: 124px;
  }
  .btn-wid-decline{
      background: $theme-color-black3;
      width: 190px;
  }
  .btn-wid-accept{
      width: 188px;
  }
  
  
  
  .cstm-inp-invite::placeholder{
        
     color: $theme-color-white;
     line-height: $theme-sm-lh;
     font-weight: 700;
     font-size: $theme-sm-fs;
  }
  .block-profile-box{
       
     width: 80px;
     height: 80px;
     background-color: $theme-color-gray1;
     border-radius: 50px;
  }
  .list-text{
    color: $theme-color-gray5;
    font-weight: 400;
  }
  .list-item{
    color: $theme-color-gray5;
  }
  .displayname{
        font-size: $theme-heading-5-size;
       line-height: $theme-heading-5-lh;
       color: $theme-color-white;
  }
  .userName-text{
   font-size: $theme-xsm-fs;
    line-height: $theme-xsm-lh;
    color:$theme-color-gray5;

  }

  .releaseImage{
    max-width: 130px;
    aspect-ratio: 1;
    border-radius: 16px;
    min-width: 130px;
  }

  @media(min-width: 320px) and (max-width: 576px){

    .hot-summer{
      font-size: $theme-heading-6-size;
       line-height:$theme-heading-6-lh ;
       color: $theme-color-white;
     }
  }