@import "../../styles/variablesstyles.scss";

.user-icon{
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    padding: 1rem;
    cursor: pointer;
    &-box{
        height: 194px;
        width: 194px;
        //background: $theme-color-gray5;
        border-radius: 50%;
        margin-bottom: 1rem;
    }
    &-heading{
        font-size: $theme-lg-fs;
        font-weight: 400;
        width: 100%;
        margin-bottom: 0.5rem;
        color:$theme-color-white ;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        
    }
    &-subheading{
        width: 100%;
        font-size: $theme-xsm-fs;
        font-weight: 400;
        color: $theme-color-gray3;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}
.user-icon:hover{
    background: $card-background-color;
    border-radius: 10px;
}


@media (min-width: 768px) and (max-width: 1023px) {
    .user-icon{
        &-box{
            height: 130px;
            width: 130px;
            background: $theme-color-gray5;
            border-radius: 50%;
            margin-bottom: 1rem;
        }
    }
     }
@media (min-width: 1024px) and (max-width: 1199px) {
    .user-icon{
        &-box{
            height: 135px;
            width: 135px;
            background: $theme-color-gray5;
            border-radius: 50%;
            margin-bottom: 1rem;
        }
        &-heading{
            font-size: $theme-sm-fs ;
        }
    }
     }
@media (min-width: 1200px) and (max-width: 1399px) {
    .user-icon{
        &-box{
            height: 169px;
            width: 168px;
            background: $theme-color-gray5;
            border-radius: 50%;
            margin-bottom: 1rem;
        }
    }
  }
  @media(min-width: 576px) and (max-width: 1024px){
    .user-icon-heading{
        text-align: center;
        margin-bottom: 0px;  
        
    }
    .user-icon-subheading{
        text-align: center; 
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 145px;
    }
    .user-icon:hover{
      background-color: transparent;
    }
    .user-icon:hover {
        background: none;
    }

  }

  @media(min-width: 360px) and (max-width: 576px){

    .user-icon{
        padding: 1rem 1rem 1rem 0rem;
        &-box{
            height: 145px;
            width: 145px;
            margin-bottom: 0.5rem;
        }
        &-heading{
            font-size: 14px !important;
            text-align: center;
            line-height: 19.6px;
            font-weight: 400;
            margin-bottom: 0px;  
        }
        &-subheading{
            font-size: 12px;
            text-align: center; 
        }
    }
    .user-icon-mobile-view{
        width: max-content !important;
        padding: 0;
        padding-right: 24px !important;

    }
    .user-icon:hover {
        background: none;
      }
  }
 @media (min-width:320px) and (max-width: 360px){

    .user-icon{
        padding: 1rem 1rem 1rem 0rem;
        &-box{
            height: 130px;
            width: 130px;
            margin-bottom: 0.5rem;
        }
        &-heading{
            font-size: $theme-xsm-fs;
            text-align: center;
            margin-bottom: 0px;  
        }
        &-subheading{
            font-size: 12px;
            text-align: center;
        }
    }
    .user-icon-mobile-view{
        width: max-content !important;
        padding: 0;
        padding-right: 24px !important;
    }
    .user-icon:hover {
        background: none;
      }
  }