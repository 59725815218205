@import "../../../../../styles/variablesstyles.scss";
 
.content {
    color: $theme-color-gray5;
}
 
.modal-8-box {
    width: 80px;
    height: 80px;
    background-color: $theme-color-gray1;
    border-radius: 10px;
    display: inline;
    border-radius: 50%;
}
.invite-8-head{
   font-size:  $theme-heading-5-size;
   line-break:  $theme-heading-5-lh;
}
.text-field{
    width: 487px;
}

 
.content-height{
    height: 448px;
    overflow: auto;
}

.content-height-invite{
    max-height: 448px !important;
    height: auto !important;
}
 
.modal-8-subcontent{
     color: $theme-color-gray2;
}