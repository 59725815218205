@import "../../../styles/variablesstyles.scss";
.beats-page {
  &-heading {
    font-size: $theme-heading-2-size;
    font-weight: 700;
  }
  &-sub-song-name {
    font-size: 24px;
    font-weight: 700;
    line-height: 26.4px;
    text-align: left;
  }
  .maxy-wid {
    width: 150px;
  }
  &-inputbox {
    width: 498px !important;
  }
  &-subheading {
    font-size: $theme-heading-5-size;
    font-weight: 700;
    margin-bottom: 2rem;
    width: fit-content;
    padding-bottom: 2px;
    font-size: 32px;
    line-height: 35.2px;
    text-align: left;
  }
  &-subheading-link {
    font-size: $theme-heading-5-size;
    font-weight: 700;
    margin-bottom: 2rem;
    width: fit-content;
    padding-bottom: 2px;
  }
  &-usericon {
    margin-bottom: 56px;
  }
  &-dropdown {
    background-color: rgba(255, 255, 255, 0.05);
    border: none;
  }

  &-body {
    display: flex;
    height: 35vh;
    margin: 5vh 0 0 0;
  }
  &-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: end;
    margin-left: 25px;
  }
  &-sub-content {
    display: flex;
    align-items: center;
    gap: 20px;
    justify-content: start;
    margin-left: 25px;
    padding: 16px 0 16px 0;
    &-img {
      width: 130px;
      height: 130px;
      border-radius: 50%;
      object-fit: cover;
    }
  }
  &-details {
    display: flex;
    align-items: center;
  }
  &-sub-details {
    display: flex;
  }

  &-song-title {
    font-size: 18px;
    font-weight: 400;
    width: 40%;
    margin-top: -15px;
    white-space: break;
    line-height: 25.2px;
    text-align: left;
    color: $theme-color-gray4;
  }
  &-para {
    font-size: 16px;
    font-weight: 400;
    line-height: 22.4px;
    text-align: "justified";
    color: $theme-color-gray5;
  }
  &-image {
    width: auto;;
    height: 35vh;
    border-radius: 16px;
    object-fit: cover;
  }
  &-playback-Btn {
    max-height: 90px;
    width: auto;
    margin-left: 10px;
    color: $theme-color-white;
    transition: transform 0.2s ease-in-out, box-shadow 0.2s ease;

    &:hover {
      transform: scale(1.04); 
      // box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.2); 
    }
  
    &:active {
      transform: scale(0.95); 
      // box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.15);
    }

  }
  &-bottons {
    display: flex;
    gap: 16px;
    max-width: 493px;
    max-height: 48px;
    margin-left: 10px;
    color: $theme-color-white;
  }

  .beats-page-subheading-link:hover {
    color: $theme-color-white;
    border-bottom: 1px solid;
    padding-bottom: 1px;
    cursor: pointer;
  }
}

.beats-page-minheight{

   min-height: 1280px;
}
.beats-icon-track{
  display: flex;
  align-items: center;
}

.loader-outer {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 100;
  margin-top: -40px !important;
}
.hide-pc-icon-beat{
  display: none;
}
@media (max-width:1440px)
{
  .beats-page-playback-Btn{
    max-width: 90px;
    max-height: 60px;
    margin-left: 10px;
    color: $theme-color-white;
  }
  .beats-page-details{
      
    align-items: center;
  }
}
.beats-page-song-title {
  font-size: 14px;
  font-weight: 400;
  width: 51%;
  margin-top: -15px;
  white-space: break;
  line-height: 19.6px;
  text-align: left;
  color: $theme-color-gray4;
  word-wrap: break-word;
}
.width-178{
  width: 178px;
}


@media(min-width: 768px) and (max-width: 1023px) {
  .beats-page {
    &-heading {
      font-size: $theme-heading-2-size;
      font-weight: 700;
    }
    &-sub-song-name {
      font-size: 14px;
      font-weight: 700;
      line-height: 19.6px;
      text-align: left;
      margin: 0px;
    }
    .maxy-wid {
      width: 150px;
    }
    &-inputbox {
      width: 498px !important;
    }
    &-subheading {
      font-size: $theme-heading-5-size;
      font-weight: 600;
      margin-bottom: 2rem;
      width: fit-content;
      padding-bottom: 2px;
      font-size: 16px;
      line-height: 17.6px;
      text-align: left;
    }
    &-subheading-link {
      font-size: $theme-heading-5-size;
      font-weight: 700;
      margin-bottom: 2rem;
      width: fit-content;
      padding-bottom: 2px;
    }
    &-usericon {
      margin-bottom: 56px;
    }
    &-dropdown {
      background-color: rgba(255, 255, 255, 0.05);
      border: none;
    }
  
    &-body {
      display: flex;
      height: 100%;
      margin: 0% 0 0 0;
      flex-direction: column;

    }
    &-content {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: end;
      margin-left: 0px;
      margin-top: 16px;
    }
    &-sub-content {
      display: flex;
      align-items: center;
      gap: 20px;
      justify-content: start;
      margin-left: 0px;
      padding: 0px;
      &-img {
        width: 58px;
        height: 58px;
        border-radius: 50%;
        object-fit: cover;
      }
    }
    &-details {
      display: flex;
      align-items: baseline !important;
      justify-content: space-between;
    }
    &-sub-details {
      display: flex;
      width: 100%;
     align-items: center;
    }
  
    &-song-title {
      font-size: 14px;
      font-weight: 400;
      width: 51%;
      margin-top: -15px;
      white-space: break;
      line-height: 19.6px;
      text-align: left;
      color: $theme-color-gray4;
      word-wrap: break-word;
    }
    &-para {
      font-size: 16px;
      font-weight: 400;
      line-height: 22.4px;
      text-align: "justified";
      color: $theme-color-gray5;
    }
    &-image {
      width: 100%;
      height: 312px;
      border-radius: 16px;
      object-fit: cover;
    }
  
   
  
    .beats-page-subheading-link:hover {
      color: $theme-color-white;
      border-bottom: 1px solid;
      padding-bottom: 1px;
      cursor: pointer;
    }
  }
  .beats-page-minheight{
  
     min-height: auto;
  }
  // .beats-icon-track{
  //   position: absolute;
  //   top: -113px;
  //   right: 0px;
  //   padding: 10px 0px !important;
  //   > img{
  //     width: 24px;
  //     height: 24px;
  //   }
  // }
  .width-178{
    width: 100%;
  }
  .drop-beats{
    margin-left: auto;
  }
 
  .invite-btn-disable-mbl{
    background: rgba(21, 21, 21, 1) !important;
    color: rgba(130, 130, 130, 1) !important;
    height: 45px;
 }
 .hide-pc-icon-beat{
  display:contents;
}
.beat-heart-mbl-hide{
  display: none;
}
}

@media(min-width: 360px) and (max-width: 767px) {
  .beats-page {
    &-heading {
      font-size: $theme-heading-2-size;
      font-weight: 700;
    }
    &-sub-song-name {
      font-size: 14px;
      font-weight: 700;
      line-height: 19.6px;
      text-align: left;
      margin: 0px;
    }
    .maxy-wid {
      width: 150px;
    }
    &-inputbox {
      width: 498px !important;
    }
    &-subheading {
      font-size: $theme-heading-5-size;
      font-weight: 600;
      margin-bottom: 2rem;
      width: fit-content;
      padding-bottom: 2px;
      font-size: 16px;
      line-height: 17.6px;
      text-align: left;
    }
    &-subheading-link {
      font-size: $theme-heading-5-size;
      font-weight: 700;
      margin-bottom: 2rem;
      width: fit-content;
      padding-bottom: 2px;
    }
    &-usericon {
      margin-bottom: 56px;
    }
    &-dropdown {
      background-color: rgba(255, 255, 255, 0.05);
      border: none;
    }
  
    &-body {
      display: flex;
      height: 100%;
      margin: 0% 0 0 0;
      flex-direction: column;

    }
    &-content {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: end;
      margin-left: 0px;
      margin-top: 16px;
    }
    &-sub-content {
      display: flex;
      align-items: center;
      gap: 20px;
      justify-content: start;
      margin-left: 0px;
      padding: 0px;
      &-img {
        width: 58px;
        height: 58px;
        border-radius: 50%;
        object-fit: cover;
      }
    }
    &-details {
      display: flex;
      align-items: baseline !important;
      justify-content: space-between;
    }
    &-sub-details {
      display: flex;
      width: 100%;
     align-items: center;
    }
  
  
      &-song-title {
        font-size: 14px;
        font-weight: 400;
        width: 72%;
        margin-top: 0px;
        white-space: break;
        line-height: 19.6px;
        text-align: left;
        color: $theme-color-gray4;
        word-wrap: break-word;
      }
  
    &-para {
      font-size: 16px;
      font-weight: 400;
      line-height: 22.4px;
      text-align: "justified";
      color: $theme-color-gray5;
    }
    &-image {
      width: 100%;
      height: 312px;
      border-radius: 16px;
      object-fit: cover;
    }
    &-playback-Btn {
      max-width: 40px;
      max-height: 40px;
      margin-left: 10px;
      width: 40px;
      height: 40px;
      color: $theme-color-white;
      transition: transform 0.2s ease-in-out, box-shadow 0.2s ease;
  
      &:hover {
        transform: scale(1.1); 
        box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.2); 
      }
    
      &:active {
        transform: scale(0.95); 
        box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.15);
      }
  
    }
    &-bottons {
      display: flex;
      gap: 8px !important;
      max-width: 100%;
      max-height: 100%;
      margin-left: 0px;
      color: $theme-color-white;
      flex-direction: column;
      position: relative;
      margin-top: 16px !important;
    }
  
    .beats-page-subheading-link:hover {
      color: $theme-color-white;
      border-bottom: 1px solid;
      padding-bottom: 1px;
      cursor: pointer;
    }
  }
  .beats-page-minheight{
  
     min-height: auto;
  }
  .beat-heart-mbl-hide{
    display: none;
  }
  // .beats-icon-track{
  //   position: absolute;
  //   top: -89px;
  //   right: 0px;
  //   padding: 10px 0px !important;
  //   > img{
  //     width: 24px;
  //     height: 24px;
  //   }
  // }
  .width-178{
    width: 100%;
  }
  .drop-beats{
    margin-left: auto;
  }
  .message-btn{
    font-size: 12px !important;
    margin: 0px !important;
  }
  .invite-btn-disable-mbl{
    background: rgba(21, 21, 21, 1) !important;
    color: rgba(130, 130, 130, 1) !important;
 }
 .hide-pc-icon-beat{
  display:contents;
}
}

@media(min-width: 320px) and (max-width: 360px) {
  .beats-page {
    &-heading {
      font-size: $theme-heading-2-size;
      font-weight: 700;
    }
    &-sub-song-name {
      font-size: 14px;
      font-weight: 700;
      line-height: 19.6px;
      text-align: left;
      margin: 0px;
    }
    .maxy-wid {
      width: 150px;
    }
    &-inputbox {
      width: 498px !important;
    }
    &-subheading {
      font-size: $theme-heading-5-size;
      font-weight: 600;
      margin-bottom: 2rem;
      width: fit-content;
      padding-bottom: 2px;
      font-size: 16px;
      line-height: 17.6px;
      text-align: left;
    }
    &-subheading-link {
      font-size: $theme-heading-5-size;
      font-weight: 700;
      margin-bottom: 2rem;
      width: fit-content;
      padding-bottom: 2px;
    }
    &-usericon {
      margin-bottom: 56px;
    }
    &-dropdown {
      background-color: rgba(255, 255, 255, 0.05);
      border: none;
    }
  
    &-body {
      display: flex;
      height: 100%;
      margin: 0% 0 0 0;
      flex-direction: column;

    }
    &-content {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: end;
      margin-left: 0px;
      margin-top: 16px;
    }
    &-sub-content {
      display: flex;
      align-items: center;
      gap: 8px;
      justify-content: start;
      margin-left: 0px;
      padding: 0px;
      &-img {
        width: 58px;
        height: 58px;
        border-radius: 50%;
        object-fit: cover;
      }
    }
    &-details {
      display: flex;
      align-items: baseline !important;
      justify-content: space-between;
    }
    &-sub-details {
      display: flex;
      width: 100%;
      align-items: center;
    }
  
    &-song-title {
      font-size: 14px;
      font-weight: 400;
      width: 72%;
      margin-top: 0px;
      white-space: break;
      line-height: 19.6px;
      text-align: left;
      color: $theme-color-gray4;
      word-wrap: break-word;
    }
    &-para {
      font-size: 16px;
      font-weight: 400;
      line-height: 22.4px;
      text-align: "justified";
      color: $theme-color-gray5;
    }
    &-image {
      width: 100%;
      height: 312px;
      border-radius: 16px;
      object-fit: cover;
    }
    &-playback-Btn {
      max-width: 40px;
      max-height: 40px;
      margin-left: 10px;
      width: 40px;
      height: 40px;
      color: $theme-color-white;
      transition: transform 0.2s ease-in-out, box-shadow 0.2s ease;
  
      &:hover {
        transform: scale(1.1); 
        box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.2); 
      }
    
      &:active {
        transform: scale(0.95); 
        box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.15);
      }
  
    }
    &-bottons {
      display: flex;
      gap: 8px !important;
      max-width: 100%;
      max-height: 100%;
      margin-left: 0px;
      color: $theme-color-white;
      flex-direction: column;
      position: relative;
      margin-top: 16px !important;
    }
  
    .beats-page-subheading-link:hover {
      color: $theme-color-white;
      border-bottom: 1px solid;
      padding-bottom: 1px;
      cursor: pointer;
    }
  }
  .beats-page-minheight{
  
     min-height: auto;
  }
  // .beats-icon-track{
  //   position: absolute;
  //   top: -101px;
  //   right: 0px;
  //   padding: 10px 0px !important;
  //   > img{
  //     width: 24px;
  //     height: 24px;
  //   }
  // }
  .width-178{
    width: 100%;
  }
  .drop-beats{
    margin-left: auto;
  }
  .message-btn{
    font-size: 12px !important;
    margin: 0px !important;
  }
  .invite-btn-disable-mbl{
     background: rgba(21, 21, 21, 1) !important;
     color: rgba(130, 130, 130, 1) !important;
  }
  .beat-heart-mbl-hide{
    display: none;
  }
  .hide-pc-icon-beat{
    display:contents;
  }
}