@import "../../../../../styles/variablesstyles.scss";
.font-col-wh{
    color: $theme-color-gray5;
}
.cancel-btn-bg{
    background: rgba(40, 40, 40, 1);
    margin-right: 16px !important;
}
.arrow-icon{
    color: rgba(188, 188, 188, 1);
    font-size: 24px;
}
.change-color{
    color: $theme-color-info;
}
.btn-wid-success{
    width: 195px;
}
