@import "../../../../../styles/variablesstyles.scss";
 
.content {
    color: $theme-color-gray5;
}
.dropworks-select-subtitle{
     color: $theme-color-gray4;
}
.owned-text{
     color: $theme-color-gray3;
}
.Dropworks-select-submit-title{
    color: $theme-color-white;
}
.Dropworks-select-submit-subtitle{
    color: $theme-color-gray3;
}
.hot-summer-select{

    line-height: 22px !important;
    font-weight: 600 !important;
}
 
.box {
    width: 80px;
    height: 80px;
    background-color: rgba(217, 217, 217, 1);
    border-radius: 10px;
    display: inline;
}
.text-field{
    width: 487px;
}
 
.modal-width-inviteModal{
//    width:753px ;
//    padding:  56px 72px 56px 72px;
}
.content-height{
    height: 448px;
    overflow: auto;
}
 