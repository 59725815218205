@import "../../../styles/variablesstyles.scss";

.table-bg-parent-composite {
  background-color: rgba(0, 0, 0, 0.6);
  padding: 51px 62px 51px 62px;
  border-radius: 10px;
  border: 1px solid $theme-color-black2;
}

.table.custom-table-composite-portfolio {
  width: 100%;
  border-collapse: collapse;
  table-layout: fixed;
}

.dropstocks-composite-portfolio-head {
  font-weight: 400;
}

.dropstocks-composite-portfolio-subhead {
  font-weight: 400;
  color: $theme-color-gray3;
}

.table.custom-table-composite-portfolio th,
.table.custom-table-composite-portfolio td {
  padding: 0px;
  font-size: 18px;
  font-weight: 400;
  color: rgba(255, 255, 255, 1);
  border: none;
  vertical-align: middle;
}


.table.custom-table-composite-portfolio th.anual-return-composit {
  padding: 0px;
  font-size: 18px;
  font-weight: 400;
  color: rgba(255, 255, 255, 1);
  border: none;
  vertical-align: middle;
  // text-align: end !important;
}

.table.custom-table-composite-portfolio th.composit-quantity {
  padding: 0px;
  font-size: 18px;
  font-weight: 400;
  color: rgba(255, 255, 255, 1);
  border: none;
  vertical-align: middle;
  // text-align: center !important;
}

.table.custom-table-composite-portfolio td.composit-table-last-data {
  padding: 0px;
  font-size: 18px;
  font-weight: 400;
  color: rgba(255, 255, 255, 1);
  border: none;
  vertical-align: middle;
  // text-align: center !important;
}

.table.custom-table-composite-portfolio td.composit-date {
  padding: 0px;
  font-size: 18px;
  font-weight: 400;
  color: rgba(255, 255, 255, 1);
  border: none;
  vertical-align: middle;
  // text-align: center !important;
}


// .table.custom-table-composite-portfolio th.track-column,
// .table.custom-table-composite-portfolio td.track-column {
//   //  width: 550px; ; 
//   // max-width: 685px;
//   // min-width: 480px;
// }

.sub-quantity-price {

  color: $theme-color-gray3;
}


.table.custom-table-composite-portfolio thead {
  background-color: transparent;
  color: rgba(255, 255, 255, 1);
  border-bottom: 1px solid rgba(79, 79, 79, 1);
}


.table.custom-table-composite-portfolio tbody {
  display: block;
  max-height: 1056px;
  overflow-y: auto;
}

.table.custom-table-composite-portfolio tr {
  display: table !important;
  width: 100%;
  table-layout: fixed;
  background-color: transparent;
}

.table.custom-table-composite-portfolio tbody tr:hover {
  background-color: rgba(255, 255, 255, 0.1);
}

.date-time span {
  display: block;
}

.quantity span {
  display: block;
}

.price span {
  display: block;
}

.table-subdata {
  font-size: $theme-md-fs;
  line-height: $theme-md-lh;
  color: $theme-color-gray3;
  font-weight: 400;
}



@media(min-width: 360px) and (max-width: 768px) {

  .table-bg-parent-composite {
    padding: 0px !important;
  }
  .table-wrapper-select-portfolio{
    width: auto;
    overflow: auto;
    padding: 16px 8px 16px 16px;
  }
  .track-column{
    width: auto !important;
  }
  .table.custom-table-select-portfolio th, .table.custom-table-select-portfolio td {
  padding: 0px;
  font-size: 12px;
  font-weight: 400;
  color: rgba(255, 255, 255, 1);
  border: none;
  vertical-align: middle;
  width: 114px !important;
}
.table.custom-table-select-portfolio tr {
  table-layout: auto;
}
.table.custom-table-select-portfolio th.quantity-table-head {
  padding: 0;
  font-size: 12px;
  font-weight: 400;
  color: rgb(255, 255, 255);
  border: none;
  vertical-align: middle;
}
.table.custom-table-select-portfolio th.anual-return {
  padding: 0;
  font-size: 12px;
  font-weight: 400;
  color: rgb(255, 255, 255);
  border: none;
  vertical-align: middle;
}
.select-box-text{
  font-size: 12px !important;
}
.table.custom-table-select-portfolio td.last-table-data {
  padding: 0;
  font-size: 12px;
  font-weight: 400;
  color: rgb(255, 255, 255);
  border: none;
  vertical-align: middle;
}
.table.custom-table-select-portfolio {
  width: 100%;
  border-collapse: collapse;
  overflow: auto;
  table-layout: auto;
}
.dropstocks-composite-portfolio-head {
  margin-bottom: 0.5rem !important;
}
.table.custom-table-select-portfolio tr {
  table-layout: fixed;
}
.dropstocks-composite-portfolio-subhead {
  font-weight: 400;
  color: rgb(130, 130, 130);
  font-size: 12px !important;
}
.table.custom-table-select-portfolio tbody {
  display: block;
  max-height: calc(70vh - 200px) !important;
height: calc(70vh - 200px) !important;
  overflow-y: scroll;
}
.mobile-p-lr{
  padding-left: 16px;
  padding-top: 16px;
}
}

@media(min-width: 320px) and (max-width: 360px) {

  .table-bg-parent-composite {
    padding: 0px !important;
  }
  .composite-port-font{
    font-weight: 700;
  }
  .table-wrapper-select-portfolio{
    width: auto;
    overflow: auto;
    padding: 16px 8px 16px 16px;
  }
  .track-column{
    width: auto !important;
  }
  .table.custom-table-select-portfolio th, .table.custom-table-select-portfolio td {
  padding: 0px;
  font-size: 12px;
  font-weight: 400;
  color: rgba(255, 255, 255, 1);
  border: none;
  vertical-align: middle;
  width: 114px !important;
}
.table.custom-table-select-portfolio tr {
  table-layout: auto;
}
.table.custom-table-select-portfolio th.quantity-table-head {
  padding: 0;
  font-size: 12px;
  font-weight: 400;
  color: rgb(255, 255, 255);
  border: none;
  vertical-align: middle;
}
.table.custom-table-select-portfolio th.anual-return {
  padding: 0;
  font-size: 12px;
  font-weight: 400;
  color: rgb(255, 255, 255);
  border: none;
  vertical-align: middle;
}
.select-box-text{
  font-size: 12px !important;
}
.table.custom-table-select-portfolio td.last-table-data {
  padding: 0;
  font-size: 12px;
  font-weight: 400;
  color: rgb(255, 255, 255);
  border: none;
  vertical-align: middle;
}
.table.custom-table-select-portfolio {
  width: 100%;
  border-collapse: collapse;
  overflow: auto;
  table-layout: auto;
}
.table.custom-table-select-portfolio tr {
  table-layout: fixed;
}
.dropstocks-composite-portfolio-subhead {
  font-weight: 400;
  color: rgb(130, 130, 130);
  font-size: 12px !important;
}
.table.custom-table-select-portfolio tbody {
  display: block;
  max-height: calc(70vh - 200px) !important;
  height: calc(70vh - 200px) !important;
  overflow-y: visible;
}
.mobile-p-lr{
  padding-left: 16px;
  padding-top: 16px;
}
}