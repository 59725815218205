@import "../../../styles/variablesstyles.scss";



.custom_radio_chekbox {
  appearance: none;
  height: 24px;
  width: 24px;
  border: 1px solid var(--Gray-4, rgba(188, 188, 188, 1));
  border-radius: 50%;
  position: relative;

  &:checked {
    border: 1px solid var(--Tertiary, rgba(252, 238, 33, 1))
  }

  &:checked::before {
    content: "";
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background-color:$theme-yellow-color;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

@media(min-width: 320px) and (max-width: 576px){

.custom_radio_chekbox {
  height: 18px;
  width: 18px;
  &:checked {
    border: 1px solid var(--Tertiary, rgba(252, 238, 33, 1))
  }
  &:checked::before {
    content: "";
    width: 9px;
    height: 9px;
  }
}
}
