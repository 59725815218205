@import "../../../../styles/variablesstyles.scss";

.profile-img{
  width: 44px;
  height: 44px;
  border-radius: 50%;
}
 
.task {

  .newstudioheader_content_nav_navbar_first_links.dropdown:hover
  .newstudioheader_content_nav_navbar_first_links_dropdown,
.newstudioheader_content_nav_navbar_first_links.dropdown:focus-within
  .newstudioheader_content_nav_navbar_first_links_dropdown,
.newstudioheader_content_nav_navbar_first_links_products.dropdown:hover
  .newstudioheader_content_nav_navbar_first_links_dropdown,
.newstudioheader_content_nav_navbar_first_links_products.dropdown:focus-within
  .newstudioheader_content_nav_navbar_first_links_dropdown,
.newstudioheader_content_nav_navbar_first_links_trade.dropdown:hover
  .newstudioheader_content_nav_navbar_first_links_dropdown,
.newstudioheader_content_nav_navbar_first_links_trade.dropdown:focus-within
  .newstudioheader_content_nav_navbar_first_links_dropdown {
  display: block;
  width: max-content;
}

.newmarketplace_content_nav_navbar_first_links.dropdown:hover
.newmarketplace_content_nav_navbar_first_links_dropdown,
.newmarketplace_content_nav_navbar_first_links.dropdown:focus-within
.newmarketplace_content_nav_navbar_first_links_dropdown,
.newmarketplace_content_nav_navbar_first_links_products.dropdown:hover
.newmarketplace_content_nav_navbar_first_links_dropdown,
.newmarketplace_content_nav_navbar_first_links_products.dropdown:focus-within
.newmarketplace_content_nav_navbar_first_links_dropdown,
.newmarketplace_content_nav_navbar_first_links_trade.dropdown:hover
.newmarketplace_content_nav_navbar_first_links_dropdown,
.newmarketplace_content_nav_navbar_first_links_trade.dropdown:focus-within
.newmarketplace_content_nav_navbar_first_links_dropdown,
.newmarketplace_content_nav_navbar_first_links-2.dropdown:hover
.newmarketplace_content_nav_navbar_first_links_dropdown2,
.newmarketplace_content_nav_navbar_first_links-2.dropdown:focus-within
.newmarketplace_content_nav_navbar_first_links_dropdown2
{
display: block;
width: max-content;
}

  background: url("../../../../assets/img/background/2560x1440.png");
  background-size: 100% 100%;
  width: 100%;
  min-height: 100vh;
  color: #fff;
  &_header {
    height: 84px;
    line-height: 84px;
    width: 100%;
    position: fixed;
    padding: 0px 56px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    top: 0;
    z-index: 99;
    background: rgba(0, 0, 0, 0.45);
    backdrop-filter: blur(9px);
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);

    &_logo{
      min-width: 306px;
    }
    &_gaplist{
      gap: 56px;
    }
    &_liststyle{
      list-style: none;
      &_linkstyle{
         text-decoration: none;
         color: $theme-color-gray5;
         font-size: $theme-md-fs;
         line-height: $theme-md-lh;
         vertical-align: middle;
      }
    }
    &_searchicon{
      font-size: 18px;
    }
    &_logoheaderbutton{
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: $theme-spacing3;
      &_login{
          width: 140px;
          background: transparent;
          border: 1px solid $theme-color-white;
          padding: 14px 70px 14px 70px;
          height: 48px;
          font-size: $theme-xsm-fs;
      }
      &_signup{
         width: 140px;
         background: rgba(169, 16, 121, 1);
         padding: 14px 70px 14px 70px;
         border-radius: 8px;
         height: 48px;
         color: $theme-color-white;
         font-size: $theme-xsm-fs;
      }
    }
  }

  &_outlet {
    // overflow-y: auto;
    position: relative;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    > div:first-child {
      margin-top: 40px;
     min-height: calc(100vh - 123px);
// padding-left: 0 !important;
// padding-right:0 !important ;
    }
  }
  canvas {
    outline: none;
    display: block;
    zoom: 50%;
    width: 100% !important;
    height: 100% !important;
    right: 0;
    left: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    position: fixed;
    overflow-x: hidden;
    z-index: 1;
  }
  .login_content, .footer,.signUp_content, .page-content > div {
    z-index: 2;
  }
  .cstm-button-modal {
    background-color: transparent;
    width: 100%;
    font-size: 1rem;
    max-width: 100%;
    padding: 3px 10px;
    text-wrap: nowrap;
  }
  .cstm-button-modal:hover{

      border: 1px solid white;
      outline: none;
  }
 
}
.logo-header-content{

   height: 83px !important;
}
.open-modal{
  text-decoration: none;
  color: #fff;
}
@media(max-width: 1024px){

  .task_header_gaplist{
    gap: 32px;
  }
  .task_outlet > div:first-child {
    min-height: calc(100vh - 92px);
  }
}

@media (max-width:1441px){
  .task_header{
    padding: 0px 28px !important;
  }
  .task_header_logo{
    min-width: auto;
  }
}

.task_header_logoheaderbutton_login:hover{
  background: transparent !important;
  border: 1px solid  rgba(169, 16, 121, 1);
}
@media(min-width: 360px) and (max-width: 768px){
  .task_outlet {
    min-height: calc(100vh - 52px);
    // overflow-y: auto;
    position: relative;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    // height: 100vh;
   > div {
        padding: 0 24px !important;
    }
}
.task{
  background: url("../../../../assets/img/background/430x932.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;

}

}

@media(min-width: 320px) and (max-width: 360px){
  .task_outlet {
    // overflow-y: auto;
    min-height: calc(100vh - 52px);
    position: relative;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    // height: 100vh;
   > div {
        padding: 0 24px !important;
    }
}
.task{
  background: url("../../../../assets/img/background/360x640.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;

}
}




.home-main-nav {
  align-items: center;
  backdrop-filter: blur(9px);
  -webkit-backdrop-filter: blur(18.6px);
  background: #00000073;
  box-shadow: 0 4px 4px 0 #00000040;
  display: flex;
  padding: 0 56px;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 99;
  height: 83px;
}

.home-nav {
  margin: auto;
}

.home-nav-ul {
  display: flex;
  margin: 0px;
  padding-left: 0px;

  >li {
    color: #fff;
    font-size: 18px;
    font-weight: 400;
    line-height: 25.2px;
    list-style: none;
    margin: 11px 28px;
    padding: 18px 0px;
    padding-top: 18px !important;
    width: -webkit-max-content;
    width: max-content;
    position: relative;

    >a {
      text-decoration: none;
      color: var(--Gray-5, rgba(224, 224, 224, 1));
      transition: all 0.7s ease;
    }
  }
  
}
.header-yellow-dot{
  position: absolute;
  top: 0px;
  right: 0px;
}

.home-nav-image {
  width: 307px;
}
.studio-nav-image{
  width: 252px;
}

.home-nav-buttons {
  align-items: center;
  display: flex;
  gap: 1.5rem;
  justify-content: space-between;
}

.home-nav-buttons-signup {
  background: #a91079;
  border-radius: 8px;
  color: #fff;
  font-size: 14px;
  height: 48px;
  padding: 14px 70px;
  width: 140px;
}

.home-nav-buttons-login {
  background: #0000;
  border: 1px solid #fff;
  font-size: 14px;
  height: 48px;
  padding: 14px 70px;
  width: 140px;
}


.home-nav-dropdown {
 
  display: none;
  position: absolute;
  padding-left: 0px;
  top: 60px;
  background: var(--Black-4, rgba(15, 15, 15, 1));
  border-radius: 10px;
  min-width: 185px;

  >li {
    color: #fff;
    font-size: 18px;
    font-weight: 400;
    line-height: 25.2px;
    list-style: none;
    width: -webkit-max-content;
    width: 100%;
    position: relative;

    >a {
      text-decoration: none;
      color: var(--Gray-4, rgba(188, 188, 188, 1));
      transition: all 0.7s ease;
    }
  }

}
.home-nav-dropdown-profile {
  display: none;
  position: absolute;
  padding-left: 0px;
  top: 72px;
  background: var(--Black-4, rgba(15, 15, 15, 1));
  border-radius: 10px;
  min-width: 185px;

  >li {
    color: #fff;
    font-size: 18px;
    font-weight: 400;
    line-height: 25.2px;
    list-style: none;
    width: -webkit-max-content;
    width: 100%;
    position: relative;

    >a {
      text-decoration: none;
      color: var(--Gray-4, rgba(188, 188, 188, 1));
      transition: all 0.7s ease;
    }
  }

}
.home-nav-hover-effect{
    >li:hover{
    background: rgba(255, 255, 255, 0.3);
   
    a{
      color: white;
    }
  }
  >li:first-child:hover{
    border-radius: 10px 10px 0px 0px;
  }
  >li:last-child{
    border-radius: 0px 0px 10px 10px;
  }
}
.home-nav-hover-effect-profile{
  >li:hover{
  background: rgba(255, 255, 255, 0.3);
 
  a{
    color: white;
  }
}
>li:first-child:hover{
  border-radius: 10px 10px 0px 0px;
}
>li:last-child{
  border-radius: 0px 0px 10px 10px;
}
}

.home-nav-dropdown2 {
  display: none;
  border-radius: 10px;
  padding-left: 0px;
  background: var(--Black-4, rgba(15, 15, 15, 1));

  >li {
    color: #fff;
    font-size: 18px;
    font-weight: 400;
    line-height: 25.2px;
    list-style: none;
    padding: 18px;
    padding-top: 18px !important;
    width: -webkit-max-content;
    width: 100%;
    position: relative;
    white-space: nowrap;

    >a {
      text-decoration: none;
      color: var(--Gray-4, rgba(188, 188, 188, 1));
      transition: all 0.3s ease;
    }
  }

  >li:hover{
    background: rgba(255, 255, 255, 0.3);
    a{
      color: white;
    }
    .home-nav-dropdown{
      display: block;
    }
    .home-nav-dropdown-profile{
      display: block;
    }
  }
}
.dropdown-arrow-header{
  opacity: 0.8;
  transition: all 0.3s ease;
}
.dropdown-arrow-header:hover{
  opacity: 1;
}


.home-nav-ul {

  >.home-nav-ul-marketplace:hover,
  .home-nav-ul-marketplace:focus-within {
    .home-nav-ul-marketplace-list.actived{
      display: none !important;
    }
    .home-nav-ul-marketplace-list {
      display: block;
    }
    .home-nav-dropdown-profile {
      display: block;
    }
    .dropdown-arrow-header{
      opacity: 1;
    }
  }

  >.home-nav-ul-studio:hover,
  .home-nav-ul-studio:focus-within {
    .home-nav-ul-studio-list.actived{
      display: none !important;
    }
    .home-nav-ul-studio-list {
      display: block;
    }
    .home-nav-dropdown-profile {
      display: block;
    }
    .dropdown-arrow-header{
      opacity: 1;
    }
  }
  .home-nav-ul-studio-main:hover,
  .home-nav-ul-studio-main:focus-within {
    .home-nav-ul-studio-list-main.actived{
      display: none !important;
    }
    .home-nav-ul-studio-list-main {
      display: block;
    }
    .home-nav-dropdown-profile {
      display: block;
    }
    .dropdown-arrow-header{
      opacity: 1;
    }
  }
}
.home-nav-ul-marketplace-list .actived{
  display: none !important;
}


.home-nav-dropdown {
  >li:hover,
  li:focus-within {
    .home-nav-dropdown2 {
      display: block;
    }
    .dropdown-arrow-header{
      opacity: 1;
    }

  }
}
.home-nav-sidevar-menu{
  display: none;
}
.home-nav-sidevar-search{
  display: none;
}
.home-nav-sidevar{
  display: none;
}
.home-nav-buttons-login:hover{
  background: transparent !important;
  border: 1px solid  rgba(169, 16, 121, 1);
}



.nav-right{
  >ul{
    display: flex;
    align-items: center;
    padding: 0px !important;
    margin: 0px;
    >li{
      list-style: none;
      padding: 18px 0px;
      >a{
        text-decoration: none;
        font-size: 16px;
        font-weight: 400;
        line-height: 22.4px;
       color: rgba(255, 255, 255, 1);
       >img{
        opacity: 0.7;
        transition: all 0.7s ease;
       }
      }
    }
  }
  >ul{
    > .profile-main:hover,
    .profile-main:focus-within{
      .profile-main-list.actived {
        display: none !important;
      }
      .profile-main-list {
        display: block;
      }
      .home-nav-dropdown-profile {
        display: block;
      }
      img{
        opacity: 1;
      }
    }
  }
}
  .uni-p-18{
    padding: 18px !important;
  }

  @media(min-width: 576px) and (max-width: 1000px) {
    .home-nav-sidevar{
      display: inline-block;
    }
    .home-nav {
      display: none;
    }
  
    .home-nav-buttons {
      display: none;
    }
  
    .home-nav-image {
      width: auto;
  
      img {
        width: 25px;
        height: 20px;
      }
  
    }
    .studio-nav-image {
      width: auto;
  
      img {
        width: 25px;
        height: 20px;
      }
  
    }
  
  
    .home-main-nav {
      display: flex;
      padding: 0 24px;
      position: sticky;
      position: -webkit-sticky;
      top: 0;
      z-index: 99;
      height: 52px;
      color: white;
      justify-content: space-between;
    }
  
    .home-nav-sidevar {
      height: 100vh;
      width: 300px;
      height: 100dvh;
      left: -350px; 
      box-shadow: 4px 0px 25px 0px #000000;
      background: #000000;
      position: fixed;
      z-index: 100;
      top: 0px;
      overflow: auto;
      transition: left 1s ease-in-out;
    }
    .home-nav-sidevar.open {
      left: 0; /* Bring the sidebar into view */
    }
  
    .home-nav-sidevar-buttons {
      margin: 0px 16px;
      padding: 16px 0px;
      border-bottom: 1px solid gray;
    }
  
    .home-nav-sidevar-buttons-login {
      width: 100%;
      background: #0000;
      border: 1px solid #fff;
      font-size: 12px;
      height: 30px;
    }
  
    .home-nav-sidevar-buttons-signup {
      width: 100%;
      background: #a91079;
      border-radius: 8px;
      color: #fff;
      font-size: 12px;
      height: 30px;
    }
  
    .home-nav-sidevar-ul {
      padding: 0px;
      >li {
        list-style: none;
        padding: 16px 0px 0px 16px;
        >a {
          text-decoration: none;
          font-size: 16px;
          font-weight: 400;
          line-height: 25.2px;
          color: var(--Gray-5, #E0E0E0);
        }
        >span{
          font-size: 16px;
          font-weight: 400;
          line-height: 25.2px;
          color: var(--Gray-5, #E0E0E0);
        }
      }
      li:focus-within{
        .home-nav-sidevar-dropdown{
          display: inline-block;
        }
      }
    }
    .font-wh-sidevar{
      font-weight: 700 !important;
      color: white !important;
    }
    .hr-sidevar{
      color: white;
      margin: 16px;
    }
    .home-nav-sidevar-menu{
      display: inline-block;
    }
    .home-nav-sidevar-search{
      display: inline-block;
      font-size: 18px;
    }
    .nav-right{
      display: none;
    }
    .home-nav-sidevar-profile{
      >ul{
        display: flex;
        align-items: center;
        padding: 0px;
        margin: 0px;
        justify-content: space-between;
        padding: 16px;
      
        >span{
          display: flex;
          align-items: center;
          gap: 16px;
          >li{
            list-style: none;
            >a{
              text-decoration: none;
              color: #FFFFFF;
              .user-img-sidevar{
                height: 30px;
                width: 30px;
              }
            }
          }
        }
      }
    }
    .home-nav-sidevar-dropdown{
      display: none;
     padding-left: 16px;
     width: 100%;
        >li{
          list-style: none;
          padding-top: 16px;
          >a{
            text-decoration: none;
            color: var(--Gray-5, #E0E0E0);
            font-size: 12px;
          }
        }
      
    }
  }
  @media(min-width: 360px) and (max-width: 576px) {
    .home-nav-sidevar{
      display: inline-block;
    }
    .home-nav {
      display: none;
    }
  
    .home-nav-buttons {
      display: none;
    }
  
    .home-nav-image {
      width: auto;
  
      img {
        width: 25px;
        height: 20px;
      }
  
    }
    .studio-nav-image {
      width: auto;
  
      img {
        width: 25px;
        height: 20px;
      }
  
    }
  
  
    .home-main-nav {
      display: flex;
      padding: 0 24px;
      position: sticky;
      position: -webkit-sticky;
      top: 0;
      z-index: 99;
      height: 52px;
      color: white;
      justify-content: space-between;
   
    }
  
    .home-nav-sidevar {
      height: 100vh;
      width: 300px;
      height: 100dvh;
      left: -350px; 
      box-shadow: 4px 0px 25px 0px #000000;
      background: #000000;
      position: fixed;
      z-index: 100;
      top: 0px;
      overflow: auto;
      transition: left 1s ease-in-out;
    }
    .home-nav-sidevar.open {
      left: 0; /* Bring the sidebar into view */
    }
  
    .home-nav-sidevar-buttons {
      margin: 0px 16px;
      padding: 16px 0px;
      border-bottom: 1px solid gray;
    }
  
    .home-nav-sidevar-buttons-login {
      width: 100%;
      background: #0000;
      border: 1px solid #fff;
      font-size: 12px;
      height: 30px;
    }
  
    .home-nav-sidevar-buttons-signup {
      width: 100%;
      background: #a91079;
      border-radius: 8px;
      color: #fff;
      font-size: 12px;
      height: 30px;
    }
  
    .home-nav-sidevar-ul {
      padding: 0px;
      >li {
        list-style: none;
        padding: 16px 0px 0px 16px;
        >a {
          text-decoration: none;
          font-size: 16px;
          font-weight: 400;
          line-height: 25.2px;
          color: var(--Gray-5, #E0E0E0);
        }
        >span{
          font-size: 16px;
          font-weight: 400;
          line-height: 25.2px;
          color: var(--Gray-5, #E0E0E0);
        }
      }
      li:focus-within{
        .home-nav-sidevar-dropdown{
          display: inline-block;
        }
      }
    }
    .font-wh-sidevar{
      font-weight: 700 !important;
      color: white !important;
    }
    .hr-sidevar{
      color: white;
      margin: 16px;
    }
    .home-nav-sidevar-menu{
      display: inline-block;
    }
    .home-nav-sidevar-search{
      display: inline-block;
      font-size: 18px;

    }
    .nav-right{
      display: none;
    }
    .home-nav-sidevar-profile{
      >ul{
        display: flex;
        align-items: center;
        padding: 0px;
        margin: 0px;
        justify-content: space-between;
        padding: 16px;
      
        >span{
          display: flex;
          align-items: center;
          gap: 16px;
          >li{
            list-style: none;
            >a{
              text-decoration: none;
              color: #FFFFFF;
              .user-img-sidevar{
                height: 30px;
                width: 30px;
              }
            }
          }
        }
      }
    }
    .home-nav-sidevar-dropdown{
      display: none;
     padding-left: 16px;
     width: 100%;
        >li{
          list-style: none;
          padding-top: 16px;
          >a{
            text-decoration: none;
            color: var(--Gray-5, #E0E0E0);
            font-size: 12px;
          }
        }
      
    }
  }

@media(min-width: 320px) and (max-width: 360px) {
  .home-nav-sidevar{
    display: inline-block;
  }
  .home-nav {
    display: none;
  }

  .home-nav-buttons {
    display: none;
  }

  .home-nav-image {
    width: auto;

    img {
      width: 25px;
      height: 20px;
    }

  }
  .studio-nav-image {
    width: auto;

    img {
      width: 25px;
      height: 20px;
    }

  }


  .home-main-nav {
    display: flex;
    padding: 0 24px;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 99;
    height: 52px;
    color: white;
    justify-content: space-between;
    background: url("../../../../assets/img/background/360x640.png") !important;
  }

  .home-nav-sidevar {
    height: 100vh;
    width: 85%;
    left: -600px;
    height: 100dvh;
    box-shadow: 4px 0px 25px 0px #000000;
    background: #000000;
    position: fixed;
    z-index: 100;
    top: 0px;
    overflow: auto;
    transition: left 1s ease-in-out;
    background: url("../../../../assets/img/background/360x640.png");
    background-repeat: round;
  }
  .home-nav-sidevar.open {
    left: 0; /* Bring the sidebar into view */
  }

  .home-nav-sidevar-buttons {
    margin: 0px 16px;
    padding: 16px 0px;
    border-bottom: 1px solid gray;
  }

  .home-nav-sidevar-buttons-login {
    width: 100%;
    background: #0000;
    border: 1px solid #fff;
    font-size: 12px;
    height: 30px;
  }

  .home-nav-sidevar-buttons-signup {
    width: 100%;
    background: #a91079;
    border-radius: 8px;
    color: #fff;
    font-size: 12px;
    height: 30px;
  }

  .home-nav-sidevar-ul {
    padding: 0px;
    >li {
      list-style: none;
      padding: 16px 0px 0px 16px;
      >a {
        text-decoration: none;
        font-size: 16px;
        font-weight: 400;
        line-height: 25.2px;
        color: var(--Gray-5, #E0E0E0);

      }
      >span{
        font-size: 16px;
        font-weight: 400;
        line-height: 25.2px;
        color: var(--Gray-5, #E0E0E0);
      }
    }
    >li:focus-within{
      .home-nav-sidevar-dropdown{
        display: inline-block;
      }
    }
  }
  .font-wh-sidevar{
    font-weight: 700 !important;
    color: white !important;
  }
  .hr-sidevar{
    color: white;
    margin: 16px;
  }
  .home-nav-sidevar-menu{
    display: inline-block;
  }
  .home-nav-sidevar-search{
    display: inline-block;
    font-size: 18px;
  }
  .nav-right{
    display: none;
  }
  .home-nav-sidevar-profile{
    >ul{
      display: flex;
      align-items: center;
      padding: 0px;
      margin: 0px;
      justify-content: space-between;
      padding: 16px;
    
      >span{
        display: flex;
        align-items: center;
        gap: 16px;
        >li{
          list-style: none;
          >a{
            text-decoration: none;
            color: #FFFFFF;
            .user-img-sidevar{
              height: 30px;
              width: 30px;
            }
          }
        }
      }
    }
  }
  .home-nav-sidevar-dropdown{
    display: none;
   padding-left: 16px;
   width: 100%;
      >li{
        list-style: none;
        padding-top: 16px;
        >a{
          text-decoration: none;
          color: var(--Gray-5, #E0E0E0);
          font-size: 12px;
        }
      }
    
  }
}
.no-scroll {
  overflow: hidden;
}

.profile-img{
  width: 44px;
  height: 44px;
  border-radius: 50%;
}


.blur {
  filter: blur(2px); 
  transition: filter 1s ease-in-out; 
}

@media (min-width:1000px) {
  .truncate-text {
    min-width: 50px;
    max-width: 105px;            /* Set the desired width */
    overflow: hidden;        /* Hide the overflow content */
    text-overflow: ellipsis; /* Add ellipsis (...) */
    white-space: nowrap;
    text-align: center;     /* Prevent text from wrapping to the next line */
  }
 
  .ml-50{
margin-left: -40px;
  }
 
}
 
// CSS for the dropdown of header 
.home-nav-ul-marketplace > .dropdown-container-header {
  height: 0px;
  overflow: hidden;
  transition: height 0.3s ease;
}

.home-nav-ul-marketplace:hover > .dropdown-container-header {
  height: 320px; /* The desired expanded height */
}