@import "../../../styles/variablesstyles.scss";


.font-yellow{
  color:$theme-yellow-color ;
}
.marketplace-dropworksSelect-main{
  margin-top: 0px;
  background: rgba(0, 0, 0, 0.6);
  &-head{
    padding: 26px 59px;
    border: 1px solid var(--Black-2, rgba(29, 29, 29, 1));
    &-first{
      &-subheading{
        color: $theme-color-gray3; 
      }
      &-text3{
        color: $theme-color-info;
        border-bottom: 2px solid  rgba(55, 48, 236, 1);
        width: fit-content;
        font-weight: 700px;
      }
    }
    &-gray4{
      color: $theme-color-gray4;
    }
  
  }
  &-option{
    padding: 11px 76px 11px 76px;
    border-left: 1px solid var(--Black-2, rgba(29, 29, 29, 1));
    border-right: 1px solid var(--Black-2, rgba(29, 29, 29, 1));
    &-text{
      color:  $theme-color-gray3;
      cursor: pointer;
      transition: background-color 0.7s ease, color 0.7s ease;
    }
  }
  &-table-content{
    padding: 20px 40px 20px 50px;
    border: 1px solid var(--Black-2, rgba(29, 29, 29, 1));
  }

  &-col1{
    width: 100%;
    &-table{
      display: table;
      table-layout: fixed;
      width: 100%;


    }
    &-table th{
      padding-bottom: 8px;
      border-bottom: 1px solid var(--Gray-1, rgba(51, 51, 51, 1))
    }
    &-table :first-child td{
      padding-top: 8px;
    }
  }
}
.text-available{

  font-weight: 400;
  font-size: $theme-xsm-fs;
  color: $theme-color-gray3;
  line-height: $theme-xsm-lh;
}

.colour-red{
  color:$theme-color-error;
}
.colour-green{
  color:$theme-color-success !important;
}
.placeholder-text{
  font-weight: 400;
  color: rgba(130, 130, 130, 1) !important;
}
.btn-green{
  width: 100%;
  background: var(--Success, rgba(39, 174, 96, 1));
  height: 45px !important;
  margin: 0px !important;
}
.btn-red{
  width: 100%;
  background: var(--Error, rgba(235, 87, 87, 1));
  height: 45px !important;
  margin: 0px !important;

}
.marketplace-dropworksSelect-table td{
      padding: 10px 0px 10px 0px ;
}
.marketplace-dropworksSelect-table :first-child td{
  padding-top: 16px;
}
.marketplace-dropworksSelect-table th{
  border-bottom: 0.5px solid var(--Black-2, rgba(29, 29, 29, 1));
  padding-bottom: 16px;
  font-weight: 400;
}

.chart-main{
  padding:  28px 74px;
  border-bottom: 1px solid var(--Black-2, rgba(29, 29, 29, 1));
  
}
.table-main{
  border: 1px solid var(--Black-2, rgba(29, 29, 29, 1));
  width: 310px;
  border-right: none;
  border-bottom: 0px;
}
.second-col{
  border: 1px solid var(--Black-2, rgba(29, 29, 29, 1));
  width: 1076px;
  border-bottom: 0px;
}
.colour-yellow{
  color:rgba(252, 238, 33, 1) !important;

}
.colour-g3{
  color: $theme-color-gray3;
}
.colour-g4{
  color: $theme-color-gray4;
}
.marketplace-dropworksSelect-table{
  font-size: 14px;
  color: $theme-color-gray4;
}

.text-wh{
  color: white;
}
.chart-bottom{
  margin: auto;
  width: 669px;
}



.traditional-table-bottom{
  background: var(--Black-4, rgba(15, 15, 15, 1));

}
.Classic-table-bottom{
  background: var(--Black-4, rgba(15, 15, 15, 1));
}
.marketplace-dropworksSelect-options{
  border: 1px solid var(--Black-2, rgba(29, 29, 29, 1));
  padding: 25px 0px 25px 0px;
}

.marketplace-dropworksSelect-options2{

  border-left: 1px solid var(--Black-2, rgba(29, 29, 29, 1));
  border-right: 1px solid var(--Black-2, rgba(29, 29, 29, 1));
}
.marketplace-dropworksSelect-table2{
  font-size: 14px;
  color: $theme-color-gray4;

}

.marketplace-dropworksSelect-table2 th{
  padding-bottom: 8px;
}
.marketplace-dropworksSelect-table2 thead{
  font-size: 16px !important;
  border-bottom: 1px solid var(--Gray-1, rgba(51, 51, 51, 1)) ;
}
.td-top-p{
  padding-top: 8px; 
}
.th-font{
  font-size: 16px !important;
}
.selectedoption{
  color: white;
  transition: background-color 0.7s ease, color 0.7s ease;
}


.chart-height{
  height: 340px;
}
.tabs-1{
  height: 48px !important;
  p{
    font-size: 14px !important;
    padding: 14px !important;
    line-height: $theme-xsm-lh!important;
  }
}
.tabs-1-limit{
  height: 420px !important;
}
.pl-70{
  padding-left: 70px;
}
.w-170{
  width: 170px !important;
}
.btn-green:hover{
  background: var(--Success, rgba(4, 135, 59, 0.918)) !important;
}
.btn-red:hover{
  background: var(--Error, rgb(234, 57, 57)) !important;
}
.tab-bottom{
  p{
    font-size: 14px !important;
    padding: 14px !important;
    line-height: $theme-xsm-lh !important;
  }
}
.marketplace-dropworksSelect-table-container{
  height: 295px;
  overflow-y: auto;
}
@media (max-width: 1440px) {
  .table-main{
    border: 1px solid var(--Black-2, rgba(29, 29, 29, 1));
    width: 30%;
    border-right: none;
  }
  .second-col{
    border: 1px solid var(--Black-2, rgba(29, 29, 29, 1));
    width: 70%;
  }
}

@media(min-width: 320px) and (max-width: 1000px){

  .tabs-1-limit{
    height: auto !important;
  }
  .marketplace-dropworksSelect-main-table-content{
    padding: 16px !important;
    // overflow-x: auto;
  }
  .tab-bottom{
    p{
      font-size: 12px !important;
      padding: 8px !important;
      line-height: 16.8px !important;
      text-wrap: nowrap;
    }
  }
  .table-main{
    width: 100%;
    border-right: 1px solid var(--Black-2, rgba(29, 29, 29, 1));
    padding-left: 8px !important;
    padding-right: 8px !important;
  }
  .second-col{
    width: 100%;
  }
  .chart-main{
    padding:  16px !important;    
  }
  .marketplace-dropworksSelect-main-head-first-text3{
    font-size: 12px;
    line-height: 16.8px;
    font-weight: 400;
    margin: 0px !important;
  }
  .btn-green{
    height: 30px !important;
    margin: 0px !important;
    width: 100% !important;
    font-size: 12px !important;
    line-height: 19.6px !important;
    border-radius: 5px;
  }
  .btn-red{
    height: 30px !important;
    margin: 0px !important;
    width: 100% !important;
    font-size: 12px !important;
    line-height: 19.6px !important;
    border-radius: 5px;
  }
  .mobile-view-dw-limit-market{
    padding: 16px !important;
  }
  .chart-height{
    height: 100% !important;
  }
  .marektplace-second-head-mobile{
    width: 100%;
    justify-content: space-between;
    padding: 8px 0px 0px 0px;
    gap: 0.5rem !important;
  }
  .marketplace-dropworksSelect-main-head-gray4{
      white-space: nowrap;
  }
  .marektplace-second-head-mobile-heading{
    font-size: 16px !important;
    line-height: 19.6px;
  }
  .marketplace-placeholders-mobile{
     margin-right: 0px !important;
  }
  .marketplace-dropworksSelect-table th{
    padding-bottom: 8px;
    font-size: 12px !important;
    line-height: 25.2px;
    white-space: nowrap;
    width: fit-content !important;
  }
  .marketplace-dropworksSelect-main-option{
    padding: 11px 25px 11px 25px !important;
  }
  .selectedoption{
    font-weight: 700;
    color: white;
    transition: background-color 0.7s ease, color 0.7s ease;
  }
  .marketplace-dropworksSelect-options{
    border: 1px solid var(--Black-2, rgba(29, 29, 29, 1));
    padding: 0px;
  }
  .classic-col{
    padding: 16px 8px 16px 8px;
    border-bottom: 1px solid var(--Black-2, rgba(29, 29, 29, 1));
  }
  .heading5-trade{
    font-size: 16px;
  }
  .marketplace-dropworksSelect-table2 th{
    padding-bottom: 8px;
    padding-left: 8px;
    padding-right: 8px;
    font-size: 12px;
  }
.marketplace-dropworksSelect-td-first{
  padding-left: 40px;
}
  .marketplace-dropworksSelect-td-last{
    padding-right:6px !important;
  }
  .marketplace-dropworksSelect-td-second{
    padding-left: 12px !important;
  }
  .Classic-table-bottom{
    background: var(--Black-4, rgba(15, 15, 15, 1));
    margin-left: 0px !important;
    margin-right: 0px !important;
    > p{
      font-size: 14px !important;
      line-height: 19.6px;
    }
  }
  .marketplace-dropworksSelect-second-td-first{
    padding-left: 16px !important;
  }
  .traditional-table-bottom{
    > p{
      font-size: 14px !important;
      line-height: 19.6px;
    }
  }
  .tabs-1{
    height: 48px !important;
    margin-left: 16px !important;
    padding: 0px !important;
    p{
      font-size: 12px !important;
    }
  }
  .marketplace-dropworksSelect-table-text{
    > p{
      font-size: 18px !important;
      line-height: 19.6px !important;
    }
  }
  .marketplace-dropworksSelect-graph-tab{
    p{
      font-size: 10px !important;
    }
  }
  .chart-bottom{
    margin: auto;
    width: 100%;
    padding-left: 30px;
    > p{
      font-size: 10px !important;
    }
  }
  .marketplace-dropworksSelect-graph-left-text{
    > p{
      font-size: 10px !important;
    }
  }
  .marketplace-dropworksSelect-table-container{
    width: 100%;
    overflow-x: auto;
  }
  .marketplace-dropworksSelect-table{
     td {
      font-size: 12px !important;
      padding-right: 50px !important;
      vertical-align: baseline;
    }
  }
  .marketplace-dropworksSelect-table{
    th {
     font-size: 12px !important;
     padding-right: 50px !important;
   }
 }
 .marketplace-dropworksSelect-main{
  margin-top: 1rem !important;
  background: transparent;

 }
 .mobile-mt-8-mb-8{
  margin-top: 8px !important;
  margin-bottom: 8px !important;
 }
 .mobile-mb-8{
  margin-bottom: 8px  !important;
 }
}

@media(min-width: 320px) and (max-width: 360px){

  .tabs-1-limit{
    height: auto !important;
  }
  .marketplace-dropworksSelect-main-table-content{
    padding: 16px !important;
    // overflow-x: auto;
  }
  .tab-bottom{
    p{
      font-size: 12px !important;
      padding: 8px !important;
      line-height: 16.8px !important;
      text-wrap: nowrap;
    }
  }
  .table-main{
    width: 100%;
    border-right: 1px solid var(--Black-2, rgba(29, 29, 29, 1));
    padding-left: 8px !important;
    padding-right: 8px !important;
  }
  .second-col{
    width: 100%;
  }
  .chart-main{
    padding:  16px !important;    
  }
  .marketplace-dropworksSelect-main-head-first-text3{
    font-size: 12px;
    line-height: 16.8px;
    font-weight: 400;
    margin: 0px !important;
  }
  .btn-green{
    height: 30px !important;
    margin: 0px !important;
    width: 100% !important;
    font-size: 12px !important;
    line-height: 19.6px !important;
    border-radius: 5px;
  }
  .btn-red{
    height: 30px !important;
    margin: 0px !important;
    width: 100% !important;
    font-size: 12px !important;
    line-height: 19.6px !important;
    border-radius: 5px;
  }
  .mobile-view-dw-limit-market{
    padding: 16px !important;
  }
  .chart-height{
    height: 100% !important;
  }
  .marektplace-second-head-mobile{
    width: 100%;
    justify-content: space-between;
    padding: 8px 0px 0px 0px;
    gap: 0.5rem !important;
  }
  .marketplace-dropworksSelect-main-head-gray4{
      white-space: nowrap;
  }
  .marektplace-second-head-mobile-heading{
    font-size: 16px !important;
    line-height: 19.6px;
  }
  .marketplace-placeholders-mobile{
     margin-right: 0px !important;
  }
  .marketplace-dropworksSelect-table th{
    padding-bottom: 8px;
    font-size: 12px !important;
    line-height: 25.2px;
    white-space: nowrap;
    width: fit-content !important;
  }
  .marketplace-dropworksSelect-main-option{
    padding: 11px 25px 11px 25px !important;
  }
  .selectedoption{
    font-weight: 700;
    color: white;
    transition: background-color 0.7s ease, color 0.7s ease;
  }
  .marketplace-dropworksSelect-options{
    border: 1px solid var(--Black-2, rgba(29, 29, 29, 1));
    padding: 0px;
  }
  .classic-col{
    padding: 16px 8px 16px 8px;
    border-bottom: 1px solid var(--Black-2, rgba(29, 29, 29, 1));
  }
  .heading5-trade{
    font-size: 16px;
  }
  .marketplace-dropworksSelect-table2 th{
    padding-bottom: 8px;
    padding-left: 8px;
    padding-right: 8px;
    font-size: 12px;
  }
.marketplace-dropworksSelect-td-first{
  padding-left: 40px;
}
  .marketplace-dropworksSelect-td-last{
    padding-right:6px !important;
  }
  .marketplace-dropworksSelect-td-second{
    padding-left: 12px !important;
  }
  .Classic-table-bottom{
    background: var(--Black-4, rgba(15, 15, 15, 1));
    margin-left: 0px !important;
    margin-right: 0px !important;
    > p{
      font-size: 14px !important;
      line-height: 19.6px;
    }
  }
  .marketplace-dropworksSelect-second-td-first{
    padding-left: 16px !important;
  }
  .traditional-table-bottom{
    > p{
      font-size: 14px !important;
      line-height: 19.6px;
    }
  }
  .tabs-1{
    height: 48px !important;
    margin-left: 16px !important;
    padding: 0px !important;
    p{
      font-size: 12px !important;
    }
  }
  .marketplace-dropworksSelect-table-text{
    > p{
      font-size: 18px !important;
      line-height: 19.6px !important;
    }
  }
  .marketplace-dropworksSelect-graph-tab{
    p{
      font-size: 10px !important;
    }
  }
  .chart-bottom{
    margin: auto;
    width: 100%;
    padding-left: 30px;
    > p{
      font-size: 10px !important;
    }
  }
  .marketplace-dropworksSelect-graph-left-text{
    > p{
      font-size: 10px !important;
    }
  }
  .marketplace-dropworksSelect-table-container{
    width: 100%;
    overflow-x: auto;
  }
  .marketplace-dropworksSelect-table{
     td {
      font-size: 12px !important;
      padding-right: 50px !important;
      vertical-align: baseline;
    }
  }
  .marketplace-dropworksSelect-table{
    th {
     font-size: 12px !important;
     padding-right: 50px !important;
   }
 }
 .marketplace-dropworksSelect-main{
  margin-top: 1rem !important;
  background: transparent;

 }
 .mobile-mt-8-mb-8{
  margin-top: 8px !important;
  margin-bottom: 8px !important;
 }
 .mobile-mb-8{
  margin-bottom: 8px  !important;
 }
}