@import "../../styles/variablesstyles.scss";

.select-arrow-dropdownImage {
  &__indicator {
    // background-image: url("../../assets/steps/arrow-down.svg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: 1rem; // Adjust size as needed
    cursor: pointer !important;
  }
  &__control {
    display: flex;
    align-items: center;
    white-space: nowrap !important;
    background-color: transparent !important;
    border: none;
    font-size: 16px;
    font-weight: 400;
    line-height: 22.4px;
    text-align: left;
    color: rgba(188, 188, 188, 1) !important;
    padding: 0px;
    background-color: rgba(255, 255, 255, 0.05) !important;
    border-radius: 10px !important;
    height: 60px;
    min-width: 190px;
    box-shadow: none !important;
  }
  &__placeholder {
    font-size: 14px;
  }
  &__input {
    color: rgba(188, 188, 188, 1) !important;
    padding: 0px !important;
  }
  &__singleValue {
    display: flex;
    align-items: center;
    padding: 0px;
  }
  &__menu {
    z-index: 9999;
    background-color: rgba(0, 0, 0, 0) !important;
    font-weight: 400;
  }
  &__menuList {
    padding: 0px;
    color: white;
    max-width: 100%;
    min-width: max-content;
    // background-color: #212121 !important;
    text-align: left;
  }

  &__option {
    white-space: nowrap !important;
    color: rgba(188, 188, 188, 1);
  }

  &____value-container {
    padding: 0px;
  }
  &__single-value {
    color: rgba(188, 188, 188, 1) !important;
  }
  &__indicator-separator {
    width: 0px !important;
  }
  &__control {
    border: none !important;
  }
  &__input-container {
    display: hidden !important;
  }
}
.select-arrow-dropdownImage__option:hover {
  color: white;
  // border-radius: 10px !important;
  white-space: nowrap !important;
  background-color: rgba(79, 79, 79, 0.7) !important;
}
.select-arrow-dropdownImage__option--is-focused {
  // border-radius: 10px !important;
  white-space: nowrap !important;
  background-color: transparent !important;
}
.select-arrow-dropdownImage__option--is-selected {
  // border-radius: 10px !important;

  white-space: nowrap !important;
  background-color: rgba(79, 79, 79, 1) !important;
}
.select-arrow-dropdownImage__control--menu-is-open {
  //  width: 200px;
  white-space: nowrap !important;
  // max-width: 100px !important;
}
.css-b62m3t-container {
  width: 100% !important;
}
.select-arrow-dropdownImage__menu-list {
  border-radius: 10px !important;
}
.select-arrow-dropdownImage__menu-list::-webkit-scrollbar {
  width: 5px !important;
  z-index: 100 !important;
}
.select-arrow-dropdownImage__menu-list::-webkit-scrollbar-track {
  // background: transparent;
  transition: opacity 0.5s 0.5s ease-out;
}

.select-arrow-dropdownImage__menu-list::-webkit-scrollbar-thumb {
  // background:transparent;
  border-radius: 5px !important;
  transition: opacity 0.5s 0.5s ease-out;
}
.select-arrow-dropdownImage__menu-list::-webkit-scrollbar-thumb:hover {
  // background:transparent;
  opacity: 0.5;
}
.css-tj5bde-Svg {
  display: inline-block;
  fill: $theme-color-gray4 !important;
  line-height: 1;
  stroke: currentColor;
  stroke-width: 0;
}
.css-13khyri {
  border-radius: 0px !important;
  overflow-y: auto;
}
.css-13khyri::-webkit-scrollbar {
  width: 12px;
}
.css-13khyri::-webkit-scrollbar-track {
  background: #f1f1f1;
}
.css-13khyri::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 10px;
}
.css-13khyri::-webkit-scrollbar-thumb:hover {
  background-color: #555;
}
.select-arrow-dropdownImage__option:hover {
  color: white;
  // border-radius: 10px !important;
  white-space: nowrap !important;
  background-color: rgba(79, 79, 79, 0.7) !important;
  cursor: pointer;
}
.select-arrow-dropdownImage__option--is-selected {
  // border-radius: 10px !important;
  cursor: pointer;
}

.css-1dimb5e-singleValue {
  margin-left: 10px !important;
}

// .select-arrow-dropdownImage__option:first-child:hover {
//   border-radius: 10px !important;
// }
// .select-arrow-dropdownImage__option:last-child:hover {
//   border-radius: 10px !important;
// }

.select-arrow-dropdownImage__option--is-selected:first-child {
  border-radius: 10px !important;
  cursor: pointer;
}

.select-arrow-dropdownImage__option--is-selected:last-child {
  border-radius: 10px !important;
  cursor: pointer;
}

@media (min-width: 576px) and (max-width: 768px) {
  .css-y6uca4-placeholder {
    margin-left: 0px !important;
    font-size: 12px !important;
    line-height: 16.8px !important;
  }
}

@media (min-width: 360px) and (max-width: 576px) {
  .css-y6uca4-placeholder {
    margin-left: 0px !important;
    font-size: 12px !important;
    line-height: 16.8px !important;
  }
  .css-1dimb5e-singleValue {
    margin-left: 0px !important;
  }
  .select-arrow-dropdownImage__control {
    font-size: 12px !important;
  }
  // .css-tr4s17-option {
  //   padding: 0px 8px !important;
  // }
  // .css-d7l1ni-option {
  //   padding: 0px 8px !important;
  // }
  .select-arrow-dropdownImage__option {
    font-size: 8px !important;
    line-height: 20px !important;
  }
}

@media (min-width: 320px) and (max-width: 360px) {
  .css-y6uca4-placeholder {
    margin-left: 0px !important;
    font-size: 12px !important;
    line-height: 16.8px !important;
  }
  .css-1dimb5e-singleValue {
    margin-left: 0px !important;
  }
  .select-arrow-dropdownImage__control {
    font-size: 12px !important;
  }
  // .css-tr4s17-option {
  //   padding: 0px 8px !important;
  // }
  // .css-d7l1ni-option {
  //   padding: 0px 8px !important;
  // }
  .select-arrow-dropdownImage__option {
    font-size: 8px !important;
    line-height: 20px !important;
  }
}

@media (min-width: 576px) and (max-width: 768px) {
  .select-arrow-dropdownImage {
    &__control {
      width: 100%;
      border-radius: 5px;
      line-height: 19.6px;
      height: 36px;
      font-size: 12px !important;
      padding: 0.5rem !important;
      margin-bottom: 0px !important;
      margin-top: 0.5rem !important;
      min-width: 150px;
      padding: 0px !important;
    }
    &__option {
      font-size: 14px !important;
    }
    &__indicator {
      padding-left: 0px !important;
    }
    &__single-value {
      margin: 0px !important;
    }
    &__menu-notice {
      font-size: 14px !important;
      height: 35px !important;
    }
    &__menu-list {
      border-radius: 5px !important;
    }
  }
}
@media (min-width: 360px) and (max-width: 576px) {
  .select-arrow-dropdownImage {
    &__control {
      width: 100%;
      border-radius: 5px !important;
      line-height: 19.6px;
      height: 36px;
      font-size: 12px !important;
      padding: 0.5rem !important;
      margin-bottom: 0px !important;
      min-width: 80px;
      padding: 0px !important;
    }
    &__option {
      font-size: 12px !important;
    }
    &__indicator {
      padding-left: 0px !important;
      padding-right: 5px !important;
    }
    &__single-value {
      margin: 0px !important;
    }
    &__menu-notice {
      font-size: 12px !important;
      height: 35px !important;
    }
    &__value-container {
      padding-right: 0px !important;
      padding-left: 5px !important;
    }
    &__menu-list {
      border-radius: 5px !important;
    }
    &__flag-img {
      display: none !important;
    }
  }
}
@media (min-width: 200px) and (max-width: 360px) {
  .select-arrow-dropdownImage {
    &__control {
      width: 100%;
      border-radius: 5px !important;
      line-height: 19.6px;
      height: 36px;
      font-size: 12px !important;
      padding: 0.5rem !important;
      margin-bottom: 0px !important;
      min-width: 80px;
      padding: 0px !important;
    }
    &__option {
      font-size: 12px !important;
    }
    &__indicator {
      padding-left: 0px !important;
      padding-right: 5px !important;
    }
    &__single-value {
      margin: 0px !important;
    }
    &__menu-notice {
      font-size: 10px !important;
      height: 35px !important;
    }
    &__value-container {
      padding-right: 0px !important;
      padding-left: 5px !important;
    }
    &__menu-list {
      border-radius: 5px !important;
    }
    &__flag-img {
      display: none !important;
    }
  }
}
@media (min-width: 200px) and (max-width: 360px) {
  .custom_inp {
    width: 100%;
    border-radius: 5px;
    line-height: 19.6px;
    height: 36px;
    font-size: 12px !important;
  }
  .modal-content {
    padding: 0 16px 24px 16px;
  }
  .custom-modal-width {
    max-width: 300px;
    width: 280px;
    margin: auto;
  }
  .text-danger-mobile {
    font-size: 10px;
}
// .custom_inp_phone-imput{
//   padding: 0.5rem !important;
//   margin: 0px !important;
// }
}
