@import "../../../../styles/variablesstyles.scss";



.uni-fs-24 {
    font-size: 24px;
}

.notify-yellow-dot {
    width: 10px;
    height: 10px;
    background-color: yellow;
    border-radius: 50%;
}

.fc-g5 {
    color: $theme-color-gray5;
}

.fc-g3 {
    color: $theme-color-gray3;
}

.notify-subheading {
    font-size: $theme-lg-fs;
    line-height: $theme-lg-lh;
}

.notify-show-more {
    color: $theme-color-gray4;
    border-bottom: 1px solid $theme-color-gray4;
    font-size: 14px;
    line-height: 19.6px;
    cursor: pointer;

}

.notify-section {
    border-top: 1px solid $theme-color-gray4;
}

.notify-section-2 {
    border-top: 1px solid $theme-color-gray4;
    border-bottom: 1px solid $theme-color-gray4;
    margin-bottom: 112px;
}

.text-container {
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    border: 1px solid #ccc;
    padding: 5px;
}
.outline-remover{
 outline: none;
-webkit-tap-highlight-color: transparent;
}


.font-colour {
    color: $theme-color-gray5 ;
}
.icon-colour{
    color: $theme-color-gray4 ;
}

@media(min-width: 360px) and (max-width: 576px){
    .custom-class {
        font-size: 14px;
    }
}


@media(min-width: 320px) and (max-width: 360px){
    .custom-class {
        font-size: 14px;
    }

    .profile-setting-head{
         
          font-size: 16px !important;
          line-height: 17.6px !important;
          font-weight: 700;
    }
    .profile-setting-mobile-spacing{
        margin-bottom: 16px !important;
    }
    .arrow-icon-mobile{
          width: 25px;
          height: 25px;
    }
}
