.more-mobile-view-ul{
  width: 100%;
  position: absolute;
  padding: 0px;
  z-index: 10000;
  left: 0px;
  padding-top: 8px;
  border-radius: 0px 0px 5px 5px !important;
  background: linear-gradient(313.89deg, #1D1D1D 1.71%, #282828 98.51%);
  li{
  
    list-style: none;
    width: 100%;
    display: flex;
    align-items: center;
    border-radius: 0px 0px 5px 5px !important;
    justify-content: center;
    padding: 5px;
    background: linear-gradient(313.89deg, #1D1D1D 1.71%, #282828 98.51%);
    font-weight: 400px;
    
  }
  li:active,li:focus-within,li:hover{
    
    background: var(--Gray-2, #4F4F4F);
border-radius: 5px;
  }
  .more-mobile-view-image {
    height: 6px;
    width: 6px;
  }
}
.moreMobile-option{
  width: 46px;
}
.more-mobile-view-image {
  // height: 14px;
  width: 14px;
}

@media(min-width: 320px) and (max-width: 768px){
  .more-mobile-view-image{
    width: 6px !important;
    height: 4px !important;
  }
}