

@import '../../../../../styles/variablesstyles.scss';


.ds-ctm-txt{
    font-weight: 700;
    color:$theme-color-gray2;
  }
  .cstm-txt-invite{
     background-color: $input-background-color;
     line-height: $theme-sm-lh;
    height: 124px;
  }
  .btn-wid-decline{
      background: $theme-color-black3;
      width: 190px;
  }
  .btn-wid-accept{
      width: 188px;
  }
  .received-label{
      color: $theme-color-gray4;
      font-size: $theme-lg-fs;
      line-height: $theme-lg-lh;
      font-weight: 700;
  }
  .file-name{
    color: $theme-color-gray5;
    line-height: $theme-sm-lh;
  }
  .collaboration{
      color: rgba(252, 238, 33, 1);
      font-weight: 400;
  }
  .received-date{
    color: $theme-color-gray3;
    font-weight: 400;

  }
  .invite-received{
         color: rgba(252, 238, 33, 1);
  }
  .cstm-inp-invite::placeholder{
        
     color: $theme-color-white;
     line-height: $theme-sm-lh;
     font-weight: 700;
     font-size: $theme-sm-fs;
  }
  
  .invite-cancel-btn{
    width: 226px;
    text-wrap: nowrap;
    font-size: 14px;
    line-height: 19.4px;
    font-weight: 700;
    color: rgba(224, 224, 224, 1);
  }
  @media(min-width: 360px) and (max-width: 576px){
    .active-invite-sent-btn-cancel{
      margin: auto !important;
      margin-right: 0px !important;
    }
    .invite-cancel-btn{
      width: 100% !important;
      text-wrap: nowrap;
      font-size: 14px;
      line-height: 19.4px;
      font-weight: 700;
      color: rgba(224, 224, 224, 1);
    }

    .invite-recived-name{
      font-size: 1rem !important;
      line-height: 19.6px;
      margin-bottom: 0rem !important;
    }
   
    .mbl-file-space{
      margin-bottom: 1rem !important;
    }
    .text-area-mbl{
      margin: 8px 0px !important;
    }
    .invite-mbl{
      gap:0.5rem !important
      }
  }

  @media(min-width: 320px) and (max-width: 360px){
    .active-invite-sent-btn-cancel{
      margin: auto !important;
      margin-right: 0px !important;
    }
    .invite-cancel-btn{
      width: 100% !important;
      text-wrap: nowrap;
      font-size: 14px;
      line-height: 19.4px;
      font-weight: 700;
      color: rgba(224, 224, 224, 1);
    }
    .invite-recived-name{
      font-size: 1rem !important;
      line-height: 19.6px !important;
      margin-bottom: 0rem !important;
    }
    .invite-mbl{
    gap:0.5rem !important
    }
    .mbl-file-space{
      margin-bottom: 1rem !important;
    }
    .text-area-mbl{
      margin: 8px 0px !important;
    }
  }