@import "../../styles/variablesstyles.scss";

.tabs {
  .invite-button-all {
    background-color: transparent;
    color: $theme-color-gray4;
    padding: 20px;
    font-size: $theme-lg-fs;
    font-weight: 400;
    border: none;
    line-height: $theme-lg-lh;
    cursor: pointer;
    position: relative;

    &::after {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 2px;
      // background-color: white;
      // opacity: 0;
      // transition: opacity 0.4s ease-in-out;
    }
  }

  &-container {
    display: flex;
    position: relative;
    .invite-button-all:last-child{
      margin-right: 0 !important;
    }
  }

  .active-invite {
    color: white;
    font-weight: 700;

    &::after {
      opacity: 1;
    }
  }

  .underline {
    position: absolute;
    bottom: 0;
    height: 3px;
    width: 100%;
    background-color: #fff; // Default or dynamic color
    // margin-left:clamp(-1.25rem, calc(-0.625rem - 0.3906vw), -0.875rem);
    left: 0;
  }
}
@media(min-width: 576px) and (max-width: 1024px){
   
  .tabs-container {
    overflow-x: auto;
    scrollbar-width: none; 
    -ms-overflow-style: none;  
  }
  .tabs-container::-webkit-scrollbar {
    display: none; 
  }
  .tabs .invite-button-all{ 
    padding: 8px;
    font-size: $theme-sm-fs;
    margin-right: 16px !important;
    white-space: nowrap;
}
.tabs .active-invite  {
  font-weight: 600;
}

}

@media(min-width: 360px) and (max-width: 576px){
  .tabs-mobile{
    margin-bottom: 0px !important;
   }
  .tabs-container {
    overflow-x: auto;
    scrollbar-width: none; 
    -ms-overflow-style: none;  
    margin-bottom: 24px !important;

  }
  .tabs-container::-webkit-scrollbar {
    display: none; 
  }
  .tabs .invite-button-all{ 
    padding: 8px;
    font-size: 14px;
    margin-right: 16px !important;
    white-space: nowrap;
}
.tabs .active-invite {
  font-weight: 400;
}

}

@media(min-width: 320px) and (max-width: 360px){
   .tabs-mobile{
    margin-bottom: 0px !important;
   }
  .tabs-container {
    overflow-x: auto;
    scrollbar-width: none; 
    -ms-overflow-style: none;  
    margin-bottom: 24px !important;
  }
  .tabs-container::-webkit-scrollbar {
    display: none; 
  }
  .tabs .invite-button-all{ 
    padding: 8px;
    font-size: 14px;
    margin-right: 16px !important;
    line-height: 19.6px !important;
    white-space: nowrap;
}
.tabs .active-invite  {
  font-weight: 400;
}
.underline {
  height: 1px !important; 
}

}
