.radio-button {
    width: 24px;
    height: 24px;
    border: 2px solid white;
    border-radius: 50%; 
    background-color: transparent; 
    appearance: none; 
    outline: none; 
    cursor: pointer; 
    position: relative; 
}

.radio-button:checked {
    border-color: rgba(252, 238, 33, 1);
}

.radio-button::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 12px; 
    height: 12px; 
    border-radius: 50%;
    background-color: transparent; 
    border: 2px solid transparent; 
    transform: translate(-50%, -50%);
}

.radio-button:checked::before {
    background-color: rgba(252, 238, 33, 1);
    border: 2px solid rgba(252, 238, 33, 1); 
}
@media(min-width:360px) and (max-width: 576px){
    .radio-button {
        width: 20px;
        height: 20px; 
    }
    .radio-button::before {
      
      
        width: 10px; 
        height: 10px; 
        transform: translate(-50%, -50%);
    }

}

@media(min-width:320px) and (max-width: 360px){
    .radio-button {
        width: 15px;
        height: 15px; 
    }
    .radio-button::before {
      
      
        width: 7.5px; 
        height: 7.5px; 
        transform: translate(-50%, -50%);
    }

}
