@import "../../../../styles/variablesstyles.scss";

.liquid-asstes-head {
  font-size: $theme-heading-5-size;
  line-height: $theme-heading-5-lh;
  font-weight: 700;
}
// .dropstocks-asstes-head {
//   font-size: $theme-heading-5-size;
//   line-height: $theme-heading-5-lh;
//   font-weight: 700;
// }

.table-wrapper-2 {
  height: 352px;
  overflow-y: auto;
}

.table-view {
  ::-webkit-scrollbar-thumb {
    background: $theme-color-gray3;
    border-radius: 5px !important;
    transition: opacity 0.5s 0.5s ease-out;
  }
}

.table {
  --bs-table-bg: transparent;
}
.table-bg-parent {
  background-color: rgba(0, 0, 0, 0.6);
  padding: 56px 62px;
  border-radius: 10px;
}
.table.custom-table {
  width: 100%;
  border-collapse: collapse;
  overflow: hidden;
}

.table.custom-table thead {
  background-color: transparent;
  color: rgba(255, 255, 255, 1);
  border-bottom: 1px solid rgba(79, 79, 79, 1);
  z-index: 1;
}

.table.custom-table td {
  padding: 0;
  font-size: 20px;
  font-weight: 400;
  color: rgba(255, 255, 255, 1);
  border: none;
  width: auto;
  line-height: 28px;
}
.table.custom-table th {
  padding: 0;
  font-size: 20px;
  font-weight: 700;
  color: $theme-color-gray5;
  border: none;
  width: auto;
  line-height: 28px;
}

.table.custom-table tbody {
  display: block;
  max-height: 603px;
  overflow-y: auto;
}
.table.custom-table-1 {
  tbody > tr > td:first-child {
    min-width: 37.5%; /* Default width for the child div */
    flex-grow: 1; /* Allows the child to grow and fill available space */
    flex-shrink: 1; /* Allows the child to shrink on smaller screens */
    box-sizing: border-box;
  }
  thead > tr > th:first-child {
    min-width: 37%; /* Default width for the child div */
    flex-grow: 1; /* Allows the child to grow and fill available space */
    flex-shrink: 1; /* Allows the child to shrink on smaller screens */
    box-sizing: border-box;
  }
  tbody > tr > td:nth-child(3),
  thead > tr > th:nth-child(3),
  tbody > tr > td:nth-child(2),
  thead > tr > th:nth-child(2) {
    min-width: 28%; /* Default width for the child div */
    flex-grow: 1; /* Allows the child to grow and fill available space */
    flex-shrink: 1; /* Allows the child to shrink on smaller screens */
    box-sizing: border-box;
  }

  tbody > tr > td:nth-child(4),
  thead > tr > th:nth-child(4) {
    min-width: 6.5%; /* Default width for the child div */
    flex-grow: 1; /* Allows the child to grow and fill available space */
    flex-shrink: 1; /* Allows the child to shrink on smaller screens */
    box-sizing: border-box;
    text-wrap: nowrap;
  }
}

.table.custom-table-1 tbody {
  display: block;
  max-height: 352px;
  overflow-y: auto;
}

.table.custom-table-1 tbody::-webkit-scrollbar-track {
  background: transparent;
}

.table.custom-table-1 tr {
  display: table;
  width: 100%;
  table-layout: fixed;
  background-color: transparent;
  padding: 0 40px 0 0;
}

.table.custom-table-1 th,
.table.custom-table-1 td {
  padding: 0;
  font-size: 20px;
  font-weight: 400;
  color: rgba(255, 255, 255, 1);
  border: none;
  width: auto;
}

.table.custom-table-1 {
  width: 100%;
  border-collapse: collapse;
}
.table.custom-table-1 thead {
  background-color: transparent;
  color: rgba(255, 255, 255, 1);
  border-bottom: 1px solid rgba(79, 79, 79, 1);
}
.table.custom-table-1 th.space-table-head {
  padding-bottom: 32px;
}

.table.custom-table-2 {
  tbody > tr > td:first-child {
    min-width: 93%; /* Default width for the child div */
    flex-grow: 1; /* Allows the child to grow and fill available space */
    flex-shrink: 1; /* Allows the child to shrink on smaller screens */
    box-sizing: border-box;
  }
  thead > tr > th:first-child {
    min-width: 92.5%; /* Default width for the child div */
    flex-grow: 1; /* Allows the child to grow and fill available space */
    flex-shrink: 1; /* Allows the child to shrink on smaller screens */
    box-sizing: border-box;
  }
  tbody > tr > td:nth-child(2),
  thead > tr > th:nth-child(2) {
    // min-width: 28%;        /* Default width for the child div */
    flex-grow: 1; /* Allows the child to grow and fill available space */
    flex-shrink: 1; /* Allows the child to shrink on smaller screens */
    box-sizing: border-box;
    text-wrap: nowrap;
  }
}

.table.custom-table-2 tbody {
  display: block;
  max-height: 352px;
  overflow-y: auto;
}

.table.custom-table-2 tbody::-webkit-scrollbar-track {
  background: transparent;
}

.table.custom-table-2 tr {
  display: table;
  width: 100%;
  table-layout: fixed;
  background-color: transparent;
  padding: 0 72px 0 0;
}

.table.custom-table-2 th,
.table.custom-table-2 td {
  padding: 0;
  font-size: 20px;
  font-weight: 400;
  color: rgba(255, 255, 255, 1);
  border: none;
  width: auto;
}

.table.custom-table-2 {
  width: 100%;
  border-collapse: collapse;
}
.table.custom-table-2 thead {
  background-color: transparent;
  color: rgba(255, 255, 255, 1);
  border-bottom: 1px solid rgba(79, 79, 79, 1);
}
.table.custom-table-2 th.space-table-head {
  padding-bottom: 32px;
}

.table.custom-table tr {
  display: table;
  width: 1890px;
  max-width: 1900px;
  table-layout: fixed;
  background-color: transparent;
  vertical-align: middle;
}

.table.custom-table-3 {
  width: 100%;
  border-collapse: collapse;
}
.table.custom-table-3 th {
  border-bottom: 1px solid $theme-color-gray2;
  font-size: $theme-lg-fs;
  font-weight: 400;
  line-height: $theme-lg-lh;
  color: $theme-color-white;
}
.table.custom-table-3 th.space-table-head {
  padding-bottom: 32px;
}
.table.custom-table-1,
.table.custom-table-2 {
  tbody > tr {
    margin-bottom: 56px;
  }
}
.table.custom-table {
  tbody > tr {
    margin-top: 16px;
  }
  // tr{
  //   display: flex;
  // }

  thead > tr > th:not(:last-child) {
    max-width: 22.5%; /* Default width for the child div */
    flex-grow: 1; /* Allows the child to grow and fill available space */
    flex-shrink: 1; /* Allows the child to shrink on smaller screens */
    box-sizing: border-box;
  }
  tbody > tr > td:not(:last-child) {
    max-width: 22.6%; /* Default width for the child div */
    flex-grow: 1; /* Allows the child to grow and fill available space */
    flex-shrink: 1; /* Allows the child to shrink on smaller screens */
    box-sizing: border-box;
  }
}

.table.custom-table-3 td {
  padding: 15px 20px;
  color: rgba(255, 255, 255, 1);
  font-size: 20px;
}

.table.custom-table-3 td.date-time-2 {
  white-space: nowrap;
}
.table.custom-table-3 th {
  border-bottom: 1px solid $theme-color-gray2;
  font-size: $theme-lg-fs;
  font-weight: 400;
  line-height: $theme-lg-lh;
  color: $theme-color-white;
}
.table.custom-table-3 td {
  border: none;
}
.table.custom-table tbody tr:hover {
  background-color: rgba(255, 255, 255, 0.1);
}
.date-time span {
  display: block;
}
.quantity span {
  display: block;
}
.date {
  font-size: 18px;
  line-height: 25.2px;
  color: rgba(255, 255, 255, 1);
  font-weight: 400;
}
.time {
  font-size: 14px;
  line-height: 19.6px;
  color: $theme-color-gray3;
  font-weight: 400;
}
.price span {
  display: block;
}
.table-subdata {
  font-size: $theme-md-fs;
  line-height: $theme-md-lh;
  color: $theme-color-gray3;
  font-weight: 400;
}

.overview {
  .overview-total-balance {
    background-color: rgba(0, 0, 0, 0.6);
    // display: flex;
    align-items: end;
    gap: 3rem;
    padding: 56px;
    .balance-amount {
      font-size: $theme-heading-2-size;
      line-height: $theme-heading-2-lh;
    }

    .deposit-button {
      width: 197px;
      height: 48px;
      background: linear-gradient(93.86deg, #a91079 0.6%, #53063b 100.02%);
    }
    .liquid-assets {
      background-color: rgba(0, 0, 0, 0.6);
    }

    .table-row-dropstock {
      padding: 0 40px 0 0;
    }
    .overview-sec {
      .overview-asset {
        width: 375px;
      }
    }
  }
}
.balance-amount {
  font-weight: 700;
}
.withdraw-button {
  width: 207px;
  height: 48px;
  background: linear-gradient(313.89deg, #1d1d1d 1.71%, #282828 98.51%);
  font-size: 14px;
}
.table-wrapper {
  overflow-x: auto;
}

@media (max-width: 1400px) {
  .table .table-order tr {
    display: flex !important;
  }
  .table.table-transaction {
    tr {
      display: table !important;
    }
  }
  // .table.custom-table-select-portfolio td.track-column, .table.custom-table-select-portfolio th.track-column{
  //   width: auto !important;
  //   max-width: auto !important;
  //   min-width: auto !important;
  // }
}

@media (min-width: 360px) and (max-width: 576px) {
  .balance-amount {
    font-size: 20px !important;
    flex-direction: column;
  }
  .wallet-amount-section {
    padding: 1rem;
  }
  .overview-total-balance {
    padding: 0px !important;
  }
  .withdraw-deposit {
    margin-top: 24px;
    flex-direction: column;
    margin-left: 0px !important;
  }
  .wallet-btns {
    width: 100% !important;
    height: 30px !important;
    font-size: 12px !important;
    line-height: 19.6px !important;
    border-radius: 5px;
    margin-bottom: 0px !important;
    white-space: nowrap;
  }
  .table-bg-parent {
    padding: 0px !important;
  }
  .mobile-view-wrapper {
    overflow-x: auto;
  }
  .table.custom-table-1 th.space-table-head {
    padding-bottom: 8px;
  }

  .table.custom-table-1 {
    tbody > tr > td:first-child {
      min-width: 56.5%;
      flex-grow: 1;
      flex-shrink: 1;
      box-sizing: border-box;
      padding-right: 24px;
    }
    thead > tr > th:first-child {
      font-size: 12px !important;
      line-height: 16.8px;
      min-width: 56%;
    }
    tbody > tr > td:nth-child(3),
    thead > tr > th:nth-child(3),
    tbody > tr > td:nth-child(2),
    thead > tr > th:nth-child(2) {
      font-size: 12px !important;
      line-height: 16.8px;
    }
    tbody > tr > td:nth-child(4),
    thead > tr > th:nth-child(4) {
      min-width: 6.5%;
      flex-grow: 1;
      flex-shrink: 1;
      box-sizing: border-box;
      text-wrap: nowrap;
      font-size: 12px !important;
      line-height: 16.8px;
    }
  }

  .select-title {
    font-size: 14px;
    line-height: 19.2px;
  }
  .wallet-select-box {
    width: 30px;
    height: 30px;
    border-radius: 5px;
  }
  .table-subdata {
    font-size: 12px !important;
    line-height: 16.8px !important;
  }

  .table.custom-table tbody > tr > td:not(:last-child) {
    max-width: fit-content;
    flex-grow: 1;
    flex-shrink: 1;
    box-sizing: border-box;
  }
  .table.custom-table th {
    padding: 0;
    font-size: 12px;
    font-weight: 700;
    color: rgb(224, 224, 224);
    border: none;
    width: 110px;
    line-height: 25px;
  }
  .table.custom-table td {
    padding: 0;
    font-size: 12px;
    font-weight: 400;
    color: rgb(255, 255, 255);
    border: none;
    width: 110px;
    line-height: 16.8px;
  }
  .date {
    font-size: 12px;
    line-height: 19.6px;
    color: rgb(255, 255, 255);
    font-weight: 400;
  }
  .table.custom-table thead > tr > th:not(:last-child) {
    max-width: fit-content;
    flex-grow: 1;
    flex-shrink: 1;
    box-sizing: border-box;
  }
  .table-wrapper {
    overflow: auto;
    margin: 16px;
  }
  .table-btn-wallet {
    font-weight: 700;
    background: transparent;
    border: 0;
    border: 1px solid rgb(224, 224, 224);
    color: rgb(224, 224, 224);
    padding: 8px;
    text-align: center;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 10px;
  }
  .table.custom-table tr {
    display: table;
  }
  .table.custom-table-2 th,
  .table.custom-table-2 td {
    padding: 0;
    font-size: 12px;
    font-weight: 400;
    color: rgb(255, 255, 255);
    border: none;
    width: auto;
    padding-bottom: 0px !important;
  }
  .bottom-table {
    gap: 8px !important;
    p {
      font-size: 14px !important;
    }
  }
  .table.custom-table-2 tr {
    display: table;
    width: 100%;
    table-layout: fixed;
    background-color: transparent;
    padding: 0 41px 0 0;
    margin-bottom: 8px !important;
  }
  .table.custom-table-2 tbody {
    display: block;
    max-height: 352px;
    overflow-y: visible;
    margin-top: 8px !important;
  }
  .dropstocks-asstes-head {
    font-size: 16px !important;
    margin-bottom: 16px !important;
  }
  .liquid-asstes-head {
    font-size: 16px !important;
    margin-bottom: 16px !important;
  }
  .table.custom-table-1 tbody {
    display: block;
    max-height: 352px;
    overflow-y: visible;
    margin-top: 8px;
  }
  .mt-mobile-0 {
    margin-top: 0 !important;
  }
  .mobile-table-thead {
    position: sticky;
    top: 0px;
    background-color: black !important;
  }

  // Prime css
  .prime-custom-table {
    padding: 21px 30px !important;
  }
  .p-datatable .p-datatable-tbody > tr > td {
    padding: 8px 0px !important;
    font-size: 12px !important;
  }
  .p-datatable .p-datatable-thead > tr > th {
    padding: 0px 0px 8px 0px !important;
    font-size: 12px !important;
    line-height: 16.2px !important;
  }
}

@media (min-width: 320px) and (max-width: 360px) {
  .balance-amount {
    font-size: 20px !important;
    flex-direction: column;
  }
  .wallet-amount-section {
    padding: 1rem;
  }
  .overview-total-balance {
    padding: 0px !important;
  }
  .withdraw-deposit {
    margin-top: 24px;
    flex-direction: column;
    margin-left: 0px !important;
  }
  .wallet-btns {
    width: 100% !important;
    height: 30px !important;
    font-size: 12px !important;
    line-height: 19.6px !important;
    border-radius: 5px;
    margin-bottom: 0px !important;
    white-space: nowrap;
  }
  .table-bg-parent {
    padding: 0px !important;
  }
  .mobile-view-wrapper {
    overflow-x: auto;
  }
  .table.custom-table-1 th.space-table-head {
    padding-bottom: 8px;
  }

  .table.custom-table-1 {
    tbody > tr > td:first-child {
      min-width: 56.5%;
      flex-grow: 1;
      flex-shrink: 1;
      box-sizing: border-box;
      padding-right: 24px;
    }
    thead > tr > th:first-child {
      font-size: 12px !important;
      line-height: 16.8px;
      min-width: 56%;
    }

    tbody > tr > td:nth-child(3),
    thead > tr > th:nth-child(3),
    tbody > tr > td:nth-child(2),
    thead > tr > th:nth-child(2) {
      font-size: 12px !important;
      line-height: 16.8px;
    }
    tbody > tr > td:nth-child(4),
    thead > tr > th:nth-child(4) {
      min-width: 6.5%;
      flex-grow: 1;
      flex-shrink: 1;
      box-sizing: border-box;
      text-wrap: nowrap;
      font-size: 12px !important;
      line-height: 16.8px;
    }
  }

  .select-title {
    font-size: 14px;
    line-height: 19.2px;
  }
  .wallet-select-box {
    width: 30px;
    height: 30px;
    border-radius: 5px;
  }
  .table-subdata {
    font-size: 12px !important;
    line-height: 16.8px !important;
  }

  .table.custom-table tbody > tr > td:not(:last-child) {
    max-width: fit-content;
    flex-grow: 1;
    flex-shrink: 1;
    box-sizing: border-box;
  }
  .table.custom-table th {
    padding: 0;
    font-size: 12px;
    font-weight: 700;
    color: rgb(224, 224, 224);
    border: none;
    width: 110px;
    line-height: 25px;
  }
  .table.custom-table td {
    padding: 0;
    font-size: 12px;
    font-weight: 400;
    color: rgb(255, 255, 255);
    border: none;
    width: 110px;
    line-height: 16.8px;
  }
  .date {
    font-size: 12px;
    line-height: 19.6px;
    color: rgb(255, 255, 255);
    font-weight: 400;
  }
  .table.custom-table thead > tr > th:not(:last-child) {
    max-width: fit-content;
    flex-grow: 1;
    flex-shrink: 1;
    box-sizing: border-box;
  }
  .table-wrapper {
    overflow: auto;
    margin: 16px;
  }
  .table-btn-wallet {
    font-weight: 700;
    background: transparent;
    border: 0;
    border: 1px solid rgb(224, 224, 224);
    color: rgb(224, 224, 224);
    padding: 8px;
    text-align: center;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 10px;
  }
  .table.custom-table tr {
    display: table;
  }
  .table.custom-table-2 th,
  .table.custom-table-2 td {
    padding: 0;
    font-size: 12px;
    font-weight: 400;
    color: rgb(255, 255, 255);
    border: none;
    width: auto;
    padding-bottom: 0px !important;
  }
  .bottom-table {
    gap: 8px !important;
    p {
      font-size: 14px !important;
    }
  }
  .table.custom-table-2 tr {
    display: table;
    width: 100%;
    table-layout: fixed;
    background-color: transparent;
    padding: 0 41px 0 0;
    margin-bottom: 8px !important;
  }
  .table.custom-table-2 tbody {
    display: block;
    max-height: 352px;
    overflow-y: visible;
    margin-top: 8px !important;
  }
  .dropstocks-asstes-head {
    font-size: 16px !important;
    margin-bottom: 16px !important;
  }
  .liquid-asstes-head {
    font-size: 16px !important;
    margin-bottom: 16px !important;
  }
  .table.custom-table-1 tbody {
    display: block;
    max-height: 352px;
    overflow-y: visible;
    margin-top: 8px;
  }
  .mt-mobile-0 {
    margin-top: 0 !important;
  }
  .mobile-table-thead {
    position: sticky;
    top: 0px;
    .p-datatable .p-sortable-column.p-highlight .p-sortable-column-icon {
    }
    background-color: black !important;
  }

  // Prime css
  .prime-custom-table {
    padding: 21px 30px !important;
  }
  .p-datatable .p-datatable-tbody > tr > td {
    padding: 16px 0px !important;
    font-size: 12px;
  }
  .p-datatable .p-datatable-thead > tr > th {
    padding: 0px 0px 8px 0px !important;
    font-size: 12px !important;
    line-height: 16.2px !important;
  }
}

// Prime css
.p-datatable .p-datatable-tbody > tr {
  background: rgba(0, 0, 0, 0) !important;
  border: none;
  color: white;
}
.p-datatable .p-datatable-tbody > tr > td {
  font-family: "Kumbh Sans", sans-serif !important;
  padding: 16px 0px;
  font-size: 18px;
}
.p-datatable .p-datatable-thead > tr > th {
  font-family: "Kumbh Sans", sans-serif !important;
  white-space:nowrap;
  background: rgba(0, 0, 0, 0) !important;
  padding: 0px 20px 16px 0px;
  font-size: 20px;
  font-weight: 700;
  color: rgb(224, 224, 224);
  border: none;
  width: auto;
  line-height: 28px;
  vertical-align: middle;
  margin-right: 5px;
}
.p-datatable .p-sortable-column.p-highlight .p-sortable-column-icon {
  color: white;
}
.p-datatable .p-sortable-column .p-sortable-column-icon {
  color: white;
}
.prime-custom-table {
  background-color: rgba(0, 0, 0, 0.6);
  padding: 51px 62px;
  border-radius: 10px;
  border: 1px solid rgb(29, 29, 29);
}
.p-datatable-thead {
  border-bottom: 1px solid rgb(79, 79, 79);
}
.p-datatable .p-sortable-column .p-column-title {
  margin-right: 0px;
}
.p-datatable .p-sortable-column.p-highlight .p-sortable-column-icon {
  display: none !important;
}
.p-datatable .p-sortable-column .p-sortable-column-icon{
  display: none !important;
}