@import "../../../../../styles/variablesstyles.scss";
 
.content {
    color: $theme-color-gray5;
}
.select-title{
   font-size:  $theme-heading-5-size;
 line-height:  $theme-heading-5-lh;
 font-weight: 700;
}
.select-subtitle{
    font-weight: 400;
    font-size: $theme-color-gray4;
}
.select-box {
    width: 80px;
    height: 80px;
    //background-color: rgba(217, 217, 217, 1);
    border-radius: 10px;
    display: inline;
}
.text-field{
    width: 487px;
}
 

.content-height{
    height: 448px;
    overflow: auto;
}

@media(min-width: 360px) and (max-width: 576px){
    .select-track-mobile{
         padding: 8px 0px !important;
         align-items: flex-start !important;
    }
    .select-box{
        width: 30px !important;
        height: 30px !important;
        border-radius: 5px !important;
        padding: 0px !important;
    }
    .select-track-second-flex{
        gap: 0.5rem !important;
        align-items: flex-start !important;
    }
    .owned-text{
        font-size: 10px !important;
        white-space: nowrap;
       line-height:  16.8px;
    }
    .select-track-owned-text{
        align-items:  center !important;
    }
    
    }
@media(min-width: 320px) and (max-width: 360px){
.select-track-mobile{
     padding: 8px 0px !important;
     align-items: flex-start !important;
}
.select-box{
    width: 30px !important;
    height: 30px !important;
    border-radius: 5px !important;
    padding: 0px !important;
}
.select-track-second-flex{
    gap: 0.5rem !important;
    align-items: flex-start !important;
}
.owned-text{
    font-size: 10px !important;
    white-space: nowrap;
   line-height:  16.8px;
}
.select-track-owned-text{
    align-items:  center !important;
}

}
 