

@import '../../../../../styles/variablesstyles.scss';


.sn-ctm-txt{
    font-weight: 700;
    color:$theme-color-gray2;
    font-size: $theme-xsm-fs;
    line-height: 19.6px;
  }







  .uploadBanner_uploadbox {
    height: 476px;
    border: 2px dashed white;
    border-radius: 10px;
    display: flex;
    width:  100%;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    opacity: 0.75;
    background:transparent ;
}


.uploadBanner-upload-text{
  font-weight: 400;
  line-height: 57.6px;
  color: $theme-color-gray4;
}