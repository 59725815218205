@import "../../../../../styles/variablesstyles.scss";
.font-col-wh{
    color: $theme-color-gray5;
}
.cancel-btn-bg{
    background: rgba(40, 40, 40, 1);
    margin-right: 16px !important;
}
   
.arrow-icon{
    color: rgba(188, 188, 188, 1);
    font-size: 24px;
}
.change-color{
    color: $theme-color-info;
}
@media(min-width:360px) and (max-width: 576px){
    .btn-wid-success{

        width: 100% !important;
    }
    .featured-not-available{
        font-weight: 400 !important;
        filter: brightness(0.8);
    }
}
@media(min-width:320px) and (max-width: 360px){
    .btn-wid-success{
        width: 100% !important;
    }
    .featured-not-available{
        font-weight: 400 !important;
        filter: brightness(0.8);
    }
}