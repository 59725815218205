@import "../../../../../styles/variablesstyles.scss";

.selldropstock3-modal{
   width: 768px !important;
}
.btn-bg-gray3-approve{
    background: rgba(40, 40, 40, 1);
    margin-right: 16px !important;
    color: $theme-color-gray5;
    font-weight: 400;
}
.custom-tree-img{
   width: 130px;
   height: 130px;
   border-radius: 10px;
}
.sell-dropstocks-subpara{
   font-weight: 400;
   color: $theme-color-gray4;
}
.sell-dropstock-sellbtn{
    width:100%;
    height: 45px;
    background: $theme-color-error !important;
}

.sell-note-3{
   color: $theme-color-gray5;
}
.sell-dropstock-highlight{

   color: rgba(252, 238, 33, 1);
}
 .approve-modal-title{
    color: $theme-color-white;
    font-weight: 700;
    line-height: $theme-heading-3-lh;
 }
 .sell-modal-subtitle{
    font-weight: 400;
    color: $theme-color-gray5;
 }
 
 .sellDropstock-modal-text{
    font-weight: 700;
    color: $theme-color-white;
    line-height: 35.2px;
    font-size: 32px;
 }
 .sell-dropstocks-available{

    color: $theme-color-gray3;
 }
 .available-stocks{
     color: $theme-color-white;
 }
 .text-g3{
    font-weight: 400;
    color: $theme-color-gray3;
 }
 .text-g5{
    font-weight: 700;
    color: $theme-color-gray5;
    line-height: $theme-lg-lh;
 }