@import "../../styles/variablesstyles.scss";

.track-icon {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  padding: 1rem;
  cursor: pointer;
  transition: background 1s ease-in-out;
  &-container {
    height: 194px;
    width: 194px;
    border-radius: 10px;
    margin-bottom: 1rem;
    position: relative;
  }
  &-box {
    height: 194px;
    width: 194px;
    background: $theme-color-gray5;
    border-radius: 10px;
    margin-bottom: 1rem;
    position: relative;
  
    &-icon {
      position: absolute;
      right: 0px;
      bottom: 0px;
      display: block;
      opacity: 0;
      transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;
      transform: translateY(20px);
      width: 65px;
      height: 65px;
    }
  }

  &-heading {
    font-size: $theme-lg-fs;
    line-height: $theme-lg-lh;
    width: 100%;
    font-weight: 400;
    margin-bottom: $theme-spacing1;
    color: $theme-color-white;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &-subheading {
    font-size: $theme-sm-fs;
    font-weight: 400;
    width: 100%;
    line-height: $theme-sm-lh;
    color: $theme-color-gray4;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.track-icon:hover {
  background: rgba(255, 255, 255, 0.1);
  border-radius: 10px;

  .track-icon-box-icon {
    opacity: 1;
    transform: translateY(0);
  }
}

@media (min-width: 768px) and (max-width: 1023px) {
  .track-icon {
    &-container {
      height: 130px;
      width: 130px;
      border-radius: 10px;
      margin-bottom: 1rem;
      position: relative;
    }
    &-box {
      height: 130px;
      width: 130px;
      background: $theme-color-gray5;
      border-radius: 10px;
      margin-bottom: 1rem;
      position: relative;
      &-icon {
        position: absolute;
        right: 0px;
        bottom: 0px;
        display: none;
      }
    }
  }
}
@media (min-width: 1024px) and (max-width: 1199px) {
  .track-icon {
    &-container{
      height: 135px;
      width: 135px;
      border-radius: 10px;
      margin-bottom: 1rem;
      position: relative;
    }
    &-box {
      height: 135px;
      width: 135px;
      background: $theme-color-gray5;
      border-radius: 10px;
      margin-bottom: 1rem;
      position: relative;
      &-icon {
        position: absolute;
        right: 0px;
        bottom: 0px;
        display: none;
      }
    }
    &-heading {
      font-size: $theme-sm-fs;
      margin-bottom: 0px;
    }
    &-subheading {
      font-size: $theme-xsm-fs;
    }
  }
}
@media (min-width: 1200px) and (max-width: 1399px) {
  .track-icon {
    &-container{
      height: 169px;
      width: 168px;
      border-radius: 10px;
      margin-bottom: 0rem;
      position: relative;
    }
    &-box {
      height: 169px;
      width: 168px;
      background: $theme-color-gray5;
      border-radius: 10px;
      margin-bottom: 0rem;
      position: relative;
      &-icon {
        position: absolute;
        right: 0px;
        bottom: 0px;
        display: none;
      }
     
    }
  }
}
@media (min-width: 576px) and (max-width: 1024px) {
  .track-icon {
    display: block;
    padding: 1rem 1rem 1rem 0rem;
  }
 
}

@media (min-width: 360px) and (max-width: 576px) {
  .track-icon {
    display: block !important;
    padding: 1rem 1rem 1rem 0rem;
    &-container {
      width: 145px;
      height: 145px;
      border-radius: 5px;
      margin-bottom: 8px !important;
  }
    &-box {
      width: 145px;
      height: 145px;
      border-radius: 5px;
      margin-bottom: 8px !important;
      &-icon {
        display: none;
      }
    }
    &-heading {
      font-size: 14px !important;
      margin-bottom: 0px;
      line-height: 19.6px !important;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 145px;
    }
    &-subheading {
      font-size: 12px !important;
      line-height: 16.8px !important;
      white-space: nowrap;
      overflow: hidden;
      width: 145px;
      text-overflow: ellipsis;
    }
  }
  .track-icon-mobile-view{
     width: min-content !important;
     margin-right: 24px !important;
     padding: 0rem !important;
     width: 145px;
     white-space: nowrap;
     overflow: hidden;
     text-overflow: ellipsis;
  }

}

@media (min-width: 320px) and (max-width: 360px) {
  .track-icon:nth-of-type(even) {
    margin-right: 20px !important;
  }
  .track-icon {
    padding: 1rem 0rem 1rem 0rem;
    width: calc(50% - 24px) !important;
    margin-right: 24px !important;

    &-container {
      width: 130px;
      height: 130px;
      border-radius: 5px;
      margin-bottom: 8px !important;
    }
   
      

    &-box {
      width: 130px;
      height: 130px;
      border-radius: 5px;
      margin-bottom: 8px !important;
      &-icon {
        display: none;
      }
    }
    &-heading {
      font-size: 14px !important;
      line-height: 19.6px;
      font-weight: 400;
      margin-bottom: 0px; 
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 145px;
    }
    &-subheading {
      font-size: 12px !important;
      line-height: 16.8px !important;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 145px;
    }
  }
  .track-icon-mobile-view{
     margin-right: 24px !important;
     padding: 0rem !important;
     width: 145px;
     white-space: nowrap;
     overflow: hidden;
     text-overflow: ellipsis;
  }
 
}
