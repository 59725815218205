@import "../../../../../styles/variablesstyles.scss";

.select-submit3-modal {
  width: 768px !important;
}
.btn-bg-gray3-approve {
  background: rgba(40, 40, 40, 1);
  margin-right: 16px !important;
  color: $theme-color-gray5;
  font-weight: 400;
}
.custom-tree-img {
  width: 130px;
  height: 130px;
  border-radius: 10px;
}
.sell-dropstocks-subpara {
  font-weight: 400;
  color: $theme-color-gray4;
}
.sell-dropstock-sellbtn {
  width: 100%;
  height: 45px;
  background: $theme-color-error !important;
}

.sell-note-3 {
  color: $theme-color-gray5;
}

.approve-modal-title {
  color: $theme-color-white;
  font-weight: 700;
  line-height: $theme-heading-3-lh;
}
.approve-modal-subtitle {
  font-weight: 700;
  color: $theme-color-gray3;
}

.sellDropstock-modal-text {
  font-weight: 700;
  color: $theme-color-white;
  line-height: 35.2px;
  font-size: 32px;
}
.sell-dropstocks-available {
  color: $theme-color-gray3;
}
.available-stocks {
  color: $theme-color-white;
}
.text-g3 {
  font-weight: 400;
  color: $theme-color-gray3;
}
.text-g5 {
  font-weight: 700;
  color: $theme-color-gray5;
  line-height: $theme-lg-lh;
}
@media (min-width: 360px) and (max-width: 576px) {
  .mobile-tree-img-select-submit {
    width: 48px !important;
    height: 48px !important;
  }
  .sell-note-3 {
    font-size: 12px !important;
    line-height: 19.6px !important;
    margin-bottom: 8px !important;
  }
  .sellDropstock-modal-text {
    line-height: 17.6px;
    font-size: 16px;
    word-break: break-all;
  }
  .approve-modal-subtitle {
  font-size: 12px;
  line-height: 16.8px;
 }
}
@media (min-width: 320px) and (max-width: 360px) {
  .mobile-tree-img-select-submit {
    width: 48px !important;
    height: 48px !important;
  }
  .sell-note-3 {
    font-size: 12px !important;
    line-height: 16.8px !important;
    margin-bottom: 8px !important;
  }
  .sellDropstock-modal-text {
    line-height: 17.6px;
    font-size: 16px;
  }
  .approve-modal-subtitle {
   font-size: 12px;
   line-height: 16.8px;
  }
}
