@import "../../../styles/variablesstyles.scss";

.table-bg-parent-personal{
  background-color: rgba(0, 0, 0, 0.6);
  padding: 51px 62px 51px 62px;
  border-radius: 10px;
  border: 1px solid $theme-color-black2;
}
.table.custom-table-personal-portfolio {
    width: 100%;
    border-collapse: collapse;
    table-layout: fixed; 
  }
  .personal-portfolio-select-box {
    width: 80px;
    height: 80px;
    // background-color: rgba(217, 217, 217, 1);
    border-radius: 10px;
    display: inline;
}
  
  .dropstocks-personal-portfolio-subhead{
    font-weight: 400;
    color: $theme-color-gray3;
 }
  
  .table.custom-table-personal-portfolio th,
  .table.custom-table-personal-portfolio td {
    padding: 0px;
    font-size: 18px;
    font-weight: 400;
    color: rgba(255, 255, 255, 1);
    border: none;
    vertical-align: middle;
  }

  
  
  .table.custom-table-personal-portfolio th.track-column:first-child {
    width: 58.3%;
  }
  
  .table.custom-table-personal-portfolio td.track-column:first-child{
    width: 58.6%;
  }

  .table.custom-table-personal-portfolio td:last-child{
    width: 8%;
  }
  .table.custom-table-personal-portfolio th:last-child{
    width: 8.5%;
  }
  .sub-quantity-price{
     color: $theme-color-gray3;
  }
  
 
  .table.custom-table-personal-portfolio thead {
    background-color: transparent;
    color: rgba(255, 255, 255, 1);
    border-bottom: 1px solid $theme-color-gray2;
  }
  

  .table.custom-table-personal-portfolio tbody {
    display: block;
    max-height: 480px;
    overflow-y: auto;
  }
  
  
  .table.custom-table-personal-portfolio tr {
    display: table !important;
    width: 100%;
    table-layout: fixed; 
    background-color: transparent;
  }
  
  .table.custom-table-personal-portfolio tbody tr:hover {
    background-color: rgba(255, 255, 255, 0.1);
  }
  
  .date-time span {
    display: block;
  }
  
  .quantity span {
    display: block;
  }
  
  .price span {
    display: block;
  }
  
  .table-subdata {
    font-size: $theme-md-fs;
    line-height: $theme-md-lh;
    color: $theme-color-gray3;
    font-weight: 400;
  }
  .personal-portfolio-dwc{
     width: 73px;
     height: 41px;
     font-weight: 400 !important;
    font-size: 16px;
     border-radius: 10px;
     padding: 9px, 16px, 9px, 16px !important;
     margin-bottom: 0px !important;
    background: linear-gradient(298.83deg, #FCEE21 2.95%, #A91079 85.66%);
     ;
  }
  .personal-portfolio-dws{
    background: linear-gradient(298.83deg, #A91079 2.95%, #3730EC 85.66%);
    width: 73px;
    height: 41px;
    border-radius: 10px;
    padding: 9px, 16px, 9px, 16px !important;
    margin-bottom: 0px !important;
    font-weight: 400 !important;
    font-size: 16px;
  }
  .personal-portfolio-sell{
     color: rgba(252, 238, 33, 1) !important;
  }
  .personal-portfolio-submit{
    a{
    color: $theme-color-info !important;
  }
  } 
  .text-end-dws{
     text-align: end !important;
  }

  @media(min-width: 576px) and (max-width: 1024px){

  
    .table-bg-parent-personal{
      padding: 16px !important;
    }
    .table.custom-table-personal-portfolio th,
    .table.custom-table-personal-portfolio td {
      padding: 0px;
      font-size: 12px !important;
      line-height: 16.8px !important;
      vertical-align: top !important;
     
    }
    .personal-portfolio-select-box {
      width: 30px !important;
      height: 30px !important;
      border-radius: 5px !important;
      min-width: 30px !important;
    }
    
      .table.custom-table-personal-portfolio th.track-column:first-child {
        width: 35% !important;
      }
      .table.custom-table-personal-portfolio td.track-column:first-child{
        width: 35% !important;
      }
    
      .table.custom-table-personal-portfolio td:last-child{
        width: auto !important;
        text-align: center;
        padding-right: 8px;
      }
      .table.custom-table-personal-portfolio th:last-child{
        width: auto !important;
        text-align: center;
      }
      .sub-quantity-price{
         color: $theme-color-gray3;
      }
      .personal-portfolio-mobile{
         font-size: 12px !important;
         line-height: 16.8px !important;
         display: block !important;
      
      }
      .personal-portfolio-dwc{
        width: 44px;
        padding: 4px 9px !important;
        height: 25px;
        font-size: 12px !important;
        line-height: 19.6px !important;
        border-radius: 5px !important;
      }
      .personal-portfolio-dws{
        width: 44px;
        padding: 4px 9px !important;
        height: 25px;
        font-size: 12px !important;
        line-height: 19.6px !important;
        border-radius: 5px !important;
      }
      .portfolio-mobile-space{
        align-items: flex-start !important;
        gap: 0.5rem !important;
      }
      .table.custom-table-personal-portfolio tbody tr:hover {
        background-color: transparent;
      }
  }
  @media(min-width: 360px) and (max-width: 576px){

    .dropstocks-personal-portfolio{
       margin-top: 1rem;
    }
    .table-bg-parent-personal{
      padding: 16px !important;
    }
    .table.custom-table-personal-portfolio th,
    .table.custom-table-personal-portfolio td {
      padding: 0px;
      font-size: 12px !important;
      line-height: 16.8px !important;
      vertical-align: top !important;
     
    }
    .personal-portfolio-select-box {
      width: 30px !important;
      height: 30px !important;
      border-radius: 5px !important;
      min-width: 30px !important;
    }
    
      .table.custom-table-personal-portfolio th.track-column:first-child {
        width: 35% !important;
      }
      .table.custom-table-personal-portfolio td.track-column:first-child{
        width: 35% !important;
      }
    
      .table.custom-table-personal-portfolio td:last-child{
        width: auto !important;
        text-align: end;
        padding-right: 8px;
      }
      .table.custom-table-personal-portfolio th:last-child{
        width: auto !important;
        text-align: center;
      }
      .sub-quantity-price{
         color: $theme-color-gray3;
      }
      .personal-portfolio-mobile{
         font-size: 12px !important;
         line-height: 16.8px !important;
         display: block !important;
      
      }
      .personal-portfolio-dwc{
        width: 44px;
        padding: 4px 9px !important;
        height: 25px;
        font-size: 12px !important;
        line-height: 19.6px !important;
        border-radius: 5px !important;
        margin-top: 0.5rem;
      }
      .personal-portfolio-dws{
        width: 44px;
        padding: 4px 9px !important;
        height: 25px;
        font-size: 12px !important;
        line-height: 19.6px !important;
        border-radius: 5px !important;
        margin-top: 0.5rem;

      }
      .portfolio-mobile-space{
        align-items: flex-start !important;
        gap: 0.5rem !important;
      }
      .table.custom-table-personal-portfolio tbody tr:hover {
        background-color: transparent;
      }
  }

@media(min-width: 320px) and (max-width: 360px){

  .dropstocks-personal-portfolio{
    margin-top: 1rem;
 }
.table-bg-parent-personal{
  padding: 16px !important;
}
.personal-portfolio-tr:first-of-type {
  margin-top: 12px !important; /* Specific margin for the first <tr> */
}
.personal-portfolio-tr{
 margin-top: 24px !important;
}
.table.custom-table-personal-portfolio th,
.table.custom-table-personal-portfolio td {
  padding: 0px;
  font-size: 12px !important;
  line-height: 16.8px !important;
  vertical-align: top !important;
 
}
.personal-portfolio-select-box {
  width: 30px !important;
  height: 30px !important;
  border-radius: 5px !important;
  min-width: 30px !important;
}

  .table.custom-table-personal-portfolio th.track-column:first-child {
    width: 35% !important;
  }
  .table.custom-table-personal-portfolio td.track-column:first-child{
    width: 35% !important;
  }

  .table.custom-table-personal-portfolio td:last-child{
    width: auto !important;
    text-align: end;
    padding-right: 8px;
  }
  .table.custom-table-personal-portfolio th:last-child{
    width: auto !important;
    text-align: center;
  }
  .sub-quantity-price{
     color: $theme-color-gray3;
  }
  .personal-portfolio-mobile{
     font-size: 12px !important;
     line-height: 19.6px !important;
     display: block !important;
  
  }
  .personal-portfolio-dwc{
    width: 44px;
    padding: 4px 9px !important;
    height: 25px;
    font-size: 12px !important;
    line-height: 19.6px !important;
    border-radius: 5px !important;
    margin-top: 0.5rem;

  }
  .personal-portfolio-dws{
    width: 44px;
    padding: 4px 9px !important;
    height: 25px;
    font-size: 12px !important;
    line-height: 19.6px !important;
    border-radius: 5px !important;
    margin-top: 0.5rem;

  }
  .portfolio-mobile-space{
    align-items: flex-start !important;
    gap: 0.5rem !important;
  }
  .table.custom-table-personal-portfolio tbody::-webkit-scrollbar {
    width: 1px !important;
}
  
.table.custom-table-personal-portfolio tbody tr:hover {
  background-color: transparent;
}

}

