

@import '../../../../../styles/variablesstyles.scss';


.ds-ctm-txt{
    font-weight: 700;
    color:$theme-color-gray2;
  }
  
  .cstm-txt-invite{
     background-color: $input-background-color;
     line-height: $theme-sm-lh;
    height: 124px;
    text-align: justify;
    font-weight: 400;    
  }
  .btn-wid-decline{
      background: $theme-color-black3;
      width: 190px;
  }
  .btn-wid-accept{
      width: 188px;
  }
  .received-label{
      color: $theme-color-gray4;
      font-size: $theme-lg-fs;
      line-height: $theme-lg-lh;
      font-weight: 700;
  }
  .file-name{
    color: $theme-color-gray5;
    line-height: $theme-sm-lh;
    margin:  0px !important;
  }
  .collaboration{
      color: rgba(252, 238, 33, 1);
      font-weight: 400;
  }
  .received-date{
    color: $theme-color-gray3;
    font-weight: 400;

  }
  .invite-received{
         color: rgba(252, 238, 33, 1);
  }
  .cstm-inp-invite::placeholder{
        
     color: $theme-color-white;
     line-height: $theme-sm-lh;
     font-weight: 700;
     font-size: $theme-sm-fs;
  }
  .invite-modal-pointer{
    cursor: pointer;
  }

  @media(min-width: 360px) and (max-width :576px){
    .username-invite-modal{
      font-size: 16px !important;
    line-height: 19.6px !important;
    font-weight: 400;
    margin-bottom: 0px !important;
    }
    .received-label{
      margin-bottom: 0px !important;
      font-size: 14px !important;
    }
    .cstm-txt-invite{
      font-size: 12px !important;
    }
    .invite-modal-btn{
       height: 30px !important;
       border-radius: 5px !important;
       margin-right:  0px !important;
       margin-bottom: 16px !important;
      width: 100% !important;
        padding: 5px 107px 5px 107px !important;
      margin-left: 0px !important;
    }
    .invite-mobile-modal-footer{
      display: flex !important;
      flex-direction: column-reverse !important;
      padding-right: 0px !important;
    }
    .cstm-inp-invite{
      margin-bottom: 8px !important;
    }
  }

  @media(min-width: 320px) and (max-width :360px){
    .username-invite-modal{
      font-size: 16px !important;
    line-height: 19.6px !important;
    font-weight: 400;
    margin-bottom: 0px !important;
    }
    .received-label{
      margin-bottom: 0px !important;
      font-size: 14px !important;
    }
    .cstm-txt-invite{
      font-size: 12px !important;
    }
    .invite-modal-btn{
       height: 30px !important;
       border-radius: 5px !important;
       margin-right:  0px !important;
       margin-bottom: 16px !important;
      width: 100% !important;
        padding: 5px 107px 5px 107px !important;
      margin-left: 0px !important;
    }
    .invite-mobile-modal-footer{
      display: flex !important;
      flex-direction: column-reverse !important;
      padding-right: 0px !important;
    }
    .cstm-inp-invite{
      margin-bottom: 8px !important;
    }
  }