@import "../../../styles/variablesstyles.scss";

.marketplace-dw-composite-main {
  margin-top: 0px !important;

  &-firstrow {
    height: 786px;
    background-color: rgba(0, 0, 0, 0.6);
    &-col1 {
      width: 52.5%;
      display: flex;
      align-items: center;
      justify-content: end;
      padding-right: 5.6% !important;
      &-content {
        width: 623px;
        &-sm {
          max-width: 567px;
        }
      }
    }
    &-col11 {
      width: 52.5%;
      display: flex;
      align-items: center;
      padding-left: 5.6% !important;
      &-content {
        width: 623px;
        &-sm {
          max-width: 567px;
        }
      }
    }
    &-col2 {
      width: 47.5%;
    }
  }

  &-thirdrow {
    width: 1386px;
    margin-top: 195px;
    margin-bottom: 400px;
    display: flex;
    &-left {
      max-width: 565px;
    }

    &-right {
      width: 821px;
      align-items: center;
      justify-content: end;
      display: flex;
      &-content {
        width: 534px;
      }
    }
  }
}
@media (max-width: 1440px) {
  .uni-px-72 {
    padding-left: 40px !important;
    padding-right: 40px !important;
  }
  .uni-px-24 {
    padding: 0 24px;
  }
  .marketplace-dw-composite-main {
    &-firstrow {
      padding: 20px 0px 20px 20px !important;
      &-col1 {
        padding-right: 0 !important;
        &-content {
          max-width: 605px !important;
          &-sm {
            max-width: 567px !important;
          }
        }
      }
      &-col11 {
        // padding-left: 0 !important;
        &-content {
          max-width: 605px !important;
          &-sm {
            max-width: 567px !important;
          }
        }
      }
    }

    &-thirdrow {
      max-width: 100% !important;
      margin-top: 195px;
      margin-bottom: 400px;
      display: block !important;
      &-left {
        max-width: 565px !important;
        margin: auto;
      }

      &-right {
        align-items: center;
        justify-content: center;
        display: flex;
        width: 100% !important;
        margin-top: 100px !important;
        &-content {
          width: 100% !important;
          padding: 20px !important;
          justify-content: center;
          h1 {
            text-align: center !important;
          }

          button {
            margin: auto !important;
          }
        }
      }
    }
  }
}

.slick-slide {
  padding: 10px;
}

.slick-track {
  display: flex;
  justify-content: space-around;
  align-items: center;
}
@media(min-width:768px) and (max-width: 1024px){

  .marketplace-dw-composite-main-firstrow-col1,.marketplace-dw-composite-main-firstrow-col11{
       width: 100% !important;
       justify-content: start !important;
    }
    .marketplace-dw-composite-main-firstrow-col1-content{
      width: 100% !important;
    }
    .marketplace-dw-composite-main-thirdrow-right-content{
      width: 534px !important;
    }
    .marketplace-dw-composite-main-thirdrow{
          margin-top: 1rem !important;
    }
  
  }
@media(min-width:576px) and (max-width: 768px){

.marketplace-dw-composite-main-firstrow-col1,.marketplace-dw-composite-main-firstrow-col11{
     width: 100% !important;
     justify-content: start !important;
  }
.marketplace-dw-composite-main-firstrow-col1-content{
    width: 100% !important;
  }
.marketplace-dw-composite-main-thirdrow{
    margin-top: 1rem !important;
}
.marketplace-dw-composite-main-firstrow{
  background-color: transparent !important;
}
}

@media(min-width:360px) and (max-width: 576px){
.marketplace-select-head{
    font-size: 32px !important;
    line-height: 35.2px;
    margin-bottom: 32px !important;
}
.marketplace-dw-btns {
flex-direction: column;
}
.marketplace-dw-head4{
font-size: 20px;
line-height: 17.6px;
font-weight: 600;
}
.marketplace-dw-composite-main {
&-firstrow {
padding: 0px !important;
background-color: transparent !important;
margin-top: 2rem !important;

&-col1,&-col11 {
  padding-right: 0 !important;
  width: 100% !important;
  padding-left: 0px !important;
  &-content {
    max-width: 100% !important;
    &-sm {
      max-width: 100% !important;
    }
  }
}

}

&-thirdrow {
max-width: 100% !important;
margin-top: 0px !important;
margin-bottom: 0px !important;
display: block !important;
&-left {
  max-width: 565px !important;
  margin: auto;
}

&-right {
  align-items: center;
  justify-content: center;
  display: flex;
  width: 100% !important;
  margin-top: 2rem !important;
  &-content {
    width: 100% !important;
    padding: 0px !important;
    justify-content: center;
    h1 {
      width: 55%;
      font-size: 32px;
      line-height: 35.2px;
      text-align: start !important;
    }
  }
}
}
}
     
}

@media(min-width: 320px) and (max-width: 360px){

  .marketplace-select-head{
          font-size: 32px !important;
          line-height: 35.2px;
          margin-bottom: 32px !important;
  }
  .marketplace-dw-btns {
    flex-direction: column;
 }
 .marketplace-dw-head4{
    font-size: 20px;
    line-height: 17.6px;
    font-weight: 600;
 }
 
  .marketplace-dw-composite-main {
    &-firstrow {
      padding: 0px !important;
      background-color: transparent !important;
      margin-top: 2rem !important;
      &-col1,&-col11 {
        padding-right: 0 !important;
        width: 100% !important;
        padding-left: 0px !important;
        &-content {
          max-width: 100% !important;
          &-sm {
            max-width: 100% !important;
          }
        }
      }
    }

    &-thirdrow {
      max-width: 100% !important;
      margin-top: 0px !important;
      margin-bottom: 0px !important;
      display: block !important;
      &-left {
        max-width: 565px !important;
        margin: auto;
      }

      &-right {
        align-items: center;
        justify-content: center;
        display: flex;
        width: 100% !important;
        margin-top: 2rem !important;
        &-content {
          width: 100% !important;
          padding: 0px !important;
          justify-content: center;
          h1 {
            width: 55%;
            font-size: 32px;
            line-height: 35.2px;
            text-align: start !important;
          }
        }
      }
    }
  }
}


