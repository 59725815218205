@import "../../styles/variablesstyles.scss";
.copy-box {
  &-container {
    width: 100%;
    // border: 1px solid #434343;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: rgba(255, 255, 255, 0.05);
    border-radius: 8px;
  }

  &-text {
    background: transparent;
    border: none;
    font-family: "Verdana" !important;
    font-size: 14px !important;
    color: #bcbcbc !important;
    scrollbar-width: none;
  }

  &-button {
    margin-left: 10px;
    padding: 5px 10px;
    font-size: 12px;
    color: white;
    border: 1px solid #434343;
    background-color: #f9f9f900;
    border-radius: 8px;
    cursor: pointer;
    transition: background-color 0.3s;

    &:hover {
      background-color: #f9f9f90c;
    }
  }
}

@media (min-width: 1024px) and (max-width: 1199px) {
}

@media (min-width: 1200px) and (max-width: 1399px) {
}

@media (min-width: 576px) and (max-width: 1024px) {
}

@media (min-width: 360px) and (max-width: 576px) {
}

@media (min-width: 320px) and (max-width: 360px) {
}
