
@import "../../../../../styles/variablesstyles.scss";



.investor-box-parent-modal2 {
    gap: $theme-spacing3;
}
.investor-boxs-favorite-geners{
    width: 187px;
    height: 96px;
    position: relative;
    border-radius: 10px;
    background: linear-gradient(rgba(29, 29, 29, 1), rgba(40, 40, 40, 1));
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);

}
.investor-boxs-under-modal2{
    position: absolute;
    bottom: $theme-spacing4;
    left: $theme-spacing4;
    font-size: $theme-lg-fs;
    line-height: $theme-sm-lh;
    color: $theme-color-gray5; 
    font-weight: 600;
    right: $theme-spacing4;
}
.investor-boxs-radiobutton{
    position: absolute;
    right: $theme-spacing1;
    top: $theme-spacing1;
}
.ctm-daws-text{
      color: $theme-color-gray2;
      font-size: $theme-xsm-fs;
      line-height: $theme-xsm-lh;
}
@media(min-width:768px) and (max-width: 1023px){
    .investor-box-parent-modal2{
        max-height: 300px;
        overflow-y: auto;
   }
   .investor-boxs-under-modal2{
    position: absolute;
    bottom: 0.5rem;
    left: 1rem;
    line-height: 22.4px;
    right: 1rem;
    font-size: 16px;
}
}
@media(min-width:500px) and (max-width: 767px){
    .investor-boxs-favorite-geners{
        width: 192px;
        height: 96px;
    }
    .investor-boxs-under-modal2{
            position: absolute;
            bottom: 0.5rem;
            left: 1rem;
            line-height: 22.4px;
            right: 1rem;
            font-size: 16px;
    }
    .investor-box-parent-modal2{
         max-height: 300px;
         overflow-y: auto;
    }
}

@media(min-width:360px) and (max-width: 500px){
    .investor-boxs-favorite-geners{
        width: 130px;
        height: 96px;
    }
    .investor-box-parent-modal2{
        max-height: 300px;
        overflow-y: auto;
   }
}
@media(min-width:320px) and (max-width: 360px){
    .investor-boxs-favorite-geners{
        width: 115px;
        height: 96px;
    }
    .investor-box-parent-modal2{
        max-height: 300px;
        overflow-y: auto;
   }
}




