@import "../../styles/variablesstyles.scss";

.custom-select {
  &__indicator {
    // background-image: url("../../assets/steps/arrow-down.svg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: 1rem; // Adjust size as needed
    cursor: pointer !important;
  }
  &__control {
    white-space: nowrap !important;
    background-color: transparent !important;
    border: none;
    font-size: 16px;
    font-weight: 400;
    line-height:55.4px;
    cursor: pointer !important;
    text-align: left;
    color: rgba(188, 188, 188, 1) !important;
    box-shadow: none !important;
    
  }
  &__placeholder  {
    cursor: pointer;
  }

  &__option {
    white-space: nowrap !important;
    color: rgba(188, 188, 188, 1);
  
  }
  &__single-value {
    color: rgba(188, 188, 188, 1) !important;
  }
  &__indicator-separator {
    width: 0px !important;
  }
  &__control {
    border: none !important;
  }
  &__input-container {
    display: hidden !important;
  }
}
.custom-select__option:hover {
  color: white;
  // border-radius: 10px !important;
  white-space: nowrap !important;
  background-color: rgba(79, 79, 79, 0.7) !important;
}
.custom-select__option--is-focused{
  // border-radius: 10px !important;
  white-space: nowrap !important;
  background-color: transparent !important;
}
.custom-select__option--is-selected{
  // border-radius: 10px !important;
  
  white-space: nowrap !important;
  background-color: rgba(79, 79, 79, 1) !important;
}
.custom-select__control--menu-is-open{
//  width: 200px;
  white-space: nowrap !important;
  // max-width: 100px !important;
}
.css-b62m3t-container{
  width: 100% !important;
}
.custom-select__menu-list {
  border-radius: 10px !important;
}
.custom-select__menu-list::-webkit-scrollbar {
  width: 5px !important;
  z-index: 100 !important;
}
.custom-select__menu-list::-webkit-scrollbar-track {
  // background: transparent;
  transition: opacity 0.5s 0.5s ease-out;
}

.custom-select__menu-list::-webkit-scrollbar-thumb {
  // background:transparent;
  border-radius: 5px!important;
  transition: opacity 0.5s 0.5s ease-out;
}
.custom-select__menu-list::-webkit-scrollbar-thumb:hover {
  // background:transparent;
  opacity: 0.5;
}
.css-tj5bde-Svg {
  display: inline-block;
  fill: $theme-color-gray4 !important;
  line-height: 1;
  stroke: currentColor;
  stroke-width: 0;
}
.css-13khyri{
    border-radius: 0px !important;
    overflow-y: auto;
}
.css-13khyri::-webkit-scrollbar {
  width: 12px; 
}
.css-13khyri::-webkit-scrollbar-track {
  background: #f1f1f1; 
}
.css-13khyri::-webkit-scrollbar-thumb {
  background-color: #888; 
  border-radius: 10px; 
}
.css-13khyri::-webkit-scrollbar-thumb:hover {
  background-color: #555; 
}
.custom-select__option:hover {
  color: white;
  // border-radius: 10px !important;
  white-space: nowrap !important;
  background-color: rgba(79, 79, 79, 0.7) !important;
  cursor: pointer;
}
.custom-select__option--is-selected{
  // border-radius: 10px !important;
  cursor: pointer;
}

.css-1dimb5e-singleValue {
  margin-left: 10px !important;
}

// .custom-select__option:first-child:hover {
//   border-radius: 10px !important;
// }
// .custom-select__option:last-child:hover {
//   border-radius: 10px !important;
// }

.custom-select__option--is-selected:first-child{
  border-radius:10px 10px 0px 0px !important;
  cursor: pointer;
}

.custom-select__option--is-selected:last-child{
  border-radius:0px 0px 10px 10px !important;
  cursor: pointer;
}

@media(min-width: 576px) and (max-width: 768px){

  .css-y6uca4-placeholder{
    margin-left: 0px !important;
    font-size: 12px !important;
    line-height: 16.8px !important;
}
.custom-select__control{
  line-height:30px;
}
}


@media(min-width: 360px) and (max-width: 576px){

  .css-y6uca4-placeholder{
    margin-left: 0px !important;
    font-size: 12px !important;
    line-height: 16.8px !important;
}
.css-1dimb5e-singleValue {
  margin-left: 0px !important;
}
.custom-select__control{
  line-height: 22px;
    font-size: 12px !important;
}
// .css-tr4s17-option{
//   padding: 0px 8px !important;
// }
.css-d7l1ni-option{
  // padding: 0px 8px !important;

}
.custom-select__option{
  font-size: 10px !important;
  // padding: 0px 8px !important;
  line-height: 20px !important;

}
.custom-select__option--is-selected{
  padding: 8px 12px !important;
}
.custom-select__option--is-focused{
  padding: 8px 12px !important;
}
}


@media(min-width: 320px) and (max-width: 360px){

  .css-y6uca4-placeholder{
    margin-left: 0px !important;
    font-size: 12px !important;
    line-height: 16.8px !important;
}
.css-1dimb5e-singleValue {
  margin-left: 0px !important;
}
.custom-select__control{
    font-size: 12px !important;
    line-height: 20px;
}
// .css-tr4s17-option{
//   padding: 0px 8px !important;
// }
.css-d7l1ni-option{
  // padding: 0px 8px !important;

}
.custom-select__option{
  font-size: 10px !important;

  line-height: 20px !important;
}
.custom-select__option--is-selected{
  padding: 8px 12px !important;
}
.custom-select__option--is-focused{
  padding: 8px 12px !important;
}

}
.css-hlgwow{
  padding: 0px 8px !important;
}