@import "../../styles/variablesstyles.scss";

.hr-line-lg {

}
.hr-line-md {
  border: 1px solid $theme-color-gray2;
  margin: 0px;
}
.hr-line-sm {

}

@media (min-width: 360px) and (max-width: 576px) {
  .hr-line-lg {
    font-size: 12px !important;
    line-height: 16.8px !important;
    margin: $theme-spacing1 auto;
  }
  .hr-line-md {
    font-size: 12px;
    line-height: 16.8px;
    margin: $theme-spacing1 auto;
  }
  .hr-line-sm {
    font-size: 14px !important;
    line-height: 19.6px !important;
    margin: 0px !important;
  }
  .hr-line-xsm {
    font-size: 12px !important;
    line-height: 16.8px !important;
    margin-bottom: 0 !important;
  }
}

@media (min-width: 320px) and (max-width: 360px) {
  .hr-line-lg {
    font-size: 12px !important;
    line-height: 16.8px !important;
    margin: $theme-spacing1 auto;
    margin-top: 0px;
  }
  .hr-line-md {
    font-size: 12px;
    line-height: 16.8px;
    margin: $theme-spacing1 auto;
  }
  .hr-line-sm {
    font-size: 14px !important;
    line-height: 19.6px !important;
    margin: 0px !important;
  }
  .hr-line-xsm {
    font-size: 12px !important;
    line-height: 16.8px !important;
    margin-bottom: 0 !important;
  }
}
