
@import "../../../../../styles/variablesstyles.scss";



.investor-box-parent{
    gap: $theme-spacing3;
}
.investor-boxs{
    width: 187px;
    height: 187px;
    position: relative;
    border-radius: 10px;
    background: linear-gradient(rgba(29, 29, 29, 1), rgba(40, 40, 40, 1));
}
.investor-boxs-under{
    position: absolute;
    bottom: $theme-spacing4;
    left: $theme-spacing4;
    font-size: $theme-heading-5-size;
    line-height: 24.4px;
    color: $theme-color-gray5; 
    font-weight: 700;
    right: $theme-spacing4;
}
.investor-boxs-radiobutton{
    position: absolute;
    right: $theme-spacing1;
    top: $theme-spacing1;
}
.ctm-daws-text{
      color: $theme-color-gray2;
      font-size: $theme-xsm-fs;
      line-height: $theme-xsm-lh;
}
.unblock-button{
    width: 196px;
    border: none;
    color: white;
    height: 48px;
    border-radius: 8px;
    padding: 14px 70px 14px 70px;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    background: linear-gradient(313.89deg, rgba(29, 29, 29, 1) 1.71%, rgba(40, 40, 40, 1) 98.51%);
}
.block-username{
    color: $theme-color-gray5;
    font-weight: 400;
}
.undo-button{
     width: 176px;
     height: 48px;
     background: var(--Error, rgba(235, 87, 87, 1));
     color: white;
     border: none;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    border-radius: 8px;
}
.block-undo{
    color: $theme-color-gray1;
    font-weight: 400;
}




