@import "../../../../../styles/variablesstyles.scss";
.font-col-wh{
    color: $theme-color-gray5;
}
.cancel-btn-bg{
    background: rgba(40, 40, 40, 1);
    margin-right: 16px !important;
}
.custom-textarea{
    width: 100%;
    background: $input-background-color;
    border: 0;
    border-radius: 10px;
    color: rgb(255, 255, 255);
    line-height: 24px;
    font-size: $input-placeholder-size !important;
    padding-left: 1rem;
    padding-right: 40px;
    margin-bottom: 1.5rem;
    font-weight: 400!important;
    height: 180px;
    padding-top: 15px;
    padding-bottom: 15px;
    max-height: 295px;
    min-height: 102px;
}