@import "/src/styles/variablesstyles.scss";


.f-18{
  font-size: 18px !important;
}

.home{
width: 100%;


.home-container{
  position: relative;
  width: 100%;
 aspect-ratio: 1.73;
  overflow: hidden;
  background: url('../../../../../public/homeImage_background.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  margin-top: -2.5vw;
  margin-bottom: 112px;
  background-color: black;
}

.dw-maintenance-videod {
  width: 100%;
 aspect-ratio: 1.73;
 opacity: 0.7;
 
}
video::-webkit-media-controls {
  display: none !important;
}

.unique-features{
  display: block;
}
.home-never-bored{
   text-align: center;
   font-weight: 400 !important;
   
}
.bubbles{
position: absolute;
    display: flex;
    flex-wrap: wrap;
    width: 100%;
}
.home-new-text{
  color: rgba(204, 204, 204, 1);
  margin: 0px !important;
}

.bubbles span{
  position: relative;
  width: 0.5vw;
  height: 0.5vw;
  background: rgb(250, 202, 45);
  margin: 0 4px;
  border-radius: 50%;
  box-shadow: 0 0 0 2px rgb(68, 67, 64),
    0 0 50px rgb(156, 148, 120), 0 0 100px rgb(43, 41, 35);
  animation: animated 15s linear infinite;
  animation-duration: calc(1000s / var(--i));  /* Delay the start of the bubbles randomly */
}
.girlImg{
  background: url('../../../../../public/homeImgGirl.png');
  position: absolute !important;
  z-index: 5;
  width: 100%;
  height: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}



@keyframes animated {
  0% {
    transform: translateY(calc(130vh - var(--i) * 1px)) scale(0);
  }
  100% {
    transform: translateY(calc(0vh + var(--i) * 1px)) scale(1);
  }
}
    .custom-img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: 1;
  }
  
  #effect-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
    z-index: 0;
  }
  
  .sparkle {
    // position: absolute;
    border-radius: 50%;
    pointer-events: none;
    background: rgb(250, 202, 45);
    opacity: 0.7;
    border: 1px solid black;
  }
.uni-my-208{
  margin-bottom: 208px !important;
  margin-top: 208px !important;
  max-width: 2560px;
}

.f-col-theme5{
  color: $theme-color-gray5;
}
.w-670{
  width: 670px;
}
.max-w-403{
max-width: 100%;
}
  &-box{
    width: 240px;
    aspect-ratio: 1;
    background-color: rgba(196, 196, 196, 1);
    // background: url('https://as2.ftcdn.net/v2/jpg/11/36/00/69/1000_F_1136006997_I8KLnWRsVXshMB2hqVxGMEueVkRuJOpE.jpg');
    margin-right: 16px;
    border-radius: 10px;
    background-size: cover; 
    background-position: center;
    background-repeat: no-repeat;
  }

 &-box:first-child{
    margin-left: 8px;
  }
  
  &-box:last-child{
    margin-right: 8px;
  }
.txt-col-204{
  color:  rgba(204, 204, 204, 1);
}
  &-w-782
  {
    max-width: 782px;
    text-align: center;
 
  }
  &-tabs {
    width: max-content !important;
    &-content{
      display: flex;
      &-box{
      width:591px ;
        height: 385px;
        background-color: rgba(217, 217, 217, 1);
        border-radius: 10px;
        background-size: cover; 
        background-position: center;
        background-repeat: no-repeat;
      }
      &-btn-bg{
        background: linear-gradient(to bottom right,rgba(55, 48, 236, 1),rgba(169, 16, 121, 1)) !important;
      }

      &-box2{
        width: 670px;
        aspect-ratio: 1;
        background-color: rgba(217, 217, 217, 1);
        border-radius: 10px;
            background-size: cover; 
    background-position: center;
    background-repeat: no-repeat;
        &-heading{
          line-height: 58.8px;
          font-weight: 900;
        }
      }
    }
}

.bg-theme-pink{
  background: $theme-pink-color !important;
}
}

@media (max-width:1440px)
{
  .home{

    .uni-my-208{
      margin-bottom: 56px !important;
      margin-top: 56px !important;
      max-width: 1440px
    }
  }
.home-tabs-content-box{
  width:450px !important;
}
  
}

@media(min-width: 576px) and (max-width: 1024px){
  .home-box-slide{
    overflow-x: auto;
    scrollbar-width: none; 
    -ms-overflow-style: none; 
  }
  .home-page-mobilespace-bottom{
    margin-bottom:  16px !important;
}
.home-page-mobilespace-top{
  margin-top: 0px !important;
}
  .txt-col-204{
    font-size: 12px;
    line-height: 16.8px;
  }
  .home-w-782
  {
    max-width: 100% !important;
    font-size: 12px;
    line-height: 16.8px;
  }
  .home-container{
    margin-bottom: 16px !important;
  }
  .home-page-mobile-pd{
    padding: 8px 0px !important;
  }
  .home-box-slide::-webkit-scrollbar {
    display: none; 
  }
  .home-box{
       width: 111px;
       height: 111px;
      border-radius: 5px;
      margin-right: 8px !important;
  }
  .home-tabs-content-box{
    width:100% !important;
    height: 143px !important;
    margin: auto;
    border-radius: 5px;

  }
  .home-box-section-spacing{
    gap: 1rem;
  }
  .home-tabs-content-box2{
    width:100% !important;
    height: 312px !important;
    margin-right: 0px !important;
    border-radius: 5px;
  }
  .home-tab-mobile-view{
    flex-direction: column;
  }
 
  .home-mobileview-btn{
    height: 30px;
    font-size: 12px !important;
    line-height: 19.6px !important;
    border-radius: 5px;
    margin-bottom: 16px !important;
    white-space: nowrap;
  }
  .trade-btn{
     margin-left: auto !important;
  }
  .content-boxes{
    width: auto !important;
    margin-left: 0px !important;
    margin-right: 0px !important;
    justify-content: flex-start !important;
  }
  .home-box-right{
    margin-left: 0px !important;
  }
  .home-page-max-width{
    max-width: 100% !important;
    width: 100% !important;
    padding: 0px !important;

  }

}
@media(min-width: 360px) and (max-width: 576px){
  .home-box-slide{
    overflow-x: auto;
    scrollbar-width: none; 
    -ms-overflow-style: none; 
  }
  .home-heading4{
    font-size: 14px;
    line-height: 16.8px;
    margin-bottom: 0.5rem !important;
    font-weight: 600;
    margin-top: 18px;
  }
  .home-page-mobilespace-bottom{
    margin-bottom:  16px !important;
}
.home-page-mobilespace-top{
  margin-top: 0px !important;
}
  .home-box-section-spacing{
    gap: 1rem;
  }
  .txt-col-204{
    font-size: 12px;
    line-height: 16.8px;
  }
  .home-w-782
  {
    max-width: 100% !important;
    font-size: 12px;
    line-height: 16.8px;
  }
  .home-container{
    margin-bottom: 2rem !important;
  }
  .home-page-mobile-pd{
    padding: 8px 0px !important;
  }
  .home-box-slide::-webkit-scrollbar {
    display: none; 
  }
  .home-box{
      width: 111px;
      height: 111px;
      border-radius: 5px;
      margin-right: 8px !important;
  }
  .home-tabs-content-box{
    width:100% !important;
    height: 143px !important;
    margin: auto;
    border-radius: 5px;

  }
  .home-tabs-content-box2{
    width:100% !important;
    height: 312px !important;
    margin-right: 0px !important;
    border-radius: 5px;
  }
  .home-tab-mobile-view{
    flex-direction: column;
    margin-bottom: 20px !important;
  }
  .learn-trade-mobileview{
    flex-direction: column;
  }
  .home-mobileview-btn{
    width: 100% !important;
    height: 30px;
    font-size: 12px !important;
    line-height: 19.6px !important;
    border-radius: 5px;
    margin-bottom: 16px !important;
    white-space: nowrap;
    font-weight: 600;
  }
  .trade-btn{
     margin-left: auto !important;
  }
  .content-boxes{
    width: auto !important;
    margin-left: 0px !important;
    margin-right: 0px !important;
    justify-content: flex-start !important;
  }
  .home-box-right{
    margin-left: 0px !important;
  }
  .home-page-max-width{
    max-width: 100% !important;
    width: 100% !important;
    padding: 0px !important;
  }
  .home-second-tab{
    padding: 8px 0px 16px 0px !important;
  }
  .reverse-col{
    flex-direction: column-reverse !important;
    gap: 1rem;
  }
}
@media(min-width: 320px) and (max-width: 360px){
  .home-box-slide{
    overflow-x: auto;
    scrollbar-width: none; 
    -ms-overflow-style: none; 
  }
  .home-font-weight{
     font-weight: 600 !important;
  }
  .home-heading4{
    font-size: 14px;
    line-height: 16.8px;
    margin-bottom: 0.5rem !important;
    font-weight: 600;
  }
  .home-textMd{
    font-size: 12px;
    line-height: 17.6px;
    font-weight: 400;
  }
  .reverse-col{
    flex-direction: column-reverse !important;
    gap: 1rem;
  }

  .home-page-mobilespace-bottom{
      margin-bottom:  16px !important;
  }
  .home-page-mobilespace-top{
    margin-top: 0px !important;
  }
  .home-box-section-spacing{
    gap: 1rem;
  }
  .txt-col-204{
    font-size: 12px;
    line-height: 16.8px;
  }
  .home-w-782
  {
    max-width: 100% !important;
    font-size: 12px;
    line-height: 16.8px;
  }
  .home-container{
    margin-bottom: 2rem !important;
  }
  .home-page-mobile-pd{
    padding: 16px 0px 8px 0px !important;
  }
  .home-box-slide::-webkit-scrollbar {
    display: none; 
  }
  .home-box{
       width: 111px;
       height: 111px;
      border-radius: 5px;
      margin-right: 8px !important;
  }
  .home-tabs-content-box{
    width:100% !important;
    height: 143px !important;
    margin: auto;
    border-radius: 5px;

  }
  .home-tabs-content-box2{
    width:100% !important;
    height: 312px !important;
    margin-right: 0px !important;
    border-radius: 5px;
  }
  .home-tab-mobile-view{
    flex-direction: column;
    margin-bottom: 20px !important;
  }
  .learn-trade-mobileview{
    flex-direction: column;
  }
  .home-mobileview-btn{
    width: 100% !important;
    height: 30px;
    font-size: 12px !important;
    line-height: 19.6px !important;
    border-radius: 5px;
    margin-bottom: 16px !important;
    font-weight: 600;
  }
  .trade-btn{
     margin-left: auto !important;
  }
  .content-boxes{
    width: auto !important;
    margin-left: 0px !important;
    margin-right: 0px !important;
    justify-content: flex-start !important;

  }
  .home-page-max-width{
    max-width: 100% !important;
    width: 100% !important;
    padding: 0px !important;
  }
  .home-box-right{
    margin-left: 0px !important;
  }
  .home-tabs-content-box2-heading{
    font-size: 16px !important;
    line-height: 17.6px !important;
    font-weight: 800;
    margin-bottom: 1rem !important;
  }
  .homecheckIcon-mobile{
    width: 11px;
    height: 8.04px;
  }
  .home-mobile-sm{
    font-size: 12px !important;
    line-height: 16.8px !important;
    margin-bottom: 8px !important;
  }
  .home-btn-mobileui-mt{
    margin-top: 1rem !important;
  }
  .home-second-tab{
    padding: 16px 0px !important;
  }
}