

@import '../../../../../styles/variablesstyles.scss';


.ds-ctm-txt{
    font-weight: 700;
    color:$theme-color-gray2;
  }
  .ctm-textarea{

     width: 609px;
     height: 180px;
     background-color: rgba(255, 255, 255, 0.05);
  }