
@import "../../../../../styles/variablesstyles.scss";

.custom-modal-email{
     width: 566px;
     height: 547px;
     padding: 56px 72px;
     background: rgba(15, 15, 15, 1);
     box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
     border: none;
     border-radius: 10px;
}

.email-sms-box-parent{
    gap: 48px;
}
.email-sms-boxs{
    width: 187px;
    height: 187px;
    position: relative;
    border-radius: 10px;
    background: linear-gradient(rgba(29, 29, 29, 1), rgba(40, 40, 40, 1));
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);

}
.investor-boxs-under-modal2{
    position: absolute;
    bottom: $theme-spacing4;
    left: $theme-spacing4;
    font-size: $theme-lg-fs;
    line-height: $theme-sm-lh;
    color: $theme-color-gray5; 
    font-weight: 600;
    right: $theme-spacing4;
}
.investor-boxs-radiobutton{
    position: absolute;
    right: $theme-spacing1;
    top: $theme-spacing1;
}
.ctm-daws-text{
      color: $theme-color-gray2;
      font-size: $theme-xsm-fs;
      line-height: $theme-xsm-lh;
}




