@import "../../../../styles/variablesstyles.scss";


.recemail {
  position: relative;
  &_content {
    width: 546px;
    padding: 0;
  }
  &_normal {
    font-weight: 400;
    color: $theme-color-gray4;
    margin: 32px 0px;
  }
  &_register {
    margin-top: 20px;
  }
  &_icon{
    font-size: 20px;
  }
 

  &_register_btn {
    font-weight: 700;
    background: rgba(169, 15, 121, 1);
    border: 0;
    color: #fff;
    width: 100%;
    text-align: center;
    border-radius: 0.5rem;
    line-height: 3rem;
  }
  &_or {
    color: $theme-color-gray4;
    margin: 18px auto;
  }
  &_register_google {
    background: $theme-color-white;
    color: rgba(31, 31, 31, 1);
    margin-bottom: 32px;
  }
  &_logins {
    color: rgba(255, 245, 0, 1);
    cursor: pointer;
    text-decoration: none;
  }
  &_privacy {
    color: $theme-color-gray3;
    &_link {
      color: $theme-color-info;
      text-decoration: none;
    }
  }

  &_already_registered {
    margin-bottom: 32px;
    color: $theme-color-gray4;
  }
}

@media(min-width: 360px) and (max-width: 768px) {
  .recemail {
    &_content {
      width: 546px;
      padding: 0;
    }
    &_normal {
      font-weight: 400;
      color: $theme-color-gray4;
      margin: 16px 0px;
      margin-top: 8px !important;
    }
    &_register {
      margin-top: 20px;
    }
    &_icon{
      font-size: 20px;
    }
   
  
    &_register_btn {
      font-weight: 700;
      background: rgba(169, 15, 121, 1);
      border: 0;
      color: #fff;
      width: 100%;
      text-align: center;
      border-radius: 0.5rem;
      line-height: 3rem;
    }
    &_or {
      color: $theme-color-gray4;
      margin: 18px auto;
    }
    &_register_google {
      background: $theme-color-white;
      color: rgba(31, 31, 31, 1);
      margin-bottom: 32px;
    }
    &_logins {
      color: rgba(255, 245, 0, 1);
      cursor: pointer;
      text-decoration: none;
    }
    &_privacy {
      margin-top: 8px !important;
      color: $theme-color-gray3;
      &_link {
        color: $theme-color-info;
        text-decoration: none;
      }
    }
  
    &_already_registered {
      margin-bottom: 8px !important;
      color: $theme-color-gray4;
      font-size: 14px !important;
      margin-top: 8px !important;
    }
  }
  .recemail-recover-btn{
    height: 30px;
      font-size: 12px;
      margin-bottom: 16px !important;
      border-radius: 5px !important;
  }
}

@media(min-width: 320px) and (max-width: 360px) {
  .recemail {
    &_content {
      width: 546px;
      padding: 0;
    }
    &_normal {
      font-weight: 400;
      color: $theme-color-gray4;
      margin: 16px 0px;
      margin-top: 8px !important;
    }
    &_register {
      margin-top: 20px;
    }
    &_icon{
      font-size: 20px;
    }
   
  
    &_register_btn {
      font-weight: 700;
      background: rgba(169, 15, 121, 1);
      border: 0;
      color: #fff;
      width: 100%;
      text-align: center;
      border-radius: 0.5rem;
      line-height: 3rem;
    }
    &_or {
      color: $theme-color-gray4;
      margin: 18px auto;
    }
    &_register_google {
      background: $theme-color-white;
      color: rgba(31, 31, 31, 1);
      margin-bottom: 32px;
    }
    &_logins {
      color: rgba(255, 245, 0, 1);
      cursor: pointer;
      text-decoration: none;
    }
    &_privacy {
      margin-top: 8px !important;
      color: $theme-color-gray3;
      &_link {
        color: $theme-color-info;
        text-decoration: none;
      }
    }
  
    &_already_registered {
      margin-bottom: 8px !important;
      color: $theme-color-gray4;
      font-size: 14px !important;
      margin-top: 8px !important;
    }
  }
  .recemail-recover-btn{
    height: 30px;
      font-size: 12px;
      margin-bottom: 16px !important;
      border-radius: 5px !important;
  }
}
