

@import '../../../../../styles/variablesstyles.scss';


.sn-ctm-txt{
    font-weight: 700;
    color:$theme-color-gray2;
    font-size: $theme-xsm-fs;
    line-height: 19.6px;
  }

  .modal-width-notification{
    width: 753px;
  //  padding:  56px 72px 56px 72px;
  }

  .text-field-notification{
    color: $theme-color-gray5;
  }


  @media(min-width: 320px) and (max-width: 360px){

    .notification-subheading{
      margin-top: 8px !important;
    }
    .notification-body-content{
       padding: 0px 0 !important;
       margin-top: 16px !important;
    }
  }
 