.image-grid-wrapper {
  padding: 30px 0px;
width: 100%;
.scroll {
  position: relative;
  display: flex;
  // width: fit-content;
  // max-width: 1240px;
  overflow: hidden;
  height: 240px;
}

.scroll div img {
  display: inline-flex;
  margin: 10px;
  /* background: black; */
  color: aliceblue;
  /* padding: 5px 10px; */
  border-radius: 16px;
  transition: 0.5s;
  width: 220px;
  height: 220px;
  object-fit: cover;
}

.scroll div img:hover {
  // background: #4caf50;
  cursor: pointer;
}

@keyframes animate {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(-100%);
  }
}
@keyframes animate2 {
  0% {
    transform: translateX(0%);
  }
  100% {
    transform: translateX(-200%);
  }
}
.scroll div {
  white-space: nowrap;
  animation: animate 30s linear infinite;
  animation-delay: -30s;
  display: flex;
  background-color: rgba(0, 0, 0, 0.6);
  height: 300px;

}
.scroll div:nth-child(2) {
  white-space: nowrap;
  animation: animate2 30s linear infinite;
  animation-delay: -15s;
  display: flex;
  /* background-color: #4caf50; */
  height: 300px;

}
}
@media(min-width: 360px) and (max-width: 576px) {

  .image-grid-wrapper {
    padding: 30px 0px;
  width: 100%;
  .scroll {
    position: relative;
    display: flex;
    // width: fit-content;
    // max-width: 1240px;
    overflow: hidden;
    height: auto !important;
  }

  .scroll div {
    white-space: nowrap;
    animation: animate 30s linear infinite;
    animation-delay: -30s;
    display: flex;
    background-color: rgba(0, 0, 0, 0.6);
    height: auto !important;
  
  }
  
  .scroll div img {
    display: inline-flex;
    margin: 10px;
    /* background: black; */
    color: aliceblue;
    /* padding: 5px 10px; */
    border-radius: 16px;
    transition: 0.5s;
    width: 93.6px !important;
    height: 93.6px !important;
  object-fit: cover;

  }
}
}
@media(min-width: 576px) and (max-width: 768px) {

  .image-grid-wrapper {
    padding: 30px 0px;
  width: 100%;
  .scroll {
    position: relative;
    display: flex;
    // width: fit-content;
    // max-width: 1240px;
    overflow: hidden;
    height: auto !important;
  }

  .scroll div {
    white-space: nowrap;
    animation: animate 30s linear infinite;
    animation-delay: -30s;
    display: flex;
    background-color: rgba(0, 0, 0, 0.6);
    height: auto !important;
  
  }
  
  .scroll div img {
    display: inline-flex;
    margin: 10px;
    /* background: black; */
    color: aliceblue;
    /* padding: 5px 10px; */
    border-radius: 16px;
    transition: 0.5s;
    width: 93.6px !important;
    height: 93.6px !important;
  object-fit: cover;

  }
}
}

@media(min-width: 320px) and (max-width: 360px) {
  
  .image-grid-wrapper {
    padding: 30px 0px;
  width: 100%;
  .scroll {
    position: relative;
    display: flex;
    // width: fit-content;
    // max-width: 1240px;
    overflow: hidden;
    height: auto !important;
  }

  .scroll div {
    white-space: nowrap;
    animation: animate 30s linear infinite;
    animation-delay: -30s;
    display: flex;
    background-color: rgba(0, 0, 0, 0.6);
    height: auto !important;
  
  }
  
  .scroll div img {
    display: inline-flex;
    margin: 10px;
    /* background: black; */
    color: aliceblue;
    /* padding: 5px 10px; */
    border-radius: 6.3px;
    transition: 0.5s;
    width: 93.6px !important;
    height: 93.6px !important;
  object-fit: cover;

  }
}
}