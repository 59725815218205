@import "../../../../styles/variablesstyles.scss";
.library{
    &-heading{
        font-size: $theme-heading-2-size;
        font-weight: 700;
    }
    .maxy-wid{
        width: 150px;
    }
    &-inputbox{
        width: 498px !important;
    }
    &-button{
        transition:  transform 0.7s ease;

        .active{
            background-color: rgb(255, 255, 255) !important;
            color: rgb(15, 15, 15) !important;
            transition:  transform 0.7s ease;
        }
        &-all{
            background-color: transparent;
            color: $theme-color-gray4;
            padding: 20px;
            font-size: $theme-lg-fs;
            font-weight: 700;
            border: none;
        }
    }
    &-subheading{
        font-size: $theme-heading-5-size;
        font-weight: 700;
        margin-bottom: 1rem;
    }
    &-usericon{
        margin-bottom: 56px ;
        position:  relative !important;
    }
    &-dropdown{
        background-color:  rgba(255, 255, 255, 0.05);
        border: none;
    }
}
.library .link-btm{
    display: none
}

.library-btn{
    width: 199px;
}
.library-btns.active{
    background-color: rgb(255, 255, 255);
            color: rgb(15, 15, 15) ;
            transition: background-color 0.7s ease, color 0.7s ease, transform 0.7s ease;
}
@media(min-width : 360px) and (max-width: 576px){
    .library-btn{
        width: 100%;
        height: 36px;
        font-size: 12px;
        font-weight: 700px;
    }
    .library-subheading{
        font-size: 16px;
        font-weight: 700;
        margin-bottom: 0.5rem;
        line-height: 17.6px;
    }
    .library-usericon{
         margin-bottom: 16px !important;
    }
    .library-btns{
     width: max-content;
     height: 27px;
     padding: 8px 8px;
     border-radius: 5px;
     font-size: 10px;
    line-height: 16.8px;
    font-weight: 400;
    }
    .library-btn-parent{
        gap: 0.5rem !important;
       }
  
}

@media(min-width : 320px) and (max-width: 360px){
    .library-btn{
        width: 100%;
        height: 36px;
        font-size: 12px;
        font-weight: 700px;
    }
    .library-subheading{
        font-size: 16px;
        font-weight: 700;
        margin-bottom: 0.5rem;
        line-height: 17.6px;
    }
    .library-usericon{
         margin-bottom: 16px !important;
    }
    .library-btns{
        width: max-content;
        height: 27px;
        padding: 8px 8px;
        border-radius: 5px;
        font-size: 10px;
        line-height: 16.8px;
        font-weight: 400;
       }
       .library-btn-parent{
        gap: 0.5rem !important;
       }
  
}