@import "../../../../styles/variablesstyles.scss";

.align-items-pages{
  // margin-top: 11.8% !important;
  align-items: center;

  position: relative;
}
.signUp {
  position: relative;
  &_content {
    padding: 0;
  }
  &_normal {
    font-weight: 400;
    color: $theme-color-gray4;
  }
  &_icon{
    font-size: 20px;
  }
  &_or {
    color: $theme-color-gray4;
  }
  &_register_google {
    background: $theme-color-white;
    color: rgba(31, 31, 31, 1);
    transition: background-color 0.3s ease, transform 0.3s ease, box-shadow 0.6s ease !important;
  }
  &_logins {
    color: rgba(255, 245, 0, 1);
    cursor: pointer;
    text-decoration: none;
  }
  &_privacy {
    color: $theme-color-gray3;
    &_link {
      color: $theme-color-info;
      text-decoration: none;
    }
  }

  &_already_registered {
    color: $theme-color-gray4;
  }
}


@media(min-width: 360px) and (max-width: 768px) {
  .align-items-pages{
    margin-top: 1rem !important;
    align-items: start;
    position: relative;
  }
  .signUp {
    &_content {
      padding: 0;
    }
    &_normal {
      font-weight: 400;
      color: $theme-color-gray4;
      margin-top: 8px !important;
    }
    &_icon{
      font-size: 20px;
    }
    &_or {
      color: $theme-color-gray4;
    }
    &_register{
      height: 30px;
      font-size: 12px;
      margin-top: 16px !important;
      margin-bottom: 8px !important;
      border-radius: 5px !important;
    }
    &_register_google {
      background: $theme-color-white;
      color: rgba(31, 31, 31, 1);
      height: 30px !important;
      margin-top: 8px;
      margin-bottom: 16px !important;
      font-size: 12px;
    }
    &_logins {
      color: rgba(255, 245, 0, 1);
      cursor: pointer;
      text-decoration: none;
    }
    &_privacy {
      color: $theme-color-gray3;
      &_link {
        color: $theme-color-info;
        text-decoration: none;
      }
    }
  
    &_already_registered {
      color: $theme-color-gray4;
      font-size: 12px !important;
      margin-bottom: 0px !important;
    }
  }
}

@media(min-width: 320px) and (max-width: 360px) {
  .align-items-pages{
    margin-top: 1rem !important;
    align-items: start;
  
    position: relative;
  }
  .signUp {
    &_content {
      padding: 0;
    }
    &_normal {
      font-weight: 400;
      color: $theme-color-gray4;
      margin-top: 8px !important;
    }
    &_icon{
      font-size: 20px;
    }
    &_or {
      color: $theme-color-gray4;
    }
    &_register{
      height: 30px;
      font-size: 12px;
      margin-top: 16px !important;
      margin-bottom: 8px !important;
      border-radius: 5px !important;
    }
    &_register_google {
      background: $theme-color-white;
      color: rgba(31, 31, 31, 1);
      height: 30px !important;
      margin-top: 8px;
      margin-bottom: 16px !important;
      font-size: 12px;
    }
    &_logins {
      color: rgba(255, 245, 0, 1);
      cursor: pointer;
      text-decoration: none;
    }
    &_privacy {
      color: $theme-color-gray3;
      &_link {
        color: $theme-color-info;
        text-decoration: none;
      }
    }
  
    &_already_registered {
      color: $theme-color-gray4;
      font-size: 12px !important;
      margin-bottom: 0px !important;
    }
  }
  .signup-cstm-pass{
    margin-top: 8px !important;
  }
  .singup-form-mobile{
    margin-top: 1rem;
  }

}
// .signUp_register_google:hover{
//   background: rgb(188, 179, 179) !important;
// }
.signUp_register_google:hover{
  background: rgb(188, 179, 179) !important;
  // animation: 0.3s ease-in-out;
  transition: opacity 0.5s ease, background-color 0.3s ease-in-out !important; /* Apply transition */
}









