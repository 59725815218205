@import "../../../../../styles/variablesstyles.scss";

.ds-ctm-txt {
  font-weight: 700;
  color: $theme-color-gray2;
}
.btn-bg-gray3 {
  background: $theme-color-black3 !important;
}
.custom-pass-space {
  margin-bottom: 32px !important;
}
.custom-confirm-pass-space {
  margin-bottom: 24px !important;
}
.gray4 {
  color: $theme-color-gray4 !important;
}
.gray3 {
  color: $theme-color-gray3 !important;
}
.gray2 {
  color: $theme-color-gray2 !important;
}
.j-text{
  text-align: justify;
}
.c-text{
  text-align: center;
}

@media (min-width: 360px) and (max-width: 576px) {
  .modal-footer.social-mobile-modal-footer {
    padding: 0;
  }

  .custom-pass-space {
    margin-bottom: 8px !important;
  }

  .custom-confirm-pass-space {
    margin-bottom: 8px !important;
  }

  button.btn-wid.social-link-modal-btns.custom_btn_small {
    margin: 0px 0px 16px 0px;
    width: 100%;
  }

  .modal-footer.social-mobile-modal-footer {
    gap: 16px;
  }
}

@media (min-width: 320px) and (max-width: 360px) {
  .custom-pass-space {
    margin-bottom: 8px !important;
  }
  .custom-confirm-pass-space {
    margin-bottom: 8px !important;
  }

  .custom-pass-space {
    margin-bottom: 8px !important;
  }

  .custom-confirm-pass-space {
    margin-bottom: 8px !important;
  }

  .modal-footer.social-mobile-modal-footer {
    padding: 0;
  }
  button.btn-wid.social-link-modal-btns.custom_btn_small {
    margin: 0px 0px 16px 0px;
    width: 100%;
  }

  .modal-footer.social-mobile-modal-footer {
    gap: 16px;
  }
}
