@media(min-width: 360px) and (max-width: 576px){

    .secureaccount-country{
         margin-right: 0.5rem !important;
    }
    .social-mobile-modal-footer{
     margin-top: 1rem !important;
    }
    .full-wid{
     width: 100%;
    }
}

@media(min-width: 320px) and (max-width: 360px){

     .secureaccount-country{
          margin-right: 0.5rem !important;
     }
     .social-mobile-modal-footer{
          margin-top: 1rem !important;
     }
     .full-wid{
          width: 100%;
     }
}