

@import '../../../../../styles/variablesstyles.scss';


.ds-ctm-txt{
    font-weight: 700;
    color:$theme-color-gray2;
  }
  
  .cstm-txt-invite{
     background-color: $input-background-color;
     line-height: $theme-sm-lh;
    height: 124px;
  }
  .btn-wid-decline{
      background: $theme-color-black3;
      width: 190px;
  }
  .btn-wid-accept{
      width: 188px;
  }
  .removeCollaborator-img{
    height: 130px;
    width: 130px;
    border-radius: 50%;
  }
  
  .cstm-inp-invite::placeholder{
        
     color: $theme-color-white;
     line-height: $theme-sm-lh;
     font-weight: 700;
     font-size: $theme-sm-fs;
  }
  .block-profile-box{
       
     width: 80px;
     height: 80px;
     background-color: $theme-color-gray1;
     border-radius: 50px;
  }
  .list-text{
    color: $theme-color-gray5;
    font-weight: 400;
  }
  .list-item{
    color: $theme-color-gray5;
  }
  .displayname{
        font-size: $theme-heading-5-size;
       line-height: $theme-heading-5-lh;
       color: $theme-color-white;
  }
  .userName-text{
   font-size: $theme-xsm-fs;
    line-height: $theme-xsm-lh;
    color:$theme-color-gray5;

  }
  .remove-content{
      color: $theme-color-gray3;
  }