@import "../../../../../styles/variablesstyles.scss";
 
.user-image-user-list{
    width: 60px;
    height: 60px;
    border-radius: 50px;
}
.user-item-list{
    max-height: 300px;
    overflow-y: auto;
    scroll-behavior: smooth;
}
.search-custm-input-userlist{
    width: auto !important;
}
.message-userlist-buttons{
    line-height: 19.6px;
    color: $theme-color-gray5;
    width: 128px !important;
}