@import "../../styles/variablesstyles.scss";

.tabs {
  .tab-marketplace-button {
    background-color: transparent;
    color: $theme-color-gray3;
    font-size: $theme-xsm-fs;
    font-weight: 400;
    border: none;
    line-height: $theme-xsm-lh;
    cursor: pointer;
    position: relative;
  }
  &-container {
    display: flex;
    position: relative;
  }
  .active-tab-marketplace {
    color: yellow;
    font-weight: 700;
    transition: all 0.9s ease;

    &::after {
      opacity: 1;
    }
  }
}

@media(min-width: 320px) and (max-width: 1024px){
  .tabs {
    .tab-marketplace-button {
      background-color: transparent;
      color: $theme-color-gray3;
      font-size: $theme-xsm-fs;
      font-weight: 400;
      border: none;
      line-height: $theme-xsm-lh;
      cursor: pointer;
      position: relative;
    }
    &-container {
      display: flex;
      position: relative;
    }
    .active-tab-marketplace {
      color: yellow;
      font-weight: 400;
      transition: all 0.9s ease;
  
      &::after {
        opacity: 1;
      }
    }
  } 
}