@import "../../../styles/variablesstyles.scss";

.marketplaceProduct-main {

  .w-mx-701 {
    max-width: 728px;
  }

  .home-w-100 {
    width: calc(100% - 728px);
  }

  .gradient-text {
    background: linear-gradient(to right bottom, rgba(55, 48, 236, 0.7), rgba(169, 16, 121, 1));
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
    font-weight: 700;
  }

  .gradient-text-2 {
    background: linear-gradient(to right bottom, rgba(169, 16, 121, 1), rgba(252, 238, 33, 1));
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
    font-weight: 700;
  }

  &-firstrow {
    height: 786px;
    background-color: rgba(0, 0, 0, 0.6);

    &-col1 {
      width: 52.5%;
      display: flex;
      align-items: center;
      justify-content: end;
      padding-right: 7.45% !important;

      &-content {
        max-width: 566px;

      }
    }

    &-col2 {
      width: 47.5%;
    }
  }

  &-secondrow {
    width: 965px;
    text-align: center;
    margin-top: 155px;

    &-subtitle {
      font-weight: 400;
      color: $theme-color-white;
      margin-bottom: 155px;
    }
  }



  &-thirdrow {
    width: 1386px;
    margin-bottom: 500px;

    &-tab {
      // width: 714px !important;
      border: 0px 0px 1px 0px;
      border-color: Gray 3;
    }

    &-content {
      display: flex;
      align-items: center;

      &-box {
        width: 591px;
        height: 385px;
        background: rgba(217, 217, 217, 1);

        border-radius: 10px;
      }

      &-title {
        margin-left: 66px;

        &-tradebtn {
          background: linear-gradient(298.83deg, #A91079 2.95%, #3730EC 85.66%);
          text-wrap: nowrap;

        }

        &-tradebtn2 {
          background: linear-gradient(298.83deg, #FCEE21 2.95%, #A91079 85.66%);
          text-wrap: nowrap;


        }
      }
    }
  }

}

.slick-slide {
  padding: 10px;
}

.slick-track {
  display: flex;
  justify-content: space-around;
  align-items: center;
}
@media(min-width: 360px) and (max-width: 576px) {
 
  .marketplaceProduct-main {

    .w-mx-701 {
      max-width: 728px;
    }

    .home-w-100 {
      width: calc(100% - 728px);
    }

    .gradient-text {
      background: linear-gradient(to right bottom, rgba(55, 48, 236, 0.7), rgba(169, 16, 121, 1));
      -webkit-background-clip: text;
      background-clip: text;
      color: transparent;
      font-weight: 700;
    }

    .gradient-text-2 {
      background: linear-gradient(to right bottom, rgba(169, 16, 121, 1), rgba(252, 238, 33, 1));
      -webkit-background-clip: text;
      background-clip: text;
      color: transparent;
      font-weight: 700;
    }

    &-firstrow {
      &-col1 {
        width: 100% !important;
        display: flex;
        align-items: center;
        justify-content: end;
        padding-right: 0px !important;
      }

      &-col2 {
        width: 100%;
      }
    }

    &-secondrow {
      width: 100%;
      text-align: center;
      margin-top: 0px;
      margin-bottom: 0px !important;
      &-subtitle {
        font-weight: 400;
        color: $theme-color-white;
        margin-bottom: 155px;
      }
    }



    &-thirdrow {
      width: auto !important;
      margin-bottom: 0px;

      &-tab {
        // width: 714px !important;
        border: 0px 0px 1px 0px;
        border-color: Gray 3;
      }

      &-content {
        display: flex;
        align-items: center;

        &-box {
          width: 591px;
          height: 385px;
          background: rgba(217, 217, 217, 1);

          border-radius: 10px;
        }

        &-title {
          margin-left: 66px;

          &-tradebtn {
            background: linear-gradient(298.83deg, #A91079 2.95%, #3730EC 85.66%);
            text-wrap: nowrap;

          }

          &-tradebtn2 {
            background: linear-gradient(298.83deg, #FCEE21 2.95%, #A91079 85.66%);
            text-wrap: nowrap;


          }
        }
      }
    }

  }

  .marketplace-dw-composite-main-firstrow{
    flex-direction: column;
    height: auto !important;
  }
  .slick-slide {
    padding: 10px;
  }
  .marketplace-mobile-view-head{
    font-size: 32px;
    line-height: 35.2px;
    margin-bottom: 32px;
  }

  .slick-track {
    display: flex;
    justify-content: space-around;
    align-items: center;
  }

  .marketplace-dw-composite-main-firstrow-col2{
    width: 100% !important;
  }
  .marketplace-mobile-btn{
    height: 30px;
    font-size: 12px;
    width: 100% !important;
    border-radius: 5px;
    line-height: 19.6px;
  }
  .marketplace-tab-mobileview{
    padding: 16px 0px !important;
  }
  .marketplace-heading4{
    font-size: 14px;
    line-height: 17.6px;
    margin-bottom: 0.5rem !important;
  }
  .products-page-second-heading{
    font-size: 12px;
    line-height: 16.8px;
  }

}

@media(min-width: 576px) and (max-width: 1024px) {
 
  .marketplaceProduct-main {

    .w-mx-701 {
      max-width: 728px;
    }

    .home-w-100 {
      width: calc(100% - 728px);
    }

    .gradient-text {
      background: linear-gradient(to right bottom, rgba(55, 48, 236, 0.7), rgba(169, 16, 121, 1));
      -webkit-background-clip: text;
      background-clip: text;
      color: transparent;
      font-weight: 700;
    }

    .gradient-text-2 {
      background: linear-gradient(to right bottom, rgba(169, 16, 121, 1), rgba(252, 238, 33, 1));
      -webkit-background-clip: text;
      background-clip: text;
      color: transparent;
      font-weight: 700;
    }

    &-firstrow {
      &-col1 {
        width: 100% !important;
        display: flex;
        align-items: center;
        justify-content: start;
        padding-right: 0px !important;
      }

      &-col2 {
        width: 100%;
      }
    }

    &-secondrow {
      width: 100%;
      text-align: center;
      margin-top: 0px;

      &-subtitle {
        font-weight: 400;
        color: $theme-color-white;
        margin-bottom: 155px;
      }
    }



    &-thirdrow {
      width: auto !important;
      margin-bottom: 0px;

      &-tab {
        // width: 714px !important;
        border: 0px 0px 1px 0px;
        border-color: Gray 3;
        margin-bottom: 1rem !important;
      }

      &-content {
        display: flex;
        align-items: center;

        &-box {
          width: 591px;
          height: 385px;
          background: rgba(217, 217, 217, 1);
          border-radius: 10px;
        }

        &-title {
          margin-left: 66px;

          &-tradebtn {
            background: linear-gradient(298.83deg, #A91079 2.95%, #3730EC 85.66%);
            text-wrap: nowrap;
            margin-left: 8px !important;

          }

          &-tradebtn2 {
            background: linear-gradient(298.83deg, #FCEE21 2.95%, #A91079 85.66%);
            text-wrap: nowrap;


          }
        }
      }
    }

  }

  .marketplace-dw-composite-main-firstrow{
    flex-direction: column;
    height: auto !important;
  }
  .slick-slide {
    padding: 10px;
  }
  .marketplace-mobile-view-head{
    font-size: 32px;
    line-height: 35.2px;
    margin-bottom: 32px;
  }

  .slick-track {
    display: flex;
    justify-content: space-around;
    align-items: center;
  }

  .marketplace-dw-composite-main-firstrow-col2{
    width: 100% !important;
  }
  .marketplace-mobile-btn{
    height: 30px;
    font-size: 12px;
    width: 100% !important;
    border-radius: 5px;
    line-height: 19.6px;
  }
  .marketplace-tab-mobileview{
    padding: 16px 0px !important;
  }
  .marketplace-heading4{
    font-size: 14px;
    line-height: 17.6px;
    margin-bottom: 0.5rem !important;
  }
  .products-page-second-heading{
    font-size: 12px;
    line-height: 16.8px;
  }
  .home-tab-mobile-view{
    flex-direction: column !important;
  }
  .home-tabs-content-box{
       width: 100% !important;
  }
  .marketplaceProduct-main-firstrow-col1{
    padding: 0 0 !important;
  }
  .all-btn-space-mobile{
    margin-bottom: 1rem !important;
 }
}

@media(min-width: 320px) and (max-width: 360px) {

  .marketplaceProduct-main {

    .w-mx-701 {
      max-width: 728px;
    }

    .home-w-100 {
      width: calc(100% - 728px);
    }

    .gradient-text {
      background: linear-gradient(to right bottom, rgba(55, 48, 236, 0.7), rgba(169, 16, 121, 1));
      -webkit-background-clip: text;
      background-clip: text;
      color: transparent;
      font-weight: 700;
    }

    .gradient-text-2 {
      background: linear-gradient(to right bottom, rgba(169, 16, 121, 1), rgba(252, 238, 33, 1));
      -webkit-background-clip: text;
      background-clip: text;
      color: transparent;
      font-weight: 700;
    }

    &-firstrow {
      &-col1 {
        width: 100% !important;
        display: flex;
        align-items: center;
        justify-content: end;
        padding-right: 0px !important;
      }

      &-col2 {
        width: 100%;
      }
    }

    &-secondrow {
      width: 100%;
      text-align: center;
      margin-top: 0px;
      margin-bottom: 0px !important;

      &-subtitle {
        font-weight: 400;
        color: $theme-color-white;
        margin-bottom: 155px;
      }
    }



    &-thirdrow {
      width: auto !important;
      margin-bottom: 0px;

      &-tab {
        // width: 714px !important;
        border: 0px 0px 1px 0px;
        border-color: Gray 3;
        margin-bottom: 1rem !important;
      }

      &-content {
        display: flex;
        align-items: center;

        &-box {
          width: 591px;
          height: 385px;
          background: rgba(217, 217, 217, 1);

          border-radius: 10px;
        }

        &-title {
          margin-left: 66px;

          &-tradebtn {
            background: linear-gradient(298.83deg, #A91079 2.95%, #3730EC 85.66%);
            text-wrap: nowrap;

          }

          &-tradebtn2 {
            background: linear-gradient(298.83deg, #FCEE21 2.95%, #A91079 85.66%);
            text-wrap: nowrap;


          }
        }
      }
    }

  }

  .marketplace-dw-composite-main-firstrow{
    flex-direction: column;
    height: auto !important;
  }
  .slick-slide {
    padding: 10px;
  }
  .marketplace-mobile-view-head{
    font-size: 32px;
    line-height: 35.2px;
    margin-bottom: 32px;
  }

  .slick-track {
    display: flex;
    justify-content: space-around;
    align-items: center;
  }

  .marketplace-dw-composite-main-firstrow-col2{
    width: 100% !important;
  }
  .marketplace-mobile-btn{
    height: 30px;
    font-size: 12px;
    width: 100% !important;
    border-radius: 5px;
    line-height: 19.6px;
  }
  .marketplace-tab-mobileview{
    padding: 16px 0px !important;
  }
  .marketplace-heading4{
    font-size: 14px;
    line-height: 17.6px;
    margin-bottom: 0.5rem !important;
  }
  .products-page-second-heading{
    font-size: 12px;
    line-height: 16.8px;
  }
  .marketplaceProduct-main-firstrow-col1{
    padding: 0 0 !important;
  }
  .all-btn-space-mobile{
     margin-bottom: 1rem !important;
  }
  .marketplace-content-mbl{
    margin-bottom: 0.5rem !important;
  }
  

}